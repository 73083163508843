import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  createItemApi,
  retrieveAllItemsApi,
  retrieveItemByIdApi,
  updateItemApi,
} from "../../../../components/api/ItemApiService";
import { createOfferApi } from "../../../../components/api/OfferApiService";
import { retrieveQuantityByItemIdApi } from "../../../../components/api/QuantityApiService";
import {
  createSampleApi,
  retrievePurposeFilteredSamplesApi,
  retrieveSampleByIdApi,
  updateSampleApi,
} from "../../../../components/api/SampleApiService";
import { retrieveAllStakeholdersApi } from "../../../../components/api/StakeholderApiService";
import {
  color,
  companies,
  currencies,
  incoterms,
  leafForm,
  offerStatus,
  origin,
  packagingTypes,
  paymentTerm,
  portsOfDischarge,
  portsOfLoading,
  recordStatus,
  samplePurposes,
  sampleStatus,
  status,
  type,
  units,
} from "../../../../utils/menu-items";

const AddSingleVendorOffer = (props) => {
  useEffect(() => {
    setItem(() => ({
      ...item,
      moisture: 12.5,
    }));
    setAddViewStates(() => ({
      ...addViewStates,
      offerType: "VENDOR",
      volumeUnit: "KG",
      firstOfferPriceCurrency: "USD",
      finalOfferPriceCurrency: "USD",
      offerYear: addOfferToGroup?.data?.offerYear,
      offerGroupNumber: addOfferToGroup?.data?.offerGroupNumber,
      company: addOfferToGroup?.data?.company,
      stakeholder: addOfferToGroup?.data?.stakeholder,
      stakeholderId: addOfferToGroup?.data?.stakeholder?.id,
      paymentTerm: addOfferToGroup?.data?.paymentTerm,
      incoterm: addOfferToGroup?.data?.incoterm,
      portOfDischarge: addOfferToGroup?.data?.portOfDischarge,
      portOfLoading: addOfferToGroup?.data?.portOfLoading,
      offerDate: addOfferToGroup?.data?.offerDate,
      validityDate: addOfferToGroup?.data?.validityDate,
      offerLink: addOfferToGroup?.data?.offerLink,
      remark: addOfferToGroup?.data?.remark,
    }));
  }, []);
  const {
    setAddViewStates,
    addViewStates,
    rows,
    setRows,
    addOfferToGroup,
    setAddOfferToGroup,
  } = props;

  const portOfDischargeIncoterms = [
    "C&F",
    "CFR",
    "CIF",
    "CIP",
    "CPT",
    "DAP",
    "DDP",
    "DPU",
  ];
  const portOfLoadingIncoterms = ["EXW", "FAS", "FCA", "FOB"];
  const [quantity, setQuantity] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedFirstSample, setSelectedFirstSample] = useState(null);
  const [selectedSecondSample, setSelectedSecondSample] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [stakeholders, setStakeholders] = useState([]);
  const [item, setItem] = useState([]);
  const [isNewItemOpen, setIsNewItemOpen] = useState(false);
  const [isEditItemOpen, setIsEditItemOpen] = useState(false);
  const [isNewSampleOpen, setIsNewSampleOpen] = useState(false);
  const [isEditSampleOpen, setIsEditSampleOpen] = useState(false);
  const [sample, setSample] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(
    addOfferToGroup?.data?.paymentTerm || "",
  );
  const [selectedPortOfLoading, setSelectedPortOfLoading] = useState(
    addOfferToGroup?.data?.portOfLoading || "",
  );
  const [selectedPortOfDischarge, setSelectedPortOfDischarge] = useState(
    addOfferToGroup?.data?.portOfDischarge || "",
  );
  const [filteredSamples, setFilteredSamples] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedIndex2, setSelectedIndex2] = useState(0);
  const [selectedIndex3, setSelectedIndex3] = useState(0);
  const anchorRef = React.useRef(null);
  const anchorRef2 = React.useRef(null);
  const anchorRef3 = React.useRef(null);
  const options = ["New", "Edit"];
  const [selectedOrigin, setSelectedOrigin] = useState("");
  const [selectedVendor, setSelectedVendor] = useState(
    addOfferToGroup?.data?.stakeholder || "",
  );

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleToggle2 = () => {
    setOpen2((prevOpen) => !prevOpen);
  };

  const handleToggle3 = () => {
    setOpen3((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleClose2 = (event) => {
    if (anchorRef2.current && anchorRef2.current.contains(event.target)) {
      return;
    }

    setOpen2(false);
  };

  const handleClose3 = (event) => {
    if (anchorRef3.current && anchorRef3.current.contains(event.target)) {
      return;
    }

    setOpen3(false);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleMenuItemClick2 = (event, index) => {
    setSelectedIndex2(index);
    setOpen2(false);
  };

  const handleMenuItemClick3 = (event, index) => {
    setSelectedIndex3(index);
    setOpen3(false);
  };

  function retrieveQuantity(itemId) {
    retrieveQuantityByItemIdApi(itemId)
      .then((response) => {
        console.log("QUANTITY: ", response?.data);
        setQuantity(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveVendors() {
    retrieveAllStakeholdersApi("VENDOR")
      .then((response) => {
        setVendors(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveStakeholders() {
    Promise.all([
      retrieveAllStakeholdersApi("VENDOR"),
      retrieveAllStakeholdersApi("CUSTOMER"),
    ])
      .then(([vendorResponse, customerResponse]) => {
        setStakeholders([...vendorResponse?.data, ...customerResponse?.data]);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveFilteredSamples(samplePurpose) {
    retrievePurposeFilteredSamplesApi(samplePurpose)
      .then((response) => {
        setFilteredSamples(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveItems() {
    retrieveAllItemsApi()
      .then((response) => {
        setItems(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveSampleById(id) {
    retrieveSampleByIdApi(id)
      .then((response) => {
        setSample(response?.data);
        console.log(id, response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveItemById(id) {
    retrieveItemByIdApi(id)
      .then((response) => {
        setItem(response?.data);
        console.log(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const navigate = useNavigate();

  const handleClick = () => {
    if (selectedIndex === 0) {
      setIsNewItemOpen(true);
      setIsEditItemOpen(false);
    } else {
      setIsEditItemOpen(true);
      setIsNewItemOpen(false);
      retrieveItemById(addViewStates?.itemId);
    }
  };
  const handleClick2 = () => {
    if (selectedIndex2 === 0) {
      setIsNewSampleOpen(true);
      setIsEditSampleOpen(false);
    } else {
      setIsEditSampleOpen(true);
      setIsNewSampleOpen(false);
      retrieveSampleById(addViewStates?.firstSampleId);
    }
  };

  const handleClick3 = () => {
    if (selectedIndex3 === 0) {
      setIsNewSampleOpen(true);
      setIsEditSampleOpen(false);
    } else {
      setIsEditSampleOpen(true);
      setIsNewSampleOpen(false);
      retrieveSampleById(addViewStates?.secondSampleId);
    }
  };

  const isButtonDisabled =
    (addViewStates?.status === "First Refusal" ||
      addViewStates?.status === "Optioned") &&
    (!addViewStates?.statusDeadline ||
      addViewStates?.statusDeadline.trim() === "");

  return (
    <>
      <Stack direcion="column">
        <Typography variant="h4" mb={2}>
          Add New Vendor Offer To Group {addViewStates?.offerYear}-
          {addViewStates?.offerGroupNumber}
        </Typography>
        <Grid container item xs={12} spacing="8px">
          <Grid item xs={2} sm={1}>
            <Typography variant="body2">Offer Year</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              defaultValue={addOfferToGroup?.data?.offerYear}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  offerYear: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={10} sm={1}>
            <Typography variant="body2">Offer Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              defaultValue={addOfferToGroup?.data?.offerGroupNumber}
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  offerGroupNumber: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select An Item</Typography>
            <Grid container xs={12} spacing="8px">
              <Grid item xs={12} sm={8}>
                <Autocomplete
                  options={items}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - Buying Grade: ${option?.buyingGrade || ""
                    } - ${option?.origin || ""} - ${option?.crop || ""}`
                  }
                  value={selectedItem}
                  onChange={(event, newValue) => {
                    setSelectedItem(newValue);
                    setAddViewStates({
                      ...addViewStates,
                      itemId: newValue ? newValue.id : null,
                      itemStorage: newValue?.storage || "",
                      salesGrade: newValue?.salesGrade || "",
                    });
                    retrieveQuantity(newValue?.id);
                  }}
                  onOpen={retrieveItems}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
                {selectedItem && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${selectedItem?.id || ""}`}
                      style={{ marginBottom: "5px" }}
                      onDelete={() => setSelectedItem(null)}
                    />
                    <br></br>
                    <Chip
                      label={`Origin: ${selectedItem?.origin || ""}`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br></br>
                    <Chip
                      label={`Type: ${selectedItem?.type || ""}`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br></br>
                    <Chip
                      label={`Buying Grade: ${selectedItem?.buyingGrade || ""}`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br></br>
                    <Chip
                      label={`Sales Grade: ${selectedItem?.salesGrade || ""}`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br></br>
                    <Chip
                      label={`Crop: ${selectedItem?.crop || ""}`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br></br>
                    <Chip
                      label={`Storage: ${selectedItem?.storage || ""}`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br></br>
                    <Chip
                      label={`Quantity: ${quantity?.quantityRemainingInNetKg || ""
                        } KG`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br></br>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <ButtonGroup
                  variant="outlined"
                  ref={anchorRef}
                  aria-label="Button group with a nested menu"
                >
                  <Button onClick={handleClick}>
                    {options[selectedIndex]}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    sx={{
                      width: "20px !important",
                      minWidth: "0px !important",
                    }}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                disabled={index === 2}
                                selected={index === selectedIndex}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Item Current Location</Typography>
            <TextField
              variant="outlined"
              size="small"
              value={addViewStates?.itemStorage || ""}
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  itemStorage: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Buying Company</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              defaultValue={addOfferToGroup?.data?.company}
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  company: e.target.value,
                })
              }
            >
              {companies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select SG Approval Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  approvalStatus: e.target.value,
                })
              }
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              onChange={(e) => {
                const newStatus = e.target.value;
                let newStatusDeadline = addViewStates?.statusDeadline;
                if (newStatus !== "First Refusal" || newStatus !== "Optioned") {
                  newStatusDeadline = null;
                }

                setAddViewStates({
                  ...addViewStates,
                  status: newStatus,
                  statusDeadline: newStatusDeadline,
                });
              }}
            >
              {offerStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Status Deadline</Typography>
            <div
              style={{
                borderColor:
                  (addViewStates?.statusDeadline === null ||
                    addViewStates?.statusDeadline === "") &&
                    (addViewStates?.status === "First Refusal" ||
                      addViewStates?.status === "Optioned")
                    ? "red"
                    : "inherit",
                borderWidth:
                  (addViewStates?.statusDeadline === null ||
                    addViewStates?.statusDeadline === "") &&
                    (addViewStates?.status === "First Refusal" ||
                      addViewStates?.status === "Optioned")
                    ? "1px"
                    : "inherit",
                borderStyle:
                  (addViewStates?.statusDeadline === null ||
                    addViewStates?.statusDeadline === "") &&
                    (addViewStates?.status === "First Refusal" ||
                      addViewStates?.status === "Optioned")
                    ? "solid"
                    : "inherit",
              }}
            >
              <TextField
                size="small"
                fullWidth
                disabled={
                  addViewStates?.status !== "First Refusal" &&
                  addViewStates?.status !== "Optioned"
                }
                variant="outlined"
                id="date"
                type="date"
                inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setAddViewStates({
                    ...addViewStates,
                    statusDeadline: e.target.value,
                  });
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Vendor</Typography>
            <Autocomplete
              options={vendors}
              getOptionLabel={(option) =>
                `${option?.id || ""} - ${option?.name || ""}`
              }
              value={selectedVendor}
              onChange={(event, newValue) => {
                setSelectedVendor(newValue);
                setAddViewStates({
                  ...addViewStates,
                  stakeholderId: newValue ? newValue.id : null,
                });
              }}
              onOpen={retrieveVendors}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedVendor && (
              <Box mt={2}>
                <Chip
                  label={`${selectedVendor?.id || ""} - ${selectedVendor?.name || ""
                    }`}
                  onDelete={() => setSelectedVendor(null)}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter First Offer Price</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  firstOfferPrice: e.target.value,
                  finalOfferPrice: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select First Offer Currency</Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              defaultValue="USD"
              fullWidth
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  firstOfferPriceCurrency: e.target.value,
                  finalOfferPriceCurrency: e.target.value,
                });
              }}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Final Offer Price</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.finalOfferPrice || ""}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  finalOfferPrice: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Final Offer Currency</Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              fullWidth
              defaultValue="USD"
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  finalOfferPriceCurrency: e.target.value,
                });
              }}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Offer Sample</Typography>
            <Grid container xs={12} spacing="8px">
              <Grid item xs={12} sm={8}>
                <Autocomplete
                  options={filteredSamples}
                  getOptionLabel={(option) =>
                    `ID: ${option?.id || ""}, Remark: ${option?.remark || ""
                    }, Buying Grade: ${option?.item?.buyingGrade || ""}`
                  }
                  value={selectedFirstSample}
                  onChange={(event, newValue) => {
                    setSelectedFirstSample(newValue);
                    setAddViewStates({
                      ...addViewStates,
                      firstSampleId: newValue ? newValue.id : null,
                    });
                  }}
                  onOpen={() => retrieveFilteredSamples("Offer Sample")}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
                {selectedFirstSample && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${selectedFirstSample?.id || ""}, Remark: ${selectedFirstSample?.remark || ""
                        }, Buying Grade: ${selectedFirstSample?.item?.buyingGrade || ""
                        }`}
                      onDelete={() => setSelectedFirstSample(null)}
                    />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                <ButtonGroup
                  variant="outlined"
                  ref={anchorRef2}
                  aria-label="Button group with a nested menu"
                >
                  <Button onClick={handleClick2}>
                    {options[selectedIndex2]}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={open2 ? "split-button-menu" : undefined}
                    aria-expanded={open2 ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle2}
                    sx={{
                      width: "20px !important",
                      minWidth: "0px !important",
                    }}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={open2}
                  anchorEl={anchorRef2.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose2}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                disabled={index === 2}
                                selected={index === selectedIndex2}
                                onClick={(event) =>
                                  handleMenuItemClick2(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Offer Sample Status</Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  firstSampleStatus: e.target.value,
                });
              }}
            >
              {sampleStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Trial Sample</Typography>
            <Grid container xs={12} spacing="8px">
              <Grid item xs={12} sm={8}>
                <Autocomplete
                  options={filteredSamples}
                  getOptionLabel={(option) =>
                    `ID: ${option?.id || ""}, Remark: ${option?.remark || ""
                    }, Buying Grade: ${option?.item?.buyingGrade || ""}`
                  }
                  value={selectedSecondSample}
                  onChange={(event, newValue) => {
                    setSelectedSecondSample(newValue);
                    setAddViewStates({
                      ...addViewStates,
                      secondSampleId: newValue ? newValue.id : null,
                    });
                  }}
                  onOpen={() => retrieveFilteredSamples("Trial Sample")}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
                {selectedSecondSample && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${selectedSecondSample?.id || ""}, Remark: ${selectedSecondSample?.remark || ""
                        }, Buying Grade: ${selectedSecondSample?.item?.buyingGrade || ""
                        }`}
                      onDelete={() => setSelectedSecondSample(null)}
                    />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <ButtonGroup
                  variant="outlined"
                  ref={anchorRef3}
                  aria-label="Button group with a nested menu"
                >
                  <Button onClick={handleClick3}>
                    {options[selectedIndex3]}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={open3 ? "split-button-menu" : undefined}
                    aria-expanded={open3 ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle3}
                    sx={{
                      width: "20px !important",
                      minWidth: "0px !important",
                    }}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={open3}
                  anchorEl={anchorRef3.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose3}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                disabled={index === 2}
                                selected={index === selectedIndex3}
                                onClick={(event) =>
                                  handleMenuItemClick3(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Trial Sample Status</Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  secondSampleStatus: e.target.value,
                });
              }}
            >
              {sampleStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Packaging</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  packaging: e.target.value,
                });
              }}
            >
              {packagingTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Payment Term</Typography>
            <Autocomplete
              options={paymentTerm}
              //getOptionLabel={(option) => option ? option?.label : ''}
              value={selectedPaymentTerm}
              onChange={(event, newValue) => {
                const newTerm = newValue ? newValue.value : "";
                setSelectedPaymentTerm(newTerm);
                setAddViewStates({
                  ...addViewStates,
                  paymentTerm: newTerm,
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedPaymentTerm && (
              <Box mt={2}>
                <Chip
                  label={
                    paymentTerm.find(
                      (term) => term.value === selectedPaymentTerm,
                    )?.label || ""
                  }
                  onDelete={() => setSelectedPaymentTerm("")}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Incoterm</Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              fullWidth
              defaultValue={addOfferToGroup?.data?.incoterm}
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  incoterm: e.target.value,
                });
              }}
            >
              {incoterms.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Port Of Discharge</Typography>
            <Autocomplete
              options={portsOfDischarge}
              value={selectedPortOfDischarge}
              onChange={(event, newValue) => {
                const newTerm = newValue ? newValue.value : "";
                setSelectedPortOfDischarge(newTerm);
                setAddViewStates({
                  ...addViewStates,
                  portOfDischarge: newTerm,
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  style={{
                    border:
                      portOfDischargeIncoterms.includes(
                        addViewStates?.incoterm,
                      ) &&
                        (addViewStates.portOfDischarge == null ||
                          addViewStates?.portOfDischarge === "")
                        ? "3px solid red"
                        : "none",
                    borderRadius:
                      portOfDischargeIncoterms.includes(
                        addViewStates?.incoterm,
                      ) &&
                        (addViewStates.portOfDischarge == null ||
                          addViewStates?.portOfDischarge === "")
                        ? "5px"
                        : "none",
                  }}
                />
              )}
              renderTags={() => null}
            />
            {selectedPortOfDischarge && (
              <Box mt={2}>
                <Chip
                  label={
                    portsOfDischarge.find(
                      (term) => term.value === selectedPortOfDischarge,
                    )?.label || ""
                  }
                  onDelete={() => setSelectedPortOfDischarge("")}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Port Of Loading</Typography>
            <Autocomplete
              options={portsOfLoading}
              value={selectedPortOfLoading}
              onChange={(event, newValue) => {
                const newTerm = newValue ? newValue.value : "";
                setSelectedPortOfLoading(newTerm);
                setAddViewStates({
                  ...addViewStates,
                  portOfLoading: newTerm,
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  style={{
                    border:
                      portOfLoadingIncoterms.includes(
                        addViewStates?.incoterm,
                      ) &&
                        (addViewStates.portOfLoading == null ||
                          addViewStates?.portOfLoading === "")
                        ? "3px solid red"
                        : "none",
                    borderRadius:
                      portOfLoadingIncoterms.includes(
                        addViewStates?.incoterm,
                      ) &&
                        (addViewStates.portOfLoading == null ||
                          addViewStates?.portOfLoading === "")
                        ? "5px"
                        : "none",
                  }}
                />
              )}
              renderTags={() => null}
            />
            {selectedPortOfLoading && (
              <Box mt={2}>
                <Chip
                  label={
                    portsOfLoading.find(
                      (term) => term.value === selectedPortOfLoading,
                    )?.label || ""
                  }
                  onDelete={() => setSelectedPortOfLoading("")}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Number Of Cartons</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  numberOfCases: e.target.value,
                  volume: e.target.value * addViewStates?.netWeightPerCase,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Net Weight Per Carton</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  netWeightPerCase: e.target.value,
                  volume: e.target.value * addViewStates?.numberOfCases,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Volume</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={addViewStates?.volume}
              fullWidth
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  volume: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Volume Unit</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              defaultValue={"KG"}
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  volumeUnit: e.target.value,
                });
              }}
            >
              {units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Offer Date</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              value={
                addViewStates?.offerDate
                  ? format(parseISO(addViewStates?.offerDate), "yyyy-MM-dd")
                  : ""
              }
              inputProps={{ min: "1999-01-01", max: new Date().toISOString().split("T")[0], }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  offerDate: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Validity Date</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              value={
                addViewStates?.validityDate
                  ? format(parseISO(addViewStates?.validityDate), "yyyy-MM-dd")
                  : ""
              }
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  validityDate: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.remark}
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  remark: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Comment</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  comment: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Enter Offer Link</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.offerLink}
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  offerLink: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Record Status</Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              fullWidth
              label={"ACTIVE"}
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  recordStatus: e.target.value,
                });
              }}
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Edit Status</Typography>
            <TextField
              id="outlined-select-status"
              select
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => {
                setAddViewStates({
                  ...addViewStates,
                  editingPermission: e.target.value,
                });
              }}
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() => {
              setAddOfferToGroup(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={isButtonDisabled}
            onClick={() => {
              const newId = Math.floor(Math.random() * 100000);
              setAddViewStates({ ...addViewStates, id: newId });
              setRows([addViewStates, ...rows]);
              setAddOfferToGroup(false);

              createOfferApi(addViewStates)
                .then((response) => {
                  toast.success("Vendor offer added successfully.");
                  window.location.reload();
                  navigate("/vendor-offer-view");
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Add
          </Button>
          {isButtonDisabled && (
            <Typography style={{ color: "red", marginTop: "10px" }}>
              Please be sure all dates for 'First Refusal' and 'Optioned' status
              are selected.
            </Typography>
          )}
        </Stack>
        <br></br>
        {isNewItemOpen && (
          <>
            {" "}
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                New Item Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "30px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid container xs={12} spacing="8px">
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Tobacco Code</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        tobaccoCode: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        salesGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Buying Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        buyingGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Internal Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        internalGrade: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Select Origin</Typography>
                  <Autocomplete
                    options={origin}
                    value={selectedOrigin}
                    onChange={(event, newValue) => {
                      const newTerm = newValue ? newValue.value : "";
                      setSelectedOrigin(newTerm);
                      setItem({
                        ...item,
                        origin: newTerm,
                      });
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />
                  {selectedOrigin && (
                    <Box mt={2}>
                      <Chip
                        label={
                          origin.find((term) => term.value === selectedOrigin)
                            ?.label || ""
                        }
                        onDelete={() => setSelectedOrigin("")}
                      />
                    </Box>
                  )}
                </Grid>
                {selectedOrigin === "China" && (
                  <Grid item xs={12} sm={2}>
                    <Typography variant="body2">Province</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) =>
                        setItem({
                          ...item,
                          province: e.target.value,
                        })
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Select Type</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-type"
                    select
                    onChange={(e) =>
                      setItem({
                        ...item,
                        type: e.target.value,
                      })
                    }
                  >
                    {type.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Select Leaf Form</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-leaf-form"
                    select
                    onChange={(e) =>
                      setItem({ ...item, leafForm: e.target.value })
                    }
                  >
                    {leafForm.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Select Color</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-color"
                    select
                    onChange={(e) =>
                      setItem({ ...item, color: e.target.value })
                    }
                  >
                    {color.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Crop Year</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        crop: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Current Location</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-storage"
                    value={item?.storage}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        storage: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Nicotine Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        nicotine: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sugar Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        sugar: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Moisture Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={item?.moisture}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        moisture: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Status</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-status"
                    select
                    onChange={(e) =>
                      setItem({
                        ...item,
                        status: e.target.value,
                      })
                    }
                  >
                    {recordStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1.5, mt: 4, mr: 1 }}
                  onClick={() => {
                    setIsNewItemOpen(false);
                    setItem([]);
                    setSelectedOrigin("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 4 }}
                  onClick={() => {
                    createItemApi(item)
                      .then((response) => {
                        toast.success("Item added successfully.");
                      })
                      .catch((error) => {
                        console.log(error.response.data);
                        toast.error("Something Went Wrong.");
                      });
                    setIsNewItemOpen(false);
                    setItem([]);
                    setSelectedOrigin("");
                  }}
                >
                  Create Item
                </Button>
              </Grid>
            </Grid>{" "}
          </>
        )}
        {isEditItemOpen && (
          <>
            {" "}
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                Edit Item {item?.id} Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "30px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid container xs={12} spacing="8px">
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Tobacco Code</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.tobaccoCode || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        tobaccoCode: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.salesGrade || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        salesGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Buying Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.buyingGrade || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        buyingGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Internal Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.internalGrade || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        internalGrade: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Select Origin</Typography>
                  <Autocomplete
                    options={origin}
                    value={selectedOrigin || ""}
                    onChange={(event, newValue) => {
                      const newTerm = newValue ? newValue.value : "";
                      setSelectedOrigin(newTerm);
                      setItem({
                        ...item,
                        origin: newTerm,
                      });
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        label={item?.origin}
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />
                  {selectedOrigin && (
                    <Box mt={2}>
                      <Chip
                        label={
                          origin.find((term) => term.value === selectedOrigin)
                            ?.label || ""
                        }
                        onDelete={() => setSelectedOrigin("")}
                      />
                    </Box>
                  )}
                </Grid>

                {(selectedOrigin === "China" || item?.origin === "China") && (
                  <Grid item xs={12} sm={2}>
                    <Typography variant="body2">Province</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={item?.province || ""}
                      onChange={(e) =>
                        setItem({
                          ...item,
                          province: e.target.value,
                        })
                      }
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Select Type</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={item?.type || ""}
                    id="outlined-select-type"
                    select
                    onChange={(e) =>
                      setItem({
                        ...item,
                        type: e.target.value,
                      })
                    }
                  >
                    {type.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Select Leaf Form</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={item?.leafForm || ""}
                    id="outlined-select-leaf-form"
                    select
                    onChange={(e) =>
                      setItem({ ...item, leafForm: e.target.value })
                    }
                  >
                    {leafForm.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Select Color</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={item?.color || ""}
                    id="outlined-select-color"
                    select
                    onChange={(e) =>
                      setItem({ ...item, color: e.target.value })
                    }
                  >
                    {color.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Crop Year</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    value={item?.crop || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        crop: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Current Location</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-storage"
                    value={item?.storage || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        storage: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Nicotine Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    value={item?.nicotine || ""}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        nicotine: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sugar Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    value={item?.sugar || ""}
                    variant="outlined"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        sugar: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Moisture Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    value={item?.moisture || ""}
                    variant="outlined"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        moisture: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Status</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={item?.status}
                    id="outlined-select-status"
                    select
                    onChange={(e) =>
                      setItem({
                        ...item,
                        status: e.target.value,
                      })
                    }
                  >
                    {recordStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1.5, mt: 4, mr: 1 }}
                  onClick={() => {
                    setIsEditItemOpen(false);
                    setItem([]);
                    setSelectedOrigin("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 4 }}
                  onClick={() => {
                    updateItemApi(item.id, item)
                      .then((response) => {
                        toast.success("Item edited successfully.");
                      })
                      .catch((error) => {
                        toast.error("Something went wrong.");
                        console.log(error.response.data);
                      });
                    setIsEditItemOpen(false);
                    setItem([]);
                    setSelectedOrigin("");
                  }}
                >
                  Save Item
                </Button>
              </Grid>
            </Grid>{" "}
          </>
        )}
        {isNewSampleOpen && (
          <>
            {" "}
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                New Sample Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "30px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid container xs={12} spacing="8px">
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sample Purpose</Typography>
                  <TextField
                    id="outlined-select-status"
                    select
                    variant="outlined"
                    size="small"
                    error={!sample?.purpose}
                    helperText={!sample?.purpose ? "Sample purpose is required" : ""}
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        purpose: e.target.value,
                      })
                    }
                  >
                    {samplePurposes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Item</Typography>
                  <Autocomplete
                    options={items}
                    getOptionLabel={(option) =>
                      `ID: ${option.id || ""} - Buying Grade: ${option.buyingGrade || ""
                      } - Sales Grade: ${option.salesGrade || ""} - ${option?.origin || ""} - Crop: ${option.crop || ""
                      }`
                    }
                    value={sample?.itemId}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        itemId: newValue ? newValue.id : null,
                      });
                    }}
                    onOpen={retrieveItems}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />
                  {sample?.itemId && (
                    <Box mt={2}>
                      <Chip
                        label={`ID: ${items.find((item) => item.id === sample?.itemId)
                            ?.id || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                        onDelete={() =>
                          setSample({
                            ...sample,
                            itemId: null,
                          })
                        }
                      />
                      <br />
                      <Chip
                        label={`Buying Grade: ${items.find((item) => item.id === sample?.itemId)
                            ?.buyingGrade || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Sales Grade: ${items.find((item) => item.id === sample?.itemId)
                            ?.salesGrade || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Crop: ${items.find((item) => item.id === sample?.itemId)
                            ?.crop || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sample Image Link</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        image: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sender</Typography>
                  <Autocomplete
                    options={stakeholders}
                    getOptionLabel={(option) =>
                      `${option.id || ""} - ${option.type || ""} - ${option.name || ""
                      }`
                    }
                    value={sample?.senderId}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        senderId: newValue ? newValue.id : null,
                      });
                    }}
                    onOpen={retrieveStakeholders}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />
                  {sample?.senderId && (
                    <Box mt={2}>
                      <Chip
                        label={`ID: ${stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.senderId,
                        )?.id || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                        onDelete={() =>
                          setSample({
                            ...sample,
                            senderId: null,
                          })
                        }
                      />
                      <br />
                      <Chip
                        label={`Type: ${stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.senderId,
                        )?.type || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Name: ${stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.senderId,
                        )?.name || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Receiver</Typography>
                  <Autocomplete
                    options={stakeholders}
                    getOptionLabel={(option) =>
                      `${option.id || ""} - ${option.type || ""} - ${option.name || ""
                      }`
                    }
                    value={sample?.receiverId}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        receiverId: newValue ? newValue.id : null,
                      });
                    }}
                    onOpen={retrieveStakeholders}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />
                  {sample?.receiverId && (
                    <Box mt={2}>
                      <Chip
                        label={`ID: ${stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.receiverId,
                        )?.id || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                        onDelete={() =>
                          setSample({
                            ...sample,
                            receiverId: null,
                          })
                        }
                      />
                      <br />
                      <Chip
                        label={`Type: ${stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.receiverId,
                        )?.type || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Name: ${stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.receiverId,
                        )?.name || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Number Of Samples</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                  onWheel={(e) => e.target.blur()}
                    fullWidth
                    value={sample?.numberOfSamples || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        numberOfSamples: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Net Weight Per Sample</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                  onWheel={(e) => e.target.blur()}
                    fullWidth
                    value={sample?.netWeightPerSample || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        netWeightPerSample: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Nicotine</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesNicotine: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Sugar</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesSugar: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Moisture</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesMoisture: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Origin</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesOrigin: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Crop</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesCrop: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">
                    Enter Date Of Delivery
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="date"
                    type="date"
                    inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        deliveryDate: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Date Of Receive</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="date"
                    type="date"
                    inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        receiveDate: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">
                    Enter Courier Information
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        courier: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter AWB Number</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        awbNumber: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Remark</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        remark: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">
                    Enter Customer Feedback
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        customerFeedback: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Is Sample Approved</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        approved: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">
                    Enter SG Approval Status
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        approvalStatus: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sample Paid By</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        paidBy: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Status</Typography>
                  <TextField
                    id="outlined-select-status"
                    select
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        status: e.target.value,
                      })
                    }
                  >
                    {recordStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1.5, mt: 4, mr: 1 }}
                  onClick={() => {
                    setIsNewSampleOpen(false);
                    setSample([]);
                    setSelectedOrigin("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 4 }}
                  onClick={() => {
                    createSampleApi(sample)
                      .then((response) => {
                        toast.success("Sample added successfully.");
                      })
                      .catch((error) => {
                        console.log(error.response.data);
                        toast.error("Something Went Wrong.");
                      });
                    setIsNewSampleOpen(false);
                    setSample([]);
                    setSelectedOrigin("");
                  }}
                >
                  Create Sample
                </Button>
              </Grid>
            </Grid>{" "}
          </>
        )}
        {isEditSampleOpen && (
          <>
            {" "}
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                Edit Sample {sample?.id} Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "30px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid container xs={12} spacing="8px">
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Sample Purpose</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={!sample?.purpose}
                    helperText={!sample?.purpose ? "Sample purpose is required" : ""}
                    id="outlined-select-purpose"
                    select
                    value={sample?.purpose || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        purpose: e.target.value,
                      })
                    }
                  >
                    {samplePurposes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Item</Typography>
                  <Autocomplete
                    options={items}
                    getOptionLabel={(option) =>
                      `ID: ${option.id || ""} - Buying Grade: ${option.buyingGrade || ""
                      } - Sales Grade: ${option.salesGrade || ""} - ${option?.origin || ""} - Crop: ${option.crop || ""
                      }`
                    }
                    value={sample?.item?.id}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        item: {
                          ...sample?.item,
                          id: newValue ? newValue.id : null,
                        },
                      });
                    }}
                    onOpen={retrieveItems}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                        label={
                          (sample?.item?.id || "") +
                          " " +
                          (sample?.item?.buyingGrade || "") +
                          " " +
                          (sample?.item?.salesGrade || "")
                        }
                      />
                    )}
                    renderTags={() => null}
                  />
                  {sample?.item?.id && (
                    <Box mt={2}>
                      <Chip
                        label={`ID: ${items.find((item) => item.id === sample?.item.id)
                            ?.id || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                        onDelete={() =>
                          setSample({
                            ...sample,
                            item: {
                              ...sample?.item,
                              id: null,
                            },
                          })
                        }
                      />
                      <br />
                      <Chip
                        label={`Buying Grade: ${items.find((item) => item.id === sample?.item.id)
                            ?.buyingGrade || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Sales Grade: ${items.find((item) => item.id === sample?.item.id)
                            ?.salesGrade || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Crop: ${items.find((item) => item.id === sample?.item.id)
                            ?.crop || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Image URL</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.image || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        image: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sender</Typography>
                  <Autocomplete
                    options={stakeholders}
                    getOptionLabel={(option) =>
                      `${option.id || ""} - ${option.type || ""} - ${option.name || ""
                      }`
                    }
                    value={sample?.sender?.id}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        sender: {
                          ...sample?.sender,
                          id: newValue ? newValue.id : null,
                        },
                      });
                    }}
                    onOpen={retrieveStakeholders}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                        label={
                          (sample?.sender?.id || "") +
                          " " +
                          (sample?.sender?.name || "")
                        }
                      />
                    )}
                    renderTags={() => null}
                  />
                  {sample?.sender?.id && (
                    <Box mt={2}>
                      <Chip
                        label={`ID: ${stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.sender.id,
                        )?.id || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                        onDelete={() =>
                          setSample({
                            ...sample,
                            sender: {
                              ...sample?.sender,
                              id: null,
                            },
                          })
                        }
                      />
                      <br />
                      <Chip
                        label={`Type: ${stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.sender.id,
                        )?.type || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Name: ${stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.sender.id,
                        )?.name || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Receiver</Typography>
                  <Autocomplete
                    options={stakeholders}
                    getOptionLabel={(option) =>
                      `${option.id || ""} - ${option.type || ""} - ${option.name || ""
                      }`
                    }
                    value={sample?.receiver?.id}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        receiver: {
                          ...sample?.receiver,
                          id: newValue ? newValue.id : null,
                        },
                      });
                    }}
                    onOpen={retrieveStakeholders}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                        label={
                          (sample?.receiver?.id || "") +
                          " " +
                          (sample?.receiver?.name || "")
                        }
                      />
                    )}
                    renderTags={() => null}
                  />
                  {sample?.receiver?.id && (
                    <Box mt={2}>
                      <Chip
                        label={`ID: ${stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.receiver.id,
                        )?.id || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                        onDelete={() =>
                          setSample({
                            ...sample,
                            receiver: {
                              ...sample?.receiver,
                              id: null,
                            },
                          })
                        }
                      />
                      <br />
                      <Chip
                        label={`Type: ${stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.receiver.id,
                        )?.type || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                      <Chip
                        label={`Name: ${stakeholders.find(
                          (stakeholder) =>
                            stakeholder.id === sample?.receiver.id,
                        )?.name || ""
                          }`}
                        style={{ marginBottom: "5px" }}
                      />
                      <br />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Number Of Samples</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                  onWheel={(e) => e.target.blur()}
                    fullWidth
                    value={sample?.numberOfSamples || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        numberOfSamples: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Net Weight Per Sample</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                  onWheel={(e) => e.target.blur()}
                    fullWidth
                    value={sample?.netWeightPerSample || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        netWeightPerSample: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.salesGrade || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Nicotine</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={sample?.salesNicotine || ""}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesNicotine: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Sugar</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    value={sample?.salesSugar || ""}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesSugar: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Moisture</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    value={sample?.salesMoisture || ""}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesMoisture: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Origin</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.salesOrigin || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesOrigin: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Crop</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={sample?.salesCrop || ""}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesCrop: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Date Of Delivery</Typography>
                  <TextField
                    id="date"
                    type="date"
                    inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={
                      sample?.deliveryDate
                        ? format(parseISO(sample?.deliveryDate), "yyyy-MM-dd")
                        : ""
                    }
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        deliveryDate: e.target.value
                          ? e.target.value + "T00:00:00.000+00:00"
                          : null,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Date Of Receive</Typography>
                  <TextField
                    id="date"
                    type="date"
                    inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={
                      sample?.receiveDate
                        ? format(parseISO(sample?.receiveDate), "yyyy-MM-dd")
                        : ""
                    }
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        receiveDate: e.target.value
                          ? e.target.value + "T00:00:00.000+00:00"
                          : null,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Courier</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.courier || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        courier: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">AWB Number</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.awbNumber || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        awbNumber: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Remark</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.remark || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        remark: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Customer Feedback</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.customerFeedback || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        customerFeedback: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Approved</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.approved || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        approved: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Approval Details</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.approvalDetails || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        approvalDetails: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Paid By</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.paidBy || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        paidBy: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Status</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-status"
                    select
                    value={sample?.status || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        status: e.target.value,
                      })
                    }
                  >
                    {recordStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1.5, mt: 4, mr: 1 }}
                  onClick={() => {
                    setIsEditSampleOpen(false);
                    setSample([]);
                    setSelectedOrigin("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 4 }}
                  onClick={() => {
                    updateSampleApi(sample?.id, sample)
                      .then((response) => {
                        toast.success("Sample edited successfully.");
                      })
                      .catch((error) => {
                        toast.error("Something went wrong.");
                        console.log(error.response.data);
                      });
                    setIsEditSampleOpen(false);
                    setSample([]);
                    setSelectedOrigin("");
                  }}
                >
                  Save Sample
                </Button>
              </Grid>
            </Grid>{" "}
          </>
        )}
      </Stack>
    </>
  );
};

export default AddSingleVendorOffer;
