import { ReloadOutlined } from "@ant-design/icons";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import {
  createCostApi,
  retrieveAllCostsApi,
  retrieveCostByIdApi,
  updateCostApi,
} from "../../../components/api/CostApiService";
import {
  createItemApi,
  retrieveAllItemsApi,
  retrieveItemByIdApi,
  updateItemApi,
} from "../../../components/api/ItemApiService";
import { releaseLockApi } from "../../../components/api/LockApiService";
import { retrieveAllOffersApi, retrieveFilteredOffersApi, retrieveOfferByIdApi, updateOfferApi } from "../../../components/api/OfferApiService";
import {
  createSampleApi,
  retrievePurposeFilteredSamplesApi,
  retrieveSampleByIdApi,
  updateSampleApi,
} from "../../../components/api/SampleApiService";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import {
  color,
  companies,
  currencies,
  incoterms,
  leafForm,
  offerStatus,
  origin,
  packagingTypes,
  paymentTerm,
  recordStatus,
  sampleStatus,
  status,
  type,
  units,
  samplePurposes,
  portsOfDischarge,
  portsOfLoading,
} from "../../../utils/menu-items";
import { retrieveQuantityByItemIdApi } from "../../../components/api/QuantityApiService";
import CostDialog from "./Dialogs/CostDialog";

const options = ["New", "Edit"];

var curr = new Date();
curr.setDate(curr.getDate());

const EditCustomerOffer = (props) => {
  const {rows, setRows, editCustomerOffer, setEditCustomerOffer } = props;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const anchorRef = React.useRef(null);
  const anchorRef2 = React.useRef(null);
  const anchorRef3 = React.useRef(null);
  const anchorRef4 = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedIndex2, setSelectedIndex2] = useState(0);
  const [selectedIndex3, setSelectedIndex3] = useState(0);
  const [selectedIndex4, setSelectedIndex4] = useState(0);
  const [filteredSamples, setFilteredSamples] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [costDialogOpen, setCostDialogOpen] = useState(false);
  const [costDialogType, setCostDialogType] = useState("new");
  const [costDialogData, setCostDialogData] = useState({});

  useEffect(() => {
    setItem({
      ...item,
      moisture: 12.5,
    });
    setCost({
      ...cost,
      basePrice: editCustomerOffer?.data?.buyingPrice,
      basePriceCurrency: "USD",
      freightCurrency: "USD",
      insuranceCurrency: "USD",
      storageCostCurrency: "USD",
      financeCostCurrency: "USD",
      othersCurrency: "USD",
      firstCommisionCurrency: "USD",
      secondCommisionCurrency: "USD",
      thirdCommisionCurrency: "USD",
      profitCurrency: "USD",
    });
    if (editCustomerOffer?.data?.referenceVendorOfferId) {
      retrieveVendorOffer(editCustomerOffer.data.referenceVendorOfferId)
        .then((offer) => {
          if (offer) {
            setSelectedVendorOffer(offer);
          }
        });
    }
  }, []);

  const handleClick = () => {
    setItem([]);
    if (selectedIndex === 0) {
      setIsNewItemOpen(true);
      setIsEditItemOpen(false);
    } else {
      setSelectedOrigin("");
      retrieveItemById(editCustomerOffer?.data?.item?.id);
      setIsEditItemOpen(true);
      setIsNewItemOpen(false);
    }
  };
  const handleClick2 = () => {
    setCost([]);
    if (selectedIndex2 === 0) {
      setCost({
        ...cost,
        basePrice: editCustomerOffer?.data?.buyingPrice,
        basePriceCurrency: editCustomerOffer?.data?.buyingPriceCurrency,
      });
      setIsEditCostOpen(false);
      setIsNewCostOpen(true);
      setCostDialogType("new");
      setCostDialogData(cost);
      setCostDialogOpen(true);
    } else {
      setIsNewCostOpen(false);
      setIsEditCostOpen(true);
      retrieveCostById(editCustomerOffer?.data?.estimatedCost?.id);
      setCostDialogType("edit");
      setCostDialogData(selectedCost);
      setCostDialogOpen(true);
    }
  };
  const handleClick3 = () => {
    if (selectedIndex3 === 0) {
      setIsNewSampleOpen(true);
      setIsEditSampleOpen(false);
      setSample([]);
    } else {
      setSample([]);
      setIsEditSampleOpen(true);
      setIsNewSampleOpen(false);
      retrieveSampleById(editCustomerOffer?.data?.firstSample?.id);
    }
  };
  const handleClick4 = () => {
    if (selectedIndex4 === 0) {
      setIsNewSampleOpen(true);
      setIsEditSampleOpen(false);
      setSample([]);
    } else {
      setSample([]);
      setIsEditSampleOpen(true);
      setIsNewSampleOpen(false);
      retrieveSampleById(editCustomerOffer?.data?.secondSample?.id);
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };
  const handleMenuItemClick2 = (event, index) => {
    setSelectedIndex2(index);
    setOpen2(false);
  };
  const handleMenuItemClick3 = (event, index) => {
    setSelectedIndex3(index);
    setOpen3(false);
  };
  const handleMenuItemClick4 = (event, index) => {
    setSelectedIndex4(index);
    setOpen4(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleToggle2 = () => {
    setOpen2((prevOpen) => !prevOpen);
  };
  const handleToggle3 = () => {
    setOpen3((prevOpen) => !prevOpen);
  };
  const handleToggle4 = () => {
    setOpen4((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleClose2 = (event) => {
    if (anchorRef2.current && anchorRef2.current.contains(event.target)) {
      return;
    }

    setOpen2(false);
  };
  const handleClose3 = (event) => {
    if (anchorRef3.current && anchorRef3.current.contains(event.target)) {
      return;
    }

    setOpen3(false);
  };
  const handleClose4 = (event) => {
    if (anchorRef4.current && anchorRef4.current.contains(event.target)) {
      return;
    }

    setOpen4(false);
  };

  function releaseLock(id) {
    // Return the promise from releaseLockApi
    return releaseLockApi("EDITCUSTOMEROFFER", id)
      .then((response) => {
        // Optionally, you can return or process the response if needed
        return response;
      })
      .catch((error) => {
        console.log(error.response?.data);
        // Optionally, you can rethrow the error or handle it as needed
        throw error;
      });
  }

  const calculateTotalCost = () => {
    const totalCost =
      (parseFloat(cost?.basePrice) || 0) +
      (parseFloat(cost?.freightCost) || 0) +
      (parseFloat(cost?.insuranceCost) || 0) +
      (parseFloat(cost?.storageCost) || 0) +
      (parseFloat(cost?.financeCost) || 0) +
      (parseFloat(cost?.others) || 0) +
      (parseFloat(cost?.firstCommision) || 0) +
      (parseFloat(cost?.secondCommision) || 0) +
      (parseFloat(cost?.thirdCommision) || 0);
    setCost({ ...cost, totalCost });
  };

  const calculateTotalCostWithProfit = () => {
    const totalOfferCost =
      (parseFloat(cost?.basePrice) || 0) +
      (parseFloat(cost?.freightCost) || 0) +
      (parseFloat(cost?.insuranceCost) || 0) +
      (parseFloat(cost?.storageCost) || 0) +
      (parseFloat(cost?.financeCost) || 0) +
      (parseFloat(cost?.others) || 0) +
      (parseFloat(cost?.firstCommision) || 0) +
      (parseFloat(cost?.secondCommision) || 0) +
      (parseFloat(cost?.thirdCommision) || 0) +
      (parseFloat(cost?.profit) || 0);
    setCost({ ...cost, totalOfferCost });
  };

  function retrieveQuantity(itemId) {
    retrieveQuantityByItemIdApi(itemId)
      .then((response) => {
        console.log("QUANTITY: ", response?.data);
        setQuantity(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveSampleById(id) {
    retrieveSampleByIdApi(id)
      .then((response) => {
        setSample(response?.data);
        console.log(id, response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveFilteredSamples(samplePurpose) {
    retrievePurposeFilteredSamplesApi(samplePurpose)
      .then((response) => {
        setFilteredSamples(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveStakeholders() {
    Promise.all([
      retrieveAllStakeholdersApi("VENDOR"),
      retrieveAllStakeholdersApi("CUSTOMER")
    ])
      .then(([vendorResponse, customerResponse]) => {
        setStakeholders([
          ...vendorResponse?.data,
          ...customerResponse?.data
        ]);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const filterOffers = {
    offerType: "VENDOR",
    recordStatusList: ["ACTIVE"],
  };

  function retrieveVendorOffers() {
    retrieveFilteredOffersApi(filterOffers)
      .then((response) => {
        setVendorOffers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveItems() {
    retrieveAllItemsApi()
      .then((response) => {
        setItems(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveItemById(id) {
    retrieveItemByIdApi(id)
      .then((response) => {
        setItem(response?.data);
        console.log(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveCustomers() {
    retrieveAllStakeholdersApi("CUSTOMER")
      .then((response) => {
        setCustomers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveCosts() {
    retrieveAllCostsApi()
      .then((response) => {
        setCosts(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveCostById(id) {
    retrieveCostByIdApi(id)
      .then((response) => {
        setCost(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveVendorOffer(id) {
    return retrieveOfferByIdApi(id)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        console.log(error.response?.data);
        return null;
      });
  }
  const [isNewCostOpen, setIsNewCostOpen] = useState(false);
  const [isEditCostOpen, setIsEditCostOpen] = useState(false);
  const [cost, setCost] = useState([]);

  const [isNewItemOpen, setIsNewItemOpen] = useState(false);
  const [isEditItemOpen, setIsEditItemOpen] = useState(false);
  const [item, setItem] = useState([]);

  const [selectedOrigin, setSelectedOrigin] = useState("");

  const [vendorOffers, setVendorOffers] = useState([]);
  const [items, setItems] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [costs, setCosts] = useState([]);
  const [selectedItem, setSelectedItem] = useState(
    editCustomerOffer?.data?.item || null,
  );
  const [selectedVendorOffer, setSelectedVendorOffer] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(
    editCustomerOffer?.data?.stakeholder || null,
  );
  const [selectedCost, setSelectedCost] = useState(
    editCustomerOffer?.data?.estimatedCost || null,
  );
  const [selectedFirstSample, setSelectedFirstSample] = useState(
    editCustomerOffer?.data?.firstSample || null,
  );
  const [selectedSecondSample, setSelectedSecondSample] = useState(
    editCustomerOffer?.data?.secondSample || null,
  );
  const initialPaymentTerm = paymentTerm.find(
    (term) => term.value === editCustomerOffer?.data?.paymentTerm,
  );
  const initialPortOfDischarge = portsOfDischarge.find(
    (term) => term.value === editCustomerOffer?.data?.portOfDischarge,
  );
  const initialPortOfLoading = portsOfLoading.find(
    (term) => term.value === editCustomerOffer?.data?.portOfLoading,
  );
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(
    initialPaymentTerm || null,
  );
  const [selectedPortOfDischarge, setSelectedPortOfDischarge] = useState(
    initialPortOfDischarge || null,
  );
  const [selectedPortOfLoading, setSelectedPortOfLoading] = useState(
    initialPortOfLoading || null,
  );

  const [isNewSampleOpen, setIsNewSampleOpen] = useState(false);
  const [isEditSampleOpen, setIsEditSampleOpen] = useState(false);
  const [sample, setSample] = useState([]);
  const [stakeholders, setStakeholders] = useState([]);

  const portOfDischargeIncoterms = [
    "C&F",
    "CFR",
    "CIF",
    "CIP",
    "CPT",
    "DAP",
    "DDP",
    "DPU",
  ];
  const portOfLoadingIncoterms = ["EXW", "FAS", "FCA", "FOB"];

  const isButtonDisabled =
    ((editCustomerOffer?.data?.status === "First Refusal" ||
      editCustomerOffer?.data?.status === "Optioned") &&
      (!editCustomerOffer?.data?.statusDeadline ||
        editCustomerOffer?.data?.statusDeadline === "")) || (!editCustomerOffer?.data?.offerDate);

  const navigate = useNavigate();

  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Edit Customer Offer: {editCustomerOffer?.data?.id}{editCustomerOffer?.data?.approvalStatus === "Approved" && <Typography variant="h5" color="error" sx={{ marginTop: '10px' }}>Approved offers can only be edited by managers.</Typography>}
        </Typography>
        <Grid container item xs={12} spacing="8px">
          <Grid item xs={2} sm={1.5}>
            <Typography variant="body2">Offer Year</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editCustomerOffer?.data?.offerYear}
              onChange={(e) =>
                setEditCustomerOffer({
                  ...editCustomerOffer,
                  data: {
                    ...editCustomerOffer.data,
                    offerYear: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Company</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editCustomerOffer?.data?.company}
              onChange={(e) =>
                setEditCustomerOffer({
                  ...editCustomerOffer,
                  data: {
                    ...editCustomerOffer.data,
                    company: e.target.value,
                  },
                })
              }
            >
              {companies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SG Approval Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              label={editCustomerOffer?.data?.approvalStatus}
              disabled
            >
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={10} sm={1.5}>
            <Typography variant="body2">Offer Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editCustomerOffer?.data?.offerGroupNumber}
              onChange={(e) =>
                setEditCustomerOffer({
                  ...editCustomerOffer,
                  data: {
                    ...editCustomerOffer.data,
                    offerGroupNumber: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}></Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Record Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              label={editCustomerOffer?.data?.recordStatus}
              disabled
            >
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Document Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editCustomerOffer?.data?.documentStatus}
              onChange={(e) =>
                setEditCustomerOffer({
                  ...editCustomerOffer,
                  data: {
                    ...editCustomerOffer.data,
                    documentStatus: e.target.value,
                  },
                })
              }
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={1.5}>
            <Typography variant="body2">Offer Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: new Date().toISOString().split("T")[0], }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              error={!editCustomerOffer?.data?.offerDate}
              helperText={!editCustomerOffer?.data?.offerDate ? "Offer date is required" : ""}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editCustomerOffer?.data?.offerDate
                  ? format(
                    parseISO(editCustomerOffer.data.offerDate),
                    "yyyy-MM-dd",
                  )
                  : ""
              }
              onChange={(e) =>
                setEditCustomerOffer({
                  ...editCustomerOffer,
                  data: {
                    ...editCustomerOffer.data,
                    offerDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="body2">Customer</Typography>
            <Autocomplete
              options={customers}
              getOptionLabel={(option) =>
                `${option?.id || ""} - ${option?.name || ""}`
              }
              value={selectedCustomer}
              onChange={(event, newValue) => {
                setSelectedCustomer(newValue);
                setEditCustomerOffer({
                  ...editCustomerOffer,
                  data: {
                    ...editCustomerOffer.data,
                    stakeholder: newValue || {},
                  },
                });
              }}
              onOpen={retrieveCustomers}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
          </Grid>
          <Grid item xs={12} sm={1}></Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editCustomerOffer?.data?.status}
              onChange={(e) => {
                const newStatus = e.target.value;
                let newStatusDeadline = editCustomerOffer?.data?.statusDeadline;
                if (newStatus !== "First Refusal" || newStatus !== "Optioned") {
                  newStatusDeadline = null;
                }
                setEditCustomerOffer({
                  ...editCustomerOffer,
                  data: {
                    ...editCustomerOffer.data,
                    status: newStatus,
                    statusDeadline: newStatusDeadline,
                  },
                });
              }}
            >
              {offerStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status Deadline</Typography>
            <div
              style={{
                borderColor:
                  (editCustomerOffer?.data?.statusDeadline === null ||
                    editCustomerOffer?.data?.statusDeadline === "") &&
                    (editCustomerOffer?.data?.status === "First Refusal" ||
                      editCustomerOffer?.data?.status === "Optioned")
                    ? "red"
                    : "inherit",
                borderWidth:
                  (editCustomerOffer?.data?.statusDeadline === null ||
                    editCustomerOffer?.data?.statusDeadline === "") &&
                    (editCustomerOffer?.data?.status === "First Refusal" ||
                      editCustomerOffer?.data?.status === "Optioned")
                    ? "1px"
                    : "inherit",
                borderStyle:
                  (editCustomerOffer?.data?.statusDeadline === null ||
                    editCustomerOffer?.data?.statusDeadline === "") &&
                    (editCustomerOffer?.data?.status === "First Refusal" ||
                      editCustomerOffer?.data?.status === "Optioned")
                    ? "solid"
                    : "inherit",
              }}
            >
              <TextField
                id="date"
                type="date"
                inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                size="small"
                fullWidth
                disabled={
                  editCustomerOffer?.data?.status !== "First Refusal" &&
                  editCustomerOffer?.data?.status !== "Optioned"
                }
                value={
                  editCustomerOffer?.data?.statusDeadline
                    ? format(
                      parseISO(editCustomerOffer.data.statusDeadline),
                      "yyyy-MM-dd",
                    )
                    : ""
                }
                onChange={(e) => {
                  setEditCustomerOffer({
                    ...editCustomerOffer,
                    data: {
                      ...editCustomerOffer.data,
                      statusDeadline: e.target.value
                        ? e.target.value + "T00:00:00.000+00:00"
                        : null,
                    },
                  });
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <Typography variant="body2">Validity Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editCustomerOffer?.data?.validityDate
                  ? format(
                    parseISO(editCustomerOffer.data.validityDate),
                    "yyyy-MM-dd",
                  )
                  : ""
              }
              onChange={(e) =>
                setEditCustomerOffer({
                  ...editCustomerOffer,
                  data: {
                    ...editCustomerOffer.data,
                    validityDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SAP Customer Code</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editCustomerOffer?.data?.stakeholder?.code || ""}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="body2">Offer Link</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editCustomerOffer?.data?.offerLink}
              onChange={(e) =>
                setEditCustomerOffer({
                  ...editCustomerOffer,
                  data: {
                    ...editCustomerOffer.data,
                    offerLink: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  margin: "10px 0",
                  borderColor: "#1890ff",
                },
              }}
            />
          </Grid>
          <TableContainer component={Paper}
            sx={{
              border: "2px solid #d1dfe8",
              borderRadius: "8px",
              overflow: "hidden",
            }}>

            <Table sx={{
              borderCollapse: "separate",
              borderSpacing: "0",
              tableLayout: "fixed",
              width: "100%",
            }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{
                    width: "15%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    fontSize: "0.7rem",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Reference Vendor Offer
                  </TableCell>
                  <TableCell sx={{
                    width: "5%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    fontSize: "0.7rem",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Date
                  </TableCell>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Final Offer Price
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        flexWrap: "nowrap",
                      }}
                    >
                      <Grid container xs={12} spacing="8px">
                        <Grid item xs={12} sm={12}>
                          <Typography variant="body2">Reference Vendor Offer</Typography>
                          <Autocomplete
                            options={vendorOffers}
                            getOptionLabel={(option) => {
                              const offerDate = option?.offerDate
                                ? format(parseISO(option.offerDate), "dd-MM-yyyy")
                                : "";
                              return `Id: ${option?.id || ""}, Item: ${option?.item?.id || ""} - ${option?.item?.buyingGrade || ""
                                }, Date: ${offerDate}`;
                            }}
                            value={selectedVendorOffer}
                            onChange={(event, newValue) => {
                              setSelectedVendorOffer(newValue);
                              setSelectedItem(newValue?.item);
                              setSelectedOrigin(newValue?.item?.origin);
                              setEditCustomerOffer({
                                ...editCustomerOffer,
                                data: {
                                  ...editCustomerOffer.data,
                                  referenceVendorOfferId: newValue ? newValue.id : null,
                                  item: newValue?.item ? newValue.item : {},
                                  itemId: newValue?.item ? newValue.item.id : null,
                                  itemStorage: newValue?.item?.storage || "",
                                  salesGrade: newValue?.item?.buyingGrade || "",
                                  salesOrigin: newValue?.item?.origin || "",
                                  salesCrop: newValue?.item?.crop || "",
                                  salesNicotine: newValue?.item?.nicotine || "",
                                  salesSugar: newValue?.item?.sugar || "",
                                  buyingPrice: newValue ? newValue.finalOfferPrice : null,
                                },
                              });
                            }}
                            onOpen={retrieveVendorOffers}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" size="small" fullWidth />
                            )}
                            renderTags={() => null}
                          />
                        </Grid>
                      </Grid>

                    </Box>
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={(selectedVendorOffer?.offerDate
                        ? format(parseISO(selectedVendorOffer.offerDate), "dd-MM-yyyy")
                        : "")}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={
                        selectedVendorOffer?.finalOfferPrice || ""
                      }
                    />
                  </TableCell>

                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Grid item xs={12} sm={12}></Grid>
          <Grid item xs={12}>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  margin: "10px 0",
                  borderColor: "#1890ff",
                },
              }}
            />
          </Grid>

          <TableContainer component={Paper}
            sx={{
              border: "2px solid #d1dfe8",
              borderRadius: "8px",
              overflow: "hidden",
            }}>

            <Table sx={{
              borderCollapse: "separate",
              borderSpacing: "0",
              tableLayout: "fixed",
              width: "100%",
            }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{
                    width: "15%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    fontSize: "0.7rem",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Item
                  </TableCell>
                  <TableCell sx={{
                    width: "5%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    fontSize: "0.7rem",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    ID
                  </TableCell>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Buying Grade
                  </TableCell>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Internal Grade
                  </TableCell>
                  <TableCell sx={{
                    width: "8%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Type
                  </TableCell>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Origin
                  </TableCell>
                  {selectedItem?.origin === "China" && <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Province
                  </TableCell>}
                  <TableCell sx={{
                    width: "6%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Crop
                  </TableCell>
                  <TableCell sx={{
                    width: "6%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Nicotine
                  </TableCell>
                  <TableCell sx={{
                    width: "6%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Sugar
                  </TableCell>
                  <TableCell sx={{
                    width: "6%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Moisture
                  </TableCell>
                  <TableCell sx={{
                    width: "6%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    fontSize: "0.7rem",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Current Location
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        flexWrap: "nowrap",
                      }}
                    >
                      <Grid container xs={12} spacing="8px">
                        <Grid item xs={12} sm={8}>
                          <Autocomplete
                            disabled={selectedVendorOffer}
                            options={items}
                            getOptionLabel={(option) =>
                              `${option?.id || ""} - Buying Grade: ${option?.buyingGrade || ""
                              } - ${option?.origin || ""} - ${option?.crop || ""}`
                            }
                            value={selectedItem}
                            onChange={(event, newValue) => {
                              setSelectedItem(newValue);
                              setEditCustomerOffer({
                                ...editCustomerOffer,
                                data: {
                                  ...editCustomerOffer.data,
                                  item: newValue || {},
                                },
                              });
                              retrieveQuantity(newValue?.id);
                            }}
                            onOpen={retrieveItems}
                            isOptionEqualToValue={(option, value) =>
                              option?.id === value?.id
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                fullWidth
                                disabled={selectedVendorOffer}
                              />
                            )}
                            renderTags={() => null}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <ButtonGroup
                            variant="outlined"
                            ref={anchorRef}
                            aria-label="Button group with a nested menu"
                          >
                            <Button onClick={handleClick}>
                              {options[selectedIndex]}
                            </Button>
                            <Button
                              size="small"
                              aria-controls={open ? "split-button-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-label="select merge strategy"
                              aria-haspopup="menu"
                              onClick={handleToggle}
                              sx={{
                                width: "20px !important",
                                minWidth: "0px !important",
                              }}
                            >
                              <ArrowDropDownIcon />
                            </Button>
                          </ButtonGroup>
                          <Popper
                            sx={{
                              zIndex: 1,
                            }}
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === "bottom"
                                      ? "center top"
                                      : "center bottom",
                                }}
                              >
                                <Paper>
                                  <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                      {options.map((option, index) => (
                                        <MenuItem
                                          key={option}
                                          disabled={index === 2}
                                          selected={index === selectedIndex}
                                          onClick={(event) =>
                                            handleMenuItemClick(event, index)
                                          }
                                        >
                                          {option}
                                        </MenuItem>
                                      ))}
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </Grid>
                      </Grid>

                    </Box>
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={(selectedItem?.id || "")}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={
                        selectedItem?.buyingGrade || ""
                      }
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={
                        selectedItem?.internalGrade || ""
                      }
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={
                        selectedItem?.type || ""
                      }
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={
                        selectedItem?.origin || ""
                      }
                    />
                  </TableCell>

                  {selectedItem?.origin === "China" && <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={
                        selectedItem?.province || ""
                      }
                    />
                  </TableCell>}

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={
                        selectedItem?.crop || ""
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={
                        selectedItem?.nicotine || ""
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={
                        selectedItem?.sugar || ""
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={
                        selectedItem?.moisture || ""
                      }
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={editCustomerOffer?.data?.item?.storage || ""}
                      fullWidth
                      onChange={(e) =>
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            itemStorage: e.target.value,
                            item: {
                              ...editCustomerOffer?.data?.item,
                              storage: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </TableCell>

                </TableRow>
              </TableBody>
            </Table>
            <Table sx={{
              borderCollapse: "separate",
              borderSpacing: "0",
              tableLayout: "fixed",
              width: "100%",
            }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    fontSize: "0.7rem",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Item Sales Grade
                  </TableCell>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    fontSize: "0.7rem",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Item Sales Origin
                  </TableCell>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Item Sales Crop
                  </TableCell>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Item Sales Nicotine
                  </TableCell>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Item Sales Sugar
                  </TableCell>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Item Current Location
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <Typography variant="body2">Item Sales Grade</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={editCustomerOffer?.data?.salesGrade || ""}
                      fullWidth
                      onChange={(e) =>
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            salesGrade: e.target.value,
                            item: {
                              ...editCustomerOffer?.data?.item,
                              salesGrade: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <Typography variant="body2"> Item Sales Origin</Typography>
                    <Autocomplete
                      options={origin}
                      value={editCustomerOffer?.data?.salesOrigin}
                      onChange={(event, newValue) => {
                        const newTerm = newValue ? newValue.value : "";
                        setSelectedOrigin(newTerm);
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            salesOrigin: newTerm,
                          },
                        });
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          label={item?.origin}
                          fullWidth
                        />
                      )}
                      renderTags={() => null}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <Typography variant="body2">Item Sales Crop</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="number"
                      value={editCustomerOffer?.data?.salesCrop}
                      fullWidth
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            salesCrop: e.target.value,
                          },
                        });
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <Typography variant="body2">Item Sales Nicotine</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="number"
                      value={editCustomerOffer?.data?.salesNicotine}
                      fullWidth
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            salesNicotine: e.target.value,
                          },
                        });
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <Typography variant="body2">Item Sales Sugar</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="number"
                      value={editCustomerOffer?.data?.salesSugar}
                      fullWidth
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            salesSugar: e.target.value,
                          },
                        });
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <Typography variant="body2">Item Current Location</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={editCustomerOffer?.data?.item?.storage || ""}
                      fullWidth
                      onChange={(e) =>
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            itemStorage: e.target.value,
                            item: {
                              ...editCustomerOffer?.data?.item,
                              storage: e.target.value,
                            },
                          },
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Grid item xs={12} sm={12}></Grid>
          <Grid item xs={12}>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  margin: "10px 0",
                  borderColor: "#1890ff",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}></Grid>

          <TableContainer component={Paper}
            sx={{
              border: "2px solid #d1dfe8",
              borderRadius: "8px",
              overflow: "hidden",
            }}>

            <Table sx={{
              borderCollapse: "separate",
              borderSpacing: "0",
              tableLayout: "fixed",
              width: "100%",
            }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{
                    width: "15%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    fontSize: "0.7rem",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Offer Sample
                  </TableCell>
                  <TableCell sx={{
                    width: "5%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    fontSize: "0.7rem",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    ID
                  </TableCell>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Offer Sample Remark
                  </TableCell>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Offer Sample Status
                  </TableCell>
                  <TableCell sx={{
                    width: "15%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    fontSize: "0.7rem",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Trial Sample
                  </TableCell>
                  <TableCell sx={{
                    width: "5%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    fontSize: "0.7rem",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    ID
                  </TableCell>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Trial Sample Remark
                  </TableCell>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Trial Sample Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        flexWrap: "nowrap",
                      }}
                    >
                      <Grid container xs={12} spacing="8px">
                        <Grid item xs={12} sm={12}>
                          <Typography variant="body2"> Offer Sample</Typography>
                          <Grid container xs={12} spacing="8px">
                            <Grid item xs={12} sm={8}>
                              <Autocomplete
                                options={filteredSamples}
                                getOptionLabel={(option) =>
                                  `ID: ${option?.id || ""}, Remark: ${option?.remark || ""
                                  }, Buying Grade: ${option?.item?.buyingGrade || ""}`
                                }
                                value={selectedFirstSample}
                                onChange={(event, newValue) => {
                                  setSelectedFirstSample(newValue);
                                  setEditCustomerOffer({
                                    ...editCustomerOffer,
                                    data: {
                                      ...editCustomerOffer.data,
                                      firstSample: newValue || {},
                                    },
                                  });
                                }}
                                onOpen={() => retrieveFilteredSamples("Offer Sample")}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    label={`ID: ${selectedFirstSample?.id || ""}, Remark: ${selectedFirstSample?.remark || ""
                                      }, Buying Grade: ${selectedFirstSample?.item?.buyingGrade || ""
                                      }`}
                                  />
                                )}
                                renderTags={() => null}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <ButtonGroup
                                variant="outlined"
                                ref={anchorRef3}
                                aria-label="Button group with a nested menu"
                              >
                                <Button onClick={handleClick3}>
                                  {options[selectedIndex3]}
                                </Button>
                                <Button
                                  size="small"
                                  aria-controls={open3 ? "split-button-menu" : undefined}
                                  aria-expanded={open3 ? "true" : undefined}
                                  aria-label="select merge strategy"
                                  aria-haspopup="menu"
                                  onClick={handleToggle3}
                                  sx={{
                                    width: "20px !important",
                                    minWidth: "0px !important",
                                  }}
                                >
                                  <ArrowDropDownIcon />
                                </Button>
                              </ButtonGroup>
                              <Popper
                                sx={{
                                  zIndex: 1,
                                }}
                                open={open3}
                                anchorEl={anchorRef3.current}
                                role={undefined}
                                transition
                                disablePortal
                              >
                                {({ TransitionProps, placement }) => (
                                  <Grow
                                    {...TransitionProps}
                                    style={{
                                      transformOrigin:
                                        placement === "bottom"
                                          ? "center top"
                                          : "center bottom",
                                    }}
                                  >
                                    <Paper>
                                      <ClickAwayListener onClickAway={handleClose3}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                          {options.map((option, index) => (
                                            <MenuItem
                                              key={option}
                                              disabled={index === 2}
                                              selected={index === selectedIndex3}
                                              onClick={(event) =>
                                                handleMenuItemClick3(event, index)
                                              }
                                            >
                                              {option}
                                            </MenuItem>
                                          ))}
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Paper>
                                  </Grow>
                                )}
                              </Popper>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Box>
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={
                        selectedFirstSample?.id || ""
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={
                        selectedFirstSample?.remark || ""
                      }
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <Typography variant="body2"> Offer Sample Status</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      select
                      value={editCustomerOffer?.data?.firstSampleStatus}
                      onChange={(e) =>
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            firstSampleStatus: e.target.value,
                          },
                        })
                      }
                    >
                      {sampleStatus.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>


                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        flexWrap: "nowrap",
                      }}
                    >
                      <Grid container xs={12} spacing="8px">
                        <Grid item xs={12} sm={12}>
                          <Typography variant="body2"> Trial Sample</Typography>
                          <Grid container xs={12} spacing="8px">
                            <Grid item xs={12} sm={8}>
                              <Autocomplete
                                options={filteredSamples}
                                getOptionLabel={(option) =>
                                  `ID: ${option?.id || ""}, Remark: ${option?.remark || ""
                                  }, Buying Grade: ${option?.item?.buyingGrade || ""}`
                                }
                                value={selectedSecondSample}
                                onChange={(event, newValue) => {
                                  setSelectedSecondSample(newValue);
                                  setEditCustomerOffer({
                                    ...editCustomerOffer,
                                    data: {
                                      ...editCustomerOffer.data,
                                      secondSample: newValue || {},
                                    },
                                  });
                                }}
                                onOpen={() => retrieveFilteredSamples("Trial Sample")}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    label={`ID: ${selectedSecondSample?.id || ""}, Remark: ${selectedSecondSample?.remark || ""
                                      }, Buying Grade: ${selectedSecondSample?.item?.buyingGrade || ""
                                      }`}
                                  />
                                )}
                                renderTags={() => null}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <ButtonGroup
                                variant="outlined"
                                ref={anchorRef4}
                                aria-label="Button group with a nested menu"
                              >
                                <Button onClick={handleClick4}>
                                  {options[selectedIndex4]}
                                </Button>
                                <Button
                                  size="small"
                                  aria-controls={open4 ? "split-button-menu" : undefined}
                                  aria-expanded={open4 ? "true" : undefined}
                                  aria-label="select merge strategy"
                                  aria-haspopup="menu"
                                  onClick={handleToggle4}
                                  sx={{
                                    width: "20px !important",
                                    minWidth: "0px !important",
                                  }}
                                >
                                  <ArrowDropDownIcon />
                                </Button>
                              </ButtonGroup>
                              <Popper
                                sx={{
                                  zIndex: 1,
                                }}
                                open={open4}
                                anchorEl={anchorRef4.current}
                                role={undefined}
                                transition
                                disablePortal
                              >
                                {({ TransitionProps, placement }) => (
                                  <Grow
                                    {...TransitionProps}
                                    style={{
                                      transformOrigin:
                                        placement === "bottom"
                                          ? "center top"
                                          : "center bottom",
                                    }}
                                  >
                                    <Paper>
                                      <ClickAwayListener onClickAway={handleClose4}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                          {options.map((option, index) => (
                                            <MenuItem
                                              key={option}
                                              disabled={index === 2}
                                              selected={index === selectedIndex4}
                                              onClick={(event) =>
                                                handleMenuItemClick4(event, index)
                                              }
                                            >
                                              {option}
                                            </MenuItem>
                                          ))}
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Paper>
                                  </Grow>
                                )}
                              </Popper>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Box>
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={
                        selectedSecondSample?.id || ""
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={
                        selectedSecondSample?.remark || ""
                      }
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <Typography variant="body2"> Trial Sample Status</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      select
                      value={editCustomerOffer?.data?.secondSampleStatus}
                      onChange={(e) =>
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            secondSampleStatus: e.target.value,
                          },
                        })
                      }
                    >
                      {sampleStatus.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>

                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Grid item xs={12} sm={12}></Grid>
          <Grid item xs={12}>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  margin: "10px 0",
                  borderColor: "#1890ff",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}></Grid>


          <TableContainer component={Paper}
            sx={{
              border: "2px solid #d1dfe8",
              borderRadius: "8px",
              overflow: "hidden",
            }}>

            <Table sx={{
              borderCollapse: "separate",
              borderSpacing: "0",
              tableLayout: "fixed",
              width: "100%",
            }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    fontSize: "0.7rem",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Estimated Cost
                  </TableCell>
                  <TableCell sx={{
                    width: "5%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    fontSize: "0.7rem",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    ID
                  </TableCell>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Total Estimated Cost
                  </TableCell>
                  <TableCell sx={{
                    width: "15%", border: "1px solid #d1dfe8",
                    backgroundColor: "#98c1d9",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Estimated Cost Remark
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        flexWrap: "nowrap",
                      }}
                    >
                      <Grid container xs={12} spacing="8px">
                        <Grid item xs={12} sm={12}>
                          <Typography variant="body2"> Cost</Typography>
                          <Grid container xs={12} spacing="8px">
                            <Grid item xs={12} sm={8}>
                              <Autocomplete
                                options={costs}
                                getOptionLabel={(option) =>
                                  `Id: ${option?.id || ""}, Remark: ${option?.remark || ""
                                  }, Total Unit Cost: ${option?.totalCost || ""}`
                                }
                                value={selectedCost}
                                onChange={(event, newValue) => {
                                  setSelectedCost(newValue);
                                  setEditCustomerOffer({
                                    ...editCustomerOffer,
                                    data: {
                                      ...editCustomerOffer.data,
                                      estimatedCost: newValue || {},
                                      firstOfferPrice: newValue?.totalOfferCost,
                                      finalOfferPrice: newValue?.totalOfferCost,
                                    },
                                  });
                                }}
                                onOpen={retrieveCosts}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    label={`Id: ${selectedCost?.id || ""}, Remark: ${selectedCost?.remark || ""
                                      }, Total Unit Cost: ${selectedCost?.totalCost || ""}`}
                                  />
                                )}
                                renderTags={() => null}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <ButtonGroup
                                variant="outlined"
                                ref={anchorRef2}
                                aria-label="Button group with a nested menu"
                              >
                                <Button onClick={handleClick2}>
                                  {options[selectedIndex2]}
                                </Button>
                                <Button
                                  size="small"
                                  aria-controls={open2 ? "split-button-menu" : undefined}
                                  aria-expanded={open2 ? "true" : undefined}
                                  aria-label="select merge strategy"
                                  aria-haspopup="menu"
                                  onClick={handleToggle2}
                                  sx={{
                                    width: "20px !important",
                                    minWidth: "0px !important",
                                  }}
                                >
                                  <ArrowDropDownIcon />
                                </Button>
                              </ButtonGroup>
                              <Popper
                                sx={{
                                  zIndex: 1,
                                }}
                                open={open2}
                                anchorEl={anchorRef2.current}
                                role={undefined}
                                transition
                                disablePortal
                              >
                                {({ TransitionProps, placement }) => (
                                  <Grow
                                    {...TransitionProps}
                                    style={{
                                      transformOrigin:
                                        placement === "bottom"
                                          ? "center top"
                                          : "center bottom",
                                    }}
                                  >
                                    <Paper>
                                      <ClickAwayListener onClickAway={handleClose2}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                          {options.map((option, index) => (
                                            <MenuItem
                                              key={option}
                                              disabled={index === 2}
                                              selected={index === selectedIndex2}
                                              onClick={(event) =>
                                                handleMenuItemClick2(event, index)
                                              }
                                            >
                                              {option}
                                            </MenuItem>
                                          ))}
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Paper>
                                  </Grow>
                                )}
                              </Popper>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Box>
                  </TableCell>

                  <CostDialog
                    open={costDialogOpen}
                    onClose={() => setCostDialogOpen(false)}
                    offerOperation="edit"
                    type={costDialogType}
                    cost={costDialogData}
                    setCost={setCostDialogData}
                    selectedCost={selectedCost}
                    setSelectedCost={setSelectedCost}
                    editCustomerOffer={editCustomerOffer}
                    setEditCustomerOffer={setEditCustomerOffer}
                  />

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      value={
                        selectedCost?.id || ""
                      }
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <Typography variant="body2">Total Estimated Calculated Cost</Typography>
                    <TextField
                      type="number"
                      variant="outlined"
                      size="small"
                      value={selectedCost?.totalCost || ""}
                      fullWidth
                      disabled
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #d1dfe8" }}>
                    <Typography variant="body2">Cost Remark</Typography>
                    <TextField
                      type="number"
                      variant="outlined"
                      size="small"
                      value={selectedCost?.remark || ""}
                      fullWidth
                      disabled
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Grid item xs={12} sm={12}></Grid>
          <Grid item xs={12}>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  margin: "10px 0",
                  borderColor: "#1890ff",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}></Grid>

          <TableContainer component={Paper}
            sx={{
              border: "2px solid #abcfff",
              borderRadius: "8px",
              overflow: "hidden",
            }}>

            <Table sx={{
              borderCollapse: "separate",
              borderSpacing: "0",
              tableLayout: "fixed",
              width: "100%",
            }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{
                    width: "9.5%", border: "1px solid #e0dada",
                    backgroundColor: "#d7e7fc",
                    fontSize: "0.7rem",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Net Weight Per Carton
                  </TableCell>
                  <TableCell sx={{
                    width: "9.5%", border: "1px solid #e0dada",
                    backgroundColor: "#d7e7fc",
                    fontSize: "0.7rem",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Number Of Cartons
                  </TableCell>
                  <TableCell sx={{
                    width: "10.5%", border: "1px solid #e0dada",
                    backgroundColor: "#d7e7fc",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Volume
                  </TableCell>
                  <TableCell sx={{
                    width: "9%", border: "1px solid #e0dada",
                    backgroundColor: "#d7e7fc",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Unit
                  </TableCell>
                  <TableCell sx={{
                    width: "10.5%", border: "1px solid #e0dada",
                    backgroundColor: "#d7e7fc",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Total Amount
                  </TableCell>
                  <TableCell sx={{
                    width: "9.5%", border: "1px solid #e0dada",
                    backgroundColor: "#d7e7fc",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Packaging
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "19%",
                      border: "1px solid #e0dada",
                      backgroundColor: "#d7e7fc",
                      fontSize: "0.7rem",
                      padding: "4px 8px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    Port Of Discharge
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "19%",
                      border: "1px solid #e0dada",
                      backgroundColor: "#d7e7fc",
                      fontSize: "0.7rem",
                      padding: "4px 8px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    Port Of Loading
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2"> Net Weight Per Carton</Typography>
                    <TextField
                      type="number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={editCustomerOffer?.data?.netWeightPerCase}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            netWeightPerCase: e.target.value,
                            totalAmount:
                              e.target.value *
                              editCustomerOffer?.data?.numberOfCases *
                              editCustomerOffer?.data?.finalOfferPrice,
                            volume:
                              e.target.value * editCustomerOffer?.data?.numberOfCases,
                          },
                        })
                      }
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2"> Number Of Cartons</Typography>
                    <TextField
                      type="number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={editCustomerOffer?.data?.numberOfCases}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            numberOfCases: e.target.value,
                            totalAmount:
                              e.target.value *
                              editCustomerOffer?.data?.netWeightPerCase *
                              editCustomerOffer?.data?.finalOfferPrice,
                            volume:
                              e.target.value *
                              editCustomerOffer?.data?.netWeightPerCase,
                          },
                        })
                      }
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2"> Volume</Typography>
                    <TextField
                      type="number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={editCustomerOffer?.data?.volume}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            volume: e.target.value,
                          },
                        })
                      }
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2"> Volume Unit</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      select
                      value={editCustomerOffer?.data?.volumeUnit}
                      onChange={(e) =>
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            volumeUnit: e.target.value,
                          },
                        })
                      }
                    >
                      {units.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2">Total Amount</Typography>
                    <TextField
                      type="number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={editCustomerOffer?.data?.totalAmount}
                      disabled
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2"> Packaging</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      select
                      value={editCustomerOffer?.data?.packaging}
                      onChange={(e) =>
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            packaging: e.target.value,
                          },
                        })
                      }
                    >
                      {packagingTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2">Port Of Discharge</Typography>
                    <Autocomplete
                      options={portsOfDischarge}
                      getOptionLabel={(option) => option?.label || ""}
                      value={selectedPortOfDischarge}
                      onChange={(event, newValue) => {
                        setSelectedPortOfDischarge(newValue);
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            portOfDischarge: newValue?.value || "",
                          },
                        });
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value?.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          fullWidth
                          style={{
                            border:
                              portOfDischargeIncoterms.includes(
                                editCustomerOffer?.data?.incoterm,
                              ) && editCustomerOffer?.data?.portOfDischarge == null
                                ? "3px solid red"
                                : "none",
                            borderRadius:
                              portOfDischargeIncoterms.includes(
                                editCustomerOffer?.data?.incoterm,
                              ) && editCustomerOffer?.data?.portOfDischarge == null
                                ? "5px"
                                : "none",
                          }}
                        />
                      )}
                      renderTags={() => null}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2">Port Of Loading</Typography>
                    <Autocomplete
                      options={portsOfLoading}
                      getOptionLabel={(option) => option?.label || ""}
                      value={selectedPortOfLoading}
                      onChange={(event, newValue) => {
                        setSelectedPortOfLoading(newValue);
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            portOfLoading: newValue?.value || "",
                          },
                        });
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value?.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          fullWidth
                          style={{
                            border:
                              portOfLoadingIncoterms.includes(
                                editCustomerOffer?.data?.incoterm,
                              ) && editCustomerOffer?.data?.portOfLoading == null
                                ? "3px solid red"
                                : "none",
                            borderRadius:
                              portOfLoadingIncoterms.includes(
                                editCustomerOffer?.data?.incoterm,
                              ) && editCustomerOffer?.data?.portOfLoading == null
                                ? "5px"
                                : "none",
                          }}
                        />
                      )}
                      renderTags={() => null}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Box sx={{ marginTop: "16px" }}></Box>

            <Table sx={{
              borderCollapse: "separate",
              borderSpacing: "0",
              tableLayout: "fixed",
              width: "100%",
            }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{
                    width: "9%", border: "1px solid #e0dada",
                    backgroundColor: "#d7e7fc",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Buying Price
                  </TableCell>
                  <TableCell sx={{
                    width: "6%", border: "1px solid #e0dada",
                    backgroundColor: "#d7e7fc",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Currency
                  </TableCell>
                  <TableCell sx={{
                    width: "9%", border: "1px solid #e0dada",
                    backgroundColor: "#d7e7fc",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    First Price
                  </TableCell>
                  <TableCell sx={{
                    width: "6%", border: "1px solid #e0dada",
                    backgroundColor: "#d7e7fc",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Currency
                  </TableCell>
                  <TableCell sx={{
                    width: "9%", border: "1px solid #e0dada",
                    backgroundColor: "#d7e7fc",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Final Price
                  </TableCell>
                  <TableCell sx={{
                    width: "6%", border: "1px solid #e0dada",
                    backgroundColor: "#d7e7fc",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Currency
                  </TableCell>
                  <TableCell sx={{
                    width: "10%", border: "1px solid #e0dada",
                    backgroundColor: "#d7e7fc",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Incoterm
                  </TableCell>
                  <TableCell sx={{
                    width: "19%", border: "1px solid #e0dada",
                    backgroundColor: "#d7e7fc",
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}>
                    Payment Term
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2"> Buying Price Per Kg</Typography>
                    <TextField
                      type="number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={editCustomerOffer?.data?.buyingPrice}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => {
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            buyingPrice: e.target.value,
                          },
                        });
                        setCost({ ...cost, basePrice: e.target.value });
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2">
                      Buying Price Per Kg Currency
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      select
                      value={editCustomerOffer?.data?.buyingPriceCurrency}
                      onChange={(e) => {
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            buyingPriceCurrency: e.target.value,
                          },
                        });
                        setCost({ ...cost, basePriceCurrency: e.target.value });
                      }}
                    >
                      {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2">First Offer Price</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="number"
                      value={editCustomerOffer?.data?.firstOfferPrice}
                      disabled
                      onChange={(e) =>
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            firstOfferPrice: e.target.value,
                          },
                        })
                      }
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2">First Offer Price Currency</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      select
                      value={editCustomerOffer?.data?.firstOfferPriceCurrency}
                      onChange={(e) =>
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            firstOfferPriceCurrency: e.target.value,
                          },
                        })
                      }
                    >
                      {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2"> Final Offer Price</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="number"
                      value={editCustomerOffer?.data?.finalOfferPrice}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            finalOfferPrice: e.target.value,
                            totalAmount:
                              e.target.value *
                              editCustomerOffer?.data?.numberOfCases *
                              editCustomerOffer?.data?.netWeightPerCase,
                          },
                        })
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2">Final Offer Price Currency</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      select
                      value={editCustomerOffer?.data?.finalOfferPriceCurrency}
                      onChange={(e) =>
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            finalOfferPriceCurrency: e.target.value,
                          },
                        })
                      }
                    >
                      {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2"> Incoterm</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      select
                      value={editCustomerOffer?.data?.incoterm}
                      onChange={(e) =>
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            incoterm: e.target.value,
                          },
                        })
                      }
                    >
                      {incoterms.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2"> Payment Term</Typography>
                    <Autocomplete
                      options={paymentTerm}
                      getOptionLabel={(option) => option?.label || ""}
                      value={selectedPaymentTerm}
                      onChange={(event, newValue) => {
                        setSelectedPaymentTerm(newValue);
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            paymentTerm: newValue?.value || "",
                          },
                        });
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value?.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                      renderTags={() => null}
                    />

                  </TableCell>

                </TableRow>
              </TableBody>
            </Table>

            <Box sx={{ marginTop: "16px" }}></Box>

            <Table
              sx={{
                borderCollapse: "separate",
                borderSpacing: "0",
                tableLayout: "fixed",
                width: "100%",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      width: "31%",
                      border: "1px solid #e0dada",
                      backgroundColor: "#d7e7fc",
                      fontSize: "0.7rem",
                      padding: "4px 8px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    Remark
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "31%",
                      border: "1px solid #e0dada",
                      backgroundColor: "#d7e7fc",
                      fontSize: "0.7rem",
                      padding: "4px 8px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    Comment
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "31%",
                      border: "1px solid #e0dada",
                      backgroundColor: "#d7e7fc",
                      fontSize: "0.7rem",
                      padding: "4px 8px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    Comment One
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "31%",
                      border: "1px solid #e0dada",
                      backgroundColor: "#d7e7fc",
                      fontSize: "0.7rem",
                      padding: "4px 8px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    Comment Two
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2"> Remark</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={editCustomerOffer?.data?.remark}
                      onChange={(e) =>
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            remark: e.target.value,
                          },
                        })
                      }
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2">Comment</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={editCustomerOffer?.data?.comment}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(e) => {
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            comment: e.target.value,
                          },
                        })
                        if (e.target.value.length === 100) {
                          toast.error("Character limit (100) exceeded");
                        }
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2">Comment One</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={editCustomerOffer?.data?.commentOne}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(e) => {
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            commentOne: e.target.value,
                          },
                        })
                        if (e.target.value.length === 100) {
                          toast.error("Character limit (100) exceeded");
                        }
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2">Comment Two</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={editCustomerOffer?.data?.commentTwo}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(e) => {
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            commentTwo: e.target.value,
                          },
                        })
                        if (e.target.value.length === 100) {
                          toast.error("Character limit (100) exceeded");
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table
              sx={{
                borderCollapse: "separate",
                borderSpacing: "0",
                tableLayout: "fixed",
                width: "100%",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      width: "31%",
                      border: "1px solid #e0dada",
                      backgroundColor: "#d7e7fc",
                      fontSize: "0.7rem",
                      padding: "4px 8px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    Shipment
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "31%",
                      border: "1px solid #e0dada",
                      backgroundColor: "#d7e7fc",
                      fontSize: "0.7rem",
                      padding: "4px 8px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    Finance Charge
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "31%",
                      border: "1px solid #e0dada",
                      backgroundColor: "#d7e7fc",
                      fontSize: "0.7rem",
                      padding: "4px 8px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    Storage Charge
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2">Shipment</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={editCustomerOffer?.data?.shipment}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(e) => {
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            shipment: e.target.value,
                          },
                        })
                        if (e.target.value.length === 100) {
                          toast.error("Character limit (100) exceeded");
                        }
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2">Finance Charge</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={editCustomerOffer?.data?.financeCharge}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(e) => {
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            financeCharge: e.target.value,
                          },
                        })
                        if (e.target.value.length === 100) {
                          toast.error("Character limit (100) exceeded");
                        }
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid #e0dada" }}>
                    <Typography variant="body2">Storage Charge</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={editCustomerOffer?.data?.storageCharge}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(e) => {
                        setEditCustomerOffer({
                          ...editCustomerOffer,
                          data: {
                            ...editCustomerOffer.data,
                            storageCharge: e.target.value,
                          },
                        })
                        if (e.target.value.length === 100) {
                          toast.error("Character limit (100) exceeded");
                        }
                      }}
                    />
                  </TableCell>

                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

        </Grid>

        {isNewItemOpen && (
          <>
            {" "}
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                New Item Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "30px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid container xs={12} spacing="8px">
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Tobacco Code</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        tobaccoCode: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        salesGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Buying Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        buyingGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Internal Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        internalGrade: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Origin</Typography>
                  <Autocomplete
                    options={origin}
                    value={selectedOrigin}
                    onChange={(event, newValue) => {
                      const newTerm = newValue ? newValue.value : "";
                      setSelectedOrigin(newTerm);
                      setItem({
                        ...item,
                        origin: newTerm,
                      });
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />

                </Grid>

                {(selectedOrigin === "China" &&
                  <Grid item xs={12} sm={2}>
                    <Typography variant="body2">Province</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) =>
                        setItem({
                          ...item,
                          province: e.target.value,
                        })
                      }
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Type</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-type"
                    select
                    onChange={(e) =>
                      setItem({
                        ...item,
                        type: e.target.value,
                      })
                    }
                  >
                    {type.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Leaf Form</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-leaf-form"
                    select
                    onChange={(e) =>
                      setItem({ ...item, leafForm: e.target.value })
                    }
                  >
                    {leafForm.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Color</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-color"
                    select
                    onChange={(e) =>
                      setItem({ ...item, color: e.target.value })
                    }
                  >
                    {color.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Crop Year</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        crop: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Current Location</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-storage"
                    value={item?.storage}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        storage: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Nicotine Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        nicotine: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sugar Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        sugar: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Moisture Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={item?.moisture}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        moisture: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Status</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-status"
                    select
                    onChange={(e) =>
                      setItem({
                        ...item,
                        status: e.target.value,
                      })
                    }
                  >
                    {recordStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1.5, mt: 4, mr: 1 }}
                  onClick={() => {
                    setIsNewItemOpen(false);
                    setItem([]);
                    setSelectedOrigin("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 4 }}
                  onClick={() => {
                    createItemApi(item)
                      .then((response) => {
                        toast.success("Item added successfully.");
                      })
                      .catch((error) => {
                        console.log(error.response.data);
                        toast.error("Something Went Wrong.");
                      });
                    setIsNewItemOpen(false);
                    setItem([]);
                    setSelectedOrigin("");
                  }}
                >
                  Create Item
                </Button>
              </Grid>
            </Grid>{" "}
          </>
        )}

        {isEditItemOpen && (
          <>
            {" "}
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                Edit Item {item?.id} Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "30px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid container xs={12} spacing="8px">
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Tobacco Code</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.tobaccoCode || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        tobaccoCode: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.salesGrade || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        salesGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Buying Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.buyingGrade || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        buyingGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Internal Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.internalGrade || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        internalGrade: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Origin</Typography>
                  <Autocomplete
                    options={origin}
                    value={selectedOrigin || ""}
                    onChange={(event, newValue) => {
                      const newTerm = newValue ? newValue.value : "";
                      setSelectedOrigin(newTerm);
                      setItem({
                        ...item,
                        origin: newTerm,
                      });
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        label={item?.origin}
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />

                </Grid>

                {((selectedOrigin === "China" || item?.origin === "China") &&
                  <Grid item xs={12} sm={2}>
                    <Typography variant="body2">Province</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={item?.province || ""}
                      onChange={(e) =>
                        setItem({
                          ...item,
                          province: e.target.value,
                        })
                      }
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Type</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={item?.type || ""}
                    id="outlined-select-type"
                    select
                    onChange={(e) =>
                      setItem({
                        ...item,
                        type: e.target.value,
                      })
                    }
                  >
                    {type.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Leaf Form</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={item?.leafForm || ""}
                    id="outlined-select-leaf-form"
                    select
                    onChange={(e) =>
                      setItem({ ...item, leafForm: e.target.value })
                    }
                  >
                    {leafForm.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Color</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={item?.color || ""}
                    id="outlined-select-color"
                    select
                    onChange={(e) =>
                      setItem({ ...item, color: e.target.value })
                    }
                  >
                    {color.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Crop Year</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    value={item?.crop || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        crop: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Current Location</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-storage"
                    value={item?.storage || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        storage: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Nicotine Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    value={item?.nicotine || ""}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        nicotine: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sugar Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    value={item?.sugar || ""}
                    variant="outlined"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        sugar: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Moisture Value</Typography>
                  <TextField
                    size="small"
                    type="number"
                    fullWidth
                    value={item?.moisture || ""}
                    variant="outlined"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        moisture: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Status</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={item?.status}
                    id="outlined-select-status"
                    select
                    onChange={(e) =>
                      setItem({
                        ...item,
                        status: e.target.value,
                      })
                    }
                  >
                    {recordStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1.5, mt: 4, mr: 1 }}
                  onClick={() => {
                    setIsEditItemOpen(false);
                    setItem([]);
                    setSelectedOrigin("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 4 }}
                  onClick={() => {
                    updateItemApi(item.id, item)
                      .then((response) => {
                        toast.success("Item edited successfully.");
                      })
                      .catch((error) => {
                        toast.error("Something went wrong.");
                        console.log(error.response.data);
                      });
                    setIsEditItemOpen(false);
                    setItem([]);
                    setSelectedOrigin("");
                  }}
                >
                  Save Item
                </Button>
              </Grid>
            </Grid>{" "}
          </>
        )}

        {isNewSampleOpen && (
          <>
            {" "}
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                New Sample Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "30px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid container xs={12} spacing="8px">
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sample Purpose</Typography>
                  <TextField
                    id="outlined-select-status"
                    select
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={!sample?.purpose}
                    helperText={!sample?.purpose ? "Sample purpose is required" : ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        purpose: e.target.value,
                      })
                    }
                  >
                    {samplePurposes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Item</Typography>
                  <Autocomplete
                    options={items}
                    getOptionLabel={(option) =>
                      `ID: ${option.id || ""} - Buying Grade: ${option.buyingGrade || ""}  - ${option?.origin || ""} - Crop: ${option.crop || ""}`
                    }
                    value={sample?.itemId}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        itemId: newValue ? newValue.id : null,
                      });
                    }}
                    onOpen={retrieveItems}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />

                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sample Image Link</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        image: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sender</Typography>
                  <Autocomplete
                    options={stakeholders}
                    getOptionLabel={(option) =>
                      `${option.id || ""} - ${option.type || ""} - ${option.name || ""}`
                    }
                    value={sample?.senderId}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        senderId: newValue ? newValue.id : null,
                      });
                    }}
                    onOpen={retrieveStakeholders}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />

                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Receiver</Typography>
                  <Autocomplete
                    options={stakeholders}
                    getOptionLabel={(option) =>
                      `${option.id || ""} - ${option.type || ""} - ${option.name || ""}`
                    }
                    value={sample?.receiverId}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        receiverId: newValue ? newValue.id : null,
                      });
                    }}
                    onOpen={retrieveStakeholders}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                    renderTags={() => null}
                  />

                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Number Of Samples</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    fullWidth
                    value={sample?.numberOfSamples || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        numberOfSamples: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Net Weight Per Sample</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    fullWidth
                    value={sample?.netWeightPerSample || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        netWeightPerSample: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Nicotine</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesNicotine: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Sugar</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesSugar: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Moisture</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesMoisture: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Origin</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesOrigin: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Sales Crop</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesCrop: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">
                    Enter Date Of Delivery
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="date"
                    type="date"
                    inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        deliveryDate: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Date Of Receive</Typography>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="date"
                    type="date"
                    inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        receiveDate: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">
                    Enter Courier Information
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        courier: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter AWB Number</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        awbNumber: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Enter Remark</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        remark: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">
                    Enter Customer Feedback
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        customerFeedback: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Is Sample Approved</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        approved: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">
                    Enter SG Approval Status
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        approvalDetails: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sample Paid By</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        paidBy: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Status</Typography>
                  <TextField
                    id="outlined-select-status"
                    select
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        status: e.target.value,
                      })
                    }
                  >
                    {recordStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1.5, mt: 4, mr: 1 }}
                  onClick={() => {
                    setIsNewSampleOpen(false);
                    setSample([]);
                    setSelectedOrigin("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 4 }}
                  onClick={() => {
                    createSampleApi(sample)
                      .then((response) => {
                        toast.success("Sample added successfully.");
                      })
                      .catch((error) => {
                        console.log(error.response.data);
                        toast.error("Something Went Wrong.");
                      });
                    setIsNewSampleOpen(false);
                    setSample([]);
                    setSelectedOrigin("");
                  }}
                >
                  Create Sample
                </Button>
              </Grid>
            </Grid>{" "}
          </>
        )}
        {isEditSampleOpen && (
          <>
            {" "}
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                Edit Sample {sample?.id} Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "30px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid container xs={12} spacing="8px">
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Sample Purpose</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={!sample?.purpose}
                    helperText={!sample?.purpose ? "Sample purpose is required" : ""}
                    id="outlined-select-purpose"
                    select
                    value={sample?.purpose || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        purpose: e.target.value,
                      })
                    }
                  >
                    {samplePurposes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Item</Typography>
                  <Autocomplete
                    options={items}
                    getOptionLabel={(option) =>
                      `ID: ${option.id || ""} - Buying Grade: ${option.buyingGrade || ""}  - ${option?.origin || ""} - Crop: ${option.crop || ""}`
                    }
                    value={sample?.item?.id}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        item: {
                          ...sample?.item,
                          id: newValue ? newValue.id : null,
                        },
                      });
                    }}
                    onOpen={retrieveItems}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                        label={
                          (sample?.item?.id || "") +
                          " " +
                          (sample?.item?.buyingGrade || "")
                        }
                      />
                    )}
                    renderTags={() => null}
                  />

                </Grid>


                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Image URL</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.image || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        image: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sender</Typography>
                  <Autocomplete
                    options={stakeholders}
                    getOptionLabel={(option) =>
                      `${option.id || ""} - ${option.type || ""} - ${option.name || ""}`
                    }
                    value={sample?.sender?.id}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        sender: {
                          ...sample?.sender,
                          id: newValue ? newValue.id : null,
                        },
                      });
                    }}
                    onOpen={retrieveStakeholders}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                        label={
                          (sample?.sender?.id || "") +
                          " " +
                          (sample?.sender?.name || "")
                        }
                      />
                    )}
                    renderTags={() => null}
                  />

                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Receiver</Typography>
                  <Autocomplete
                    options={stakeholders}
                    getOptionLabel={(option) =>
                      `${option.id || ""} - ${option.type || ""} - ${option.name || ""}`
                    }
                    value={sample?.receiver?.id}
                    onChange={(event, newValue) => {
                      setSample({
                        ...sample,
                        receiver: {
                          ...sample?.receiver,
                          id: newValue ? newValue.id : null,
                        },
                      });
                    }}
                    onOpen={retrieveStakeholders}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                        label={
                          (sample?.receiver?.id || "") +
                          " " +
                          (sample?.receiver?.name || "")
                        }
                      />
                    )}
                    renderTags={() => null}
                  />

                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Number Of Samples</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    fullWidth
                    value={sample?.numberOfSamples || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        numberOfSamples: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Net Weight Per Sample</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    fullWidth
                    value={sample?.netWeightPerSample || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        netWeightPerSample: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Grade</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.salesGrade || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesGrade: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Nicotine</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    onWheel={(e) => e.target.blur()}
                    value={sample?.salesNicotine || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesNicotine: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Sugar</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    value={sample?.salesSugar || ""}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesSugar: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Moisture</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    value={sample?.salesMoisture || ""}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesMoisture: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Origin</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.salesOrigin || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesOrigin: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Sales Crop</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="number"
                    fullWidth
                    value={sample?.salesCrop || ""}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        salesCrop: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Date Of Delivery</Typography>
                  <TextField
                    id="date"
                    type="date"
                    inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={
                      sample?.deliveryDate
                        ? format(parseISO(sample?.deliveryDate), "yyyy-MM-dd")
                        : ""
                    }
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        deliveryDate: e.target.value
                          ? e.target.value + "T00:00:00.000+00:00"
                          : null,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Date Of Receive</Typography>
                  <TextField
                    id="date"
                    type="date"
                    inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={
                      sample?.receiveDate
                        ? format(parseISO(sample?.receiveDate), "yyyy-MM-dd")
                        : ""
                    }
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        receiveDate: e.target.value
                          ? e.target.value + "T00:00:00.000+00:00"
                          : null,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Courier</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.courier || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        courier: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">AWB Number</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.awbNumber || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        awbNumber: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Remark</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.remark || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        remark: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Customer Feedback</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.customerFeedback || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        customerFeedback: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Approved</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.approved || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        approved: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Approval Details</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.approvalDetails || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        approvalDetails: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Paid By</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={sample?.paidBy || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        paidBy: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2"> Status</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    id="outlined-select-status"
                    select
                    value={sample?.status || ""}
                    onChange={(e) =>
                      setSample({
                        ...sample,
                        status: e.target.value,
                      })
                    }
                  >
                    {recordStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1.5, mt: 4, mr: 1 }}
                  onClick={() => {
                    setIsEditSampleOpen(false);
                    setSample([]);
                    setSelectedOrigin("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mt: 4 }}
                  onClick={() => {
                    updateSampleApi(sample?.id, sample)
                      .then((response) => {
                        toast.success("Sample edited successfully.");
                      })
                      .catch((error) => {
                        toast.error("Something went wrong.");
                        console.log(error.response.data);
                      });
                    setIsEditSampleOpen(false);
                    setSample([]);
                    setSelectedOrigin("");
                  }}
                >
                  Save Sample
                </Button>
              </Grid>
            </Grid>{" "}
          </>
        )}

        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>
              releaseLock(editCustomerOffer?.data?.id)
                .then(() => {
                  setEditCustomerOffer({
                    visible: false,
                    data: null,
                    index: null,
                  });
                })
                .catch((error) => {
                  toast.error("Error releasing lock:", error);
                })
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={isButtonDisabled}
            onClick={() => {
              releaseLock(editCustomerOffer?.data?.id)
                .then(() => {
                  let updatedOfferData = { ...editCustomerOffer.data };

                  if (editCustomerOffer?.data?.approvalStatus === "Approved") {
                    updatedOfferData = {
                      ...updatedOfferData,
                      operation: "APPROVEDEDIT",
                      approvalStatus: "Not Approved"
                    };
                  }

                  updateOfferApi(
                    updatedOfferData.id,
                    updatedOfferData,
                  )
                    .then(() => {
                      toast.success("Customer offer edited successfully.");

                      if (rows && setRows) {
                        const idx = rows.findIndex(row => row.id === updatedOfferData.id);
                      
                        if (idx !== -1) {
                          const newRows = [...rows];
                          newRows[idx] = updatedOfferData; 
                          setRows(newRows); 
                        }
                      }

                      setEditCustomerOffer({
                        visible: false,
                        data: null,
                        index: null,
                      });
                    })
                    .catch((error) => {
                      toast.error("Error updating offer:", error);
                    });
                })
                .catch((error) => {
                  toast.error("Error releasing lock:", error);
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              releaseLock(editCustomerOffer?.data?.id)
                .then(() => {
                  editCustomerOffer.data.recordStatus = "PASSIVE";
                  updateOfferApi(
                    editCustomerOffer.data.id,
                    editCustomerOffer.data,
                  )
                    .then((response) => {
                      toast.success("Customer offer deleted successfully.");
                      window.location.reload();

                      navigate("/customer-offer-view");
                    })
                    .catch((error) => {
                      toast.error("Something went wrong.");
                      console.log(error.response.data);
                    });
                  setEditCustomerOffer({
                    visible: false,
                    data: null,
                    index: null,
                  });
                })
                .catch((error) => {
                  toast.error("Error updating offer:", error);
                });
            }}
          >
            Delete
          </Button>
        </Stack>
        {isButtonDisabled && (
          <Typography style={{ color: "red", marginTop: "10px" }}>
            Please ensure all required fields are completed.
          </Typography>
        )}
      </Stack>
    </>
  );
};

export default EditCustomerOffer;
