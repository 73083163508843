import {
  Autocomplete,
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import { updateSampleApi } from "../../../components/api/SampleApiService";
import { recordStatus, status, samplePurposes, origin } from "../../../utils/menu-items";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import { retrieveAllItemsApi, retrieveItemByIdApi } from "../../../components/api/ItemApiService";
import Box from "@mui/material/Box";
import toast from "react-hot-toast";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { retrieveQuantityByItemIdApi } from "../../../components/api/QuantityApiService";

const EditSample = (props) => {
  const { editSample, setEditSample } = props;
  const [item, setItem] = useState([]);
  const [items, setItems] = useState([]);
  const [stakeholders, setStakeholders] = useState([]);
  const [selectedItem, setSelectedItem] = useState(editSample?.data?.item);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const anchorRef = React.useRef(null);
  const options = ["New", "Edit"];
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState([]);
  const [isNewItemOpen, setIsNewItemOpen] = useState(false);
  const [isEditItemOpen, setIsEditItemOpen] = useState(false);
  const [selectedOrigin, setSelectedOrigin] = useState(editSample?.data?.item?.origin);
  const [selectedSender, setSelectedSender] = useState(
    editSample?.data?.sender || "",
  );
  const [selectedReceiver, setSelectedReceiver] = useState(
    editSample?.data?.receiver || "",
  );

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClick = () => {
    if (selectedIndex === 0) {
      setIsNewItemOpen(true);
      setIsEditItemOpen(false);
    } else {
      setIsEditItemOpen(true);
      setIsNewItemOpen(false);
      retrieveItemById(editSample?.data?.itemId);
    }
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };
  function retrieveQuantity(itemId) {
    retrieveQuantityByItemIdApi(itemId)
      .then((response) => {
        console.log("QUANTITY: ", response?.data)
        setQuantity(response?.data)
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveItemById(id) {
    retrieveItemByIdApi(id)
      .then((response) => {
        setItem(response?.data);
        console.log(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveItems() {
    retrieveAllItemsApi()
      .then((response) => {
        setItems(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveStakeholders() {
    Promise.all([
      retrieveAllStakeholdersApi("VENDOR"),
      retrieveAllStakeholdersApi("CUSTOMER")
    ])
      .then(([vendorResponse, customerResponse]) => {
        setStakeholders([
          ...vendorResponse?.data,
          ...customerResponse?.data
        ]);
      })
      .catch((error) => console.log(error.response?.data));
  }

  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Edit Sample
        </Typography>
        <Grid container item xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2"> Sample Purpose</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              error={!editSample?.data?.purpose}
              helperText={!editSample?.data?.purpose ? "Sample purpose is required" : ""}
              id="outlined-select-purpose"
              select
              value={editSample?.data?.purpose}
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    purpose: e.target.value,
                  },
                })
              }
            >
              {samplePurposes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select An Item</Typography>
            <Grid container spacing="8px">
              <Grid item xs={12} sm={8}>
                <Autocomplete
                  options={items}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - Buying Grade: ${option?.buyingGrade || ""} - ${option?.origin || ""} - ${option?.crop || ""}`
                  }
                  value={editSample?.data?.item?.id}
                  onChange={(event, newValue) => {
                    setSelectedItem(newValue);
                    setSelectedOrigin(newValue ? newValue?.origin : "");
                    setEditSample({
                      ...editSample,
                      data: {
                        ...editSample.data,
                        salesGrade: newValue ? newValue?.buyingGrade : null,
                        salesNicotine: newValue ? newValue?.nicotine : null,
                        salesSugar: newValue ? newValue?.sugar : null,
                        salesOrigin: newValue ? newValue?.origin : null,
                        salesCrop: newValue ? newValue?.crop : null,
                        item: {
                          ...editSample.data.item,
                          id: newValue ? newValue.id : null,
                        },
                      },
                    });
                    retrieveQuantity(newValue?.id);
                  }}
                  onOpen={retrieveItems}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={
                        (editSample?.data?.item?.id || "") +
                        " " +
                        (editSample?.data?.item?.buyingGrade || "") +
                        " " +
                        (editSample?.data?.item?.salesGrade || "")
                      }
                    />
                  )}
                  renderTags={() => null}
                />
                {selectedItem && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${selectedItem?.id || ""}`}
                      style={{ marginBottom: '5px' }}
                      onDelete={() => setSelectedItem(null)}
                    /><br />
                    <Chip
                      label={`Origin: ${selectedItem?.origin || ""}`}
                      style={{ marginBottom: '5px' }}
                    /><br />
                    <Chip
                      label={`Type: ${selectedItem?.type || ""}`}
                      style={{ marginBottom: '5px' }}
                    /><br />
                    <Chip
                      label={`Buying Grade: ${selectedItem?.buyingGrade || ""}`}
                      style={{ marginBottom: '5px' }}
                    /><br />
                    <Chip
                      label={`Sales Grade: ${selectedItem?.salesGrade || ""}`}
                      style={{ marginBottom: '5px' }}
                    /><br />
                    <Chip
                      label={`Crop: ${selectedItem?.crop || ""}`}
                      style={{ marginBottom: '5px' }}
                    /><br />
                    <Chip
                      label={`Nicotine: ${selectedItem?.nicotine || ""}`}
                      style={{ marginBottom: '5px' }}
                    /><br></br>
                    <Chip
                      label={`Sugar: ${selectedItem?.sugar || ""}`}
                      style={{ marginBottom: '5px' }}
                    /><br></br>
                    <Chip
                      label={`Storage: ${selectedItem?.storage || ""}`}
                      style={{ marginBottom: '5px' }}
                    /><br />
                    <Chip
                      label={`Quantity: ${quantity?.quantityRemainingInNetKg || ""} KG`}
                      style={{ marginBottom: '5px' }}
                    /><br />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <ButtonGroup
                  variant="outlined"
                  ref={anchorRef}
                  aria-label="Button group with a nested menu"
                >
                  <Button onClick={handleClick}>
                    {options[selectedIndex]}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    sx={{
                      width: "20px !important",
                      minWidth: "0px !important",
                    }}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                disabled={index === 2}
                                selected={index === selectedIndex}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
          </Grid>



          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Image URL</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editSample?.data?.image}
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    image: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Sender</Typography>
            <Autocomplete
              options={stakeholders}
              getOptionLabel={(option) =>
                `${option.id || ""} - ${option.type || ""} - ${option.name || ""}`
              }
              value={selectedSender}
              onChange={(event, newValue) => {
                setSelectedSender(newValue);
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    sender: {
                      ...(editSample.data.sender || {}),
                      id: newValue ? newValue.id : null,
                    },
                  },
                })
              }}
              onOpen={retrieveStakeholders}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedSender && (
              <Box mt={2}>
                <Chip
                  label={`ID: ${selectedSender?.id || ""}`}
                  style={{ marginBottom: '5px' }}
                  onDelete={() => setSelectedSender(null)}
                /><br />
                <Chip
                  label={`Type: ${selectedSender?.type || ""}`}
                  style={{ marginBottom: '5px' }}
                /><br />
                <Chip
                  label={`Name: ${selectedSender?.name || ""}`}
                  style={{ marginBottom: '5px' }}
                /><br />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select Receiver</Typography>
            <Autocomplete
              options={stakeholders}
              getOptionLabel={(option) =>
                `${option.id || ""} - ${option.type || ""} - ${option.name || ""}`
              }
              value={selectedReceiver}
              onChange={(event, newValue) => {
                setSelectedReceiver(newValue);
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    receiver: {
                      ...(editSample.data.receiver || {}),
                      id: newValue ? newValue.id : null,
                    },
                  },
                })
              }}
              onOpen={retrieveStakeholders}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedReceiver && (
              <Box mt={2}>
                <Chip
                  label={`ID: ${selectedReceiver?.id || ""}`}
                  style={{ marginBottom: '5px' }}
                  onDelete={() => setSelectedReceiver(null)}
                /><br />
                <Chip
                  label={`Type: ${selectedReceiver?.type || ""}`}
                  style={{ marginBottom: '5px' }}
                /><br />
                <Chip
                  label={`Name: ${selectedReceiver?.name || ""}`}
                  style={{ marginBottom: '5px' }}
                /><br />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Number Of Samples</Typography>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              onWheel={(e) => e.target.blur()}
              value={editSample?.data?.numberOfSamples}
              fullWidth
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    numberOfSamples: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Net Weight Per Sample</Typography>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              onWheel={(e) => e.target.blur()}
              value={editSample?.data?.netWeightPerSample}
              fullWidth
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    netWeightPerSample: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Sales Grade</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editSample?.data?.salesGrade}
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    salesGrade: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Sales Nicotine</Typography>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={editSample?.data?.salesNicotine}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    salesNicotine: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Sales Sugar</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editSample?.data?.salesSugar}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    salesSugar: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Sales Origin</Typography>
            <Autocomplete
              options={origin}
              value={selectedOrigin}
              onChange={(event, newValue) => {
                const newTerm = newValue ? newValue.value : "";
                setSelectedOrigin(newTerm);
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    salesOrigin: newTerm,
                  },
                })
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedOrigin && (
              <Box mt={2}>
                <Chip
                  label={
                    origin.find((term) => term.value === selectedOrigin)
                      ?.label || ""
                  }
                  onDelete={() => setSelectedOrigin("")}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Sales Origin</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editSample?.data?.salesOrigin}
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    salesOrigin: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Sales Crop</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editSample?.data?.salesCrop}
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    salesCrop: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Date Of Delivery</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editSample?.data?.deliveryDate
                  ? format(parseISO(editSample.data.deliveryDate), "yyyy-MM-dd")
                  : ""
              }
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    deliveryDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Date Of Receive</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editSample?.data?.receiveDate
                  ? format(parseISO(editSample.data.receiveDate), "yyyy-MM-dd")
                  : ""
              }
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    receiveDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Courier</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editSample?.data?.courier}
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    courier: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">AWB Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editSample?.data?.awbNumber}
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    awbNumber: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editSample?.data?.remark}
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    remark: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Customer Feedback</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editSample?.data?.customerFeedback}
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    customerFeedback: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Approved</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editSample?.data?.approved}
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    approved: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Approval Details</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editSample?.data?.approvalDetails}
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    approvalDetails: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Paid By</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editSample?.data?.paidBy}
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    paidBy: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Recod Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              value={editSample?.data?.status}
              onChange={(e) =>
                setEditSample({
                  ...editSample,
                  data: {
                    ...editSample.data,
                    status: e.target.value,
                  },
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>
              setEditSample({ visible: false, data: null, index: null })
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              updateSampleApi(editSample.data.id, editSample.data)
                .then((response) => {
                  toast.success("Sample edited successfully.");
                  window.location.reload();
                  setEditSample({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              editSample.data.status = "PASSIVE";
              updateSampleApi(editSample.data.id, editSample.data)
                .then((response) => {
                  toast.success("Sample deleted successfully.");
                  window.location.reload();
                  setEditSample({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default EditSample;
