import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import imageCompression from "browser-image-compression";
import { createTicketApi } from "../../../components/api/TicketApiService";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { retrieveUsersApi } from "../../../components/api/UserApiService";
import { ClipLoader } from 'react-spinners';

// Example Priorities & Statuses
const priorities = [
  { value: "Low", label: "Low" },
  { value: "Normal", label: "Normal" },
  { value: "High", label: "High" },
  { value: "Critical", label: "Critical" },
];

const statuses = [
  { value: "Open", label: "Open" },
  { value: "In Progress", label: "In Progress" },
  { value: "Closed", label: "Closed" },
];

const maxImages = 3;

// A helper to convert a File (or Blob) to a base64-encoded string
const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      // e.g.: "data:image/png;base64, iVBOR..."
      // we split off "data:image..." and keep only the actual base64 after the comma
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };
    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(file);
  });

export default function AddTicket(props) {
  const { addViewStates, setAddViewStates, rows, setRows, setAddView } = props;
  const navigate = useNavigate();

  const [previewImage, setPreviewImage] = useState(null);
  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Initialize default fields if missing
    if (!addViewStates?.recordStatus) {
      setAddViewStates((prev) => ({ ...prev, recordStatus: "ACTIVE" }));
    }
    if (!addViewStates?.status) {
      setAddViewStates((prev) => ({ ...prev, status: "Open" }));
    }
    if (!addViewStates?.priority) {
      setAddViewStates((prev) => ({ ...prev, priority: "Normal" }));
    }
    if (!addViewStates?.images) {
      setAddViewStates((prev) => ({ ...prev, images: [] }));
    }
    retrieveUsersApi()
      .then((response) => {
        setUsers(response?.data || []);
      })
      .catch((error) => console.log(error.response?.data));
  }, []);

  // File input changed: compress + convert to base64
  const handleFileChange = async (event) => {
    const fileList = Array.from(event.target.files);
    const currentImages = addViewStates?.images || [];

    if (fileList.length + currentImages.length > maxImages) {
      alert(`You can only attach up to ${maxImages} images.`);
      return;
    }

    const options = { maxSizeMB: 1, maxWidthOrHeight: 1000, useWebWorker: true };
    const newImages = [];

    for (const file of fileList) {
      try {
        // 1) compress the file
        const compressedFile = await imageCompression(file, options);
        // 2) convert compressedFile to base64
        const base64String = await fileToBase64(compressedFile);

        // We'll store { base64: base64String, previewUrl } so we can show a preview
        const previewUrl = URL.createObjectURL(compressedFile);
        newImages.push({
          base64: base64String, // <--- fixed naming here
          previewUrl,
        });
      } catch (error) {
        console.error("Image compression error:", error);
      }
    }

    // Merge these images into addViewStates.images
    setAddViewStates((prev) => ({
      ...prev,
      images: [...(prev.images || []), ...newImages],
    }));
  };

  // Remove image by index
  const removeImage = (index) => {
    setAddViewStates((prev) => {
      const updated = [...(prev.images || [])];
      URL.revokeObjectURL(updated[index].previewUrl);
      updated.splice(index, 1);
      return { ...prev, images: updated };
    });
  };

  // When user hits "Add"
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const finalTicket = {
        ...addViewStates,
        createdBy: addViewStates?.createdBy || "user123",
        timestamp: new Date(),
      };
      window.location.reload();
      // Update local table
      setRows([finalTicket, ...rows]);

      // Convert images => array of base64 strings
      const imagesBase64Array = (finalTicket.images || []).map((imgObj) => {
        return imgObj.base64;
      });

      // Build payload for backend
      const payloadForApi = {
        ...finalTicket,
        images: imagesBase64Array,
      };

      const response = await createTicketApi(payloadForApi);
      toast.success("Ticket added successfully.");
      setAddView(false);

    } catch (error) {
      toast.error("Something went wrong. " + (error?.response?.data || ""));
      console.log(error?.response?.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Create New Ticket
      </Typography>

      {/* Title */}
      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        size="small"
        value={addViewStates?.title || ""}
        error={!addViewStates?.title}
        helperText={!addViewStates?.title ? "Ticket title is required" : ""}
        onChange={(e) => setAddViewStates({ ...addViewStates, title: e.target.value })}
        sx={{ mb: 2 }}
      />

      {/* Priority + Status */}
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <TextField
          label="Priority"
          select
          fullWidth
          variant="outlined"
          size="small"
          value={addViewStates?.priority || ""}
          onChange={(e) => setAddViewStates({ ...addViewStates, priority: e.target.value })}
        >
          {priorities.map((p) => (
            <MenuItem key={p.value} value={p.value}>
              {p.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Status"
          select
          fullWidth
          variant="outlined"
          size="small"
          disabled
          value={addViewStates?.status || ""}
        >
          {statuses.map((s) => (
            <MenuItem key={s.value} value={s.value}>
              {s.label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <Autocomplete
        options={users}
        getOptionLabel={(option) => `${option.name} (${option.username})`}
        value={addViewStates?.assignedTo || null}
        onChange={(event, newValue) =>
          setAddViewStates({
            ...addViewStates,
            assignedTo: newValue,
            operation: "ASSIGNMENT"
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Assign To"
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
        sx={{ mb: 2 }}
      />

      {/* Description */}
      <TextField
        label="Description"
        variant="outlined"
        fullWidth
        multiline
        rows={6}
        value={addViewStates?.description || ""}
        onChange={(e) => setAddViewStates({ ...addViewStates, description: e.target.value })}
        sx={{ mb: 2 }}
      />

      {/* Attachments */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Attach images (max {maxImages})
        </Typography>
        <Button variant="outlined" component="label">
          Select Images
          <input hidden accept="image/*" multiple type="file" onChange={handleFileChange} />
        </Button>

        {/* Image Previews */}
        <Box sx={{ mt: 2, display: "flex", gap: 2, flexWrap: "wrap" }}>
          {(addViewStates?.images || []).map((item, idx) => (
            <Box
              key={idx}
              sx={{
                position: "relative",
                width: 100,
                height: 100,
                borderRadius: 2,
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #ccc",
                cursor: "pointer",
              }}
              onClick={() => setPreviewImage(item.previewUrl)}
            >
              <img
                src={item.previewUrl}
                alt="preview"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: 5,
                }}
              />
              <Chip
                size="small"
                icon={<DeleteIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  removeImage(idx);
                }}
                label="Remove"
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  color: "white",
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Stack direction="row" spacing={2}>
        <Button variant="outlined" onClick={() => setAddView(false)}>
          Cancel
        </Button>
        {loading ? (
          <ClipLoader color="#000000" loading={loading} size={35} />
        ) : (
          <Button variant="contained" onClick={handleSubmit}>
            Add
          </Button>
        )}
      </Stack>

      {/* Image Preview Modal */}
      {previewImage && (
        <Dialog open={!!previewImage} onClose={() => setPreviewImage(null)} maxWidth="md">
          <DialogTitle>Image Preview</DialogTitle>
          <DialogContent>
            <img
              src={previewImage}
              alt="Full Preview"
              style={{ width: "100%", height: "auto", borderRadius: 5 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPreviewImage(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </Paper>
  );
}
