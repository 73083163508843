import { apiClient } from "./ApiClient";

export const createWorkLogApi = (worklog) =>
  apiClient.post(`/work-log-view/add`, worklog);

export const retrieveAllWorkLogsApi = () =>
  apiClient.get(`/work-log-view/worklogs`);

export const updateWorkLogApi = (worklogId, worklog) =>
  apiClient.put(`/work-log-view/edit/${worklogId}`, worklog);

export const retrieveFilteredWorkLogsApi = (filterDto) =>
  apiClient.post(`/work-log-view/filter`, filterDto);

export const retrieveWorkLogByIdApi = (worklogId) =>
  apiClient.get(`/work-log-view/get/${worklogId}`);

export const deleteMultipleWorkLogsApi = (idList) =>
  apiClient.put(`/work-log-view/delete-list`, idList);

export const retrieveSelectedWorkLogsByIdApi = (idList) =>
  apiClient.post(`/work-log-view/worklogs/get-selected`, idList);
