import axios from 'axios';

 /*export const apiClient = axios.create({
  baseURL: "http://localhost:8080",
  headers: {
    'Content-Type': 'application/json',
  }
});*/


/*export const apiClient = axios.create({
  baseURL: "https://san-back-staging.onrender.com",
  headers: {
    'Content-Type': 'application/json',
  }
});*/


export const apiClient = axios.create({
  baseURL: "https://san-back-h0xa.onrender.com",
  headers: {
    'Content-Type': 'application/json',
  }
});
