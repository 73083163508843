import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip
} from "@mui/material";
import { retrieveFilteredOrdersApi } from "../../../../components/api/OrderApiService";
import { format, parseISO } from "date-fns";

const SoExistsDialog = ({ open, onClose, onConfirm, stakeholderId, itemId }) => {
  const [loading, setLoading] = useState(true);
  const [existingOrders, setExistingOrders] = useState([]);

  useEffect(() => {
    if (open) {
      checkExistingOrders();
    }
  }, [open]);

  const checkExistingOrders = async () => {
    setLoading(true);
    try {
      const requestDto = {
        stakeholderIdList: [stakeholderId],
        itemIdList: [itemId],
        recordStatusList: ["ACTIVE"], 
        orderType: "CUSTOMER",
      };
      const response = await retrieveFilteredOrdersApi(requestDto);
      if (response?.data?.length > 0) {
        setExistingOrders(response.data);
      } else {
        handleProceed(); 
      }
    } catch (error) {
      console.error("Error checking existing SO:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleProceed = () => {
    onClose();
    onConfirm(); 
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Existing Sales Orders</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : existingOrders.length > 0 ? (
          <>
            <p>A sales order already exists with the selected stakeholder and item.</p>
            <TableContainer
      component={Paper}
      sx={{
        border: "2px solid #d1dfe8",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Table
        sx={{
          borderCollapse: "separate",
          borderSpacing: "0",
          width: "100%",
        }}
      >
        <TableHead>
          <TableRow sx={{ backgroundColor: "#98c1d9" }}>
            <TableCell
              sx={{
                width: "8%", 
                border: "1px solid #d1dfe8",
                fontSize: "0.8rem",
                fontWeight: "bold",
                textAlign: "center",
                padding: "8px",
                color: "#fff",
                whiteSpace: "nowrap",
              }}
            >
              SO ID
            </TableCell>
            <TableCell
              sx={{
                width: "8%", 
                border: "1px solid #d1dfe8",
                fontSize: "0.8rem",
                fontWeight: "bold",
                textAlign: "center",
                padding: "8px",
                color: "#fff",
                whiteSpace: "nowrap",
              }}
            >
              SAP SO Number
            </TableCell>
            <TableCell
              sx={{
                width: "15%",
                border: "1px solid #d1dfe8",
                fontSize: "0.8rem",
                fontWeight: "bold",
                textAlign: "center",
                padding: "8px",
                color: "#fff",
                whiteSpace: "nowrap",
              }}
            >
              Item
            </TableCell>
            <TableCell
              sx={{
                width: "30%", 
                border: "1px solid #d1dfe8",
                fontSize: "0.8rem",
                fontWeight: "bold",
                textAlign: "center",
                padding: "8px",
                color: "#fff",
              }}
            >
              Stakeholder
            </TableCell>
            <TableCell
              sx={{
                width: "12%",
                border: "1px solid #d1dfe8",
                fontSize: "0.8rem",
                fontWeight: "bold",
                textAlign: "center",
                padding: "8px",
                color: "#fff",
              }}
            >
              Quantity
            </TableCell>
            <TableCell
              sx={{
                width: "10%",
                border: "1px solid #d1dfe8",
                fontSize: "0.8rem",
                fontWeight: "bold",
                textAlign: "center",
                padding: "8px",
                color: "#fff",
                whiteSpace: "nowrap",
              }}
            >
              Issue Date
            </TableCell>
            <TableCell
              sx={{
                width: "10%",
                border: "1px solid #d1dfe8",
                fontSize: "0.8rem",
                fontWeight: "bold",
                textAlign: "center",
                padding: "8px",
                color: "#fff",
                whiteSpace: "nowrap",
              }}
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {existingOrders.map((order) => (
            <TableRow
              key={order?.id}
              sx={{
                "&:nth-of-type(odd)": { backgroundColor: "#f5f5f5" },
                "&:hover": { backgroundColor: "#e0f2f1" },
              }}
            >
               <TableCell sx={{ border: "1px solid #d1dfe8", textAlign: "center" }}>
                {order?.id}
              </TableCell>
                <TableCell sx={{
                  border: "1px solid #d1dfe8",
                  textAlign: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}>
                {order?.salesOrderNumber}
              </TableCell>
              <TableCell sx={{ border: "1px solid #d1dfe8", textAlign: "center" }}>
                {order?.offer?.item?.buyingGrade || "N/A"}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #d1dfe8",
                  textAlign: "center",
                  maxWidth: "250px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Tooltip title={order?.offer?.stakeholder?.name || "N/A"} arrow>
                  <span>{order?.offer?.stakeholder?.name || "N/A"}</span>
                </Tooltip>
              </TableCell>
              <TableCell sx={{ border: "1px solid #d1dfe8", textAlign: "center" }}>
                  {order?.volume !== undefined && order?.volume !== null
                    ? new Intl.NumberFormat("en-US", { style: "decimal", minimumFractionDigits: 2 }).format(order.volume)
                    : "0.00"}
                </TableCell>
              <TableCell sx={{
                  border: "1px solid #d1dfe8",
                  textAlign: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}>
                {order?.operationDate ? format(
                                      parseISO(order.operationDate),
                                      "dd-MMM-yyyy",
                                    )
                                  : ""}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #d1dfe8",
                  textAlign: "center",
                  color: order?.recordStatus === "ACTIVE" ? "green" : "red",
                  fontWeight: "bold",
                }}
              >
                {order?.recordStatus || "N/A"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            <p>Do you still want to create this SO?</p>
          </>
        ) : null}
      </DialogContent>
      {!loading && existingOrders.length > 0 && (
        <DialogActions>
          <Button onClick={onClose}>No</Button>
          <Button onClick={handleProceed} color="primary">
            Yes
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SoExistsDialog;
