import React from "react";
import {
    Grid,
    Typography,
    TextField,
    MenuItem,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { format, parseISO } from "date-fns";
import toast from "react-hot-toast";
import { createCostApi, updateCostApi, eurUsdExchangeApi } from "../../../../components/api/CostApiService";
import { currencies, recordStatus } from "../../../../utils/menu-items";
import { ReloadOutlined } from "@ant-design/icons";
import RefreshIcon from "@mui/icons-material/Refresh";

const toNum = (val) => parseFloat(val) || 0;

const sumAllCosts = (data) => {
    // Sum all cost fields except profit
    return (
        toNum(data.basePrice) +
        toNum(data.freightCost) +
        toNum(data.insuranceCost) +
        toNum(data.storageCost) +
        toNum(data.financeCost) +
        toNum(data.others) +
        toNum(data.firstCommision) +
        toNum(data.secondCommision) +
        toNum(data.thirdCommision)
    );
};

const recalcTotals = (data) => {
    const totalBeforeProfit = sumAllCosts(data);
    data.totalCost = totalBeforeProfit.toFixed(2);
    data.totalOfferCost = (totalBeforeProfit + toNum(data.profit)).toFixed(2);
};

// Maps currency fields to their cost fields
const costFieldMap = {
    basePriceCurrency: "basePrice",
    freightCurrency: "freightCost",
    insuranceCurrency: "insuranceCost",
    storageCostCurrency: "storageCost",
    financeCostCurrency: "financeCost",
    othersCurrency: "others",
    firstCommisionCurrency: "firstCommision",
    secondCommisionCurrency: "secondCommision",
    thirdCommisionCurrency: "thirdCommision",
    profitCurrency: "profit",
};

function CostDialog({
    open,
    onClose,
    offerOperation,
    type = "new",
    cost,
    setCost,
    selectedCost,
    setSelectedCost,
    editCustomerOffer,
    setEditCustomerOffer,
    index
}) {
    if (!open) return null;

    // Close the dialog
    const handleClose = () => {
        onClose();
    };

    // Exchange logic
    const handleCurrencyExchange = async (currencyField, amount) => {
        const costField = costFieldMap[currencyField]; // e.g. "freightCurrency" -> "freightCost"
        if (!costField) return;

        const currentCurrency = cost[currencyField];
        const targetCurrency = currentCurrency === "EUR" ? "USD" : "EUR";

        try {
            const response = await eurUsdExchangeApi(currentCurrency, amount);
            const newFloatValue = parseFloat(response?.data) || 0;

            // Update cost in state
            setCost((prev) => {
                const newData = { ...prev };
                newData[costField] = newFloatValue;
                newData[currencyField] = targetCurrency;
                recalcTotals(newData);
                return newData;
            });
        } catch (error) {
            console.error(error?.response?.data || error.message);
            // revert if needed
        }
    };

    const handleChange = (field, value, extraLogicFn) => {
        // Generic field update + re-sum
        setCost((prev) => {
            const newData = { ...prev };
            newData[field] = value;

            // If there's extra logic (e.g. freightCost from pricePerContainer / kg)
            if (extraLogicFn) extraLogicFn(newData);

            recalcTotals(newData);
            return newData;
        });
    };

    const handleCreate = () => {
      if(offerOperation==="add"){
        createCostApi(cost)
            .then((response) => {
                let dummyArr = [...editCustomerOffer];
                dummyArr[index] = {
                    ...dummyArr[index],
                    estimatedCostId: response?.data?.id || "",
                    firstOfferPrice: response?.data?.totalOfferCost || "",
                    finalOfferPrice: response?.data?.totalOfferCost || "",
                };
                if (setEditCustomerOffer) {
                    setEditCustomerOffer(dummyArr);
                }
                setSelectedCost(response?.data || "");
                setCost({});
                toast.success("Cost added successfully.");
                onClose();
            })
            .catch((error) => {
                console.error("Error:", error?.response?.data || error.message);
                toast.error("Something Went Wrong.");
            });
      }else if(offerOperation==="edit"){
        createCostApi(cost)
        .then((response) => {
            if (setEditCustomerOffer) {
                setEditCustomerOffer({...editCustomerOffer, 
                  data: {
                    ...editCustomerOffer.data,
                  estimatedCost: response?.data || {},
                  firstOfferPrice: response?.data?.totalOfferCost || "",
                  finalOfferPrice: response?.data?.totalOfferCost || "",
                },
                });
            }
            setSelectedCost(response?.data || "");
            setCost({});
            toast.success("Cost added successfully.");
            onClose();
        })
        .catch((error) => {
            console.error("Error:", error?.response?.data || error.message);
            toast.error("Something Went Wrong.");
        });
      }
    };

    const handleUpdate = () => {
      if(offerOperation==="add"){
        updateCostApi(cost.id, cost)
            .then(() => {
                toast.success("Cost edited successfully.");
                setCost({});
            })
            .catch((error) => {
                toast.error("Something went wrong.");
                console.log(error?.response?.data || error.message);
            });

        onClose();
        setCost({});
        let dummyArr = editCustomerOffer;
        dummyArr[index] = {
            ...dummyArr[index],
            estimatedCost: cost || {},
            firstOfferPrice: cost?.totalOfferCost,
            finalOfferPrice: cost?.totalOfferCost,
        };
        if (setEditCustomerOffer) {
            setEditCustomerOffer([...dummyArr]);
        }
        setSelectedCost(cost)
      }else if(offerOperation==="edit"){
          updateCostApi(cost.id, cost)
          .then(() => {
              toast.success("Cost edited successfully.");
              setCost({});
          })
          .catch((error) => {
              toast.error("Something went wrong.");
              console.log(error?.response?.data || error.message);
          });

          onClose();
          setCost({});
          if (setEditCustomerOffer) {
            setEditCustomerOffer({...editCustomerOffer, 
              data: {
                ...editCustomerOffer.data,
              estimatedCost: cost || {},
              firstOfferPrice: cost?.totalOfferCost || "",
              finalOfferPrice: cost?.totalOfferCost || "",
            },
            });
        }
          setSelectedCost(cost)
          }
        };

    const dialogTitle = type === "new" ? "New Cost Details" : `Edit Cost ${cost?.id || ""} Details`;

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                {dialogTitle}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>

                <Stack direction="column">
                    <Grid container spacing={2}>
                        {/* Buying Price Per Kg (Disabled) */}
                        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                            <Typography variant="body2">Base Price /KG</Typography>
                            <TextField
                                disabled
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={cost?.basePrice || ""}
                            />
                        </Grid>

                        {/* Buying Price Per Kg Currency (Disabled) */}
                        <Grid item xs={12} sm={2} md={1.5} lg={1}>
                            <Typography variant="body2">Currency</Typography>
                            <TextField
                                disabled
                                variant="outlined"
                                size="small"
                                fullWidth
                                label={cost?.basePriceCurrency || ""}
                            />
                        </Grid>
       {/* Exchange Buttons (Base Price) 
                <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                  <Typography variant="body2">Exchange</Typography>
                  <Button
                    variant="outlined"
                    disabled={
                      cost?.basePriceCurrency === "USD"
                    }
                    onClick={() =>
                      handleCurrencyExchange(
                        "basePriceCurrency",
                        cost?.basePrice || 0.0
                      )
                    }
                  >
                    $
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={
                      cost?.basePriceCurrency === "EUR"
                    }
                    onClick={() =>
                      handleCurrencyExchange(
                        "basePriceCurrency",
                        cost?.basePrice || 0.0
                      )
                    }
                  >
                    €
                  </Button>
      </Grid>*/}
      <Grid item xs={1} sm={1}>
                        <Typography variant="body2">Refresh</Typography>
                           <IconButton 
                                onClick={(e) => {
                                  if(offerOperation==="add"){
                                    handleChange("basePrice", editCustomerOffer[index]?.buyingPrice)
                                    handleChange("basePriceCurrency", editCustomerOffer[index]?.buyingPriceCurrency)
                                  }else if(offerOperation==="edit"){
                                    handleChange("basePrice", editCustomerOffer?.data?.buyingPrice)
                                    handleChange("basePriceCurrency", editCustomerOffer?.data?.buyingPriceCurrency)
                                  }
                                  }}
                                size="small"
                                sx={{ color:"red"}}
                            >
                                <RefreshIcon fontSize="small" />
                            </IconButton>
                       </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        {/* Freight Cost */}
                        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                            <Typography variant="body2">Freight Cost /KG</Typography>
                            <TextField
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={cost?.freightCost || ""}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => handleChange("freightCost", e.target.value)}
                            />
                        </Grid>

                        {/* Freight Currency */}
                        <Grid item xs={12} sm={2} md={1.5} lg={1}>
                            <Typography variant="body2">Currency</Typography>
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                select
                                value={cost?.freightCurrency || "USD"}
                                onChange={(e) => handleChange("freightCurrency", e.target.value)}
                            >
                                {currencies.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        {/* Exchange Buttons (Freight) */}
                        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                            <Typography variant="body2">Exchange Freight</Typography>
                            <Button
                                variant="outlined"
                                disabled={cost?.freightCurrency === "USD"}
                                onClick={() => handleCurrencyExchange("freightCurrency", cost?.freightCost || 0)}
                            >
                                $
                            </Button>
                            <Button
                                variant="outlined"
                                disabled={cost?.freightCurrency === "EUR"}
                                onClick={() => handleCurrencyExchange("freightCurrency", cost?.freightCost || 0)}
                            >
                                €
                            </Button>
                        </Grid>

<Grid item xs={12} sm={2}>
          <Typography variant="body2">Freight Offer Date</Typography>
          <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) =>
                handleChange("freightOfferDate", e.target.value)
              }
            />
        </Grid>

                        {/* Price Per Container */}
                         <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                            <Typography variant="body2">Price Per Container</Typography>
                            <TextField
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={cost?.pricePerContainer || ""}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) =>
                                    handleChange("pricePerContainer", e.target.value, (data) => {
                                        // Extra logic: freightCost = pricePerContainer / kgPerContainer
                                        const ppc = toNum(e.target.value);
                                        const kg = toNum(data.kgPerContainer) || 1;
                                        data.freightCost = (ppc / kg).toFixed(2);
                                    })
                                }
                            />
                        </Grid>

                        {/* Kg Per Container */}
                        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                            <Typography variant="body2">Kg Per Container</Typography>
                            <TextField
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={cost?.kgPerContainer || ""}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) =>
                                    handleChange("kgPerContainer", e.target.value, (data) => {
                                        // Extra logic: freightCost = pricePerContainer / kgPerContainer
                                        const kg = toNum(e.target.value) || 1;
                                        const ppc = toNum(data.pricePerContainer);
                                        data.freightCost = (ppc / kg).toFixed(2);
                                    })
                                }
                            />
                        </Grid>
                    </Grid>
                    
      <Grid container spacing={2}>
        {/* Insurance Cost */}
        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
          <Typography variant="body2">Insurance Cost /KG</Typography>
          <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={cost?.insuranceCost}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                handleChange("insuranceCost", e.target.value)
              }
            />
        </Grid>

        {/* Insurance Currency */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Currency</Typography>
          <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-insurance-cost-currency"
              select
              label={cost?.insuranceCurrency}
              onChange={(e) => handleChange("insuranceCurrency", e.target.value)}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
        </Grid>

        {/* Exchange Buttons (Insurance) */}
        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
          <Typography variant="body2">Exchange</Typography>
          <Button
            variant="outlined"
            disabled={
              cost?.insuranceCurrency === "USD"
            }
            onClick={() =>
              handleCurrencyExchange(
                "insuranceCurrency",
                cost?.insuranceCost || 0.0
              )
            }
          >
            $
          </Button>
          <Button
            variant="outlined"
            disabled={
              cost?.insuranceCurrency === "EUR"
            }
            onClick={() =>
              handleCurrencyExchange(
                "insuranceCurrency",
                cost?.insuranceCost || 0.0
              )
            }
          >
            €
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {/* Storage Cost */}
        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
          <Typography variant="body2">Storage Cost /KG</Typography>
          <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={cost?.storageCost}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                handleChange("storageCost", e.target.value)
              }
            />
        </Grid>

        {/* Storage Cost Currency */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Currency</Typography>
          <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-storage-cost-currency"
              select
              label={cost?.storageCostCurrency}
              onChange={(e) => handleChange("storageCostCurrency", e.target.value)}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
        </Grid>

        {/* Exchange Buttons (Storage) */}
        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
          <Typography variant="body2">Exchange</Typography>
          <Button
             variant="outlined"
             disabled={
               cost?.storageCostCurrency === "USD"
             }
             onClick={() =>
              handleCurrencyExchange(
                "storageCostCurrency",
                cost?.storageCost || 0.0
              )
            }
           >
             $
           </Button>
           <Button
             variant="outlined"
             disabled={
               cost?.storageCostCurrency === "EUR"
             }
             onClick={() =>
              handleCurrencyExchange(
                "storageCostCurrency",
                cost?.storageCost || 0.0
              )
            }
           >
             €
           </Button>
        </Grid>
      </Grid>
                    <Grid container spacing={2}>
                                {/* First Commision */}
                                <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                                  <Typography variant="body2">1st Service Fee /KG</Typography>
                                  <TextField
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={cost?.firstCommision}
                                  onWheel={(e) => e.target.blur()}
                                  onChange={(e) =>
                                    handleChange("firstCommision", e.target.value)
                                  }
                                />
                                </Grid>
                              
                                {/* First Commision Currency */}
                                <Grid item xs={12} sm={2} md={1.5} lg={1}>
                                  <Typography variant="body2">Currency</Typography>
                                  <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  id="outlined-select-status"
                                  select
                                  label={cost?.firstCommisionCurrency}
                                  onChange={(e) => handleChange("firstCommisionCurrency", e.target.value)}
                                >
                                  {currencies.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                </Grid>
                              
                                {/* Exchange Buttons (First Commision) */}
                                <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                                  <Typography variant="body2">Exchange</Typography>
                                  <Button
                                    variant="outlined"
                                    disabled={
                                      cost?.firstCommisionCurrency === "USD"
                                    }
                                    onClick={() =>
                                      handleCurrencyExchange(
                                        "firstCommisionCurrency",
                                        cost?.firstCommision || 0.0
                                      )
                                    }
                                  >
                                    $
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    disabled={
                                      cost?.firstCommisionCurrency === "EUR"
                                    }
                                    onClick={() =>
                                      handleCurrencyExchange(
                                        "firstCommisionCurrency",
                                        cost?.firstCommision || 0.0
                                      )
                                    }
                                  >
                                    €
                                  </Button>
                                </Grid>
                              
                                {/* Second Commision */}
                                <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                                  <Typography variant="body2">2nd Service Fee /KG</Typography>
                                  <TextField
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={cost?.secondCommision}
                                  onWheel={(e) => e.target.blur()}
                                  onChange={(e) =>
                                    handleChange("secondCommision", e.target.value)
                                  }
                                />
                                </Grid>
                              
                                {/* Second Commision Currency */}
                                <Grid item xs={12} sm={2} md={1.5} lg={1}>
                                  <Typography variant="body2">Currency</Typography>
                                  <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  id="outlined-select-status"
                                  select
                                  label={cost?.secondCommisionCurrency}
                                  onChange={(e) => handleChange("secondCommisionCurrency", e.target.value)}
                                >
                                  {currencies.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                </Grid>
                              
                                {/* Exchange Buttons (Second Commision) */}
                                <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                                  <Typography variant="body2">Exchange</Typography>
                                  <Button
                                      variant="outlined"
                                      disabled={
                                        cost?.secondCommisionCurrency === "USD"
                                      }
                                      onClick={() =>
                                        handleCurrencyExchange(
                                          "secondCommisionCurrency",
                                          cost?.secondCommision || 0.0
                                        )
                                      }
                                    >
                                      $
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      disabled={
                                        cost?.secondCommisionCurrency === "EUR"
                                      }
                                      onClick={() =>
                                        handleCurrencyExchange(
                                          "secondCommisionCurrency",
                                          cost?.secondCommision || 0.0
                                        )
                                      }
                                    >
                                      €
                                    </Button>
                                </Grid>
                              
                                {/* Third Commision */}
                                <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                                  <Typography variant="body2">3rd Service Fee /KG</Typography>
                                  <TextField
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={cost?.thirdCommision}
                                  onWheel={(e) => e.target.blur()}
                                  onChange={(e) =>
                                    handleChange("thirdCommision", e.target.value)
                                  }
                                />
                                </Grid>
                              
                                {/* Third Commision Currency */}
                                <Grid item xs={12} sm={2} md={1.5} lg={1}>
                                  <Typography variant="body2">Currency</Typography>
                                  <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  id="outlined-select-status"
                                  select
                                  label={cost?.thirdCommisionCurrency}
                                  onChange={(e) => handleChange("thirdCommisionCurrency", e.target.value)}
                                >
                                  {currencies.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                </Grid>
                              
                                {/* Exchange Buttons (Third Commision) */}
                                <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                                  <Typography variant="body2">Exchange</Typography>
                                  <Button
                                    variant="outlined"
                                    disabled={
                                      cost?.thirdCommisionCurrency === "USD"
                                    }
                                    onClick={() =>
                                      handleCurrencyExchange(
                                        "thirdCommisionCurrency",
                                        cost?.thirdCommision || 0.0
                                      )
                                    }
                                  >
                                    $
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    disabled={
                                      cost?.thirdCommisionCurrency === "EUR"
                                    }
                                    onClick={() =>
                                      handleCurrencyExchange(
                                        "thirdCommisionCurrency",
                                        cost?.thirdCommision || 0.0
                                      )
                                    }
                                  >
                                    €
                                  </Button>
                                </Grid>
                              </Grid>

                                        <Grid container spacing={2}>
                                                {/* Finance Cost */}
                                                <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                                                  <Typography variant="body2">Finance Cost /KG</Typography>
                                                  <TextField
                                                      type="number"
                                                      variant="outlined"
                                                      size="small"
                                                      fullWidth
                                                      value={cost?.financeCost}
                                                      onWheel={(e) => e.target.blur()}
                                                      onChange={(e) =>
                                                        handleChange("financeCost", e.target.value)
                                                      }
                                                    />
                                                </Grid>
                                        
                                                {/* Finance Cost Currency */}
                                                <Grid item xs={12} sm={2} md={1.5} lg={1}>
                                                  <Typography variant="body2">Currency</Typography>
                                                  <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    id="outlined-select-finance-cost-currency"
                                                    select
                                                    label={cost?.financeCostCurrency}
                                                    onChange={(e) => handleChange("financeCostCurrency", e.target.value)}
                                                  >
                                                    {currencies.map((option) => (
                                                      <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                      </MenuItem>
                                                    ))}
                                                  </TextField>
                                                </Grid>
                                        
                                                {/* Exchange Buttons (Finance) */}
                                                <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                                                  <Typography variant="body2">Exchange</Typography>
                                                  <Button
                                                     variant="outlined"
                                                     disabled={
                                                       cost?.financeCostCurrency === "USD"
                                                     }
                                                     onClick={() =>
                                                      handleCurrencyExchange(
                                                        "financeCostCurrency",
                                                        cost?.financeCost || 0.0
                                                      )
                                                    }
                                                   >
                                                     $
                                                   </Button>
                                                   <Button
                                                     variant="outlined"
                                                     disabled={
                                                       cost?.financeCostCurrency === "EUR"
                                                     }
                                                     onClick={() =>
                                                      handleCurrencyExchange(
                                                        "financeCostCurrency",
                                                        cost?.financeCost || 0.0
                                                      )
                                                    }
                                                   >
                                                     €
                                                   </Button>
                                                </Grid>
                                              </Grid>
                              
                                    <Grid container spacing={2}>
                                      {/* Others */}
                                      <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                                        <Typography variant="body2">Others /KG</Typography>
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            value={cost?.others}
                                            onWheel={(e) => e.target.blur()}
                                            onChange={(e) =>
                                              handleChange("others", e.target.value)
                                            }
                                          />
                                      </Grid>
                              
                                      {/* Others Currency */}
                                      <Grid item xs={12} sm={2} md={1.5} lg={1}>
                                        <Typography variant="body2">Currency</Typography>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            id="outlined-select-other-cost-currency"
                                            select
                                            label={cost?.othersCurrency}
                                            onChange={(e) => handleChange("othersCurrency", e.target.value)}
                                          >
                                            {currencies.map((option) => (
                                              <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                      </Grid>
                              
                                      {/* Exchange Buttons (Others) */}
                                      <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                                        <Typography variant="body2">Exchange</Typography>
                                        <Button
                                           variant="outlined"
                                           disabled={
                                             cost?.othersCurrency === "USD"
                                           }
                                           onClick={() =>
                                            handleCurrencyExchange(
                                              "othersCurrency",
                                              cost?.others || 0.0
                                            )
                                          }
                                         >
                                           $
                                         </Button>
                                         <Button
                                           variant="outlined"
                                           disabled={
                                             cost?.othersCurrency === "EUR"
                                           }
                                           onClick={() =>
                                            handleCurrencyExchange(
                                              "othersCurrency",
                                              cost?.others || 0.0
                                            )
                                          }
                                         >
                                           €
                                         </Button>
                                      </Grid>
                              
                                      {/* Others Remark */}
                                      <Grid item xs={12} sm={2}>
                                        <Typography variant="body2">Other Costs Remark</Typography>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            value={cost?.othersRemark || ""}
                                            onChange={(e) => handleChange("othersRemark", e.target.value)}
                                          />
                                      </Grid>
                                    </Grid>
                              
                               <Grid container spacing={2}>
                                      <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                                        <Typography variant="body2">Total Unit Cost Before Profit /KG</Typography>
                                        <TextField
                                                type="number"
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                  // Give the overall root a background/border radius, etc.
                                                  backgroundColor: '#ddebf7',
                                                  borderRadius: '4px',
                                               
                                                  // Then specifically target the disabled state
                                                  '&.Mui-disabled': {
                                                    opacity: 1, // remove default "disabled" dim
                                                    color: 'gray', // set text color
                                                  },
                                               
                                                  // OR to be super safe, target the input inside the disabled root:
                                                  '& .MuiOutlinedInput-input.Mui-disabled': {
                                                    color: 'gray',
                                                    WebkitTextFillColor: 'gray', // for Safari
                                                  },
                                                }}
                                                disabled
                                                fullWidth
                                                value={cost?.totalCost || ""}
                                                onWheel={(e) => e.target.blur()}
                                              />
                                      </Grid>
                                    </Grid>
                              
                                <Grid container spacing={2}>
                                      <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                                        <Typography variant="body2">Profit /KG</Typography>
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            value={cost?.profit}
                                            onWheel={(e) => e.target.blur()}
                                            onChange={(e) => handleChange("profit", e.target.value)}
                                          />
                                      </Grid>
                              
                                      {/* Profit Currency */}
                                      <Grid item xs={12} sm={2} md={1.5} lg={1}>
                                        <Typography variant="body2">Currency</Typography>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            id="outlined-select-profit-currency"
                                            select
                                            label={cost?.profitCurrency}
                                            onChange={(e) => handleChange("profitCurrency", e.target.value)}
                                          >
                                            {currencies.map((option) => (
                                              <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                      </Grid>
                              
                                      {/* Exchange Buttons (Profit) */}
                                      <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                                        <Typography variant="body2">Exchange</Typography>
                                        <Button
                                          variant="outlined"
                                          disabled={
                                            cost?.profitCurrency === "USD"
                                          }
                                          onClick={() =>
                                            handleCurrencyExchange("profitCurrency", cost?.profit || 0.0)
                                          }
                                        >
                                          $
                                        </Button>
                                        <Button
                                          variant="outlined"
                                          disabled={
                                            cost?.profitCurrency === "EUR"
                                          }
                                          onClick={() =>
                                            handleCurrencyExchange("profitCurrency", cost?.profit || 0.0)
                                          }
                                        >
                                          €
                                        </Button>
                                      </Grid>
                                    </Grid>
                              
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                                        <Typography variant="body2">Total Unit Cost After Profit /KG</Typography>
                                          <TextField
                                                type="number"
                                                variant="outlined"
                                                size="small"
                                                 sx={{
                                                   // Give the overall root a background/border radius, etc.
                                                   backgroundColor: '#9bc2e6',
                                                   borderRadius: '4px',
                                                 
                                                   // Then specifically target the disabled state
                                                   '&.Mui-disabled': {
                                                     opacity: 1, // remove default "disabled" dim
                                                     color: 'gray', // set text color
                                                   },
                                                 
                                                   // OR to be super safe, target the input inside the disabled root:
                                                   '& .MuiOutlinedInput-input.Mui-disabled': {
                                                     color: 'gray',
                                                     WebkitTextFillColor: 'gray', // for Safari
                                                   },
                                                 }}
                                                disabled
                                                fullWidth
                                                value={cost?.totalOfferCost || ""}
                                                onWheel={(e) => e.target.blur()}
                                              />
                                      </Grid>
                                    </Grid>
                     
                    {/* Remark & Status */}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <Typography variant="body2">Cost Remark</Typography>
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={cost?.remark || ""}
                                onChange={(e) => handleChange("remark", e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <Typography variant="body2">Cost Record Status</Typography>
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                select
                                value={cost?.status || ""}
                                onChange={(e) => handleChange("status", e.target.value)}
                            >
                                {recordStatus.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Stack>
                {/* Action Buttons */}
                <Stack direction="row" spacing={1} mt={2}>
                    {type === "new" ? (
                        <>
                            <Button variant="outlined" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={handleCreate}>
                                Create Cost
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button variant="outlined" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={handleUpdate}>
                                Save Cost
                            </Button>
                        </>
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default CostDialog;
