import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  LoadingOutlined,
  UserOutlined,
  DollarOutlined,
  FormOutlined,
  SendOutlined,
  FileDoneOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { Typography } from "@mui/material";

const icons = {
  FontSizeOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  AppstoreAddOutlined,
  UserOutlined,
  DollarOutlined,
  FormOutlined,
  SendOutlined,
  FileDoneOutlined,
  ClockCircleOutlined,
};

const utilities = {
  id: "utilities",
  title: (
    <Typography variant="h5" component="div">
      Master Data
    </Typography>
  ),
  type: "accordion",
  children: [
    {
      id: "item",
      title: "Items",
      type: "item",
      url: "/item-view",
      icon: icons.BarcodeOutlined,
      style: { 
        fontSize: "50px", // or 32px, 3rem, etc.
        fontWeight: "bold",
      },
    },
    {
      id: "vendor",
      title: "Vendors",
      type: "item",
      url: "/vendor-view",
      icon: icons.UserOutlined,
    },
    {
      id: "customer",
      title: "Customers",
      type: "item",
      url: "/customer-view",
      icon: icons.UserOutlined,
    },
    {
      id: "consignee",
      title: "Consignees",
      type: "item",
      url: "/consignee-view",
      icon: icons.UserOutlined,
    },
    {
      id: "address",
      title: "Addresses",
      type: "item",
      url: "/stakeholder-address-view",
      icon: icons.UserOutlined,
    },
    {
      id: "warehouse",
      title: "Warehouse",
      type: "item",
      url: "/warehouse-view",
      icon: icons.DatabaseOutlined,
    },
    {
      id: "storagePrice",
      title: "Storage Price",
      type: "item",
      url: "/storage-price",
      icon: icons.DatabaseOutlined,
    },
    {
      id: "cost",
      title: "Costs",
      type: "item",
      url: "/cost-view",
      icon: icons.DollarOutlined,
    },
  ],
};

export default utilities;
