import React, { useState } from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Box,
  Autocomplete,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import imageCompression from "browser-image-compression";
import { updateTicketApi } from "../../../components/api/TicketApiService";
import toast from "react-hot-toast";
import { retrieveUsersApi } from "../../../components/api/UserApiService";
import { ClipLoader } from 'react-spinners';

const priorities = [
  { value: "Low", label: "Low" },
  { value: "Normal", label: "Normal" },
  { value: "High", label: "High" },
  { value: "Critical", label: "Critical" },
];

const statuses = [
  { value: "Open", label: "Open" },
  { value: "In Progress", label: "In Progress" },
  { value: "Closed", label: "Closed" },
];

const recordStatusOptions = [
  { value: "ACTIVE", label: "ACTIVE" },
  { value: "PASSIVE", label: "PASSIVE" },
];

const maxImages = 3;

export default function EditTicket({ editTicket, setEditTicket, rows, setRows }) {
  const [previewImage, setPreviewImage] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedUser, setSelectedUser] = useState(
    editTicket?.data?.assignedTo || null,
  );
  if (!editTicket?.visible) {
    return null;
  }
  const data = editTicket?.data || {};

  if (!Array.isArray(data.imageBase64List)) {
    data.imageBase64List = [];
  }

  // Initialize existingImages from data.images or data.imageBase64List
  if (!Array.isArray(data.existingImages)) {
    data.existingImages = Array.isArray(data.images) && data.images.length > 0
      ? data.images
      : data.imageBase64List;
  }

  // Initialize newImages array if not defined
  if (!Array.isArray(data.newImages)) {
    data.newImages = [];
  }

  function retrieveUsers() {
    retrieveUsersApi()
      .then((response) => {
        setUsers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = (err) => reject(err);
      reader.readAsDataURL(file);
    });

  const handleFileChange = async (event) => {
    const fileList = Array.from(event.target.files);

    const existingCount = (editTicket?.data?.existingImages?.length) || 0;
    const newCount = (editTicket?.data?.newImages?.length) || 0;
    const totalCount = existingCount + newCount;

    if (fileList.length + totalCount > maxImages) {
      alert(`You can only attach up to ${maxImages} images.`);
      return;
    }
    const options = { maxSizeMB: 1, maxWidthOrHeight: 1000, useWebWorker: true };
    const newImages = [];

    for (const file of fileList) {
      try {
        const compressedFile = await imageCompression(file, options);
        const base64String = await fileToBase64(compressedFile);
        const previewUrl = URL.createObjectURL(compressedFile);
        newImages.push({
          base64: base64String,
          previewUrl,
        });
      } catch (error) {
        console.error("Image compression error:", error);
      }
    }

    // Update newImages array instead of images
    setEditTicket((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        newImages: [...(prev.data.newImages || []), ...newImages],
      },
    }));
  };

  const removeExistingImage = (index) => {
    setEditTicket((prev) => {
      const updatedData = { ...prev.data };
      const updatedArr = [...updatedData.existingImages];
      updatedArr.splice(index, 1);
      updatedData.existingImages = updatedArr;
      return { ...prev, data: updatedData };
    });
  };

  const removeNewImage = (index) => {
    setEditTicket((prev) => {
      const updatedData = { ...prev.data };
      const updatedArr = [...updatedData.newImages];
      URL.revokeObjectURL(updatedArr[index].previewUrl);
      updatedArr.splice(index, 1);
      updatedData.newImages = updatedArr;
      return { ...prev, data: updatedData };
    });
  };

  const handleCancel = () => {
    setEditTicket({ visible: false, data: null });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
    const mergedImages = [
      ...data.existingImages,
      ...data.newImages.map((item) => item.base64),
    ];

    const updatedTicket = {
      ...data,
      images: mergedImages,
    };

    const response = await updateTicketApi(editTicket?.data?.id, updatedTicket);
        toast.success("Ticket edited successfully.");
        window.location.reload();
        setEditTicket({ visible: false, data: null, index: null });

    } catch (error) {
      toast.error("Something went wrong.");
      console.log(error.response.data);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!window.confirm("Are you sure you want to delete this ticket?")) return;
    setLoading(true);
    try {
      const deletedTicket = { ...data, recordStatus: "PASSIVE" };
      if (rows && setRows) {
        const idx = rows.findIndex((r) => r.id === data.id);
        if (idx !== -1) {
          const updatedRows = [...rows];
          updatedRows[idx] = deletedTicket;
          setRows(updatedRows);
        }
      }

      const response = await updateTicketApi(deletedTicket?.id, deletedTicket);
      toast.success("Ticket edited successfully.");
      window.location.reload();
      setEditTicket({ visible: false, data: null, index: null });
    } catch (error) {
      toast.error("Something went wrong.");
      console.log(error?.response?.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={editTicket.visible} onClose={handleCancel} maxWidth="md" fullWidth>
        <DialogTitle>Edit Ticket #{data.id}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 1 }}>
            {/* TITLE */}
            <TextField
              label="Title"
              fullWidth
              size="small"
              value={data.title || ""}
              error={!data?.title}
              helperText={!data?.title ? "Ticket title is required" : ""}
              onChange={(e) =>
                setEditTicket({
                  ...editTicket,
                  data: { ...data, title: e.target.value },
                })
              }
            />

            {/* PRIORITY & STATUS */}
            <Stack direction="row" spacing={2}>
              <TextField
                label="Priority"
                select
                fullWidth
                size="small"
                value={data.priority || "Normal"}
                onChange={(e) =>
                  setEditTicket({
                    ...editTicket,
                    data: { ...data, priority: e.target.value },
                  })
                }
              >
                {priorities.map((p) => (
                  <MenuItem key={p.value} value={p.value}>
                    {p.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                label="Status"
                select
                fullWidth
                size="small"
                disabled
                value={data.status || "Open"}
              >
                {statuses.map((s) => (
                  <MenuItem key={s.value} value={s.value}>
                    {s.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>

            {(editTicket?.data?.assignedTo?.email === localStorage.getItem("username")) && <TextField
              label="Status Remark"
              fullWidth
              value={data.statusRemark || ""}
              onChange={(e) =>
                setEditTicket({
                  ...editTicket,
                  data: { ...data, statusRemark: e.target.value },
                })
              }
            />}

            <Autocomplete
              options={users}
              getOptionLabel={(option) =>
                `${option.name} (${option.username})`
              }
              value={selectedUser}
              onChange={(event, newValue) => {
                setSelectedUser(newValue);
                setEditTicket({
                  ...editTicket,
                  data: {
                    ...editTicket.data,
                    assignedTo: newValue ? newValue : null,
                    operation: "ASSIGNMENT",
                  },
                });
              }}
              onOpen={retrieveUsers}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={
                    ("Assigned User")
                  }
                />
              )}
              renderTags={() => null}
            />

            {/* DESCRIPTION */}
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={data.description || ""}
              onChange={(e) =>
                setEditTicket({
                  ...editTicket,
                  data: { ...data, description: e.target.value },
                })
              }
            />

            {/* RECORD STATUS */}
            <TextField
              label="Record Status"
              select
              fullWidth
              size="small"
              value={data.recordStatus || "ACTIVE"}
              onChange={(e) =>
                setEditTicket({
                  ...editTicket,
                  data: { ...data, recordStatus: e.target.value },
                })
              }
            >
              {recordStatusOptions.map((rs) => (
                <MenuItem key={rs.value} value={rs.value}>
                  {rs.label}
                </MenuItem>
              ))}
            </TextField>

            {data?.existingImages && data?.existingImages.length > 0 && (
              <Box>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Images:
                </Typography>
                <Stack direction="row" flexWrap="wrap" gap={2}>
                  {data.existingImages.map((imgBase64, index) => (
                    <Box
                      key={index}
                      sx={{
                        position: "relative",
                        width: 100,
                        height: 100,
                        borderRadius: 2,
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid #ccc",
                        cursor: "pointer",
                      }}
                      onClick={() => setPreviewImage(`data:image/png;base64,${imgBase64}`)}
                    >
                      <img
                        src={`data:image/png;base64,${imgBase64}`}
                        alt={`Ticket ${index}`}
                        width="100%"
                        height="100%"
                        style={{ objectFit: "cover" }}
                      />
                      <Chip
                        size="small"
                        icon={<DeleteIcon />}
                        onClick={(e) => {
                          e.stopPropagation();
                          removeExistingImage(index);
                        }}
                        label="Remove"
                        sx={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                          backgroundColor: "rgba(0,0,0,0.5)",
                          color: "white",
                        }}
                      />
                    </Box>
                  ))}
                </Stack>
              </Box>
            )}


            {/* NEW IMAGES */}
            <Box>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Add/Replace Images (max {maxImages})
              </Typography>
              <Button variant="outlined" component="label">
                Select Images
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={handleFileChange}
                />
              </Button>

              {data.newImages.length > 0 && (
                <Stack direction="row" flexWrap="wrap" gap={2} sx={{ mt: 2 }}>
                  {data.newImages.map((item, idx) => (
                    <Box
                      key={idx}
                      sx={{
                        position: "relative",
                        width: 100,
                        height: 100,
                        borderRadius: 2,
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid #ccc",
                        cursor: "pointer",
                      }}
                      onClick={() => setPreviewImage(item.previewUrl)}
                    >
                      <img
                        src={item.previewUrl}
                        alt="preview"
                        width="100%"
                        height="100%"
                        style={{ objectFit: "cover", borderRadius: 5 }}
                      />
                      <Chip
                        size="small"
                        icon={<DeleteIcon />}
                        onClick={(e) => {
                          e.stopPropagation();
                          removeNewImage(idx);
                        }}
                        label="Remove"
                        sx={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                          backgroundColor: "rgba(0,0,0,0.5)",
                          color: "white",
                        }}
                      />
                    </Box>
                  ))}
                </Stack>
              )}
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          {loading ? (
            <ClipLoader color="#000000" loading={loading} size={35} />
          ) : (
            <Button variant="contained" color="error" onClick={handleDelete}>
              Delete
            </Button>
          )}
          {loading ? (
            <ClipLoader color="#000000" loading={loading} size={35} />
          ) : (
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* PREVIEW IMAGE DIALOG */}
      {previewImage && (
        <Dialog
          open={!!previewImage}
          onClose={() => setPreviewImage(null)}
          maxWidth="md"
        >
          <DialogTitle>Image Preview</DialogTitle>
          <DialogContent>
            <img
              src={previewImage}
              alt="Full Preview"
              style={{ width: "100%", height: "auto", borderRadius: 5 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPreviewImage(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
