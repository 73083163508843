import {
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import { eurUsdExchangeApi, updateCostApi } from "../../../components/api/CostApiService";
import { currencies, incoterms, status, units, recordStatus, color } from "../../../utils/menu-items";
import toast from "react-hot-toast";

const EditCost = (props) => {
  const { editCost, setEditCost } = props;

  const initialCurrencyState = {
    basePriceCurrency: "USD",
    freightCurrency: "USD",
    insuranceCurrency: "USD",
    storageCostCurrency: "USD",
    financeCostCurrency: "USD",
    othersCurrency: "USD",
    firstCommisionCurrency: "USD",
    secondCommisionCurrency: "USD",
    thirdCommisionCurrency: "USD",
    profitCurrency: "USD",
  };
  
  const [currenciesState, setCurrenciesState] = useState(initialCurrencyState);
  
  const editCostFieldMap = {
    basePriceCurrency: "basePrice",
    freightCurrency: "freightCost",
    insuranceCurrency: "insuranceCost",
    storageCostCurrency: "storageCost",
    financeCostCurrency: "financeCost",
    othersCurrency: "others",
    firstCommisionCurrency: "firstCommision",
    secondCommisionCurrency: "secondCommision",
    thirdCommisionCurrency: "thirdCommision",
    profitCurrency: "profit",
  };

  const toNum = (val) => parseFloat(val) || 0;

  const sumAllCosts = (data) => {
    return (
      toNum(data.basePrice) +
      toNum(data.freightCost) +
      toNum(data.insuranceCost) +
      toNum(data.storageCost) +
      toNum(data.financeCost) +
      toNum(data.others) +
      toNum(data.firstCommision) +
      toNum(data.secondCommision) +
      toNum(data.thirdCommision)
    );
  };

  // Recalculate totalCost (sum of cost fields) AND totalOfferCost (totalCost + profit)
  const recalcTotals = (data) => {
    const totalBeforeProfit = sumAllCosts(data);
    data.totalCost = totalBeforeProfit.toFixed(2);
    data.totalOfferCost = (totalBeforeProfit + toNum(data.profit)).toFixed(2);
  };

  const handleCurrencyExchange = async (field, amount) => {
    const editCostField = editCostFieldMap[field];
    const currentCurrency = editCost?.data?.[field];
    const targetCurrency = currentCurrency === "EUR" ? "USD" : "EUR";
    
    if (!currentCurrency) {
      console.log(`Currency type for field ${field} is undefined`);
      return;
    }

    try {
      const response = await eurUsdExchangeApi(currentCurrency, amount);
      const newFloatValue = parseFloat(response?.data) || 0;
      const updatedCost = response?.data;
      setEditCost((prev) => {
        const newData = { ...prev.data };
        newData[editCostField] = newFloatValue;
        newData[field] = targetCurrency;
        recalcTotals(newData);
        return { ...prev, data: newData };
      });
    } catch (error) {
      console.error(`Failed to exchange currency: ${error.response?.data || error.message}`);
      setEditCost((prevCost) => ({
        ...prevCost,
        data: {
          ...prevCost.data,
          [editCostField]: prevCost.data[editCostField],
          [field]: prevCost.data[field],
        }
      }));
    }
  };

 const handleCostChange = (fieldName, newValue, extraLogicFn) => {
    setEditCost((prev) => {
      const newData = { ...prev.data };

      // Assign the new value to the specified field
      newData[fieldName] = newValue;

      // If there's extra logic (e.g. setting freightCost from pricePerContainer),
      // run it before recalculating totals.
      if (extraLogicFn) extraLogicFn(newData);

      // Recalculate totalCost and totalOfferCost
      recalcTotals(newData);

      return { ...prev, data: newData };
    });
  };

 return (
  <>
    <Stack direction="column" spacing={1}>
      <Typography variant="h2">
        Edit Cost {editCost?.data?.id}
      </Typography>

      {/* ------------------- Row: Base Price ------------------- */}
      <Grid container spacing={2}>
        {/* Base Price */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Base Price /KG</Typography>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            fullWidth
            onWheel={(e) => e.target.blur()}
            value={editCost?.data?.basePrice}
            onChange={(e) => handleCostChange("basePrice", e.target.value)}
          />
        </Grid>

        {/* Base Price Currency */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Currency</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            select
            value={editCost?.data?.basePriceCurrency || ""}
            onChange={(e) =>
              handleCostChange("basePriceCurrency", e.target.value)
            }
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Exchange Buttons (Base Price) */}
        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
          <Typography variant="body2">Exchange</Typography>
          <Button
            variant="outlined"
            disabled={editCost?.data?.basePriceCurrency === "USD"}
            onClick={() =>
              handleCurrencyExchange(
                "basePriceCurrency",
                editCost?.data?.basePrice || 0.0
              )
            }
          >
            $
          </Button>
          <Button
            variant="outlined"
            disabled={editCost?.data?.basePriceCurrency === "EUR"}
            onClick={() =>
              handleCurrencyExchange(
                "basePriceCurrency",
                editCost?.data?.basePrice || 0.0
              )
            }
          >
            €
          </Button>
        </Grid>
      </Grid>

      {/* ------------------- Row: Freight ------------------- */}
      <Grid container spacing={2}>
        {/* Freight Cost (/KG) */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Freight Cost /KG</Typography>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            sx={{backgroundColor:"#d6dce4", borderRadius:"4px"}}
            fullWidth
            onWheel={(e) => e.target.blur()}
            value={editCost?.data?.freightCost}
            onChange={(e) => handleCostChange("freightCost", e.target.value)}
          />
        </Grid>

        {/* Freight Currency */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Currency</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            select
            value={editCost?.data?.freightCurrency}
            onChange={(e) =>
              handleCostChange("freightCurrency", e.target.value)
            }
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Exchange Buttons (Freight) */}
        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
          <Typography variant="body2">Exchange</Typography>
          <Button
            variant="outlined"
            disabled={editCost?.data?.freightCurrency === "USD"}
            onClick={() =>
              handleCurrencyExchange(
                "freightCurrency",
                editCost?.data?.freightCost || 0.0
              )
            }
          >
            $
          </Button>
          <Button
            variant="outlined"
            disabled={editCost?.data?.freightCurrency === "EUR"}
            onClick={() =>
              handleCurrencyExchange(
                "freightCurrency",
                editCost?.data?.freightCost || 0.0
              )
            }
          >
            €
          </Button>
        </Grid>

        {/* Freight Offer By */}
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Freight Offer By</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={editCost?.data?.freightOfferBy}
            onChange={(e) => handleCostChange("freightOfferBy", e.target.value)}
          />
        </Grid>

        {/* Freight Offer Date */}
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Freight Offer Date</Typography>
          <TextField
            id="date"
            type="date"
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
            onKeyDown={(e) => e.preventDefault()}
            value={
              editCost?.data?.freightOfferDate
                ? format(
                    parseISO(editCost.data.freightOfferDate),
                    "yyyy-MM-dd"
                  )
                : ""
            }
            onChange={(e) =>
              handleCostChange("freightOfferDate", e.target.value)
            }
          />
        </Grid>

        {/* Price Per Container */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Price Per Container</Typography>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            fullWidth
            onWheel={(e) => e.target.blur()}
            value={editCost?.data?.pricePerContainer}
            onChange={(e) => {
              // Additional logic: freightCost = pricePerContainer / kgPerContainer
              handleCostChange("pricePerContainer", e.target.value, (data) => {
                const ppc = toNum(e.target.value);
                const kg = toNum(data.kgPerContainer) || 1;
                data.freightCost = (ppc / kg).toFixed(2);
              });
            }}
          />
        </Grid>

        {/* Kg Per Container */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Kg Per Container</Typography>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            fullWidth
            onWheel={(e) => e.target.blur()}
            value={editCost?.data?.kgPerContainer}
            onChange={(e) => {
              // Additional logic: freightCost = pricePerContainer / kgPerContainer
              handleCostChange("kgPerContainer", e.target.value, (data) => {
                const ppc = toNum(data.pricePerContainer);
                const kg = toNum(e.target.value) || 1;
                data.freightCost = (ppc / kg).toFixed(2);
              });
            }}
          />
        </Grid>
      </Grid>

      {/* ------------------- Row: Insurance ------------------- */}
      <Grid container spacing={2}>
        {/* Insurance Cost */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Insurance Cost /KG</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onWheel={(e) => e.target.blur()}
            value={editCost?.data?.insuranceCost}
            onChange={(e) =>
              handleCostChange("insuranceCost", e.target.value)
            }
          />
        </Grid>

        {/* Insurance Currency */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Currency</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            select
            id="outlined-select-insurance-cost-currency"
            value={editCost?.data?.insuranceCurrency}
            onChange={(e) =>
              handleCostChange("insuranceCurrency", e.target.value)
            }
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Exchange Buttons (Insurance) */}
        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
          <Typography variant="body2">Exchange</Typography>
          <Button
            variant="outlined"
            disabled={editCost?.data?.insuranceCurrency === "USD"}
            onClick={() =>
              handleCurrencyExchange(
                "insuranceCurrency",
                editCost?.data?.insuranceCost || 0.0
              )
            }
          >
            $
          </Button>
          <Button
            variant="outlined"
            disabled={editCost?.data?.insuranceCurrency === "EUR"}
            onClick={() =>
              handleCurrencyExchange(
                "insuranceCurrency",
                editCost?.data?.insuranceCost || 0.0
              )
            }
          >
            €
          </Button>
        </Grid>
      </Grid>

      {/* ------------------- Row: Storage ------------------- */}
      <Grid container spacing={2}>
        {/* Storage Cost */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Storage Cost /KG</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onWheel={(e) => e.target.blur()}
            value={editCost?.data?.storageCost}
            onChange={(e) =>
              handleCostChange("storageCost", e.target.value)
            }
          />
        </Grid>

        {/* Storage Cost Currency */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Currency</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            select
            id="outlined-select-storage-cost-currency"
            value={editCost?.data?.storageCostCurrency}
            onChange={(e) =>
              handleCostChange("storageCostCurrency", e.target.value)
            }
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Exchange Buttons (Storage) */}
        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
          <Typography variant="body2">Exchange</Typography>
          <Button
            variant="outlined"
            disabled={editCost?.data?.storageCostCurrency === "USD"}
            onClick={() =>
              handleCurrencyExchange(
                "storageCostCurrency",
                editCost?.data?.storageCost || 0.0
              )
            }
          >
            $
          </Button>
          <Button
            variant="outlined"
            disabled={editCost?.data?.storageCostCurrency === "EUR"}
            onClick={() =>
              handleCurrencyExchange(
                "storageCostCurrency",
                editCost?.data?.storageCost || 0.0
              )
            }
          >
            €
          </Button>
        </Grid>
      </Grid>

      {/* ------------------- Row: Commissions ------------------- */}
      <Grid container spacing={2}>
  {/* First Commision */}
  <Grid item xs={12} sm={2} md={1.5} lg={1}>
    <Typography variant="body2">1st Service Fee /KG</Typography>
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      type="number"
      onWheel={(e) => e.target.blur()}
      value={editCost?.data?.firstCommision}
      onChange={(e) =>
        handleCostChange("firstCommision", e.target.value)
      }
    />
  </Grid>

  {/* First Commision Currency */}
  <Grid item xs={12} sm={2} md={1.5} lg={1}>
    <Typography variant="body2">Currency</Typography>
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      select
      id="outlined-select-first-commision-currency"
      value={editCost?.data?.firstCommisionCurrency}
      onChange={(e) =>
        handleCostChange("firstCommisionCurrency", e.target.value)
      }
    >
      {currencies.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  </Grid>

  {/* Exchange Buttons (First Commision) */}
  <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
    <Typography variant="body2">Exchange</Typography>
    <Button
      variant="outlined"
      disabled={editCost?.data?.firstCommisionCurrency === "USD"}
      onClick={() =>
        handleCurrencyExchange(
          "firstCommisionCurrency",
          editCost?.data?.firstCommision || 0.0
        )
      }
    >
      $
    </Button>
    <Button
      variant="outlined"
      disabled={editCost?.data?.firstCommisionCurrency === "EUR"}
      onClick={() =>
        handleCurrencyExchange(
          "firstCommisionCurrency",
          editCost?.data?.firstCommision || 0.0
        )
      }
    >
      €
    </Button>
  </Grid>

  {/* Second Commision */}
  <Grid item xs={12} sm={2} md={1.5} lg={1}>
    <Typography variant="body2">2nd Service Fee /KG</Typography>
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      type="number"
      onWheel={(e) => e.target.blur()}
      value={editCost?.data?.secondCommision}
      onChange={(e) =>
        handleCostChange("secondCommision", e.target.value)
      }
    />
  </Grid>

  {/* Second Commision Currency */}
  <Grid item xs={12} sm={2} md={1.5} lg={1}>
    <Typography variant="body2">Currency</Typography>
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      select
      id="outlined-select-second-commision-currency"
      value={editCost?.data?.secondCommisionCurrency}
      onChange={(e) =>
        handleCostChange("secondCommisionCurrency", e.target.value)
      }
    >
      {currencies.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  </Grid>

  {/* Exchange Buttons (Second Commision) */}
  <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
    <Typography variant="body2">Exchange</Typography>
    <Button
      variant="outlined"
      disabled={editCost?.data?.secondCommisionCurrency === "USD"}
      onClick={() =>
        handleCurrencyExchange(
          "secondCommisionCurrency",
          editCost?.data?.secondCommision || 0.0
        )
      }
    >
      $
    </Button>
    <Button
      variant="outlined"
      disabled={editCost?.data?.secondCommisionCurrency === "EUR"}
      onClick={() =>
        handleCurrencyExchange(
          "secondCommisionCurrency",
          editCost?.data?.secondCommision || 0.0
        )
      }
    >
      €
    </Button>
  </Grid>

  {/* Third Commision */}
  <Grid item xs={12} sm={2} md={1.5} lg={1}>
    <Typography variant="body2">3rd Service Fee /KG</Typography>
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      type="number"
      onWheel={(e) => e.target.blur()}
      value={editCost?.data?.thirdCommision}
      onChange={(e) =>
        handleCostChange("thirdCommision", e.target.value)
      }
    />
  </Grid>

  {/* Third Commision Currency */}
  <Grid item xs={12} sm={2} md={1.5} lg={1}>
    <Typography variant="body2">Currency</Typography>
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      select
      id="outlined-select-third-commision-currency"
      value={editCost?.data?.thirdCommisionCurrency}
      onChange={(e) =>
        handleCostChange("thirdCommisionCurrency", e.target.value)
      }
    >
      {currencies.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  </Grid>

  {/* Exchange Buttons (Third Commision) */}
  <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
    <Typography variant="body2">Exchange</Typography>
    <Button
      variant="outlined"
      disabled={editCost?.data?.thirdCommisionCurrency === "USD"}
      onClick={() =>
        handleCurrencyExchange(
          "thirdCommisionCurrency",
          editCost?.data?.thirdCommision || 0.0
        )
      }
    >
      $
    </Button>
    <Button
      variant="outlined"
      disabled={editCost?.data?.thirdCommisionCurrency === "EUR"}
      onClick={() =>
        handleCurrencyExchange(
          "thirdCommisionCurrency",
          editCost?.data?.thirdCommision || 0.0
        )
      }
    >
      €
    </Button>
  </Grid>
</Grid>

      {/* ------------------- Row: Finance ------------------- */}
      <Grid container spacing={2}>
        {/* Finance Cost */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Finance Cost /KG</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onWheel={(e) => e.target.blur()}
            value={editCost?.data?.financeCost}
            onChange={(e) =>
              handleCostChange("financeCost", e.target.value)
            }
          />
        </Grid>

        {/* Finance Cost Currency */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Currency</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            select
            id="outlined-select-finance-cost-currency"
            value={editCost?.data?.financeCostCurrency}
            onChange={(e) =>
              handleCostChange("financeCostCurrency", e.target.value)
            }
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Exchange Buttons (Finance) */}
        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
          <Typography variant="body2">Exchange</Typography>
          <Button
            variant="outlined"
            disabled={editCost?.data?.financeCostCurrency === "USD"}
            onClick={() =>
              handleCurrencyExchange(
                "financeCostCurrency",
                editCost?.data?.financeCost || 0.0
              )
            }
          >
            $
          </Button>
          <Button
            variant="outlined"
            disabled={editCost?.data?.financeCostCurrency === "EUR"}
            onClick={() =>
              handleCurrencyExchange(
                "financeCostCurrency",
                editCost?.data?.financeCost || 0.0
              )
            }
          >
            €
          </Button>
        </Grid>
      </Grid>

      {/* ------------------- Row: Others ------------------- */}
      <Grid container spacing={2}>
        {/* Others */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Others /KG</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            onWheel={(e) => e.target.blur()}
            value={editCost?.data?.others}
            onChange={(e) =>
              handleCostChange("others", e.target.value)
            }
          />
        </Grid>

        {/* Others Currency */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Currency</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="outlined-select-other-cost-currency"
            select
            value={editCost?.data?.othersCurrency}
            onChange={(e) =>
              handleCostChange("othersCurrency", e.target.value)
            }
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Exchange Buttons (Others) */}
        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
          <Typography variant="body2">Exchange</Typography>
          <Button
            variant="outlined"
            disabled={editCost?.data?.othersCurrency === "USD"}
            onClick={() =>
              handleCurrencyExchange(
                "othersCurrency",
                editCost?.data?.others || 0.0
              )
            }
          >
            $
          </Button>
          <Button
            variant="outlined"
            disabled={editCost?.data?.othersCurrency === "EUR"}
            onClick={() =>
              handleCurrencyExchange(
                "othersCurrency",
                editCost?.data?.others || 0.0
              )
            }
          >
            €
          </Button>
        </Grid>

        {/* Others Remark */}
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Other Costs Remark</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={editCost?.data?.othersRemark}
            onChange={(e) =>
              handleCostChange("othersRemark", e.target.value)
            }
          />
        </Grid>
      </Grid>

      {/* ------------------- Row: Total Cost Before Profit (Auto) ------------------- */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Total Unit Cost Before Profit /KG</Typography>
          <TextField
            variant="outlined"
            size="small"
            sx={{
              // Give the overall root a background/border radius, etc.
              backgroundColor: '#ddebf7',
              borderRadius: '4px',
          
              // Then specifically target the disabled state
              '&.Mui-disabled': {
                opacity: 1, // remove default "disabled" dim
                color: 'gray', // set text color
              },
          
              // OR to be super safe, target the input inside the disabled root:
              '& .MuiOutlinedInput-input.Mui-disabled': {
                color: 'gray',
                WebkitTextFillColor: 'gray', // for Safari
              },
            }}
            fullWidth
            type="number"
            onWheel={(e) => e.target.blur()}
            value={editCost?.data?.totalCost}
            disabled
          />
        </Grid>
      </Grid>

      {/* ------------------- Row: Profit ------------------- */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Profit /KG</Typography>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            fullWidth
            onWheel={(e) => e.target.blur()}
            value={editCost?.data?.profit}
            onChange={(e) => handleCostChange("profit", e.target.value)}
          />
        </Grid>

        {/* Profit Currency */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Currency</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="outlined-select-profit-currency"
            select
            value={editCost?.data?.profitCurrency}
            onChange={(e) =>
              handleCostChange("profitCurrency", e.target.value)
            }
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Exchange Buttons (Profit) */}
        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
          <Typography variant="body2">Exchange</Typography>
          <Button
            variant="outlined"
            disabled={editCost?.data?.profitCurrency === "USD"}
            onClick={() =>
              handleCurrencyExchange(
                "profitCurrency",
                editCost?.data?.profit || 0.0
              )
            }
          >
            $
          </Button>
          <Button
            variant="outlined"
            disabled={editCost?.data?.profitCurrency === "EUR"}
            onClick={() =>
              handleCurrencyExchange(
                "profitCurrency",
                editCost?.data?.profit || 0.0
              )
            }
          >
            €
          </Button>
        </Grid>
      </Grid>

      {/* ------------------- Row: Total Cost After Profit (Auto) ------------------- */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Total Unit Cost After Profit /KG</Typography>
          <TextField
            variant="outlined"
            size="small"
            sx={{
              // Give the overall root a background/border radius, etc.
              backgroundColor: '#9bc2e6',
              borderRadius: '4px',
          
              // Then specifically target the disabled state
              '&.Mui-disabled': {
                opacity: 1, // remove default "disabled" dim
                color: 'gray', // set text color
              },
          
              // OR to be super safe, target the input inside the disabled root:
              '& .MuiOutlinedInput-input.Mui-disabled': {
                color: 'gray',
                WebkitTextFillColor: 'gray', // for Safari
              },
            }}
            fullWidth
            type="number"
            onWheel={(e) => e.target.blur()}
            value={editCost?.data?.totalOfferCost}
            disabled
          />
        </Grid>
      </Grid>

      {/* ------------------- Row: Remark & Status ------------------- */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Remark</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={editCost?.data?.remark}
            onChange={(e) =>
              handleCostChange("remark", e.target.value)
            }
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Status</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            id="outlined-select-status"
            select
            value={editCost?.data?.status}
            onChange={(e) => handleCostChange("status", e.target.value)}
          >
            {recordStatus.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {/* ------------------- Action Buttons ------------------- */}
      <Stack direction="row" spacing={1} mt={2}>
        <Button
          variant="outlined"
          onClick={() =>
            setEditCost({ visible: false, data: null, index: null })
          }
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            updateCostApi(editCost.data.id, editCost.data)
              .then(() => {
                toast.success("Cost edited successfully.");
                window.location.reload();
                setEditCost({ visible: false, data: null, index: null });
              })
              .catch((error) => {
                toast.error("Something went wrong.");
                console.log(error?.response?.data);
              });
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            editCost.data.status = "PASSIVE";
            updateCostApi(editCost.data.id, editCost.data)
              .then(() => {
                toast.success("Cost deleted successfully.");
                window.location.reload();
                setEditCost({ visible: false, data: null, index: null });
              })
              .catch((error) => {
                toast.error("Something went wrong.");
                console.log(error?.response?.data);
              });
          }}
        >
          Delete
        </Button>
      </Stack>
    </Stack>
    </>
  );
};

export default EditCost;

