import { ChromeOutlined, FormOutlined, QuestionOutlined } from "@ant-design/icons";
import { Typography } from "@mui/material";

const icons = {
  ChromeOutlined,
  QuestionOutlined,
  FormOutlined
};

const support = {
  id: "support-accordion",
  title: (
    <Typography variant="h5" component="div">
      Support
    </Typography>
  ),
  type: "accordion",
  children: [
    {
      id: "ticket",
      title: "Get Help",
      type: "item",
      url: "/ticket-view",
      icon: icons.FormOutlined,
    },
    {
      id: "workLogs",
      title: "WorkLog",
      type: "item",
      url: "/work-log-view",
      icon: icons.FormOutlined,
    },
    {
      id: "documentation",
      title: "Documentation",
      type: "item",
      url: "",
      icon: icons.QuestionOutlined,
      external: true,
      target: true
    }
  ]
};

export default support;
