import { lazy } from "react";

import Loadable from "../components/Loadable";
import MainLayout from "../layout/MainLayout";
import CostView from "../pages/dashboard/CostView/CostView";
import CustomerOfferView from "../pages/dashboard/CustomerOfferView/CustomerOfferView";
import FreightOfferView from "../pages/dashboard/FreightOfferView/FreightOfferView";
import CustomerOrderView from "../pages/dashboard/CustomerOrder/CustomerOrder";
import CustomerView from "../pages/dashboard/CustomerView/CustomerView";
import InventoryView from "../pages/dashboard/InventoryView/InventoryView";
import WarehouseView from "../pages/dashboard/WarehouseView/WarehouseView";
import ItemView from "../pages/dashboard/ItemView/ItemView";
import OfferView from "../pages/dashboard/OfferView/OfferView";
import PurchaseOrderView from "../pages/dashboard/PurchaseOrderView/PurchaseOrderView";
import SalesOrderView from "../pages/dashboard/SalesOrderView/SalesOrderView";
import SampleView from "../pages/dashboard/SampleView/SampleView";
import ShippingInstructionView from "../pages/dashboard/ShippingInstructionView/ShippingInstructionView";
import ShippingInstructionCostingView from "../pages/dashboard/ShippingInstructionCostingView/ShippingInstructionCostingView";
import ShipmentRequestView from "../pages/dashboard/ShipmentRequestView/ShipmentRequestView";
import TradeView from "../pages/dashboard/TradeView/TradeView";
import TicketView from "../pages/dashboard/TicketView/TicketView";
import VendorOfferView from "../pages/dashboard/VendorOfferView/VendorOfferView";
import VendorOrderView from "../pages/dashboard/VendorOrderView/VendorOrderView";
import VendorView from "../pages/dashboard/VendorView/VendorView";
import AddressView from "../pages/dashboard/AddressView/AddressView";
import ConsigneeView from "../pages/dashboard/ConsigneeView/ConsigneeView";
import WarehouseInventoryView from "../pages/dashboard/WarehouseInventoryView/WarehouseInventoryView";
import StoragePrice from "../pages/dashboard/StoragePriceView/StoragePriceElements";
import WorkLogView from "../pages/dashboard/WorkLogView/WorkLogView";

const DashboardDefault = Loadable(lazy(() => import("../pages/dashboard")));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <DashboardDefault />,
        },
      ],
    },
    { path: "item-view", element: <ItemView /> },
    { path: "sample-view", element: <SampleView /> },
    { path: "vendor-offer-view", element: <VendorOfferView /> },
    { path: "purchase-order-view", element: <VendorOrderView /> },
    { path: "sales-order", element: <CustomerOrderView /> },
    {
      path: "customer-offer-view",
      children: [{ index: true, element: <CustomerOfferView /> }],
    },
    {
      path: "freight-offer-view",
      children: [{ index: true, element: <FreightOfferView /> }],
    },
    { path: "trade-view", element: <TradeView /> },
    { path: "storage-price", element: <StoragePrice /> },
    { path: "warehouse-view", element: <WarehouseView /> },
    { path: "warehouse-inventory-view", element: <WarehouseInventoryView /> },
    { path: "inventory-view", element: <InventoryView /> },
    { path: "vendor-view", element: <VendorView /> },
    { path: "customer-view", element: <CustomerView /> },
    { path: "consignee-view", element: <ConsigneeView /> },
    { path: "stakeholder-address-view", element: <AddressView /> },
    { path: "cost-view", element: <CostView /> },
    { path: "shipment-request-view", element: <ShipmentRequestView /> },
    //{ path: "admin-cost-view", element: <AdminCostView /> },
    { path: "purchase-order-view", element: <PurchaseOrderView /> },
    { path: "sales-order-view", element: <SalesOrderView /> },
    { path: "shipping-instruction-view", element: <ShippingInstructionView /> },
    { path: "shipping-instruction-costing-view", element: <ShippingInstructionCostingView /> },
    { path: "offer-view", element: <OfferView /> },
    { path: "ticket-view", element: <TicketView /> },
    { path: "work-log-view", element: <WorkLogView /> },
  ],
};

export default MainRoutes;
