import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  LoadingOutlined,
  UserOutlined,
  DollarOutlined,
  FormOutlined,
  SendOutlined,
  FileDoneOutlined,
  UpCircleOutlined,
  BorderBottomOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  RadiusBottomrightOutlined,
  FullscreenOutlined,
  PicCenterOutlined,
  BorderInnerOutlined,
  DownCircleOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  CaretDownOutlined ,
} from "@ant-design/icons";
import { Typography } from "@mui/material";

const icons = {
  FontSizeOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  AppstoreAddOutlined,
  UserOutlined,
  DollarOutlined,
  FormOutlined,
  SendOutlined,
  FileDoneOutlined,
  UpCircleOutlined,
  BorderBottomOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  RadiusBottomrightOutlined,
  FullscreenOutlined,
  PicCenterOutlined,
  BorderInnerOutlined,
  DownCircleOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  CaretDownOutlined ,
};

const accordion = {
  id: "offer-accordion",
  title: (
    <Typography variant="h5" component="div">
      Offers
    </Typography>
  ),
  type: "accordion",
  children: [
    {
      id: "vendor-offer",
      title: "Vendor Offers",
      type: "item",
      url: "/vendor-offer-view",
      icon: icons.FileTextOutlined,
    },
    {
      id: "customer-offer",
      title: "Customer Offers",
      type: "item",
      url: "/customer-offer-view",
      icon: icons.FileTextOutlined,
    },
  ],
};

export default accordion;
