import pages from "./pages";
import dashboard from "./dashboard";
import utilities from "./utilities";
import logistics from "./logistics";
import support from "./support";
import accordion from "./accordion";
import order from "./order";
import sample from "./sample";
import reports from "./reports";

const menuItems = {
  items: [dashboard, pages, accordion, sample, order, logistics, utilities, reports, support],
};

export default menuItems;
