import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  LoadingOutlined,
  UserOutlined,
  DollarOutlined,
  FormOutlined,
  SendOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Typography } from "@mui/material";

const icons = {
  FontSizeOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  AppstoreAddOutlined,
  UserOutlined,
  DollarOutlined,
  FormOutlined,
  SendOutlined,
  FileDoneOutlined,
};

const sample = {
  id: "sample-accordion",
  title: (
    <Typography variant="h5" component="div">
      Samples
    </Typography>
  ),
  type: "accordion",
  children: [
   {
      id: "sample",
      title: "Samples",
      type: "item",
      url: "/sample-view",
      icon: icons.AntDesignOutlined,
    },
  ],
};

export default sample;
