import React, { useState } from "react";
import {
    Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { createWorkLogApi } from "../../../components/api/WorkLogApiService";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { retrieveAllTicketsApi } from "../../../components/api/TicketApiService";

// Example Task Types for the Work Log
const taskTypes = [
  { value: "Bug Fix", label: "Bug Fix" },
  { value: "Meeting", label: "Meeting" },
  { value: "Testing & QA", label: "Testing & QA" },
  { value: "Technical Review", label: "Technical Review" },
  { value: "Feature Development", label: "Feature Development" },
  { value: "General Maintenance", label: "General Maintenance" },
  { value: "Other", label: "Other" },
];

export default function AddWorkLog({
  setAddViewStates,
  addViewStates,
  rows,
  setRows,
  setAddView,
}) {
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(addViewStates?.ticket || null,);
    function retrieveTickets() {
      retrieveAllTicketsApi()
        .then((response) => {
          setTickets(response?.data);
        })
        .catch((error) => console.log(error.response?.data));
    }
  const handleSubmit = () => {
    const finalOperationDate = addViewStates?.operationDate || new Date().toISOString().substring(0, 10);

    const finalTaskType =
      addViewStates?.taskType === "Other" && addViewStates?.customTaskType
        ? addViewStates?.customTaskType
        : addViewStates?.taskType;

        const newId = Math.floor(Math.random() * 100000);

        const newWorkLog = {
          ...addViewStates,
          id: newId,
          operationDate: finalOperationDate,
          taskType: finalTaskType,
        };
        
        setAddViewStates(newWorkLog);
        setRows([newWorkLog, ...rows]);
        setAddView(false);

        createWorkLogApi(newWorkLog)
          .then((response) => {
            toast.success("Log added successfully.");
            window.location.reload();
          })
          .catch((error) => {
            toast.error("Something went wrong.");
            console.log(error.response.data);
          });

    setAddView(false);
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Create New Work Log
      </Typography>

      <Stack spacing={2}>
        {/* Ticket Dropdown */}
      <Autocomplete
        options={tickets}
        getOptionLabel={(option) =>
          `${option?.id || ""} - Title: ${option?.title || ""}`
        }
        value={selectedTicket}
        onChange={(event, newValue) => {
          setSelectedTicket(newValue);
          setAddViewStates({
            ...addViewStates,
            ticket: newValue ? newValue : null,
          });
        }}
        onOpen={retrieveTickets}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            label="Ticket (Optional)"
          />
        )}
        renderTags={() => null}
      />
      {selectedTicket && (
        <Box mt={2}>
         <Chip
            label={`Description: ${selectedTicket?.description || ""}`}
            style={{ marginBottom: '5px' }}
            onDelete={() => {
                setSelectedTicket(null);
                setAddViewStates({
                  ...addViewStates,
                  ticket: null,
                });
            }}
          /><br></br>               
        </Box>
      )}

        <TextField
          label="Operation Date"
          type="date"
          variant="outlined"
          fullWidth
          size="small"
          value={
            addViewStates?.operationDate ||
            new Date().toISOString().substring(0, 10)
          }
          inputProps={{ min: "1999-01-01", max: new Date().toISOString().split("T")[0], }}
              InputLabelProps={{
                shrink: true,
              }}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(e) =>
            setAddViewStates({
              ...addViewStates,
              operationDate: e.target.value,
            })
          }
        />

        <TextField
          label="Task Type"
          select
          variant="outlined"
          fullWidth
          size="small"
          value={addViewStates?.taskType}
          error={!addViewStates?.taskType}
          helperText={!addViewStates?.taskType ? "Task type is required" : ""}
          onChange={(e) =>
            setAddViewStates({
              ...addViewStates,
              taskType: e.target.value,
              ...(e.target.value !== "Other" && { customTaskType: "" }),
            })
          }
        >
          {taskTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        {addViewStates?.taskType === "Other" && (
          <TextField
            label="Enter Task Type"
            variant="outlined"
            fullWidth
            size="small"
            value={addViewStates?.customTaskType}
            onChange={(e) =>
              setAddViewStates({ ...addViewStates, customTaskType: e.target.value })
            }
          />
        )}

        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={addViewStates?.description}
          onChange={(e) =>
            setAddViewStates({ ...addViewStates, description: e.target.value })
          }
        />

        <TextField
          label="Time Spent (hrs)"
          variant="outlined"
          fullWidth
          size="small"
          type="number"
          inputProps={{ min: "0", step: "0.1" }}
          value={addViewStates?.timeSpent}
          onChange={(e) =>
            setAddViewStates({ ...addViewStates, timeSpent: e.target.value })
          }
        />
      </Stack>

      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Button variant="outlined" onClick={() => setAddView(false)}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Add
        </Button>
      </Stack>
    </Paper>
  );
}
