import { Grid, MenuItem, TextField, Box, Collapse, ClickAwayListener, Paper, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useRef, useState } from "react";
import {
  color,
  companies,
  currencies,
  incoterms,
  leafForm,
  offerStatus,
  origin,
  paymentTerm,
  portsOfLoading,
  portsOfDischarge,
  recordStatus,
  sampleStatus,
  status,
  type,
  units,
} from "../../../utils/menu-items";
import { format } from "date-fns";

const VendorOfferSearch = (props) => {
  const {
    setFilterVendorOffer,
    filterVendorOffer,
    retrieveItems,
    items,
    retrieveVendors,
    vendors,
    retrieveSamples,
    samples,
  } = props;

  const [componentsState, setComponentsState] = useState({
    origin: {
      dropdownOpen: false,
      inputValue: "",
    },
    item: {
      dropdownOpen: false,
      inputValue: "",
    },
    stakeholder: {
      dropdownOpen: false,
      inputValue: "",
    },
    type: {
      dropdownOpen: false,
      inputValue: "",
    },
    leafForm: {
      dropdownOpen: false,
      inputValue: "",
    },
    storage: {
      dropdownOpen: false,
      inputValue: "",
    },
    cost: {
      dropdownOpen: false,
      inputValue: "",
    },
    firstSample: {
      dropdownOpen: false,
      inputValue: "",
    },
    secondSample: {
      dropdownOpen: false,
      inputValue: "",
    },
    paymentTerm: {
      dropdownOpen: false,
      inputValue: "",
    },
    portOfLoading: {
      dropdownOpen: false,
      inputValue: "",
    },
    portOfDischarge: {
      dropdownOpen: false,
      inputValue: "",
    },
  });

  // Function to update state for a specific component
  const updateComponentState = (componentKey, newState) => {
    setComponentsState((prevState) => ({
      ...prevState,
      [componentKey]: {
        ...prevState[componentKey],
        ...newState,
      },
    }));
  };

  return (
    <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
      <TextField
        id="outlined-search"
        label="Offer Year"
        value={filterVendorOffer?.offerYear || ""}
        type="number"
        onWheel={(e) => e.target.blur()}
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            offerYear: e.target.value,
          })
        }
      />
      <TextField
        id="outlined-search"
        label="Offer Number"
        value={filterVendorOffer?.offerGroupNumber || ""}
        type="number"
        onWheel={(e) => e.target.blur()}
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            offerGroupNumber: e.target.value,
          })
        }
      />

      <Autocomplete
        multiple
        id="outlined-select-item"
        disableClearable
        open={componentsState.item.dropdownOpen}
        onOpen={() => updateComponentState("item", { dropdownOpen: true })}
        inputValue={componentsState.item.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("item", { inputValue: newInputValue });
          }
        }}
        options={[
          ...items.filter((item) =>
            filterVendorOffer.itemIdList.includes(item.id),
          ),
          ...items.filter(
            (item) => !filterVendorOffer.itemIdList.includes(item.id),
          ),
        ]}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.buyingGrade || ""} - ${
            option?.crop || ""
          } - ${option?.origin || ""}`
        }
        value={items.filter((item) =>
          filterVendorOffer.itemIdList.includes(item.id),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            itemIdList: newValue.map((item) => item.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.buyingGrade || ""} - ${
              option?.crop || ""
            } - ${option?.origin || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.itemIdList.length > 0
              ? `Items Selected (${filterVendorOffer?.itemIdList.length})`
              : "Item";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.item.inputValue}
              onChange={(e) =>
                updateComponentState("item", { inputValue: e.target.value })
              }
              onMouseDown={retrieveItems}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("item", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        id="outlined-search"
        label="Tobacco Code"
        type="search"
        value={filterVendorOffer?.tobaccoCode || ""}
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            tobaccoCode: e.target.value,
          })
        }
      />

      <TextField
        id="outlined-search"
        label="Sales Grade"
        value={filterVendorOffer?.salesGrade || ""}
        type="search"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            salesGrade: e.target.value,
          })
        }
      />

      <TextField
        id="outlined-search"
        label="Buying Grade"
        value={filterVendorOffer?.buyingGrade || ""}
        type="search"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            buyingGrade: e.target.value,
          })
        }
      />

      <TextField
        id="outlined-search"
        label="Internal Grade"
        value={filterVendorOffer?.internalGrade || ""}
        type="search"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            internalGrade: e.target.value,
          })
        }
      />

      <Autocomplete
        multiple
        id="outlined-select-origin"
        disableClearable
        open={componentsState.origin.dropdownOpen}
        onOpen={() => updateComponentState("origin", { dropdownOpen: true })}
        inputValue={componentsState.origin.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("origin", { inputValue: newInputValue });
          }
        }}
        options={[
          ...origin.filter((option) =>
            filterVendorOffer.originList.includes(option.value),
          ),
          ...origin.filter(
            (option) => !filterVendorOffer.originList.includes(option.value),
          ),
        ]}
        getOptionLabel={(option) => option.label}
        value={origin.filter((option) =>
          filterVendorOffer.originList.includes(option.value),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            originList: newValue.map((option) => option.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option.label}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.originList.length > 0
              ? `Origins Selected (${filterVendorOffer?.originList.length})`
              : "Origin";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.origin.inputValue}
              onChange={(e) =>
                updateComponentState("origin", { inputValue: e.target.value })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("origin", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <Autocomplete
        multiple
        id="outlined-select-type"
        disableClearable
        open={componentsState.type.dropdownOpen}
        onOpen={() => updateComponentState("type", { dropdownOpen: true })}
        inputValue={componentsState.type.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("type", { inputValue: newInputValue });
          }
        }}
        options={[
          ...type.filter((option) =>
            filterVendorOffer.typeList.includes(option.value),
          ),
          ...type.filter(
            (option) => !filterVendorOffer.typeList.includes(option.value),
          ),
        ]}
        getOptionLabel={(option) => option.label}
        value={type.filter((option) =>
          filterVendorOffer.typeList.includes(option.value),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            typeList: newValue.map((option) => option.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option.label}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.typeList.length > 0
              ? `Types Selected (${filterVendorOffer?.typeList.length})`
              : "Type";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.type.inputValue}
              onChange={(e) =>
                updateComponentState("type", { inputValue: e.target.value })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("type", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <Autocomplete
        multiple
        id="outlined-select-leaf-form"
        disableClearable
        open={componentsState.leafForm.dropdownOpen}
        onOpen={() => updateComponentState("leafForm", { dropdownOpen: true })}
        inputValue={componentsState.leafForm.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("leafForm", { inputValue: newInputValue });
          }
        }}
        options={[
          ...leafForm.filter((option) =>
            filterVendorOffer.leafFormList.includes(option.value),
          ),
          ...leafForm.filter(
            (option) => !filterVendorOffer.leafFormList.includes(option.value),
          ),
        ]}
        getOptionLabel={(option) => option.label}
        value={leafForm.filter((option) =>
          filterVendorOffer.leafFormList.includes(option.value),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            leafFormList: newValue.map((option) => option.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option.label}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.leafFormList.length > 0
              ? `Leaf Forms Selected (${filterVendorOffer?.leafFormList.length})`
              : "Leaf Form";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.leafForm.inputValue}
              onChange={(e) =>
                updateComponentState("leafForm", { inputValue: e.target.value })
              }
              onBlur={() =>
                updateComponentState("leafForm", { dropdownOpen: false })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="Color"
        type="search"
        id="outlined-select-color"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.colorList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              colorList: e.target.value,
            });
          },
        }}
      >
        {color.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

<ClickAwayListener
        onClickAway={() => {
          if (filterVendorOffer?.showCropFields) {
            setFilterVendorOffer({
              ...filterVendorOffer,
              showCropFields: false,
            });
          }
        }}
      >
        <Box sx={{ position: "relative" }}>
          <TextField
            id="crop"
            type="text"
            label="Crop"
            variant="outlined"
            value={
              filterVendorOffer?.cropFrom && filterVendorOffer?.cropTo
                ? `${filterVendorOffer?.cropFrom} - ${filterVendorOffer?.cropTo}`
                : filterVendorOffer?.cropFrom
                ? `From ${filterVendorOffer?.cropFrom}`
                : filterVendorOffer?.cropTo
                ? `To ${filterVendorOffer?.cropTo}`
                : ""
            }
            fullWidth
            onFocus={() =>
              setFilterVendorOffer({
                ...filterVendorOffer,
                showCropFields: true,
              })
            }
          />

          {filterVendorOffer?.showCropFields && (
            <Paper
            elevation={3}
            sx={{
              position: "absolute",
              mt: 1,
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              zIndex: 10,
            }}
          >
              <TextField
                id="crop-from"
                type="number"
                label="Crop From"
                value={filterVendorOffer?.cropFrom || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    cropFrom: e.target.value,
                  })
                }
              />

              <TextField
                id="crop-to"
                type="number"
                label="Crop To"
                value={filterVendorOffer?.cropTo || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    cropTo: e.target.value,
                  })
                }
              />

              <Button
                variant="contained"
                sx={{ mt: 1, alignSelf: "center" }}
                onClick={() =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    showCropFields: false, 
                  })
                }
              >
                Done
              </Button>
            </Paper>
          )}
        </Box>
      </ClickAwayListener>

      <TextField
        label="Current Location"
        type="search"
        value={filterVendorOffer?.itemStorage || ""}
        id="outlined-storage"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            itemStorage: e.target.value,
          })
        }
      />

<ClickAwayListener
        onClickAway={() => {
          if (filterVendorOffer?.showNicotineFields) {
            setFilterVendorOffer({
              ...filterVendorOffer,
              showNicotineFields: false,
            });
          }
        }}
      >
        <Box sx={{ position: "relative" }}>
          <TextField
            id="nicotine"
            type="text"
            label="Nicotine"
            variant="outlined"
            value={
              filterVendorOffer?.nicotineFrom && filterVendorOffer?.nicotineTo
                ? `${filterVendorOffer?.nicotineFrom} - ${filterVendorOffer?.nicotineTo}`
                : filterVendorOffer?.nicotineFrom
                ? `From ${filterVendorOffer?.nicotineFrom}`
                : filterVendorOffer?.nicotineTo
                ? `To ${filterVendorOffer?.nicotineTo}`
                : ""
            }
            fullWidth
            onFocus={() =>
              setFilterVendorOffer({
                ...filterVendorOffer,
                showNicotineFields: true,
              })
            }
          />

          {/* Expanding Container */}
          {filterVendorOffer?.showNicotineFields && (
            <Paper
            elevation={3}
            sx={{
              position: "absolute",
              mt: 1,
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              zIndex: 10,
            }}
          >
              <TextField
                id="nicotine-from"
                type="number"
                label="Nicotine From"
                value={filterVendorOffer?.nicotineFrom || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    nicotineFrom: e.target.value,
                  })
                }
              />

              <TextField
                id="nicotine-to"
                type="number"
                label="Nicotine To"
                value={filterVendorOffer?.nicotineTo || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    nicotineTo: e.target.value,
                  })
                }
              />

              {/* Done Button to Close */}
              <Button
                variant="contained"
                sx={{ mt: 1, alignSelf: "center" }}
                onClick={() =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    showNicotineFields: false, // Close dropdown
                  })
                }
              >
                Done
              </Button>
            </Paper>
          )}
        </Box>
      </ClickAwayListener>

      <ClickAwayListener
        onClickAway={() => {
          if (filterVendorOffer?.showSugarFields) {
            setFilterVendorOffer({
              ...filterVendorOffer,
              showSugarFields: false,
            });
          }
        }}
      >
        <Box sx={{ position: "relative"}}>
          <TextField
            id="sugar"
            type="text"
            label="Sugar"
            variant="outlined"
            value={
              filterVendorOffer?.sugarFrom && filterVendorOffer?.sugarTo
                ? `${filterVendorOffer?.sugarFrom} - ${filterVendorOffer?.sugarTo}`
                : filterVendorOffer?.sugarFrom
                ? `From ${filterVendorOffer?.sugarFrom}`
                : filterVendorOffer?.sugarTo
                ? `To ${filterVendorOffer?.sugarTo}`
                : ""
            }
            fullWidth
            onFocus={() =>
              setFilterVendorOffer({
                ...filterVendorOffer,
                showSugarFields: true,
              })
            }
          />

          {/* Expanding Container */}
          {filterVendorOffer?.showSugarFields && (
               <Paper
               elevation={3}
               sx={{
                 position: "absolute",
                 mt: 1,
                 p: 2,
                 display: "flex",
                 flexDirection: "column",
                 gap: 2,
                 zIndex: 10,
               }}
              >
              <TextField
                id="sugar-from"
                type="number"
                label="Sugar From"
                value={filterVendorOffer?.sugarFrom || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    sugarFrom: e.target.value,
                  })
                }
              />

              <TextField
                id="sugar-to"
                type="number"
                label="Sugar To"
                value={filterVendorOffer?.sugarTo || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    sugarTo: e.target.value,
                  })
                }
              />

              {/* Done Button to Close */}
              <Button
                variant="contained"
                sx={{ mt: 1, alignSelf: "center" }}
                onClick={() =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    showSugarFields: false, // Close dropdown
                  })
                }
              >
                Done
              </Button>
            </Paper>
          )}
        </Box>
      </ClickAwayListener>

      <TextField
        label="Buying Company"
        type="search"
        id="outlined-select-company"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.companyList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              companyList: e.target.value,
            });
          },
        }}
      >
        {companies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="SG Approval Status"
        type="search"
        id="outlined-select-approval-status"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.approvalStatusList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              approvalStatusList: e.target.value,
            });
          },
        }}
      >
        {status.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Status"
        type="search"
        id="outlined-select-status"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.statusList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              statusList: e.target.value,
            });
          },
        }}
      >
        {offerStatus.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <ClickAwayListener
      onClickAway={() => {
        if (filterVendorOffer?.showStatusDeadlineFields) {
          setFilterVendorOffer({
            ...filterVendorOffer,
            showStatusDeadlineFields: false,
          });
        }
      }}
    >
      <Box sx={{ position: "relative"}}>  {/* Make it full width */}
        {/* Main Clickable Field */}
        <TextField
          id="date"
          type="text"
          label="Status Deadline"
          variant="outlined"
          value={
            filterVendorOffer?.statusDeadlineFrom && filterVendorOffer?.statusDeadlineTo
              ? `${filterVendorOffer?.statusDeadlineFrom
                ? format(new Date(filterVendorOffer.statusDeadlineFrom), "dd-MMM-yyyy")
                : ""}, ${filterVendorOffer?.statusDeadlineTo
                  ? format(new Date(filterVendorOffer.statusDeadlineTo), "dd-MMM-yyyy")
                  : ""}`
              : filterVendorOffer?.statusDeadlineFrom
              ? `From ${filterVendorOffer?.statusDeadlineFrom
                        ? format(new Date(filterVendorOffer.statusDeadlineFrom), "dd-MMM-yyyy")
                        : ""}`
              : filterVendorOffer?.statusDeadlineTo
              ? `To ${filterVendorOffer?.statusDeadlineTo
                ? format(new Date(filterVendorOffer.statusDeadlineTo), "dd-MMM-yyyy")
                : ""}`
              : ""
          }
          fullWidth
          onFocus={() =>
            setFilterVendorOffer({
              ...filterVendorOffer,
              showStatusDeadlineFields: true,
            })
          }
        />

        {/* Expanding Container */}
        {filterVendorOffer?.showStatusDeadlineFields && (
          <Paper
            elevation={3}
            sx={{
              position: "absolute",
              mt: 1,
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              zIndex: 10,
            }}
          >

            <TextField
              id="statusDeadlineFrom"
              type="date"
              label="From"
              value={filterVendorOffer?.statusDeadlineFrom || ""}
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) =>
                setFilterVendorOffer({
                  ...filterVendorOffer,
                  statusDeadlineFrom: e.target.value,
                })
              }
            />

            <TextField
              id="statusDeadlineTo"
              type="date"
              label="To"
              value={filterVendorOffer?.statusDeadlineTo || ""}
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) =>
                setFilterVendorOffer({
                  ...filterVendorOffer,
                  statusDeadlineTo: e.target.value,
                })
              }
            />
            {/* Done Button to Close */}
            <Button
              variant="contained"
              sx={{ mt: 1, alignSelf: "center" }}
              onClick={() =>
                setFilterVendorOffer({
                  ...filterVendorOffer,
                  showStatusDeadlineFields: false, // Close dropdown
                })
              }
            >
              Done
            </Button>
          </Paper>
        )}
      </Box>
    </ClickAwayListener>

      <Autocomplete
        multiple
        id="outlined-select-vendor"
        disableClearable
        open={componentsState.stakeholder.dropdownOpen}
        onOpen={() =>
          updateComponentState("stakeholder", { dropdownOpen: true })
        }
        inputValue={componentsState.stakeholder.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("stakeholder", { inputValue: newInputValue });
          }
        }}
        options={[
          ...vendors.filter((option) =>
            filterVendorOffer.stakeholderIdList.includes(option.id),
          ),
          ...vendors.filter(
            (option) =>
              !filterVendorOffer.stakeholderIdList.includes(option.id),
          ),
        ]}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.name || ""}`
        }
        value={vendors.filter((option) =>
          filterVendorOffer.stakeholderIdList.includes(option.id),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            stakeholderIdList: newValue.map((option) => option.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.name || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.stakeholderIdList.length > 0
              ? `Vendors Selected (${filterVendorOffer?.stakeholderIdList.length})`
              : "Vendor";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.stakeholder.inputValue}
              onChange={(e) =>
                updateComponentState("stakeholder", {
                  inputValue: e.target.value,
                })
              }
              onMouseDown={retrieveVendors}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("stakeholder", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

<ClickAwayListener
        onClickAway={() => {
          if (filterVendorOffer?.showFirstOfferPriceFields) {
            setFilterVendorOffer({
              ...filterVendorOffer,
              showFirstOfferPriceFields: false,
            });
          }
        }}
      >
        <Box sx={{ position: "relative" }}>
          <TextField
            id="first-offer-price"
            type="text"
            label="First Offer Price"
            variant="outlined"
            value={
              filterVendorOffer?.firstOfferPriceFrom && filterVendorOffer?.firstOfferPriceTo
                ? `${filterVendorOffer?.firstOfferPriceFrom} - ${filterVendorOffer?.firstOfferPriceTo}`
                : filterVendorOffer?.firstOfferPriceFrom
                ? `From ${filterVendorOffer?.firstOfferPriceFrom}`
                : filterVendorOffer?.firstOfferPriceTo
                ? `To ${filterVendorOffer?.firstOfferPriceTo}`
                : ""
            }
            fullWidth
            onFocus={() =>
              setFilterVendorOffer({
                ...filterVendorOffer,
                showFirstOfferPriceFields: true,
              })
            }
          />

          {filterVendorOffer?.showFirstOfferPriceFields && (
            <Paper
            elevation={3}
            sx={{
              position: "absolute",
              mt: 1,
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              zIndex: 10,
            }}
          >
              <TextField
                id="first-offer-price-from"
                type="number"
                label="First Offer Price From"
                value={filterVendorOffer?.firstOfferPriceFrom || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    firstOfferPriceFrom: e.target.value,
                  })
                }
              />

              <TextField
                id="first-offer-price-to"
                type="number"
                label="First Offer Price To"
                value={filterVendorOffer?.firstOfferPriceTo || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    firstOfferPriceTo: e.target.value,
                  })
                }
              />

              <Button
                variant="contained"
                sx={{ mt: 1, alignSelf: "center" }}
                onClick={() =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    showFirstOfferPriceFields: false, 
                  })
                }
              >
                Done
              </Button>
            </Paper>
          )}
        </Box>
      </ClickAwayListener>


      <TextField
        label="First Offer Price Currency"
        type="search"
        id="outlined-select-first-offer-currency"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.firstPriceCurrencyList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              firstPriceCurrencyList: e.target.value,
            });
          },
        }}
      >
        {currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <ClickAwayListener
        onClickAway={() => {
          if (filterVendorOffer?.showFinalOfferPriceFields) {
            setFilterVendorOffer({
              ...filterVendorOffer,
              showFinalOfferPriceFields: false,
            });
          }
        }}
      >
        <Box sx={{ position: "relative" }}>
          <TextField
            id="finalOfferPrice"
            type="text"
            label="Final Offer Price"
            variant="outlined"
            value={
              filterVendorOffer?.finalOfferPriceFrom && filterVendorOffer?.finalOfferPriceTo
                ? `${filterVendorOffer?.finalOfferPriceFrom} - ${filterVendorOffer?.finalOfferPriceTo}`
                : filterVendorOffer?.finalOfferPriceFrom
                ? `From ${filterVendorOffer?.finalOfferPriceFrom}`
                : filterVendorOffer?.finalOfferPriceTo
                ? `To ${filterVendorOffer?.finalOfferPriceTo}`
                : ""
            }
            fullWidth
            onFocus={() =>
              setFilterVendorOffer({
                ...filterVendorOffer,
                showFinalOfferPriceFields: true,
              })
            }
          />

          {filterVendorOffer?.showFinalOfferPriceFields && (
            <Paper
            elevation={3}
            sx={{
              position: "absolute",
              mt: 1,
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              zIndex: 10,
            }}
          >
              <TextField
                id="finalOfferPrice-from"
                type="number"
                label="Final Offer Price From"
                value={filterVendorOffer?.finalOfferPriceFrom || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    finalOfferPriceFrom: e.target.value,
                  })
                }
              />

              <TextField
                id="finalOfferPrice-to"
                type="number"
                label="Final Offer Price To"
                value={filterVendorOffer?.finalOfferPriceTo || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    finalOfferPriceTo: e.target.value,
                  })
                }
              />

              <Button
                variant="contained"
                sx={{ mt: 1, alignSelf: "center" }}
                onClick={() =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    showFinalOfferPriceFields: false, 
                  })
                }
              >
                Done
              </Button>
            </Paper>
          )}
        </Box>
      </ClickAwayListener>


      <TextField
        label="Final Offer Price Currency"
        type="search"
        id="outlined-select-final-offer-currency"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.finalPriceCurrencyList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              finalPriceCurrencyList: e.target.value,
            });
          },
        }}
      >
        {currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Autocomplete
        multiple
        id="outlined-select-first-sample"
        disableClearable
        open={componentsState.firstSample.dropdownOpen}
        onOpen={() =>
          updateComponentState("firstSample", { dropdownOpen: true })
        }
        inputValue={componentsState.firstSample.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("firstSample", { inputValue: newInputValue });
          }
        }}
        options={[
          ...samples.filter((sample) =>
            filterVendorOffer.firstSampleIdList.includes(sample.id),
          ),
          ...samples.filter(
            (sample) =>
              !filterVendorOffer.firstSampleIdList.includes(sample.id),
          ),
        ]}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.remark || ""}`
        }
        value={samples.filter((sample) =>
          filterVendorOffer.firstSampleIdList.includes(sample.id),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            firstSampleIdList: newValue.map((sample) => sample.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.remark || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.firstSampleIdList.length > 0
              ? `Offer Samples Selected (${filterVendorOffer?.firstSampleIdList.length})`
              : "Offer Sample";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.firstSample.inputValue}
              onChange={(e) =>
                updateComponentState("firstSample", {
                  inputValue: e.target.value,
                })
              }
              onMouseDown={retrieveSamples}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("firstSample", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="Offer Sample Status"
        type="search"
        id="outlined-select-first-sample-status"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.firstSampleStatusList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              firstSampleStatusList: e.target.value,
            });
          },
        }}
      >
        {sampleStatus.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Autocomplete
        multiple
        id="outlined-select-second-sample"
        disableClearable
        open={componentsState.secondSample.dropdownOpen}
        onOpen={() =>
          updateComponentState("secondSample", { dropdownOpen: true })
        }
        inputValue={componentsState.secondSample.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("secondSample", { inputValue: newInputValue });
          }
        }}
        options={[
          ...samples.filter((sample) =>
            filterVendorOffer.secondSampleIdList.includes(sample.id),
          ),
          ...samples.filter(
            (sample) =>
              !filterVendorOffer.secondSampleIdList.includes(sample.id),
          ),
        ]}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.remark || ""}`
        }
        value={samples.filter((sample) =>
          filterVendorOffer.secondSampleIdList.includes(sample.id),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            secondSampleIdList: newValue.map((sample) => sample.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.remark || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.secondSampleIdList.length > 0
              ? `Trial Samples Selected (${filterVendorOffer?.secondSampleIdList.length})`
              : "Trial Sample";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.secondSample.inputValue}
              onChange={(e) =>
                updateComponentState("secondSample", {
                  inputValue: e.target.value,
                })
              }
              onMouseDown={retrieveSamples}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("secondSample", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="Trial Sample Status"
        type="search"
        id="outlined-select-second-sample-status"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.secondSampleStatusList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              secondSampleStatusList: e.target.value,
            });
          },
        }}
      >
        {sampleStatus.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Autocomplete
        multiple
        id="outlined-select-payment-term"
        disableClearable
        open={componentsState.paymentTerm.dropdownOpen}
        onOpen={() =>
          updateComponentState("paymentTerm", { dropdownOpen: true })
        }
        inputValue={componentsState.paymentTerm.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("paymentTerm", { inputValue: newInputValue });
          }
        }}
        options={paymentTerm}
        getOptionLabel={(option) => option.label}
        value={paymentTerm.filter((term) =>
          filterVendorOffer.paymentTermList.includes(term.value),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            paymentTermList: newValue.map((term) => term.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option.label}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.paymentTermList.length > 0
              ? `Payment Terms Selected (${filterVendorOffer?.paymentTermList.length})`
              : "Payment Term";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.paymentTerm.inputValue}
              onChange={(e) =>
                updateComponentState("paymentTerm", {
                  inputValue: e.target.value,
                })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("paymentTerm", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="Incoterm"
        type="search"
        id="outlined-select-incoterm"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.incotermList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              incotermList: e.target.value,
            });
          },
        }}
      >
        {incoterms.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <ClickAwayListener
        onClickAway={() => {
          if (filterVendorOffer?.showNumberOfCasesFields) {
            setFilterVendorOffer({
              ...filterVendorOffer,
              showNumberOfCasesFields: false,
            });
          }
        }}
      >
        <Box sx={{ position: "relative" }}>
          <TextField
            id="number-of-cases"
            type="text"
            label="Number Of Cartons"
            variant="outlined"
            value={
              filterVendorOffer?.numberOfCasesFrom && filterVendorOffer?.numberOfCasesTo
                ? `${filterVendorOffer?.numberOfCasesFrom} - ${filterVendorOffer?.numberOfCasesTo}`
                : filterVendorOffer?.numberOfCasesFrom
                ? `From ${filterVendorOffer?.numberOfCasesFrom}`
                : filterVendorOffer?.numberOfCasesTo
                ? `To ${filterVendorOffer?.numberOfCasesTo}`
                : ""
            }
            fullWidth
            onFocus={() =>
              setFilterVendorOffer({
                ...filterVendorOffer,
                showNumberOfCasesFields: true,
              })
            }
          />

          {filterVendorOffer?.showNumberOfCasesFields && (
            <Paper
            elevation={3}
            sx={{
              position: "absolute",
              mt: 1,
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              zIndex: 10,
            }}
          >
              <TextField
                id="numberOfCases-from"
                type="number"
                label="Number Of Cartons From"
                value={filterVendorOffer?.numberOfCasesFrom || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    numberOfCasesFrom: e.target.value,
                  })
                }
              />

              <TextField
                id="numberOfCases-to"
                type="number"
                label="Number Of Cartons To"
                value={filterVendorOffer?.numberOfCasesTo || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    numberOfCasesTo: e.target.value,
                  })
                }
              />

              <Button
                variant="contained"
                sx={{ mt: 1, alignSelf: "center" }}
                onClick={() =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    showNumberOfCasesFields: false, 
                  })
                }
              >
                Done
              </Button>
            </Paper>
          )}
        </Box>
      </ClickAwayListener>

<ClickAwayListener
        onClickAway={() => {
          if (filterVendorOffer?.showNetWeightPerCaseFields) {
            setFilterVendorOffer({
              ...filterVendorOffer,
              showNetWeightPerCaseFields: false,
            });
          }
        }}
      >
        <Box sx={{ position: "relative" }}>
          <TextField
            id="net-weight-per-case"
            type="text"
            label="Net Weight Per Carton"
            variant="outlined"
            value={
              filterVendorOffer?.netWeightPerCaseFrom && filterVendorOffer?.netWeightPerCaseTo
                ? `${filterVendorOffer?.netWeightPerCaseFrom} - ${filterVendorOffer?.netWeightPerCaseTo}`
                : filterVendorOffer?.netWeightPerCaseFrom
                ? `From ${filterVendorOffer?.netWeightPerCaseFrom}`
                : filterVendorOffer?.netWeightPerCaseTo
                ? `To ${filterVendorOffer?.netWeightPerCaseTo}`
                : ""
            }
            fullWidth
            onFocus={() =>
              setFilterVendorOffer({
                ...filterVendorOffer,
                showNetWeightPerCaseFields: true,
              })
            }
          />

          {filterVendorOffer?.showNetWeightPerCaseFields && (
            <Paper
            elevation={3}
            sx={{
              position: "absolute",
              mt: 1,
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              zIndex: 10,
            }}
          >
              <TextField
                id="net-weight-per-case-from"
                type="number"
                label="Net Weight Per Carton From"
                value={filterVendorOffer?.netWeightPerCaseFrom || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    netWeightPerCaseFrom: e.target.value,
                  })
                }
              />

              <TextField
                id="net-weight-per-case-to"
                type="number"
                label="Net Weight Per Carton To"
                value={filterVendorOffer?.netWeightPerCaseTo || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    netWeightPerCaseTo: e.target.value,
                  })
                }
              />

              <Button
                variant="contained"
                sx={{ mt: 1, alignSelf: "center" }}
                onClick={() =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    showNetWeightPerCaseFields: false, 
                  })
                }
              >
                Done
              </Button>
            </Paper>
          )}
        </Box>
      </ClickAwayListener>

<ClickAwayListener
        onClickAway={() => {
          if (filterVendorOffer?.showVolumeFields) {
            setFilterVendorOffer({
              ...filterVendorOffer,
              showVolumeFields: false,
            });
          }
        }}
      >
        <Box sx={{ position: "relative" }}>
          <TextField
            id="volume"
            type="text"
            label="Volume"
            variant="outlined"
            value={
              filterVendorOffer?.volumeFrom && filterVendorOffer?.volumeTo
                ? `${filterVendorOffer?.volumeFrom} - ${filterVendorOffer?.volumeTo}`
                : filterVendorOffer?.volumeFrom
                ? `From ${filterVendorOffer?.volumeFrom}`
                : filterVendorOffer?.volumeTo
                ? `To ${filterVendorOffer?.volumeTo}`
                : ""
            }
            fullWidth
            onFocus={() =>
              setFilterVendorOffer({
                ...filterVendorOffer,
                showVolumeFields: true,
              })
            }
          />

          {filterVendorOffer?.showVolumeFields && (
            <Paper
            elevation={3}
            sx={{
              position: "absolute",
              mt: 1,
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              zIndex: 10,
            }}
          >
              <TextField
                id="volume"
                type="number"
                label="Volume From"
                value={filterVendorOffer?.volumeFrom || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    volumeFrom: e.target.value,
                  })
                }
              />

              <TextField
                id="volume-to"
                type="number"
                label="Volume To"
                value={filterVendorOffer?.volumeTo || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    volumeTo: e.target.value,
                  })
                }
              />

              <Button
                variant="contained"
                sx={{ mt: 1, alignSelf: "center" }}
                onClick={() =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    showVolumeFields: false, 
                  })
                }
              >
                Done
              </Button>
            </Paper>
          )}
        </Box>
      </ClickAwayListener>


      <TextField
        label="Volume Unit"
        type="search"
        id="outlined-select-volume-unit"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.volumeUnitList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              volumeUnitList: e.target.value,
            });
          },
        }}
      >
        {units.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

<ClickAwayListener
        onClickAway={() => {
          if (filterVendorOffer?.showTotalAmountFields) {
            setFilterVendorOffer({
              ...filterVendorOffer,
              showTotalAmountFields: false,
            });
          }
        }}
      >
        <Box sx={{ position: "relative" }}>
          <TextField
            id="total-amount"
            type="text"
            label="Total Amount"
            variant="outlined"
            value={
              filterVendorOffer?.totalAmountFrom && filterVendorOffer?.totalAmountTo
                ? `${filterVendorOffer?.totalAmountFrom} - ${filterVendorOffer?.totalAmountTo}`
                : filterVendorOffer?.totalAmountFrom
                ? `From ${filterVendorOffer?.totalAmountFrom}`
                : filterVendorOffer?.totalAmountTo
                ? `To ${filterVendorOffer?.totalAmountTo}`
                : ""
            }
            fullWidth
            onFocus={() =>
              setFilterVendorOffer({
                ...filterVendorOffer,
                showTotalAmountFields: true,
              })
            }
          />

          {filterVendorOffer?.showTotalAmountFields && (
            <Paper
            elevation={3}
            sx={{
              position: "absolute",
              mt: 1,
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              zIndex: 10,
            }}
          >
              <TextField
                id="total-amount-from"
                type="number"
                label="Total Amount From"
                value={filterVendorOffer?.totalAmountFrom || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    totalAmountFrom: e.target.value,
                  })
                }
              />

              <TextField
                id="total-amount-to"
                type="number"
                label="Total Amount To"
                value={filterVendorOffer?.totalAmountTo || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    totalAmountTo: e.target.value,
                  })
                }
              />

              <Button
                variant="contained"
                sx={{ mt: 1, alignSelf: "center" }}
                onClick={() =>
                  setFilterVendorOffer({
                    ...filterVendorOffer,
                    showTotalAmountFields: false, 
                  })
                }
              >
                Done
              </Button>
            </Paper>
          )}
        </Box>
      </ClickAwayListener>

<ClickAwayListener
      onClickAway={() => {
        if (filterVendorOffer?.showofferDateFields) {
          setFilterVendorOffer({
            ...filterVendorOffer,
            showofferDateFields: false,
          });
        }
      }}
    >
      <Box sx={{ position: "relative"}}>  {/* Make it full width */}
        {/* Main Clickable Field */}
        <TextField
          id="date"
          type="text"
          label="Offer Date"
          variant="outlined"
          value={
            filterVendorOffer?.offerDateFrom && filterVendorOffer?.offerDateTo
              ? `${filterVendorOffer?.offerDateFrom
                ? format(new Date(filterVendorOffer.offerDateFrom), "dd-MMM-yyyy")
                : ""}, ${filterVendorOffer?.offerDateTo
                  ? format(new Date(filterVendorOffer.offerDateTo), "dd-MMM-yyyy")
                  : ""}`
              : filterVendorOffer?.offerDateFrom
              ? `From ${filterVendorOffer?.offerDateFrom
                        ? format(new Date(filterVendorOffer.offerDateFrom), "dd-MMM-yyyy")
                        : ""}`
              : filterVendorOffer?.offerDateTo
              ? `To ${filterVendorOffer?.offerDateTo
                ? format(new Date(filterVendorOffer.offerDateTo), "dd-MMM-yyyy")
                : ""}`
              : ""
          }
          fullWidth
          onFocus={() =>
            setFilterVendorOffer({
              ...filterVendorOffer,
              showofferDateFields: true,
            })
          }
        />

        {/* Expanding Container */}
        {filterVendorOffer?.showofferDateFields && (
          <Paper
            elevation={3}
            sx={{
              position: "absolute",
              mt: 1,
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              zIndex: 10,
            }}
          >

            <TextField
              id="offerDateFrom"
              type="date"
              label="From"
              value={filterVendorOffer?.offerDateFrom || ""}
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) =>
                setFilterVendorOffer({
                  ...filterVendorOffer,
                  offerDateFrom: e.target.value,
                })
              }
            />

            <TextField
              id="offerDateTo"
              type="date"
              label="To"
              value={filterVendorOffer?.offerDateTo || ""}
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) =>
                setFilterVendorOffer({
                  ...filterVendorOffer,
                  offerDateTo: e.target.value,
                })
              }
            />
            {/* Done Button to Close */}
            <Button
              variant="contained"
              sx={{ mt: 1, alignSelf: "center" }}
              onClick={() =>
                setFilterVendorOffer({
                  ...filterVendorOffer,
                  showofferDateFields: false, // Close dropdown
                })
              }
            >
              Done
            </Button>
          </Paper>
        )}
      </Box>
    </ClickAwayListener>

<ClickAwayListener
      onClickAway={() => {
        if (filterVendorOffer?.showvalidityDateFields) {
          setFilterVendorOffer({
            ...filterVendorOffer,
            showvalidityDateFields: false,
          });
        }
      }}
    >
      <Box sx={{ position: "relative"}}>  {/* Make it full width */}
        {/* Main Clickable Field */}
        <TextField
          id="date"
          type="text"
          label="Validity Date"
          variant="outlined"
          value={
            filterVendorOffer?.validityDateFrom && filterVendorOffer?.validityDateTo
              ? `${filterVendorOffer?.validityDateFrom
                ? format(new Date(filterVendorOffer.validityDateFrom), "dd-MMM-yyyy")
                : ""}, ${filterVendorOffer?.validityDateTo
                  ? format(new Date(filterVendorOffer.validityDateTo), "dd-MMM-yyyy")
                  : ""}`
              : filterVendorOffer?.validityDateFrom
              ? `From ${filterVendorOffer?.validityDateFrom
                        ? format(new Date(filterVendorOffer.validityDateFrom), "dd-MMM-yyyy")
                        : ""}`
              : filterVendorOffer?.validityDateTo
              ? `To ${filterVendorOffer?.validityDateTo
                ? format(new Date(filterVendorOffer.validityDateTo), "dd-MMM-yyyy")
                : ""}`
              : ""
          }
          fullWidth
          onFocus={() =>
            setFilterVendorOffer({
              ...filterVendorOffer,
              showvalidityDateFields: true,
            })
          }
        />

        {/* Expanding Container */}
        {filterVendorOffer?.showvalidityDateFields && (
          <Paper
            elevation={3}
            sx={{
              position: "absolute",
              mt: 1,
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              zIndex: 10,
            }}
          >

            <TextField
              id="validityDateFrom"
              type="date"
              label="From"
              value={filterVendorOffer?.validityDateFrom || ""}
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) =>
                setFilterVendorOffer({
                  ...filterVendorOffer,
                  validityDateFrom: e.target.value,
                })
              }
            />

            <TextField
              id="validityDateTo"
              type="date"
              label="To"
              value={filterVendorOffer?.validityDateTo || ""}
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) =>
                setFilterVendorOffer({
                  ...filterVendorOffer,
                  validityDateTo: e.target.value,
                })
              }
            />
            {/* Done Button to Close */}
            <Button
              variant="contained"
              sx={{ mt: 1, alignSelf: "center" }}
              onClick={() =>
                setFilterVendorOffer({
                  ...filterVendorOffer,
                  showvalidityDateFields: false, // Close dropdown
                })
              }
            >
              Done
            </Button>
          </Paper>
        )}
      </Box>
    </ClickAwayListener>

      <TextField
        id="outlined-search"
        label="Remark"
        value={filterVendorOffer?.remark || ""}
        type="search"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            remark: e.target.value,
          })
        }
      />

      <TextField
        id="outlined-search"
        label="Comment"
        value={filterVendorOffer?.comment || ""}
        type="search"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            comment: e.target.value,
          })
        }
      />

      <Autocomplete
        multiple
        id="outlined-select-port-of-loading"
        disableClearable
        open={componentsState.portOfLoading.dropdownOpen}
        onOpen={() =>
          updateComponentState("portOfLoading", { dropdownOpen: true })
        }
        inputValue={componentsState.portOfLoading.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("portOfLoading", {
              inputValue: newInputValue,
            });
          }
        }}
        options={portsOfLoading}
        getOptionLabel={(option) =>
          option ? `${option.value} - ${option.label}` : ""
        }
        value={portsOfLoading.filter((term) =>
          filterVendorOffer.portOfLoadingList.includes(term.value),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            portOfLoadingList: newValue.map((term) => term.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option ? `${option.label}` : ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.portOfLoadingList.length > 0
              ? `Ports Of Loading Selected (${filterVendorOffer?.portOfLoadingList.length})`
              : "Port Of Loading";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.portOfLoading.inputValue}
              onChange={(e) =>
                updateComponentState("portOfLoading", {
                  inputValue: e.target.value,
                })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("portOfLoading", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

<Autocomplete
        multiple
        id="outlined-select-port-of-discharge"
        disableClearable
        open={componentsState.portOfDischarge.dropdownOpen}
        onOpen={() =>
          updateComponentState("portOfDischarge", { dropdownOpen: true })
        }
        inputValue={componentsState.portOfDischarge.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("portOfDischarge", {
              inputValue: newInputValue,
            });
          }
        }}
        options={portsOfDischarge}
        getOptionLabel={(option) =>
          option ? `${option.value} - ${option.label}` : ""
        }
        value={portsOfDischarge.filter((term) =>
          filterVendorOffer.portOfDischargeList.includes(term.value),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            portOfDischargeList: newValue.map((term) => term.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option ? `${option.label}` : ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.portOfDischargeList.length > 0
              ? `Ports Of Discharge Selected (${filterVendorOffer?.portOfDischargeList.length})`
              : "Port Of Discharge";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.portOfDischarge.inputValue}
              onChange={(e) =>
                updateComponentState("portOfDischarge", {
                  inputValue: e.target.value,
                })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("portOfDischarge", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="Document Status"
        type="search"
        id="outlined-select-document-status"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.documentStatusList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              documentStatusList: e.target.value,
            });
          },
        }}
      >
        {status.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Record Status"
        type="search"
        id="outlined-select-record-status"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.recordStatusList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              recordStatusList: e.target.value,
            });
          },
        }}
      >
        {recordStatus.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

export default VendorOfferSearch;
