import {
    AppstoreAddOutlined,
    AntDesignOutlined,
    BarcodeOutlined,
    BgColorsOutlined,
    FontSizeOutlined,
    DatabaseOutlined,
    FileTextOutlined,
    LoadingOutlined,
    UserOutlined,
    DollarOutlined,
    FormOutlined,
    SendOutlined,
    FileDoneOutlined,
  } from "@ant-design/icons";
import { Typography } from "@mui/material";
  
  const icons = {
    FontSizeOutlined,
    BgColorsOutlined,
    BarcodeOutlined,
    AntDesignOutlined,
    LoadingOutlined,
    DatabaseOutlined,
    FileTextOutlined,
    AppstoreAddOutlined,
    UserOutlined,
    DollarOutlined,
    FormOutlined,
    SendOutlined,
    FileDoneOutlined,
  };
  
  const logistics = {
    id: "logistics",
    title: (
      <Typography variant="h5" component="div">
        Logistics
      </Typography>
    ),
    type: "accordion",
    children: [
      {
        id: "shipment-request",
        title: "Shipment Requests",
        type: "item",
        url: "/shipment-request-view",
        icon: icons.FileTextOutlined,
      },
      {
        id: "shipping-instruction",
        title: "Shipping Instructions",
        type: "item",
        url: "/shipping-instruction-view",
        icon: icons.FileTextOutlined,
      },
      {
        id: "freight-offer",
        title: "Freight Offers",
        type: "item",
        url: "/freight-offer-view",
        icon: icons.FileTextOutlined,
      },
      {
        id: "shipping-instruction-costing",
        title: "SI Costing",
        type: "item",
        url: "/shipping-instruction-costing-view",
        icon: icons.DollarOutlined,
      },
    ],
  };
  
  export default logistics;
  