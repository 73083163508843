import { apiClient } from './components/api/ApiClient.js';
import toast from "react-hot-toast";

apiClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem('accessToken'); 
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("🛑 Interceptor Caught Error:", error.response || error.message); 

         if (error.response) {
           if (error.response.status === 401) {

            toast.dismiss();

            toast.error("❌ 401 Unauthorized detected! Logging out...", { autoClose: 3000 });
            console.error("❌ 401 Unauthorized detected! Logging out...");
           
         setTimeout(() => {
           localStorage.removeItem("accessToken");
           window.location.href = "/authenticate";
         }, 2000); 
        }
      } else {
      console.error("🚨 Network or CORS error:", error.message);
    }

    return Promise.reject(error);
  }
);

export default apiClient;