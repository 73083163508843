import { DashboardOutlined } from "@ant-design/icons";
import { Typography } from "@mui/material";

const icons = {
  DashboardOutlined,
};

const dashboard = {
  id: "group-dashboard",
  title: (
    <Typography variant="h5" component="div">
      Navigation
    </Typography>
  ),
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/dashboard/default",
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
