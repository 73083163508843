import { apiClient } from "./ApiClient";

// Create a new Ticket
export const createTicketApi = (ticket) =>
  apiClient.post(`/ticket-view/add`, ticket);

// Retrieve all active Tickets
export const retrieveAllTicketsApi = () =>
  apiClient.get(`/ticket-view/tickets`);

// Update an existing Ticket by ID
export const updateTicketApi = (ticketId, ticket) =>
  apiClient.put(`/ticket-view/edit/${ticketId}`, ticket);

// Retrieve filtered Tickets (dynamic filtering)
export const retrieveFilteredTicketsApi = (filterDto) =>
  apiClient.post(`/ticket-view/filter`, filterDto);

// Retrieve a single Ticket by ID
export const retrieveTicketByIdApi = (ticketId) =>
  apiClient.get(`/ticket-view/get/${ticketId}`);

// Optionally, if you want to replicate "deleteMultiple" approach:
export const deleteMultipleTicketsApi = (idList) =>
  apiClient.put(`/ticket-view/delete-list`, idList);

// And if you have the "get-selected" endpoint:
export const retrieveSelectedTicketsByIdApi = (idList) =>
  apiClient.post(`/ticket-view/tickets/get-selected`, idList);
