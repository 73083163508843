import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  LoadingOutlined,
  UserOutlined,
  DollarOutlined,
  FormOutlined,
  SendOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Typography } from "@mui/material";

const icons = {
  FontSizeOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  AppstoreAddOutlined,
  UserOutlined,
  DollarOutlined,
  FormOutlined,
  SendOutlined,
  FileDoneOutlined,
};

const order = {
  id: "order-accordion",
  title: (
    <Typography variant="h5" component="div">
      Orders
    </Typography>
  ),
  type: "accordion",
  children: [
    {
      id: "san-order",
      title: "Purchase Orders",
      type: "item",
      url: "/purchase-order-view",
      icon: icons.FileDoneOutlined,
    },
    {
      id: "sales-order",
      title: "Sales Orders",
      type: "item",
      url: "/sales-order",
      icon: icons.FormOutlined,
    },
          {
            id: "shipment-request",
            title: "Shipment Requests",
            type: "item",
            url: "/shipment-request-view",
            icon: icons.FileTextOutlined,
          },
  ],
};

export default order;
