import { Autocomplete, Box, ClickAwayListener, Grid, MenuItem, Paper, TextField, Button } from "@mui/material";
import React, { useState } from "react";
import {
  color,
  companies,
  incoterms,
  leafForm,
  offerStatus,
  origin,
  paymentTerm,
  recordStatus,
  sampleStatus,
  status,
  type,
  currencies,
  units,
  portsOfLoading,
  portsOfDischarge,
} from "../../../utils/menu-items";

const CustomerOfferSearch = (props) => {
  const {
    setFilterCustomerOffer,
    filterCustomerOffer,
    retrieveItems,
    items,
    retrieveVendorOffers,
    referenceVendorOffers,
    retrieveCustomers,
    customers,
    retrieveCosts,
    costs,
    retrieveSamples,
    samples,
  } = props;

  const [componentsState, setComponentsState] = useState({
    origin: {
      dropdownOpen: false,
      inputValue: "",
    },
    salesOrigin: {
      dropdownOpen: false,
      inputValue: "",
    },
    item: {
      dropdownOpen: false,
      inputValue: "",
    },
    customer: {
      dropdownOpen: false,
      inputValue: "",
    },
    type: {
      dropdownOpen: false,
      inputValue: "",
    },
    leafForm: {
      dropdownOpen: false,
      inputValue: "",
    },
    storage: {
      dropdownOpen: false,
      inputValue: "",
    },
    cost: {
      dropdownOpen: false,
      inputValue: "",
    },
    firstSample: {
      dropdownOpen: false,
      inputValue: "",
    },
    secondSample: {
      dropdownOpen: false,
      inputValue: "",
    },
    paymentTerm: {
      dropdownOpen: false,
      inputValue: "",
    },
     portOfDischarge: {
      dropdownOpen: false,
      inputValue: "",
    },
    portOfLoading: {
      dropdownOpen: false,
      inputValue: "",
    },
    referenceVendorOffer: {
      dropdownOpen: false,
      inputValue: "",
    },
  });

  // Function to update state for a specific component
  const updateComponentState = (componentKey, newState) => {
    setComponentsState((prevState) => ({
      ...prevState,
      [componentKey]: {
        ...prevState[componentKey],
        ...newState,
      },
    }));
  };

  return (
    <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
         <TextField
        id="outlined-search"
        label="Offer Year"
        value={filterCustomerOffer?.offerYear || ""}
        type="number"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            offerYear: e.target.value,
          })
        }
      />
      <TextField
        id="outlined-search"
        label="Offer Number"
        value={filterCustomerOffer?.offerGroupNumber || ""}
        type="number"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            offerGroupNumber: e.target.value,
          })
        }
      />
      <Autocomplete
        multiple
        id="outlined-select-vendor-offer"
        disableClearable
        open={componentsState.referenceVendorOffer.dropdownOpen}
        onOpen={() => updateComponentState("referenceVendorOffer", { dropdownOpen: true })}
        inputValue={componentsState.referenceVendorOffer.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("referenceVendorOffer", { inputValue: newInputValue });
          }
        }}
        options={referenceVendorOffers}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.offerYear || ""} - ${
            option?.offerGroupNumber || ""
          } Item: ${option?.item?.id || ""} - ${option?.item?.buyingGrade || ""}`
        }
        value={referenceVendorOffers.filter((referenceVendorOffer) =>
          filterCustomerOffer.referenceVendorOfferIdList.includes(referenceVendorOffer.id),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            referenceVendorOfferIdList: newValue.map((referenceVendorOffer) => referenceVendorOffer.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.offerYear || ""} - ${
              option?.offerGroupNumber || ""
            } Item: ${option?.item?.id || ""} - ${option?.item?.buyingGrade || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.referenceVendorOfferIdList.length > 0
              ? `Reference Vendor Offers Selected (${filterCustomerOffer?.referenceVendorOfferIdList.length})`
              : "Reference Vendor Offer";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.referenceVendorOffer.inputValue}
              onChange={(e) =>
                updateComponentState("referenceVendorOffer", { inputValue: e.target.value })
              }
              onMouseDown={retrieveVendorOffers}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("referenceVendorOffer", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

<Autocomplete
        multiple
        id="outlined-select-item"
        disableClearable
        open={componentsState.item.dropdownOpen}
        onOpen={() => updateComponentState("item", { dropdownOpen: true })}
        inputValue={componentsState.item.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("item", { inputValue: newInputValue });
          }
        }}
        options={items}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.buyingGrade || ""} - ${
            option?.crop || ""
          }- ${option?.origin || ""}`
        }
        value={items.filter((item) =>
          filterCustomerOffer.itemIdList.includes(item.id),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            itemIdList: newValue.map((item) => item.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.buyingGrade || ""} - ${
              option?.crop || ""
            }- ${option?.origin || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.itemIdList.length > 0
              ? `Items Selected (${filterCustomerOffer?.itemIdList.length})`
              : "Item";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.item.inputValue}
              onChange={(e) =>
                updateComponentState("item", { inputValue: e.target.value })
              }
              onMouseDown={retrieveItems}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("item", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        id="outlined-search"
        label="Tobacco Code"
        value={filterCustomerOffer?.tobaccoCode || ""}
        type="search"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            tobaccoCode: e.target.value,
          })
        }
      />

      <TextField
        id="outlined-search"
        label="Sales Grade"
        value={filterCustomerOffer?.salesGrade || ""}
        type="search"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            salesGrade: e.target.value,
          })
        }
      />

<Autocomplete
        multiple
        id="outlined-select-sales-origin"
        disableClearable
        open={componentsState.salesOrigin.dropdownOpen}
        onOpen={() => updateComponentState("salesOrigin", { dropdownOpen: true })}
        inputValue={componentsState.salesOrigin.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("salesOrigin", { inputValue: newInputValue });
          }
        }}
        options={origin}
        getOptionLabel={(option) => option.label || ""}
        value={origin.filter((salesOriginOption) =>
          filterCustomerOffer.salesOriginList.includes(salesOriginOption.value),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            salesOriginList: newValue.map((salesOriginOption) => salesOriginOption.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option.label || ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.salesOriginList.length > 0
              ? `Sales Origins Selected (${filterCustomerOffer?.salesOriginList.length})`
              : "Sales Origin";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.salesOrigin.inputValue}
              onChange={(e) =>
                updateComponentState("salesOrigin", { inputValue: e.target.value })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("salesOrigin", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

                <TextField
        id="outlined-search"
        label="Sales Crop"
        value={filterCustomerOffer?.salesCrop || ""}
        type="numeric"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            salesCrop: e.target.value,
          })
        }
      />


<TextField
        id="outlined-search"
        label="Sales Nicotine"
        value={filterCustomerOffer?.salesNicotine || ""}
        type="numeric"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            salesNicotine: e.target.value,
          })
        }
      />

      
<TextField
        id="outlined-search"
        label="Sales Sugar"
        value={filterCustomerOffer?.salesSugar || ""}
        type="numeric"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            salesSugar: e.target.value,
          })
        }
      />

      <TextField
        id="outlined-search"
        label="Buying Grade"
        value={filterCustomerOffer?.buyingGrade || ""}
        type="search"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            buyingGrade: e.target.value,
          })
        }
      />

      <TextField
        id="outlined-search"
        label="Internal Grade"
        value={filterCustomerOffer?.internalGrade || ""}
        type="search"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            internalGrade: e.target.value,
          })
        }
      />
      <Autocomplete
        multiple
        id="outlined-select-origin"
        disableClearable
        open={componentsState.origin.dropdownOpen}
        onOpen={() => updateComponentState("origin", { dropdownOpen: true })}
        inputValue={componentsState.origin.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("origin", { inputValue: newInputValue });
          }
        }}
        options={origin}
        getOptionLabel={(option) => option.label || ""}
        value={origin.filter((originOption) =>
          filterCustomerOffer.originList.includes(originOption.value),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            originList: newValue.map((originOption) => originOption.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option.label || ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.originList.length > 0
              ? `Origins Selected (${filterCustomerOffer?.originList.length})`
              : "Origin";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.origin.inputValue}
              onChange={(e) =>
                updateComponentState("origin", { inputValue: e.target.value })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("origin", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <Autocomplete
        multiple
        id="outlined-select-type"
        disableClearable
        open={componentsState.type.dropdownOpen}
        onOpen={() => updateComponentState("type", { dropdownOpen: true })}
        inputValue={componentsState.type.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("type", { inputValue: newInputValue });
          }
        }}
        options={type}
        getOptionLabel={(option) => option.label || ""}
        value={type.filter((typeOption) =>
          filterCustomerOffer.typeList.includes(typeOption.value),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            typeList: newValue.map((typeOption) => typeOption.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option.label || ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.typeList.length > 0
              ? `Types Selected (${filterCustomerOffer?.typeList.length})`
              : "Type";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.type.inputValue}
              onChange={(e) =>
                updateComponentState("type", { inputValue: e.target.value })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("type", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <Autocomplete
        multiple
        id="outlined-select-leaf-form"
        disableClearable
        open={componentsState.leafForm.dropdownOpen}
        onOpen={() => updateComponentState("leafForm", { dropdownOpen: true })}
        inputValue={componentsState.leafForm.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("leafForm", { inputValue: newInputValue });
          }
        }}
        options={leafForm}
        getOptionLabel={(option) => option.label || ""}
        value={leafForm.filter((leafFormOption) =>
          filterCustomerOffer.leafFormList.includes(leafFormOption.value),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            leafFormList: newValue.map(
              (leafFormOption) => leafFormOption.value,
            ),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option.label || ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.leafFormList.length > 0
              ? `Leaf Forms Selected (${filterCustomerOffer?.leafFormList.length})`
              : "Leaf Form";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.leafForm.inputValue}
              onChange={(e) =>
                updateComponentState("leafForm", { inputValue: e.target.value })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("leafForm", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="Color"
        type="search"
        id="outlined-select-color"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.colorList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              colorList: e.target.value,
            });
          },
        }}
      >
        {color.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <ClickAwayListener
  onClickAway={() => {
    if (filterCustomerOffer?.showCropFields) {
      setFilterCustomerOffer({
        ...filterCustomerOffer,
        showCropFields: false,
      });
    }
  }}
>
  <Box sx={{ position: "relative" }}>
    <TextField
      id="crop-year"
      type="text"
      label="Crop Year"
      fullWidth
      variant="outlined"
      value={
        filterCustomerOffer?.cropFrom && filterCustomerOffer?.cropTo
          ? `${filterCustomerOffer.cropFrom} - ${filterCustomerOffer.cropTo}`
          : filterCustomerOffer?.cropFrom
          ? `From ${filterCustomerOffer.cropFrom}`
          : filterCustomerOffer?.cropTo
          ? `To ${filterCustomerOffer.cropTo}`
          : ""
      }
      onFocus={() =>
        setFilterCustomerOffer({
          ...filterCustomerOffer,
          showCropFields: true,
        })
      }
    />
    {filterCustomerOffer?.showCropFields && (
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          mt: 1,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          zIndex: 10,
        }}
      >
        <TextField
          id="crop-year-from"
          type="number"
          label="Crop Year From"
          value={filterCustomerOffer?.cropFrom || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              cropFrom: e.target.value,
            })
          }
        />
        <TextField
          id="crop-year-to"
          type="number"
          label="Crop Year To"
          value={filterCustomerOffer?.cropTo || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              cropTo: e.target.value,
            })
          }
        />
        <Button
          variant="contained"
          sx={{ mt: 1, alignSelf: "center" }}
          onClick={() =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              showCropFields: false,
            })
          }
        >
          Done
        </Button>
      </Paper>
    )}
  </Box>
</ClickAwayListener>

      <TextField
        label="Current Location"
        type="search"
        value={filterCustomerOffer?.itemStorage || ""}
        id="outlined-storage"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            itemStorage: e.target.value,
          })
        }
      />

<ClickAwayListener
  onClickAway={() => {
    if (filterCustomerOffer?.showNicotineFields) {
      setFilterCustomerOffer({
        ...filterCustomerOffer,
        showNicotineFields: false,
      });
    }
  }}
>
  <Box sx={{ position: "relative" }}>
    <TextField
      id="nicotine"
      type="text"
      label="Nicotine"
      fullWidth
      variant="outlined"
      value={
        filterCustomerOffer?.nicotineFrom && filterCustomerOffer?.nicotineTo
          ? `${filterCustomerOffer.nicotineFrom} - ${filterCustomerOffer.nicotineTo}`
          : filterCustomerOffer?.nicotineFrom
          ? `From ${filterCustomerOffer.nicotineFrom}`
          : filterCustomerOffer?.nicotineTo
          ? `To ${filterCustomerOffer.nicotineTo}`
          : ""
      }
      onFocus={() =>
        setFilterCustomerOffer({
          ...filterCustomerOffer,
          showNicotineFields: true,
        })
      }
    />
    {filterCustomerOffer?.showNicotineFields && (
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          mt: 1,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          zIndex: 10,
        }}
      >
        <TextField
          id="nicotine-from"
          type="number"
          label="Nicotine From"
          value={filterCustomerOffer?.nicotineFrom || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              nicotineFrom: e.target.value,
            })
          }
        />
        <TextField
          id="nicotine-to"
          type="number"
          label="Nicotine To"
          value={filterCustomerOffer?.nicotineTo || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              nicotineTo: e.target.value,
            })
          }
        />
        <Button
          variant="contained"
          sx={{ mt: 1, alignSelf: "center" }}
          onClick={() =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              showNicotineFields: false,
            })
          }
        >
          Done
        </Button>
      </Paper>
    )}
  </Box>
</ClickAwayListener>

<ClickAwayListener
  onClickAway={() => {
    if (filterCustomerOffer?.showSugarFields) {
      setFilterCustomerOffer({
        ...filterCustomerOffer,
        showSugarFields: false,
      });
    }
  }}
>
  <Box sx={{ position: "relative" }}>
    <TextField
      id="sugar"
      type="text"
      label="Sugar"
      fullWidth
      variant="outlined"
      value={
        filterCustomerOffer?.sugarFrom && filterCustomerOffer?.sugarTo
          ? `${filterCustomerOffer.sugarFrom} - ${filterCustomerOffer.sugarTo}`
          : filterCustomerOffer?.sugarFrom
          ? `From ${filterCustomerOffer.sugarFrom}`
          : filterCustomerOffer?.sugarTo
          ? `To ${filterCustomerOffer.sugarTo}`
          : ""
      }
      onFocus={() =>
        setFilterCustomerOffer({
          ...filterCustomerOffer,
          showSugarFields: true,
        })
      }
    />
    {filterCustomerOffer?.showSugarFields && (
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          mt: 1,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          zIndex: 10,
        }}
      >
        <TextField
          id="sugar-from"
          type="number"
          label="Sugar From"
          value={filterCustomerOffer?.sugarFrom || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              sugarFrom: e.target.value,
            })
          }
        />
        <TextField
          id="sugar-to"
          type="number"
          label="Sugar To"
          value={filterCustomerOffer?.sugarTo || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              sugarTo: e.target.value,
            })
          }
        />
        <Button
          variant="contained"
          sx={{ mt: 1, alignSelf: "center" }}
          onClick={() =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              showSugarFields: false,
            })
          }
        >
          Done
        </Button>
      </Paper>
    )}
  </Box>
</ClickAwayListener>

      <TextField
        label="Company"
        type="search"
        id="outlined-select-company"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.companyList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              companyList: e.target.value,
            });
          },
        }}
      >
        {companies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="SG Approval Status"
        type="search"
        id="outlined-select-approval-status"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.approvalStatusList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              approvalStatusList: e.target.value,
            });
          },
        }}
      >
        {status.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Status"
        type="search"
        id="outlined-select-status"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.statusList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              statusList: e.target.value,
            });
          },
        }}
      >
        {offerStatus.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <ClickAwayListener
  onClickAway={() => {
    if (filterCustomerOffer?.showStatusDeadlineFields) {
      setFilterCustomerOffer({
        ...filterCustomerOffer,
        showStatusDeadlineFields: false,
      });
    }
  }}
>
  <Box sx={{ position: "relative" }}>
    <TextField
      id="status-deadline"
      type="text"
      label="Status Deadline"
      fullWidth
      variant="outlined"
      value={
        filterCustomerOffer?.statusDeadlineFrom && filterCustomerOffer?.statusDeadlineTo
          ? `${filterCustomerOffer.statusDeadlineFrom}, ${filterCustomerOffer.statusDeadlineTo}`
          : filterCustomerOffer?.statusDeadlineFrom
          ? `From ${filterCustomerOffer.statusDeadlineFrom}`
          : filterCustomerOffer?.statusDeadlineTo
          ? `To ${filterCustomerOffer.statusDeadlineTo}`
          : ""
      }
      onFocus={() =>
        setFilterCustomerOffer({
          ...filterCustomerOffer,
          showStatusDeadlineFields: true,
        })
      }
    />
    {filterCustomerOffer?.showStatusDeadlineFields && (
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          mt: 1,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          zIndex: 10,
        }}
      >
        <TextField
          id="status-deadline-from"
          type="date"
          label="From"
          value={filterCustomerOffer?.statusDeadlineFrom || ""}
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{ shrink: true }}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              statusDeadlineFrom: e.target.value,
            })
          }
        />
        <TextField
          id="status-deadline-to"
          type="date"
          label="To"
          value={filterCustomerOffer?.statusDeadlineTo || ""}
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{ shrink: true }}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              statusDeadlineTo: e.target.value,
            })
          }
        />
        <Button
          variant="contained"
          sx={{ mt: 1, alignSelf: "center" }}
          onClick={() =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              showStatusDeadlineFields: false,
            })
          }
        >
          Done
        </Button>
      </Paper>
    )}
  </Box>
</ClickAwayListener>

      <Autocomplete
        multiple
        id="outlined-select-customer"
        disableClearable
        open={componentsState.customer.dropdownOpen}
        onOpen={() => updateComponentState("customer", { dropdownOpen: true })}
        inputValue={componentsState.customer.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("customer", { inputValue: newInputValue });
          }
        }}
        options={customers}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.name || ""}`
        }
        value={customers.filter((customer) =>
          filterCustomerOffer.stakeholderIdList.includes(customer.id),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            stakeholderIdList: newValue.map((customer) => customer.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.name || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.stakeholderIdList.length > 0
              ? `Customers Selected (${filterCustomerOffer?.stakeholderIdList.length})`
              : "Customer";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.customer.inputValue}
              onChange={(e) =>
                updateComponentState("customer", { inputValue: e.target.value })
              }
              onMouseDown={retrieveCustomers}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("customer", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <Autocomplete
        multiple
        id="outlined-select-cost"
        disableClearable
        open={componentsState.cost.dropdownOpen}
        onOpen={() => updateComponentState("cost", { dropdownOpen: true })}
        inputValue={componentsState.cost.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("cost", { inputValue: newInputValue });
          }
        }}
        options={costs}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.remark || ""}`
        }
        value={costs.filter((costOption) =>
          filterCustomerOffer.costIdList.includes(costOption.id),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            costIdList: newValue.map((costOption) => costOption.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.remark || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.costIdList.length > 0
              ? `Costs Selected (${filterCustomerOffer?.costIdList.length})`
              : "Cost";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.cost.inputValue}
              onChange={(e) =>
                updateComponentState("cost", { inputValue: e.target.value })
              }
              onMouseDown={retrieveCosts}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("cost", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

<ClickAwayListener
  onClickAway={() => {
    if (filterCustomerOffer?.showFirstOfferPriceFields) {
      setFilterCustomerOffer({
        ...filterCustomerOffer,
        showFirstOfferPriceFields: false,
      });
    }
  }}
>
  <Box sx={{ position: "relative" }}>
    <TextField
      id="first-offer"
      type="text"
      label="First Offer"
      fullWidth
      variant="outlined"
      value={
        filterCustomerOffer?.firstOfferPriceFrom && filterCustomerOffer?.firstOfferPriceTo
          ? `${filterCustomerOffer.firstOfferPriceFrom} - ${filterCustomerOffer.firstOfferPriceTo}`
          : filterCustomerOffer?.firstOfferPriceFrom
          ? `From ${filterCustomerOffer.firstOfferPriceFrom}`
          : filterCustomerOffer?.firstOfferPriceTo
          ? `To ${filterCustomerOffer.firstOfferPriceTo}`
          : ""
      }
      onFocus={() =>
        setFilterCustomerOffer({
          ...filterCustomerOffer,
          showFirstOfferPriceFields: true,
        })
      }
    />
    {filterCustomerOffer?.showFirstOfferPriceFields && (
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          mt: 1,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          zIndex: 10,
        }}
      >
        <TextField
          id="first-offer-from"
          type="number"
          label="First Offer From"
          value={filterCustomerOffer?.firstOfferPriceFrom || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              firstOfferPriceFrom: e.target.value,
            })
          }
        />
        <TextField
          id="first-offer-to"
          type="number"
          label="First Offer To"
          value={filterCustomerOffer?.firstOfferPriceTo || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              firstOfferPriceTo: e.target.value,
            })
          }
        />
        <Button
          variant="contained"
          sx={{ mt: 1, alignSelf: "center" }}
          onClick={() =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              showFirstOfferPriceFields: false,
            })
          }
        >
          Done
        </Button>
      </Paper>
    )}
  </Box>
</ClickAwayListener>

  <TextField
        label="First Offer Price Currency"
        type="search"
        id="outlined-select-first-offer-currency"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.firstPriceCurrencyList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              firstPriceCurrencyList: e.target.value,
            });
          },
        }}
      >
        {currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <ClickAwayListener
  onClickAway={() => {
    if (filterCustomerOffer?.showFinalOfferPriceFields) {
      setFilterCustomerOffer({
        ...filterCustomerOffer,
        showFinalOfferPriceFields: false,
      });
    }
  }}
>
  <Box sx={{ position: "relative" }}>
    <TextField
      id="final-offer"
      type="text"
      label="Final Offer"
      fullWidth
      variant="outlined"
      value={
        filterCustomerOffer?.finalOfferPriceFrom && filterCustomerOffer?.finalOfferPriceTo
          ? `${filterCustomerOffer.finalOfferPriceFrom} - ${filterCustomerOffer.finalOfferPriceTo}`
          : filterCustomerOffer?.finalOfferPriceFrom
          ? `From ${filterCustomerOffer.finalOfferPriceFrom}`
          : filterCustomerOffer?.finalOfferPriceTo
          ? `To ${filterCustomerOffer.finalOfferPriceTo}`
          : ""
      }
      onFocus={() =>
        setFilterCustomerOffer({
          ...filterCustomerOffer,
          showFinalOfferPriceFields: true,
        })
      }
    />
    {filterCustomerOffer?.showFinalOfferPriceFields && (
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          mt: 1,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          zIndex: 10,
        }}
      >
        <TextField
          id="final-offer-from"
          type="number"
          label="Final Offer From"
          value={filterCustomerOffer?.finalOfferPriceFrom || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              finalOfferPriceFrom: e.target.value,
            })
          }
        />
        <TextField
          id="final-offer-to"
          type="number"
          label="Final Offer To"
          value={filterCustomerOffer?.finalOfferPriceTo || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              finalOfferPriceTo: e.target.value,
            })
          }
        />
        <Button
          variant="contained"
          sx={{ mt: 1, alignSelf: "center" }}
          onClick={() =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              showFinalOfferPriceFields: false,
            })
          }
        >
          Done
        </Button>
      </Paper>
    )}
  </Box>
</ClickAwayListener>


<TextField
        label="Final Offer Price Currency"
        type="search"
        id="outlined-select-final-offer-currency"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.finalPriceCurrencyList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              finalPriceCurrencyList: e.target.value,
            });
          },
        }}
      >
        {currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Autocomplete
        multiple
        id="outlined-select-offer-sample"
        disableClearable
        open={componentsState.firstSample.dropdownOpen}
        onOpen={() =>
          updateComponentState("firstSample", { dropdownOpen: true })
        }
        inputValue={componentsState.firstSample.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("firstSample", { inputValue: newInputValue });
          }
        }}
        options={samples}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.remark || ""}`
        }
        value={samples.filter((sample) =>
          filterCustomerOffer.firstSampleIdList.includes(sample.id),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            firstSampleIdList: newValue.map((sample) => sample.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.remark || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.firstSampleIdList.length > 0
              ? `Offer Samples Selected (${filterCustomerOffer?.firstSampleIdList.length})`
              : "Offer Sample";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.firstSample.inputValue}
              onChange={(e) =>
                updateComponentState("firstSample", {
                  inputValue: e.target.value,
                })
              }
              onMouseDown={retrieveSamples}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("firstSample", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="Offer Sample Status"
        type="search"
        id="outlined-select-offer-sample-status"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.firstSampleStatusList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              firstSampleStatusList: e.target.value,
            });
          },
        }}
      >
        {sampleStatus.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Autocomplete
        multiple
        id="outlined-select-first-sample"
        disableClearable
        open={componentsState.secondSample.dropdownOpen}
        onOpen={() =>
          updateComponentState("secondSample", { dropdownOpen: true })
        }
        inputValue={componentsState.secondSample.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("secondSample", { inputValue: newInputValue });
          }
        }}
        options={samples}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.remark || ""}`
        }
        value={samples.filter((sample) =>
          filterCustomerOffer.secondSampleIdList.includes(sample.id),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            secondSampleIdList: newValue.map((sample) => sample.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.remark || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.secondSampleIdList.length > 0
              ? `Trial Samples Selected (${filterCustomerOffer?.secondSampleIdList.length})`
              : "Trial Sample";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.secondSample.inputValue}
              onChange={(e) =>
                updateComponentState("secondSample", {
                  inputValue: e.target.value,
                })
              }
              onMouseDown={retrieveSamples}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("secondSample", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />
      <TextField
        label="Trial Sample Status"
        type="search"
        id="outlined-select-second-sample-status"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.secondSampleStatusList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              secondSampleStatusList: e.target.value,
            });
          },
        }}
      >
        {sampleStatus.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Autocomplete
        multiple
        id="outlined-select-payment-term"
        disableClearable
        open={componentsState.paymentTerm.dropdownOpen}
        onOpen={() =>
          updateComponentState("paymentTerm", { dropdownOpen: true })
        }
        inputValue={componentsState.paymentTerm.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("paymentTerm", { inputValue: newInputValue });
          }
        }}
        options={paymentTerm}
        getOptionLabel={(option) =>
          option ? `${option.value} - ${option.label}` : ""
        }
        value={paymentTerm.filter((term) =>
          filterCustomerOffer.paymentTermList.includes(term.value),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            paymentTermList: newValue.map((term) => term.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option ? `${option.value} - ${option.label}` : ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.paymentTermList.length > 0
              ? `Payment Terms Selected (${filterCustomerOffer?.paymentTermList.length})`
              : "Payment Term";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.paymentTerm.inputValue}
              onChange={(e) =>
                updateComponentState("paymentTerm", {
                  inputValue: e.target.value,
                })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("paymentTerm", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="Incoterm"
        type="search"
        id="outlined-select-incoterm"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.incotermList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              incotermList: e.target.value,
            });
          },
        }}
      >
        {incoterms.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      
            <ClickAwayListener
              onClickAway={() => {
                if (filterCustomerOffer?.showNumberOfCasesFields) {
                  setFilterCustomerOffer({
                    ...filterCustomerOffer,
                    showNumberOfCasesFields: false,
                  });
                }
              }}
            >
              <Box sx={{ position: "relative" }}>
                <TextField
                  id="number-of-cases"
                  type="text"
                  label="Number Of Cartons"
                  variant="outlined"
                  value={
                    filterCustomerOffer?.numberOfCasesFrom && filterCustomerOffer?.numberOfCasesTo
                      ? `${filterCustomerOffer?.numberOfCasesFrom} - ${filterCustomerOffer?.numberOfCasesTo}`
                      : filterCustomerOffer?.numberOfCasesFrom
                      ? `From ${filterCustomerOffer?.numberOfCasesFrom}`
                      : filterCustomerOffer?.numberOfCasesTo
                      ? `To ${filterCustomerOffer?.numberOfCasesTo}`
                      : ""
                  }
                  fullWidth
                  onFocus={() =>
                    setFilterCustomerOffer({
                      ...filterCustomerOffer,
                      showNumberOfCasesFields: true,
                    })
                  }
                />
      
                {filterCustomerOffer?.showNumberOfCasesFields && (
                  <Paper
                  elevation={3}
                  sx={{
                    position: "absolute",
                    mt: 1,
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    zIndex: 10,
                  }}
                >
                    <TextField
                      id="numberOfCases-from"
                      type="number"
                      label="Number Of Cartons From"
                      value={filterCustomerOffer?.numberOfCasesFrom || ""}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        setFilterCustomerOffer({
                          ...filterCustomerOffer,
                          numberOfCasesFrom: e.target.value,
                        })
                      }
                    />
      
                    <TextField
                      id="numberOfCases-to"
                      type="number"
                      label="Number Of Cartons To"
                      value={filterCustomerOffer?.numberOfCasesTo || ""}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) =>
                        setFilterCustomerOffer({
                          ...filterCustomerOffer,
                          numberOfCasesTo: e.target.value,
                        })
                      }
                    />
      
                    <Button
                      variant="contained"
                      sx={{ mt: 1, alignSelf: "center" }}
                      onClick={() =>
                        setFilterCustomerOffer({
                          ...filterCustomerOffer,
                          showNumberOfCasesFields: false, 
                        })
                      }
                    >
                      Done
                    </Button>
                  </Paper>
                )}
              </Box>
            </ClickAwayListener>

<ClickAwayListener
        onClickAway={() => {
          if (filterCustomerOffer?.showNetWeightPerCaseFields) {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              showNetWeightPerCaseFields: false,
            });
          }
        }}
      >
        <Box sx={{ position: "relative" }}>
          <TextField
            id="net-weight-per-case"
            type="text"
            label="Net Weight Per Carton"
            variant="outlined"
            value={
              filterCustomerOffer?.netWeightPerCaseFrom && filterCustomerOffer?.netWeightPerCaseTo
                ? `${filterCustomerOffer?.netWeightPerCaseFrom} - ${filterCustomerOffer?.netWeightPerCaseTo}`
                : filterCustomerOffer?.netWeightPerCaseFrom
                ? `From ${filterCustomerOffer?.netWeightPerCaseFrom}`
                : filterCustomerOffer?.netWeightPerCaseTo
                ? `To ${filterCustomerOffer?.netWeightPerCaseTo}`
                : ""
            }
            fullWidth
            onFocus={() =>
              setFilterCustomerOffer({
                ...filterCustomerOffer,
                showNetWeightPerCaseFields: true,
              })
            }
          />

          {filterCustomerOffer?.showNetWeightPerCaseFields && (
            <Paper
            elevation={3}
            sx={{
              position: "absolute",
              mt: 1,
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              zIndex: 10,
            }}
          >
              <TextField
                id="net-weight-per-case-from"
                type="number"
                label="Net Weight Per Carton From"
                value={filterCustomerOffer?.netWeightPerCaseFrom || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterCustomerOffer({
                    ...filterCustomerOffer,
                    netWeightPerCaseFrom: e.target.value,
                  })
                }
              />

              <TextField
                id="net-weight-per-case-to"
                type="number"
                label="Net Weight Per Carton To"
                value={filterCustomerOffer?.netWeightPerCaseTo || ""}
                onWheel={(e) => e.target.blur()}
                onChange={(e) =>
                  setFilterCustomerOffer({
                    ...filterCustomerOffer,
                    netWeightPerCaseTo: e.target.value,
                  })
                }
              />

              <Button
                variant="contained"
                sx={{ mt: 1, alignSelf: "center" }}
                onClick={() =>
                  setFilterCustomerOffer({
                    ...filterCustomerOffer,
                    showNetWeightPerCaseFields: false, 
                  })
                }
              >
                Done
              </Button>
            </Paper>
          )}
        </Box>
      </ClickAwayListener>
<ClickAwayListener
  onClickAway={() => {
    if (filterCustomerOffer?.showVolumeFields) {
      setFilterCustomerOffer({
        ...filterCustomerOffer,
        showVolumeFields: false,
      });
    }
  }}
>
  <Box sx={{ position: "relative" }}>
    <TextField
      id="volume"
      type="text"
      label="Volume"
      variant="outlined"
      fullWidth
      value={
        filterCustomerOffer?.volumeFrom && filterCustomerOffer?.volumeTo
          ? `${filterCustomerOffer.volumeFrom} - ${filterCustomerOffer.volumeTo}`
          : filterCustomerOffer?.volumeFrom
          ? `From ${filterCustomerOffer.volumeFrom}`
          : filterCustomerOffer?.volumeTo
          ? `To ${filterCustomerOffer.volumeTo}`
          : ""
      }
      onFocus={() =>
        setFilterCustomerOffer({
          ...filterCustomerOffer,
          showVolumeFields: true,
        })
      }
    />
    {filterCustomerOffer?.showVolumeFields && (
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          mt: 1,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          zIndex: 10,
        }}
      >
        <TextField
          id="volume-from"
          type="number"
          label="Volume From"
          value={filterCustomerOffer?.volumeFrom || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              volumeFrom: e.target.value,
            })
          }
        />
        <TextField
          id="volume-to"
          type="number"
          label="Volume To"
          value={filterCustomerOffer?.volumeTo || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              volumeTo: e.target.value,
            })
          }
        />
        <Button
          variant="contained"
          sx={{ mt: 1, alignSelf: "center" }}
          onClick={() =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              showVolumeFields: false,
            })
          }
        >
          Done
        </Button>
      </Paper>
    )}
  </Box>
</ClickAwayListener>

      <TextField
        label="Volume Unit"
        type="search"
        id="outlined-select-volume-unit"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.volumeUnitList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              volumeUnitList: e.target.value,
            });
          },
        }}
      >
        {units.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <ClickAwayListener
  onClickAway={() => {
    if (filterCustomerOffer?.showTotalAmountFields) {
      setFilterCustomerOffer({
        ...filterCustomerOffer,
        showTotalAmountFields: false,
      });
    }
  }}
>
  <Box sx={{ position: "relative" }}>
    <TextField
      id="total-amount"
      type="text"
      label="Total Amount"
      fullWidth
      variant="outlined"
      value={
        filterCustomerOffer?.totalAmountFrom && filterCustomerOffer?.totalAmountTo
          ? `${filterCustomerOffer.totalAmountFrom} - ${filterCustomerOffer.totalAmountTo}`
          : filterCustomerOffer?.totalAmountFrom
          ? `From ${filterCustomerOffer.totalAmountFrom}`
          : filterCustomerOffer?.totalAmountTo
          ? `To ${filterCustomerOffer.totalAmountTo}`
          : ""
      }
      onFocus={() =>
        setFilterCustomerOffer({
          ...filterCustomerOffer,
          showTotalAmountFields: true,
        })
      }
    />
    {filterCustomerOffer?.showTotalAmountFields && (
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          mt: 1,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          zIndex: 10,
        }}
      >
        <TextField
          id="total-amount-from"
          type="number"
          label="Total Amount From"
          value={filterCustomerOffer?.totalAmountFrom || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              totalAmountFrom: e.target.value,
            })
          }
        />
        <TextField
          id="total-amount-to"
          type="number"
          label="Total Amount To"
          value={filterCustomerOffer?.totalAmountTo || ""}
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              totalAmountTo: e.target.value,
            })
          }
        />
        <Button
          variant="contained"
          sx={{ mt: 1, alignSelf: "center" }}
          onClick={() =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              showTotalAmountFields: false,
            })
          }
        >
          Done
        </Button>
      </Paper>
    )}
  </Box>
</ClickAwayListener>

<ClickAwayListener
  onClickAway={() => {
    if (filterCustomerOffer?.showOfferDateFields) {
      setFilterCustomerOffer({
        ...filterCustomerOffer,
        showOfferDateFields: false,
      });
    }
  }}
>
  <Box sx={{ position: "relative" }}>
    <TextField
      id="offer-date"
      type="text"
      label="Offer Date"
      fullWidth
      variant="outlined"
      value={
        filterCustomerOffer?.offerDateFrom && filterCustomerOffer?.offerDateTo
          ? `${filterCustomerOffer.offerDateFrom}, ${filterCustomerOffer.offerDateTo}`
          : filterCustomerOffer?.offerDateFrom
          ? `From ${filterCustomerOffer.offerDateFrom}`
          : filterCustomerOffer?.offerDateTo
          ? `To ${filterCustomerOffer.offerDateTo}`
          : ""
      }
      onFocus={() =>
        setFilterCustomerOffer({
          ...filterCustomerOffer,
          showOfferDateFields: true,
        })
      }
    />
    {filterCustomerOffer?.showOfferDateFields && (
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          mt: 1,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          zIndex: 10,
        }}
      >
        <TextField
          id="offer-date-from"
          type="date"
          label="From"
          value={filterCustomerOffer?.offerDateFrom || ""}
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{ shrink: true }}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              offerDateFrom: e.target.value,
            })
          }
        />
        <TextField
          id="offer-date-to"
          type="date"
          label="To"
          value={filterCustomerOffer?.offerDateTo || ""}
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{ shrink: true }}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              offerDateTo: e.target.value,
            })
          }
        />
        <Button
          variant="contained"
          sx={{ mt: 1, alignSelf: "center" }}
          onClick={() =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              showOfferDateFields: false,
            })
          }
        >
          Done
        </Button>
      </Paper>
    )}
  </Box>
</ClickAwayListener>


<ClickAwayListener
  onClickAway={() => {
    if (filterCustomerOffer?.showValidityDateFields) {
      setFilterCustomerOffer({
        ...filterCustomerOffer,
        showValidityDateFields: false,
      });
    }
  }}
>
  <Box sx={{ position: "relative" }}>
    <TextField
      id="validity-date"
      type="text"
      label="Validity Date"
      fullWidth
      variant="outlined"
      value={
        filterCustomerOffer?.validityDateFrom && filterCustomerOffer?.validityDateTo
          ? `${filterCustomerOffer.validityDateFrom}, ${filterCustomerOffer.validityDateTo}`
          : filterCustomerOffer?.validityDateFrom
          ? `From ${filterCustomerOffer.validityDateFrom}`
          : filterCustomerOffer?.validityDateTo
          ? `To ${filterCustomerOffer.validityDateTo}`
          : ""
      }
      onFocus={() =>
        setFilterCustomerOffer({
          ...filterCustomerOffer,
          showValidityDateFields: true,
        })
      }
    />
    {filterCustomerOffer?.showValidityDateFields && (
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          mt: 1,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          zIndex: 10,
        }}
      >
        <TextField
          id="validity-date-from"
          type="date"
          label="From"
          value={filterCustomerOffer?.validityDateFrom || ""}
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{ shrink: true }}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              validityDateFrom: e.target.value,
            })
          }
        />
        <TextField
          id="validity-date-to"
          type="date"
          label="To"
          value={filterCustomerOffer?.validityDateTo || ""}
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{ shrink: true }}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(e) =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              validityDateTo: e.target.value,
            })
          }
        />
        <Button
          variant="contained"
          sx={{ mt: 1, alignSelf: "center" }}
          onClick={() =>
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              showValidityDateFields: false,
            })
          }
        >
          Done
        </Button>
      </Paper>
    )}
  </Box>
</ClickAwayListener>

      <TextField
        id="outlined-search"
        label="Remark"
        value={filterCustomerOffer?.remark || ""}
        type="search"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            remark: e.target.value,
          })
        }
      />
            <TextField
        id="outlined-search"
        label="Comment"
        value={filterCustomerOffer?.comment || ""}
        type="search"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            comment: e.target.value,
          })
        }
      />

<Autocomplete
        multiple
        id="outlined-select-port-of-loading"
        disableClearable
        open={componentsState.portOfLoading.dropdownOpen}
        onOpen={() =>
          updateComponentState("portOfLoading", { dropdownOpen: true })
        }
        inputValue={componentsState.portOfLoading.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("portOfLoading", { inputValue: newInputValue });
          }
        }}
        options={portsOfLoading}
        getOptionLabel={(option) =>
          option ? `${option.value} - ${option.label}` : ""
        }
        value={portsOfLoading.filter((term) =>
          filterCustomerOffer.portOfLoadingList.includes(term.value),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            portOfLoadingList: newValue.map((term) => term.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option ? `${option.value} - ${option.label}` : ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.portOfLoadingList.length > 0
              ? `Ports Of Loading Selected (${filterCustomerOffer?.portOfLoadingList.length})`
              : "Port Of Loading";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.portOfLoading.inputValue}
              onChange={(e) =>
                updateComponentState("portOfLoading", {
                  inputValue: e.target.value,
                })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("portOfLoading", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

<Autocomplete
        multiple
        id="outlined-select-port-of-discharge"
        disableClearable
        open={componentsState.portOfDischarge.dropdownOpen}
        onOpen={() =>
          updateComponentState("portOfDischarge", { dropdownOpen: true })
        }
        inputValue={componentsState.portOfDischarge.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("portOfDischarge", { inputValue: newInputValue });
          }
        }}
        options={portsOfDischarge}
        getOptionLabel={(option) =>
          option ? `${option.value} - ${option.label}` : ""
        }
        value={portsOfDischarge.filter((term) =>
          filterCustomerOffer.portOfDischargeList.includes(term.value),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            portOfDischargeList: newValue.map((term) => term.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option ? `${option.value} - ${option.label}` : ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.portOfDischargeList.length > 0
              ? `Ports Of Discharge Selected (${filterCustomerOffer?.portOfDischargeList.length})`
              : "Port Of Discharge";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.portOfDischarge.inputValue}
              onChange={(e) =>
                updateComponentState("portOfDischarge", {
                  inputValue: e.target.value,
                })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("portOfDischarge", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="Document Status"
        type="search"
        id="outlined-select-document-status"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.documentStatusList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              documentStatusList: e.target.value,
            });
          },
        }}
      >
        {status.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Record Status"
        type="search"
        id="outlined-select-record-status"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.recordStatusList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              recordStatusList: e.target.value,
            });
          },
        }}
      >
        {recordStatus.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

export default CustomerOfferSearch;
