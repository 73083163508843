import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  MenuItem,
  Stack,
  TextField,
  Chip,
  Autocomplete,
  Typography,
  Box,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { updateWorkLogApi } from "../../../components/api/WorkLogApiService";
import toast from "react-hot-toast";
import { retrieveAllTicketsApi } from "../../../components/api/TicketApiService";

const taskTypes = [
  { value: "Bug Fix", label: "Bug Fix" },
  { value: "Meeting", label: "Meeting" },
  { value: "Testing & QA", label: "Testing & QA" },
  { value: "Technical Review", label: "Technical Review" },
  { value: "Feature Development", label: "Feature Development" },
  { value: "General Maintenance", label: "General Maintenance" },
  { value: "Other", label: "Other" },
];
const predefinedTaskTypes = taskTypes.map((option) => option.value);

const recordStatusOptions = [
  { value: "ACTIVE", label: "ACTIVE" },
  { value: "PASSIVE", label: "PASSIVE" },
];

export default function EditWorkLog({
  editWorkLog,
  setEditWorkLog,
  workLogs,
  setWorkLogs,
}) {

    const [tickets, setTickets] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState(
        editWorkLog?.data?.ticket || null,
    );

  if (!editWorkLog?.visible) return null;

  function retrieveTickets() {
       retrieveAllTicketsApi()
         .then((response) => {
           setTickets(response?.data);
         })
         .catch((error) => console.log(error.response?.data));
     }

  const updateField = (field, value) => {
    setEditWorkLog((prev) => ({
      ...prev,
      data: { ...prev.data, [field]: value },
    }));
  };

  const handleCancel = () => {
    setEditWorkLog({ visible: false, data: null });
  };

  const handleSave = () => {
    const currentData = editWorkLog?.data;
    // If "Other" is selected and a custom task type is provided, use it.
    const finalTaskType =
      currentData.taskType === "Other" && currentData.customTaskType
        ? currentData.customTaskType
        : currentData.taskType;

    const updatedWorkLog = {
      ...currentData,
      ticketId: currentData.ticketId ? Number(currentData.ticketId) : null,
      taskType: finalTaskType,
      timeSpent: Number(currentData.timeSpent),
    };

    if (workLogs && setWorkLogs) {
      const idx = workLogs.findIndex((w) => w.id === currentData.id);
      if (idx !== -1) {
        const updatedLogs = [...workLogs];
        updatedLogs[idx] = updatedWorkLog;
        setWorkLogs(updatedLogs);
      }
    }
    updateWorkLogApi(updatedWorkLog?.id, updatedWorkLog)
    .then((response) => {
      toast.success("Log edited successfully.");
      window.location.reload();
      setEditWorkLog({ visible: false, data: null, index: null });
    })
    .catch((error) => {
      toast.error("Something went wrong.");
      console.log(error.response.data);
    });
  };

  const handleDelete = () => {
    if (!window.confirm("Are you sure you want to delete this work log entry?")) return;

    const currentData = editWorkLog?.data;
    const deletedWorkLog = { ...currentData, recordStatus: "PASSIVE" };

    if (workLogs && setWorkLogs) {
      const idx = workLogs.findIndex((w) => w.id === currentData.id);
      if (idx !== -1) {
        const updatedLogs = [...workLogs];
        updatedLogs[idx] = deletedWorkLog;
        setWorkLogs(updatedLogs);
      }
    }
    updateWorkLogApi(deletedWorkLog?.id, deletedWorkLog)
    .then((response) => {
      toast.success("Log edited successfully.");
      window.location.reload();
      setEditWorkLog({ visible: false, data: null, index: null });
    })
    .catch((error) => {
      toast.error("Something went wrong.");
      console.log(error.response.data);
    });
  };

  return (
    <Dialog open={editWorkLog?.visible} onClose={handleCancel} maxWidth="md" fullWidth>
      <DialogTitle>Edit Work Log #{editWorkLog?.data?.id}</DialogTitle>
      <DialogContent>
        
        <Stack spacing={2} sx={{ mt: 1 }}>
      <Autocomplete
        options={tickets}
        getOptionLabel={(option) =>
          `${option?.id || ""} - Title: ${option?.title || ""}`
        }
        value={selectedTicket}
        onChange={(event, newValue) => {
          setSelectedTicket(newValue);
          setEditWorkLog({
            ...editWorkLog,
            data: {
              ...editWorkLog.data,
              ticket: newValue ? newValue : null,
            },
          });
        }}
        onOpen={retrieveTickets}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            label={
              (selectedTicket?.id || "Ticket (Optional)") +
              " " +
              (selectedTicket?.title || "")
            }
          />
        )}
        renderTags={() => null}
      />
      {selectedTicket && (
        <Box mt={2}>
          <Chip
            label={`Description: ${selectedTicket?.description || ""}`}
            style={{ marginBottom: '5px' }}
            onDelete={() => {
                setSelectedTicket(null);
                setEditWorkLog({
                    ...editWorkLog,
                    data: {
                      ...editWorkLog.data,
                      ticket: null,
                    },
                  });
            }}
          /><br />
        </Box>
      )}

  <TextField
          label="Operation Date"
          type="date"
          variant="outlined"
          fullWidth
          size="small"
          inputProps={{ min: "1999-01-01", max: new Date().toISOString().split("T")[0], }}
              InputLabelProps={{
                shrink: true,
              }}
          onKeyDown={(e) => e.preventDefault()}
                value={
                  editWorkLog?.data?.operationDate
                    ? format(
                        parseISO(editWorkLog.data.operationDate),
                        "yyyy-MM-dd",
                      )
                    : ""
                }
                onChange={(e) =>
                  setEditWorkLog({
                    ...editWorkLog,
                    data: {
                      ...editWorkLog.data,
                      operationDate: e.target.value
                        ? e.target.value + "T00:00:00.000+00:00"
                        : null,
                    },
                  })
                }
        />

          {/* Task Type */}
          <TextField
            label="Task Type"
            select
            variant="outlined"
            fullWidth
            size="small"
            value={editWorkLog?.data?.taskType || ""}
            error={!editWorkLog?.data?.taskType}
            helperText={!editWorkLog?.data?.taskType ? "Task type is required" : ""}
            onChange={(e) => {
              const newTaskType = e.target.value;
              updateField("taskType", newTaskType);
              // Clear customTaskType if not "Other"
              if (newTaskType !== "Other") {
                updateField("customTaskType", "");
              }
            }}
          >
            {taskTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {(editWorkLog?.data?.taskType === "Other" ||
            !predefinedTaskTypes.includes(editWorkLog?.data?.taskType)
          ) && (
            <TextField
              label="Enter Task Type"
              variant="outlined"
              fullWidth
              size="small"
              value={
                !predefinedTaskTypes.includes(editWorkLog?.data?.taskType)
                  ? editWorkLog.data.taskType
                  : editWorkLog.data.customTaskType || ""
              }
              onChange={(e) => {
                updateField("customTaskType", e.target.value);
                updateField("taskType", e.target.value);
              }}
              sx={{ mt: 2 }}
            />
          )}

          {/* Description */}
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={editWorkLog?.data?.description || ""}
            onChange={(e) => updateField("description", e.target.value)}
          />

          {/* Time Spent */}
          <TextField
            label="Time Spent (hrs)"
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            inputProps={{ min: "0", step: "0.1" }}
            value={editWorkLog?.data?.timeSpent || ""}
            onChange={(e) => updateField("timeSpent", e.target.value)}
          />

          {/* Record Status */}
          <TextField
            label="Record Status"
            select
            variant="outlined"
            fullWidth
            size="small"
            value={editWorkLog?.data?.recordStatus || "ACTIVE"}
            onChange={(e) => updateField("recordStatus", e.target.value)}
          >
            {recordStatusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete}>
          Delete
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
