import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";

function ViewTicketDialog({ viewTicket, setViewTicket }) {
  const [previewImage, setPreviewImage] = useState(null);
  const ticket = viewTicket.data || {};

  return (
    <>
      <Dialog
        open={viewTicket.visible}
        onClose={() => setViewTicket({ visible: false, data: null })}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Ticket Details #{ticket?.id}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 1 }}>
            <TextField
              label="Title"
              fullWidth
              size="small"
              value={ticket?.title || ""}
              disabled
            />
            <Stack direction="row" spacing={2}>
              <TextField
                label="Priority"
                fullWidth
                size="small"
                value={ticket?.priority || ""}
                disabled
              />
              <TextField
                label="Status"
                fullWidth
                size="small"
                value={ticket?.status || ""}
                disabled
              />
            </Stack>
            <TextField
              label="Status Remark"
              fullWidth
              value={ticket?.statusRemark || ""}
              disabled
            />
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={ticket?.description || ""}
              disabled
            />
            <Stack direction="row" spacing={2}>
              <TextField
                label="Created By"
                fullWidth
                size="small"
                value={ticket?.createdBy || ""}
                disabled
              />
              <TextField
                label="Assigned To"
                fullWidth
                size="small"
                value={ticket?.assignedTo?.name || "Unassigned"}
                disabled
              />
            </Stack>
            <TextField
              label="Created At"
              fullWidth
              size="small"
              value={
                ticket?.createdAt
                  ? new Date(ticket?.createdAt).toLocaleString()
                  : ""
              }
              disabled
            />
            <TextField
              label="Record Status"
              fullWidth
              size="small"
              value={ticket?.recordStatus || "ACTIVE"}
              disabled
            />
 {ticket?.imageBase64List && ticket?.imageBase64List.length > 0 && (
  <Box>
    <Typography variant="body2" sx={{ mb: 1 }}>
      Images:
    </Typography>
    <Stack direction="row" flexWrap="wrap" gap={2}>
      {ticket.imageBase64List.map((imgBase64, index) => (
        <Box
          key={index}
          sx={{
            width: 100,
            height: 100,
            borderRadius: 2,
            overflow: "hidden",
            border: "1px solid #ccc",
            cursor: "pointer",
          }}
          onClick={() => setPreviewImage(`data:image/png;base64,${imgBase64}`)}
        >
          <img
            src={`data:image/png;base64,${imgBase64}`}
            alt={`Ticket ${index}`}
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        </Box>
      ))}
    </Stack>
  </Box>
)}

          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewTicket({ visible: false, data: null })}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {previewImage && (
        <Dialog
          open={!!previewImage}
          onClose={() => setPreviewImage(null)}
          maxWidth="md"
        >
          <DialogTitle>Image Preview</DialogTitle>
          <DialogContent>
            <img
              src={previewImage}
              alt="Full Preview"
              style={{ width: "100%", height: "auto", borderRadius: 5 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPreviewImage(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default ViewTicketDialog;
