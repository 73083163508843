import {
    AppstoreAddOutlined,
    AntDesignOutlined,
    BarcodeOutlined,
    BgColorsOutlined,
    FontSizeOutlined,
    DatabaseOutlined,
    FileTextOutlined,
    LoadingOutlined,
    UserOutlined,
    DollarOutlined,
    FormOutlined,
    SendOutlined,
    FileDoneOutlined,
    ClockCircleOutlined,
  } from "@ant-design/icons";
import { Typography } from "@mui/material";
  
  const icons = {
    FontSizeOutlined,
    BgColorsOutlined,
    BarcodeOutlined,
    AntDesignOutlined,
    LoadingOutlined,
    DatabaseOutlined,
    FileTextOutlined,
    AppstoreAddOutlined,
    UserOutlined,
    DollarOutlined,
    FormOutlined,
    SendOutlined,
    FileDoneOutlined,
    ClockCircleOutlined,
  };
  
  const reports = {
    id: "utilities",
    title: (
      <Typography variant="h5" component="div">
        General Reports
      </Typography>
    ),
    type: "accordion",
    children: [
    {
       id: "trade",
       title: "Trade History",
       type: "item",
       url: "/trade-view",
       icon: icons.ClockCircleOutlined,
    },
    {
      id: "warehouseInventory",
      title: "Warehouse Inventory",
      type: "item",
      url: "/warehouse-inventory-view",
      icon: icons.DatabaseOutlined,
    },
    {
      id: "inventory",
      title: "Inventory",
      type: "item",
      url: "/inventory-view",
      icon: icons.DatabaseOutlined,
    },
    ],
  };
  
  export default reports;
  