import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  currencies,
  packagingTypes,
  recordStatus,
  sampleStatus,
} from "../../../utils/menu-items";

const CreateOrder = (props) => {
  const { setCreateOrder, createOrder } = props;

  const [orderData, setOrderData] = useState({});

  return (
    <Grid container spacing="12px">
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Offer</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({ ...orderData, offerId: e.target.value })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Status Deadline</Typography>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) =>
            setOrderData({ ...orderData, statusDeadline: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select First Sample</Typography>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          fullWidth
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setOrderData({ ...orderData, firstSampleId: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select First Sample Status</Typography>
        <TextField
          id="outlined-select-status"
          select
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({ ...orderData, firstSampleStatus: e.target.value })
          }
        >
          {sampleStatus.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Second Sample</Typography>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          fullWidth
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setOrderData({ ...orderData, secondSampleId: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Second Sample Status</Typography>
        <TextField
          id="outlined-select-status"
          select
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({ ...orderData, secondSampleStatus: e.target.value })
          }
        >
          {sampleStatus.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Packaging Type</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          seelect
          onChange={(e) =>
            setOrderData({ ...orderData, packaging: e.target.value })
          }
        >
          {packagingTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2"> Net Weight Per Carton</Typography>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          fullWidth
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setOrderData({ ...orderData, netWeightPerCase: e.target.value })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2"> Number Of Cartons</Typography>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          fullWidth
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setOrderData({ ...orderData, numberOfCases: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2"> Quantity (KG)</Typography>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          fullWidth
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setOrderData({ ...orderData, quantity: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2"> Final Price</Typography>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          fullWidth
          onWheel={(e) => e.target.blur()}
          onChange={(e) =>
            setOrderData({ ...orderData, finalPrice: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Final Price Currency</Typography>
        <TextField
          id="outlined-select-status"
          select
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({ ...orderData, finalPriceCurrency: e.target.value })
          }
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Cost</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({ ...orderData, costId: e.target.value })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Contract Value Date</Typography>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(e) =>
            setOrderData({ ...orderData, contractValueDate: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Issue Date</Typography>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: new Date().toISOString().split("T")[0], }}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyDown={(e) => e.preventDefault()}
          onChange={(e) =>
            setOrderData({ ...orderData, operationDate: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select ETD</Typography>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              estimatedTimeOfDeparture: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select ETA</Typography>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          id="date"
          type="date"
          inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              estimatedTimeOfArrival: e.target.value,
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography variant="body2"> Province</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              province: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Shipping Term</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              shippingTerm: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Typography variant="body2">Customer Reference</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) =>
            setOrderData({
              ...orderData,
              customerReference: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2">Select Record Status</Typography>
        <TextField
          id="outlined-select-status"
          select
          variant="outlined"
          size="small"
          fullWidth
          label={"ACTIVE"}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              recordStatus: e.target.value,
            })
          }
        >
          {recordStatus.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Button
        variant="outlined"
        size="small"
        sx={{ mt: 1, mr: 1 }}
        onClick={() => setCreateOrder({ visible: false, data: null })}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        size="small"
        sx={{ mt: 1 }}
        onClick={() =>
          setCreateOrder({ ...createOrder, data: orderData, visible: false })
        }
      >
        Create
      </Button>
    </Grid>
  );
};

export default CreateOrder;
