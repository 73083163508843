import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";

function StatusRemarkDialog({ open, action, ticket, onCancel, onSubmit }) {
  const [remark, setRemark] = useState("");

  useEffect(() => {
    if (open && ticket) {
      const defaultRemark =
        action === "inprogress"
          ? `Your ticket #${ticket.id} - '${ticket?.title}' has got in progress. You will be hearing from us soon`
          : `Your ticket #${ticket.id} - '${ticket?.title}' has been closed.`;
      setRemark(defaultRemark);
    }
  }, [open, ticket, action]);

  const handleChange = (e) => {
    setRemark(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit({ action, ticket, remark });
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>
        {action === "inprogress" ? "Mark Ticket as In Progress" : "Close Ticket"}
      </DialogTitle>
      <DialogContent>
      <TextField
        label="Status Remark"
        fullWidth
        sx={{ minWidth: 700 }}
        value={remark}
        onChange={handleChange}
        margin="dense"
      />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default StatusRemarkDialog;
