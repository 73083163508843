import DeleteIcon from "@mui/icons-material/Delete";
import {
  MenuItem,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  Grid,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { format, isValid } from "date-fns"; // Notice we also import isValid
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ExportExcel from "../../../utils/excelExport";
import AddTicket from "./AddTicket";
import EditTicket from "./EditTicket";
import ViewTicketDialog from "./Dialogs/ViewTicketDialog";
import { retrieveAllTicketsApi, retrieveFilteredTicketsApi, updateTicketApi } from "../../../components/api/TicketApiService";
import AddWorkLog from "../WorkLogView/AddWorkLog";
import StatusRemarkDialog from "./Dialogs/StatusRemarkDialog";
import toast from "react-hot-toast";
import { recordStatus } from "../../../utils/menu-items";

// Helper for safe date formatting
function safeFormatDate(dateValue) {
  if (!dateValue) return "";
  const d = new Date(dateValue);
  return isValid(d) ? format(d, "dd/MM/yyyy") : "";
}

// Sorting/pagination helpers
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Table columns
const headCells = [
  { id: "actions", numeric: false, label: "Actions" },
  { id: "id", numeric: true, label: "ID" },
  { id: "title", numeric: false, label: "Title" },
  { id: "priority", numeric: false, label: "Priority" },
  { id: "status", numeric: false, label: "Status" },
  { id: "createdBy", numeric: false, label: "Created By" },
  { id: "assignedTo", numeric: false, label: "Assigned To" },
  { id: "createdAt", numeric: false, label: "Creation Date" },
  { id: "images", numeric: false, label: "Attachments" },
  { id: "recordStatus", numeric: false, label: "Record Status" },
  { id: "createLog", numeric: false, label: "" },
];

function TicketView() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dense, setDense] = useState(true);
  const [rows, setRows] = useState([]);
  const [addView, setAddView] = useState(false);

  // Edit dialog state
  const [editTicket, setEditTicket] = useState({
    visible: false,
    data: null,
  });

  // For the 'View' dialog
  const [viewTicket, setViewTicket] = useState({
    visible: false,
    data: null,
  });

  const [showFilters, setShowFilters] = useState(false);
  const [addViewStates, setAddViewStates] = useState(null);
  const [addWorkLog, setAddWorkLog] = useState(null);

  const [filterMyTickets, setFilterMyTickets] = useState(true);
  const [filterAssignedTickets, setFilterAssignedTickets] = useState(false);

  const username = localStorage.getItem("username");

  const [filterTickets, setFilterTickets] = useState({
    assignedMail: filterAssignedTickets ? username : undefined,
    createdBy: filterMyTickets ? username : undefined,
    recordStatusList: ["ACTIVE"],
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuTicket, setMenuTicket] = useState(null);

  const priorities = [
    { value: "Low", label: "Low" },
    { value: "Normal", label: "Normal" },
    { value: "High", label: "High" },
    { value: "Critical", label: "Critical" },
  ];
  const statuses = [
    { value: "Open", label: "Open" },
    { value: "In Progress", label: "In Progress" },
    { value: "Closed", label: "Closed" },
  ];
  useEffect(() => {
    const createdBy = username;
    const filterTickets = {
      createdBy,
      recordStatusList: ["ACTIVE"],
    };
    retrieveTickets(filterTickets);
  }, []);

  const handleMenuOpen = (event, ticket) => {
    setAnchorEl(event.currentTarget);
    setMenuTicket(ticket);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuTicket(null);
  };

  const [statusRemarkDialog, setStatusRemarkDialog] = useState({
    open: false,
    action: "",
    ticket: null,
  });

  const handleGetInProgress = (ticket) => {
    setStatusRemarkDialog({ open: true, action: "inprogress", ticket });
  };

  const handleCloseTicket = (ticket) => {
    setStatusRemarkDialog({ open: true, action: "close", ticket });
  };

  const handleStatusRemarkClose = () => {
    setStatusRemarkDialog({ open: false, action: "", ticket: null });
  };

  const handleStatusRemarkSubmit = ({ action, ticket, remark }) => {
    const newStatus = action === "inprogress" ? "In Progress" : "Closed";

    setRows((prevRows) =>
      prevRows.map((r) =>
        r.id === ticket.id
          ? { ...r, status: newStatus, statusRemark: (remark || ""), operation: "STATUSCHANGE" }
          : r
      )
    );
    console.log(`Ticket ${ticket.id} updated: ${remark}`);
    const updatedTicket = {
      ...ticket,
      status: newStatus,
      statusRemark: (remark || ""),
      operation: "STATUSCHANGE",
    };
    updateTicketApi(ticket.id, updatedTicket)
      .then((response) => {
        toast.success("Ticket edited successfully.");
      })
      .catch((error) => {
        toast.error("Something went wrong.");
        console.log(error.response.data);
      });

    handleStatusRemarkClose();
  };


  const retrieveTickets = (filterTickets) => {
    retrieveFilteredTicketsApi(filterTickets)
      .then((response) => {
        setRows(response?.data || []);
        console.log(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  };
  // Sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // SELECT ALL
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(rows.map((n) => n.id));
      return;
    }
    setSelected([]);
  };

  // VIEW
  const handleView = (row) => {
    setViewTicket({ visible: true, data: row });
  };

  // EDIT
  const handleEditClick = (row) => {
    if ((row?.createdBy !== username) && (row?.assignedTo?.email !== username)) {
      alert("You can only edit your own tickets.");
      return;
    }
    setEditTicket({ visible: true, data: row });
  };

  // DELETE
  const handleDelete = (row) => {
    if (row?.createdBy !== localStorage.getItem("username")) {
      alert("You can only delete your own tickets.");
      return;
    }
    setRows(rows.filter((r) => r.id !== row?.id));
  };

  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleCreateWorkLog = (ticket) => {
    if ((ticket?.assignedTo?.email !== username)) {
      alert("You can only log the tickets assigned to you.");
      return;
    }
    setAddViewStates({
      ticket,
    });
    setAddWorkLog(true);
  };

  // Empty rows for layout
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // Visible rows
  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <div>
      {addView ? (
        <AddTicket
          addViewStates={addViewStates}
          setAddViewStates={setAddViewStates}
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
        />
      ) : addWorkLog ? (
        <AddWorkLog
          addViewStates={addViewStates}
          setAddViewStates={setAddViewStates}
          rows={rows}
          setRows={setRows}
          setAddView={setAddWorkLog}
        />
      ) : editTicket.visible ? (
        <EditTicket
          editTicket={editTicket}
          setEditTicket={setEditTicket}
          rows={rows}
          setRows={setRows}
        />
      ) : (
        <>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography sx={{ fontWeight: 600, fontSize: "20px" }} color="#1E2685">
                Ticket Tracker
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterMyTickets}
                    onChange={(e) => {
                      const value = e.target.checked;
                      { !filterMyTickets && setFilterAssignedTickets(!value); }
                      setFilterMyTickets(value);
                      const filterTickets = {
                        createdBy: username,
                        recordStatusList: ["ACTIVE"],
                      };
                      setFilterTickets({
                        ...filterTickets,
                        createdBy: username,
                        assignedMail: null,
                      });
                      retrieveTickets(filterTickets);
                    }}
                  />
                }
                label="My Tickets"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterAssignedTickets}
                    onChange={(e) => {
                      const value = e.target.checked;
                      { !filterAssignedTickets && setFilterMyTickets(!value); }
                      setFilterAssignedTickets(value);
                      const filterTickets = {
                        assignedMail: username,
                        recordStatusList: ["ACTIVE"],
                      };
                      setFilterTickets({
                        ...filterTickets,
                        assignedMail: username,
                        createdBy: null,
                      });
                      retrieveTickets(filterTickets);
                    }}
                  />
                }
                label="Tickets Assigned To Me"
              />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <ExportExcel excelData={rows} fileName={"TicketsExport"} buttonLabel="Export Table" />
              <Button variant="outlined" size="small" onClick={() => setAddView(true)}>
                Add
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setShowFilters(!showFilters)}
                sx={{ minWidth: "130px" }}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
              </Button>
            </Stack>
          </Toolbar>

          {showFilters && (
            <>
              <Box sx={{ p: 2, backgroundColor: "#f5f5f5", borderRadius: 1 }}>
                <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
                  <Grid item xs={12} sm={2}>
                    <TextField
                      id="title"
                      type="text"
                      label="Title"
                      size="small"
                      value={filterTickets?.title || ""}
                      onChange={(e) =>
                        setFilterTickets({ ...filterTickets, title: e.target.value })
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      id="description"
                      type="text"
                      label="Description"
                      size="small"
                      value={filterTickets?.description || ""}
                      onChange={(e) =>
                        setFilterTickets({ ...filterTickets, description: e.target.value })
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Priority"
                      select
                      fullWidth
                      size="small"
                      SelectProps={{
                        multiple: true,
                        value: filterTickets.priorityList || [],
                        onChange: (e) => {
                          setFilterTickets({
                            ...filterTickets,
                            priorityList: e.target.value,
                          });
                        },
                      }}
                    >
                      {priorities.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Status"
                      select
                      fullWidth
                      size="small"
                      SelectProps={{
                        multiple: true,
                        value: filterTickets.statusList || [],
                        onChange: (e) => {
                          setFilterTickets({
                            ...filterTickets,
                            statusList: e.target.value,
                          });
                        },
                      }}
                    >
                      {statuses.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Record Status"
                      select
                      fullWidth
                      size="small"
                      SelectProps={{
                        multiple: true,
                        value: filterTickets.recordStatusList || [],
                        onChange: (e) => {
                          setFilterTickets({
                            ...filterTickets,
                            recordStatusList: e.target.value,
                          });
                        },
                      }}
                    >
                      {recordStatus.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ p: 2, backgroundColor: "#f5f5f5", borderRadius: 1 }}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={3} sm={1}>
                    <Button
                      disableElevation
                      variant="contained"
                      fullWidth
                      size="small"
                      onClick={() => {
                        retrieveFilteredTicketsApi(filterTickets)
                          .then((response) => {
                            setRows(response?.data);
                          })
                          .catch((error) => {
                            console.log(error?.response?.data);
                          });
                      }}
                    >
                      Search
                    </Button>
                  </Grid>
                  <Grid item xs={3} sm={1}>
                    <Button
                      disableElevation
                      variant="contained"
                      fullWidth
                      size="small"
                      onClick={() => {
                        setFilterTickets({
                          title: "",
                          description: "",
                          priorityList: [],
                          statusList: [],
                          assignedMail: filterAssignedTickets ? username : undefined,
                          createdBy: filterMyTickets ? username : undefined,
                          recordStatusList: ["ACTIVE"],
                        });
                      }}
                    >
                      Clear Filters
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}

          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader sx={{ minWidth: 750 }} size={dense ? "small" : "medium"}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#20315d" }}>
                    <TableCell padding="checkbox" sx={{ backgroundColor: "#20315d", color: "white" }}>
                      <Checkbox
                        color="primary"
                        indeterminate={selected.length > 0 && selected.length < rows.length}
                        checked={rows.length > 0 && selected.length === rows.length}
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align="center"
                        sx={{ backgroundColor: "#20315d", color: "white" }}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={(e) => handleRequestSort(e, headCell.id)}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc" ? "sorted descending" : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visibleRows.map((row) => (
                    <TableRow hover key={row?.id}>
                      <TableCell padding="checkbox">
                        <Checkbox color="primary" />
                      </TableCell>
                      <TableCell align="center">
                        <Button onClick={() => handleView(row)} variant="outlined" size="small">
                          View
                        </Button>
                        <Button
                          onClick={() => handleEditClick(row)}
                          variant="outlined"
                          size="small"
                          sx={{ ml: 1, mr: 1 }}
                        >
                          Edit
                        </Button>
                        <IconButton disabled={row?.assignedTo?.email !== username} onClick={(event) => handleMenuOpen(event, row)}>
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">{row?.id}</TableCell>
                      <TableCell align="center">
                        <Tooltip title={row?.title} arrow>
                          <Box
                            sx={{
                              maxWidth: 200,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {row?.title}
                          </Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">{row?.priority}</TableCell>
                      <TableCell align="center">{row?.status}</TableCell>
                      <TableCell align="center">
                        {row?.createdBy && row.createdBy.split('@')[0]}
                      </TableCell>
                      <TableCell align="center">{(row?.assignedTo?.email && row.assignedTo.email.split('@')[0]) || "Unassigned"}</TableCell>

                      {/* FIX for date formatting using safeFormatDate */}
                      <TableCell align="center">{safeFormatDate(row?.timestamp)}</TableCell>

                      <TableCell align="center">{row?.imageBase64List?.length || "No Images"}</TableCell>
                      <TableCell align="center">{row?.recordStatus || "ACTIVE"}</TableCell>
                      <TableCell align="center">
                        <Button variant="outlined" size="small" onClick={() => handleCreateWorkLog(row)}>
                          Create Log
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
        </>
      )}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            handleGetInProgress(menuTicket);
            handleMenuClose();
          }}
        >
          In Progress
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseTicket(menuTicket);
            handleMenuClose();
          }}
        >
          Close
        </MenuItem>
      </Menu>

      <StatusRemarkDialog
        open={statusRemarkDialog.open}
        action={statusRemarkDialog.action}
        ticket={statusRemarkDialog.ticket}
        onCancel={handleStatusRemarkClose}
        onSubmit={handleStatusRemarkSubmit}
      />
      {viewTicket.visible && (
        <ViewTicketDialog viewTicket={viewTicket} setViewTicket={setViewTicket} />
      )}
    </div>
  );
}

export default TicketView;
