import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { retrieveAllCostsApi } from "../../../components/api/CostApiService";
import { createShipmentRequestApi, retrieveFilteredShipmentRequestsApi } from "../../../components/api/ShipmentRequestApiService";
import { retrieveAllSamplesApi } from "../../../components/api/SampleApiService";
import toast from "react-hot-toast";
import {
  currencies,
  incoterms,
  offerStatus,
  packagingTypes,
  paymentTerm,
  portsOfDischarge,
  portsOfLoading,
  recordStatus,
  sampleStatus,
  status,
  units,
} from "../../../utils/menu-items";
import { retrieveFilteredOrdersApi } from "../../../components/api/OrderApiService";
import { retrieveQuantityByItemIdApi } from "../../../components/api/QuantityApiService";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import { retrieveFilteredShippingInstructionsApi } from "../../../components/api/ShippingInstructionApiService";
import { retrieveFilteredWarehouseRecordsApi } from "../../../components/api/WarehouseApiService";

const CreateShipment = (props) => {
  const { setCreateShipmentRequest, createShipmentRequest } = props;

  const determineDestination = (incoterm, portOfLoading, portOfDischarge) => {
    const incotermsUsingPortOfLoading = ["EXW", "FCA", "FAS", "FOB"];
    const incotermsUsingPortOfDischarge = ["CFR", "C&F", "CIF", "CPT", "CIP", "DAP", "DPU", "DDP"];

    const safeIncoterm = incoterm || "";

    if (incotermsUsingPortOfLoading.includes(safeIncoterm)) {
      return `${safeIncoterm} ${portOfLoading || ""}`;
    } else if (incotermsUsingPortOfDischarge.includes(safeIncoterm)) {
      return `${safeIncoterm} ${portOfDischarge || ""}`;
    }
    return "";
  };

  const [shipmentRequestData, setShipmentRequestData] = useState({
    id: 0,
    shipmentQuantityUnit: "KG",
    shipmentRequestType: "TC",
    offerId: createShipmentRequest?.data?.id,
    salesOrderId: createShipmentRequest?.data?.id,
    portOfLoading: createShipmentRequest?.data?.portOfLoading || "",
    portOfDischarge: createShipmentRequest?.data?.portOfDischarge || "",
    destination: determineDestination(
      createShipmentRequest?.data?.incoterm || "",
      createShipmentRequest?.data?.portOfLoading || "",
      createShipmentRequest?.data?.portOfDischarge || ""
    ),
    netWeightPerCase: createShipmentRequest?.data?.netWeightPerCase || "",
    numberOfCases: createShipmentRequest?.data?.numberOfCases || "",
    customerGradeMark: createShipmentRequest?.data?.offer?.salesGrade || "",
    shipmentQuantity: createShipmentRequest?.data?.volume || "",
    shipmentQuantityUnit: createShipmentRequest?.data?.volumeUnit || "KG",
    approvalStatus: "Not Approved",
  });
  const [stakeholders, setStakeholders] = useState([]);
  const [selectedStakeholder, setSelectedStakeholder] = useState(null);

  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [shipmentRequests, setShipmentRequests] = useState(null);
  const [includeSO, setIncludeSO] = useState(false);
  const [includePO, setIncludePO] = useState(false);
  const [filterShipmentRequest, setFilterShipmentRequest] = useState(null);

  const handleSOChange = (event) => {
    if (event.target.checked) {
      setFilterShipmentRequest({
        ...filterShipmentRequest,
        salesOrderIdList: [createShipmentRequest?.data?.id],
        recordStatus: "ACTIVE",
      });
    } else {
      setFilterShipmentRequest({
        ...filterShipmentRequest,
        salesOrderIdList: [],
        recordStatus: "ACTIVE",
      });
    }
    setIncludeSO(event.target.checked);
  };

  const handlePOChange = (event) => {
    if (event.target.checked) {
      setFilterShipmentRequest({
        ...filterShipmentRequest,
        purchaseOrderIdList: [selectedPo?.id],
        recordStatusList: ["ACTIVE"],
      });
    } else {
      setFilterShipmentRequest({
        ...filterShipmentRequest,
        purchaseOrderIdList: [],
        recordStatusList: ["ACTIVE"],
      });
    }
    setIncludePO(event.target.checked);
  };

  function retrieveStakeholders() {
    Promise.all([
      retrieveAllStakeholdersApi("VENDOR"),
      retrieveAllStakeholdersApi("CUSTOMER")
    ])
      .then(([vendorResponse, customerResponse]) => {
        setStakeholders([
          ...vendorResponse?.data,
          ...customerResponse?.data
        ]);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveQuantity(itemId) {
    retrieveQuantityByItemIdApi(itemId)
      .then((response) => {
        console.log("QUANTITY: ", response?.data)
        setQuantity(response?.data)
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveFilteredOrders(orderDto) {
    retrieveFilteredOrdersApi(orderDto)
      .then((response) => {
        setOrders(response?.data)
        return response?.data || [];
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveFilteredShippingInstructions(filterShippingInstruction) {
    retrieveFilteredShippingInstructionsApi(filterShippingInstruction)
      .then((response) => {
        setShippingInstructions(response?.data)
        return response?.data || [];
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveFilteredShipmentRequests(filterShipmentRequest) {
    retrieveFilteredShipmentRequestsApi(filterShipmentRequest)
      .then((response) => {
        setShipmentRequests(response?.data);
        return response?.data || [];
      })
      .catch((error) => console.log(error.response?.data));
  }

  const handleHistoryClick = () => {
    retrieveFilteredShipmentRequests(filterShipmentRequest);
    setIsHistoryVisible((prevVisibility) => !prevVisibility);
  };

  const orderDto = ({
    orderType: "VENDOR",
    offerId: createShipmentRequest?.data?.offer?.id,
    offerIdList: [] || [],
    itemIdList: [createShipmentRequest?.data?.offer?.item?.id],
    stakeholderIdList: [],
    costIdList: [],
    companyList: [],
    approvalStatusList: ["Approved"],
    orderStatusList: [],
    customerList: [],
    firstSampleIdList: [],
    secondSampleIdList: [],
    firstSampleStatusList: [],
    secondSampleStatusList: [],
    paymentTermList: [],
    incotermList: [],
    packagingList: [],
    volumeUnitList: [],
    portOfLoadingList: [],
    portOfDischargeList: [],
    firstPriceCurrencyList: [],
    finalPriceCurrencyList: [],
    documentStatusList: [],
    originList: [],
    typeList: [],
    leafFormList: [],
    colorList: [],
    storageList: [],
    recordStatusList: ["ACTIVE"],
  })

  const [orders, setOrders] = useState([]);
  const [shippingInstructions, setShippingInstructions] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [selectedPortOfLoading, setSelectedPortOfLoading] = useState(null);
  const [selectedPortOfDischarge, setSelectedPortOfDischarge] = useState(null);
  const [splitShipment, setSplitShipment] = useState(false);
  const [splitParts, setSplitParts] = useState(1);
  const [splitShipmentData, setSplitShipmentData] = useState([]);

  useEffect(() => {
    if (splitShipment) {
      const initialData = Array.from({ length: splitParts }, (_, i) => ({
        numberOfCartons: i === 0 ? shipmentRequestData?.numberOfCases || "" : "",
        netWeightPerCarton: i === 0 ? shipmentRequestData?.netWeightPerCase || "" : "",
        shipmentQuantity: i === 0 ? shipmentRequestData?.shipmentQuantity || "" : "",
        shipmentETD: i === 0 ? shipmentRequestData?.shipmentEstimatedTimeOfDeparture || "" : "",
        shipmentQuantityUnit: "KG",
        cartonsPerContainer: i === 0 ? shipmentRequestData?.cartonsPerContainer || "" : "",
        }));
      setSplitShipmentData(initialData);
    } else {
      setSplitShipmentData([]);
    }
  }, [splitShipment, splitParts]);

  const updateSplitData = (index, field, value) => {
    const newData = [...splitShipmentData];
    newData[index][field] = value;
    if (field === "numberOfCartons" || field === "netWeightPerCarton") {
      const cartons = parseFloat(newData[index].numberOfCartons);
      const netWeight = parseFloat(newData[index].netWeightPerCarton);
      if (!isNaN(cartons) && !isNaN(netWeight)) {
        newData[index].shipmentQuantity = cartons * netWeight;
      }
    }
    setSplitShipmentData(newData);
  };

  useEffect(() => {
    retrieveQuantity(createShipmentRequest?.data?.offer?.item?.id);
  }, []);
  const [selectedPo, setSelectedPo] = useState(null);
  const [selectedSi, setSelectedSi] = useState(null);
  const [fvSelected, setFvSelected] = useState(false);
  const [fwSelected, setFwSelected] = useState(false);

  const [filterShippingInstruction, setFilterShippingInstruction] = useState({
    id: 0,
    shipmentRequestIdList: [],
    freightOfferIdList: [],
    purchaseOrderIdList: [],
    salesOrderIdList: [],
    transportationContact: "",
    cartonNumber: "",
    finalShippingInstructionNumber: "",
    shippedByList: [],
    billOfLadingNumber: "",
    cmrNumber: "",
    shipperIdList: [],
    consigneeIdList: [],
    notifyIdList: [],
    pointOfLoadingList: [],
    pointOfUnloadingList: [],
    description: "",
    remark: "",
    statusList: ["Open", "Closed"],
    recordStatusList: ["ACTIVE"],
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === 'fv') {
      setFvSelected(checked);
      setFwSelected(false);
      setSelectedStakeholder(selectedPo?.offer?.stakeholder || null)
      setSelectedSi(null);
      setShipmentRequestData({
        ...shipmentRequestData,
        shipmentRequestType: "FVTC",
        shipmentRequestTo: selectedPo?.offer?.stakeholder?.name || "",
        warehouse: "",
      });
    } else if (name === 'fw') {
      setFvSelected(false);
      setFwSelected(checked);
      setSelectedStakeholder(null)
      setShipmentRequestData({
        ...shipmentRequestData,
        shipmentRequestType: "FWTC",
        shipmentRequestTo: shipmentRequestData?.shipmentRequestTo || "",
        warehouse: "",
      });
    }

  };
  const navigate = useNavigate();

  return (
    <>
      <Grid container>

        <Grid item xs={12} style={{ position: "relative" }}>
          <Typography
            variant="h3"
            align="center"
            style={{
              color: "#777f87",
              position: "absolute",
              top: "3px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "#fafafb",
              padding: "0 10px",
            }}
          >
            SO Details {createShipmentRequest?.data?.id}
          </Typography>
        </Grid>
        <Grid
          container
          spacing="12px"
          style={{
            border: "1px solid #dbd9d9",
            borderRadius: "20px",
            marginTop: "20px",
            marginBottom: "10px",
            padding: "20px",
            boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">SO Quantity</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createShipmentRequest?.data?.numberOfCases || "") + " cases"
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography style={{ color: '#fafafb' }} variant="body2">SO Quantity</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createShipmentRequest?.data?.netWeightPerCase || "") + " " + (createShipmentRequest?.data?.volumeUnit || "") + " per case"
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography style={{ color: '#fafafb' }} variant="body2">SO Quantity</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createShipmentRequest?.data?.volume || "") + " " + (createShipmentRequest?.data?.volumeUnit || "")
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Item ID</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createShipmentRequest?.data?.offer?.item?.id || "")
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Origin</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createShipmentRequest?.data?.offer?.item?.origin || "")
              }
              fullWidth
            />
          </Grid>

          {(createShipmentRequest?.data?.offer?.item?.origin === "China" && <Grid item xs={12} sm={1}>
            <Typography variant="body2">Province</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createShipmentRequest?.data?.offer?.item?.province || "")
              }
              fullWidth
            />
          </Grid>)}

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Type</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createShipmentRequest?.data?.offer?.item?.type || "")
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Buying Grade</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createShipmentRequest?.data?.offer?.item?.buyingGrade || "")
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Sales Grade</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createShipmentRequest?.data?.offer?.item?.salesGrade || "")
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Crop</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createShipmentRequest?.data?.offer?.item?.crop || "")
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Redrawn Status</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createShipmentRequest?.data?.secondSampleStatus || "")
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">SAP SO Number</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createShipmentRequest?.data?.salesOrderNumber || "")
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Customer</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createShipmentRequest?.data?.offer?.stakeholder?.name || "")
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Item Current Location</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createShipmentRequest?.data?.offer?.item?.storage || "")
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">ETD</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              defaultValue={
                createShipmentRequest?.data?.estimatedTimeOfDeparture
                  ? format(parseISO(createShipmentRequest.data.estimatedTimeOfDeparture), "yyyy-MM-dd")
                  : ""
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Port Of Loading</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                createShipmentRequest?.data?.portOfLoading || ""
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Port Of Discharge</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                createShipmentRequest?.data?.portOfDischarge || ""
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <Typography variant="body2">Description Of Goods</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (createShipmentRequest?.data?.descriptionOfGoods || "")
              }
              fullWidth
            />
          </Grid>

        </Grid>

        <Grid item xs={12} style={{ position: "relative" }}>
          <Typography
            variant="h3"
            align="center"
            style={{
              color: "#777f87",
              position: "absolute",
              top: "8px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "#fafafb",
              padding: "0 10px",
            }}
          >
            PO Details
          </Typography>
        </Grid>
        <Grid
          container
          spacing="12px"
          style={{
            border: "1px solid #dbd9d9",
            borderRadius: "20px",
            marginTop: "25px",
            padding: "20px",
            boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Select PO</Typography>
            <Autocomplete
              options={orders || []}
              getOptionLabel={(option) =>
                `ID: ${option?.id || ""}, SAP PO Number: ${option?.purchaseOrderNumber || ""}, Vendor: ${option?.stakeholder?.name || ""},
                  Item ID: ${option?.offer?.item?.id || ""}
                  , Grade: ${option?.offer?.item?.buyingGrade || ""},
                   Origin: ${option?.offer?.item?.origin || ""},
                   Crop: ${option?.offer?.item?.crop || ""}`
              }
              value={selectedPo}
              onChange={(event, newValue) => {
                setSelectedPo(newValue);
                setSelectedStakeholder(newValue?.offer?.stakeholder || null);
                setSelectedSi(null);
                setFilterShippingInstruction({
                  ...filterShippingInstruction,
                  purchaseOrderIdList: [newValue ? newValue.id : ""],
                })
                setShipmentRequestData({
                  ...shipmentRequestData,
                  purchaseOrderId: newValue ? newValue.id : "",
                  shipmentRequestTo: newValue ? newValue?.offer?.stakeholder?.name : null,
                  referenceShippingInstructionNumber: null,
                  referenceShippingInstructionId: null,
                });
              }}
              onOpen={() => retrieveFilteredOrders(orderDto)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedPo && (
              <Box mt={2}>
                <Chip
                  label={`ID: ${selectedPo?.id || ""}`}
                  onDelete={() => {
                    setSelectedPo(null);
                    setShipmentRequestData({ ...shipmentRequestData, purchaseOrderId: "" });
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">PO Quantity</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (selectedPo?.numberOfCases || "") + " cases"
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography style={{ color: '#fafafb' }} variant="body2">PO Quantity</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (selectedPo?.netWeightPerCase || "") + " " + (selectedPo?.volumeUnit || "") + " per case"
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography style={{ color: '#fafafb' }} variant="body2">PO Quantity</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (selectedPo?.volume || "") + " " + (selectedPo?.volumeUnit || "")
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Supplier</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (selectedPo?.offer?.stakeholder?.name || "")
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Item ID</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (selectedPo?.offer?.item?.id || "")
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Origin</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (selectedPo?.offer?.item?.origin || "")
              }
              fullWidth
            />
          </Grid>
          {(selectedPo?.offer?.item?.origin === "China" && <Grid item xs={12} sm={1}>
            <Typography variant="body2">Province</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (selectedPo?.offer?.item?.province || "")
              }
              fullWidth
            />
          </Grid>
          )}

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Type</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (selectedPo?.offer?.item?.type || "")
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Buying Grade</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (selectedPo?.offer?.item?.buyingGrade || "")
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Sales Grade</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (selectedPo?.offer?.item?.salesGrade || "")
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Crop</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              value={
                (selectedPo?.offer?.item?.crop || "")
              }
              fullWidth
            />
          </Grid>

        </Grid>

        {((shipmentRequestData?.shipmentRequestType === "FWTC") && (selectedPo) && (
          <>
            <Grid item xs={12} style={{ position: "relative" }}>
              <Typography
                variant="h3"
                align="center"
                style={{
                  color: "#777f87",
                  position: "absolute",
                  top: "8px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fafafb",
                  padding: "0 10px",
                }}
              >
                SI Details
              </Typography>
            </Grid>
            <Grid
              container
              spacing="12px"
              style={{
                border: "1px solid #dbd9d9",
                borderRadius: "20px",
                marginTop: "25px",
                padding: "20px",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Select SI</Typography>
                <Autocomplete
                  options={shippingInstructions || []}
                  getOptionLabel={(option) =>
                    `ID: ${option?.id || ""}, SI Number: ${option?.finalShippingInstructionNumber || ""}, SR : ${option?.shipmentRequest?.shipmentRequestType || ""}, SR Number: ${option?.shipmentRequest?.id || ""}, Date: ${option?.stakeholder?.name || ""}, POD: ${option?.pointOfUnloading || ""}`
                  }
                  value={selectedSi}
                  onChange={(event, newValue) => {
                    setSelectedSi(newValue);
                    setShipmentRequestData({
                      ...shipmentRequestData,
                      referenceShippingInstructionId: newValue ? newValue.id : "",
                      referenceShippingInstructionNumber: newValue ? newValue.finalShippingInstructionNumber : "",
                      warehouse: newValue ? newValue.destination : "",
                      shipmentRequestTo: newValue ? newValue.destination : "",
                    });
                    if (newValue) {
                      const filterWarehouse = {
                        recordStatusList: ["ACTIVE"],
                        warehouseList: [newValue?.destination]
                      }
                      retrieveFilteredWarehouseRecordsApi(filterWarehouse)
                        .then((warehouseInfo) => {
                          console.log("warehouseInfo", warehouseInfo?.data[0]);
                          setShipmentRequestData((prevData) => ({
                            ...prevData,
                            shipmentRequestTo: warehouseInfo?.data[0]?.contactPerson?.name,
                          }));
                          setSelectedStakeholder(warehouseInfo?.data[0]?.contactPerson);
                        })
                        .catch((error) => {
                          console.error("Error fetching warehouse info:", error);
                        });
                    }
                  }}
                  onOpen={() => retrieveFilteredShippingInstructions(filterShippingInstruction)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
                {selectedSi && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${selectedSi?.id || ""}`}
                      onDelete={() => {
                        setSelectedSi(null);
                        setShipmentRequestData({ ...shipmentRequestData, shippingInstructionNumber: "", warehouse: "" });
                      }}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </>
        ))}
        <Grid item xs={12} style={{ position: "relative" }}>
          <Typography
            variant="h3"
            align="center"
            style={{
              color: "#777f87",
              position: "absolute",
              top: "23px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "#fafafb",
              padding: "0 10px",
            }}
          >
            Shipment Request Details
          </Typography>
        </Grid>
        <Grid
          container
          spacing="12px"
          style={{
            border: "1px solid #dbd9d9",
            borderRadius: "20px",
            marginTop: "40px",
            padding: "20px",
            boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Grid item xs={12} sm={1.2}>
            <Typography variant="body1" mb={2}>
              From:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <FormControlLabel
              control={<Checkbox checked={fvSelected} onChange={handleCheckboxChange} name="fv" />}
              label="Vendor"
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <FormControlLabel
              control={<Checkbox checked={fwSelected} onChange={handleCheckboxChange} name="fw" />}
              label="Warehouse"
            />
          </Grid>
          <Grid item xs={12} sm={7.8}></Grid>

          <Grid item xs={12} sm={1.2}>
            <Typography variant="body1" mb={2}>
              To:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <FormControlLabel
              control={<Checkbox checked={true} name="tc" />}
              label="Customer"
            />
          </Grid>
          <Grid item xs={12} sm={9.3}></Grid>


          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SI To</Typography>
            <Autocomplete
              options={stakeholders}
              getOptionLabel={(option) =>
                `${option.id || ""} - ${option.type || ""} - ${option.name || ""}`
              }
              value={selectedStakeholder}
              onChange={(event, newValue) => {
                setSelectedStakeholder(newValue);
                setShipmentRequestData({
                  ...shipmentRequestData,
                  shipmentRequestTo: newValue ? newValue?.name : null,
                });
              }}
              onOpen={retrieveStakeholders}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={shipmentRequestData?.shipmentRequestType === "FVTC"}
                  value={selectedStakeholder?.name || ""}
                />
              )}
              renderTags={() => null}
            />
            {selectedStakeholder && (
              <Box mt={2}>
                <Chip
                  label={`ID: ${selectedStakeholder?.id || ""}`}
                  style={{ marginBottom: '5px' }}
                  onDelete={() => setSelectedStakeholder(null)}
                /><br />
                <Chip
                  label={`Type: ${selectedStakeholder?.type || ""}`}
                  style={{ marginBottom: '5px' }}
                /><br />
                <Chip
                  label={`Name: ${selectedStakeholder?.name || ""}`}
                  style={{ marginBottom: '5px' }}
                /><br />
              </Box>
            )}
          </Grid>

          {/*<Grid item xs={12} sm={2}>
            <Typography variant="body2">Total Quantity</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={quantity?.quantityRemainingInNetKg + "KG"}
            />
          </Grid>*/}

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Unshipped Quantity</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={
                createShipmentRequest?.data?.remainingQuantity
                  ? `${new Intl.NumberFormat("en-US", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                  }).format(createShipmentRequest?.data?.remainingQuantity)} ${createShipmentRequest?.data?.volumeUnit || ""}`
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Customer Grade Mark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentRequestData?.customerGradeMark || ""}
              onChange={(e) =>
                setShipmentRequestData({
                  ...shipmentRequestData,
                  customerGradeMark: e.target.value,
                })
              }
            />
          </Grid>
          {(fwSelected) && (selectedSi) && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Warehouse</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                id="outlined-select-pol"
                value={selectedSi?.destination}
              >
              </TextField>
            </Grid>
          )}
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Destination</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentRequestData?.destination}
              onChange={(e) =>
                setShipmentRequestData({ ...shipmentRequestData, destination: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={12} sm={1.5}>
            <Typography variant="body2">Port Of Loading</Typography>
            <Autocomplete
              options={portsOfLoading}
              getOptionLabel={(option) => (option ? option.label : "")}
              value={selectedPortOfLoading}
              onChange={(event, newValue) => {
                setSelectedPortOfLoading(newValue);
                const newDestination = determineDestination(
                  createShipmentRequest?.data?.incoterm || "",
                  newValue ? newValue.value : shipmentRequestData.portOfLoading,
                  shipmentRequestData.portOfDischarge
                );
                setShipmentRequestData({
                  ...shipmentRequestData,
                  portOfLoading: newValue ? newValue.value : "",
                  destination: newDestination,
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label={shipmentRequestData?.portOfLoading}
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedPortOfLoading && (
              <Box mt={2}>
                <Chip
                  label={selectedPortOfLoading.label}
                  onDelete={() => {
                    setSelectedPortOfLoading(null);
                    const newDestination = determineDestination(
                      createShipmentRequest?.data?.incoterm || "",
                      "",
                      shipmentRequestData.portOfDischarge
                    );
                    setShipmentRequestData({
                      ...shipmentRequestData,
                      portOfLoading: "",
                      destination: newDestination,
                    });
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={1.5}>
            <Typography variant="body2">Port Of Discharge</Typography>
            <Autocomplete
              options={portsOfDischarge}
              getOptionLabel={(option) => (option ? option.label : "")}
              value={selectedPortOfDischarge}
              onChange={(event, newValue) => {
                setSelectedPortOfDischarge(newValue);
                const newDestination = determineDestination(
                  createShipmentRequest?.data?.incoterm || "",
                  shipmentRequestData.portOfLoading,
                  newValue ? newValue.value : shipmentRequestData.portOfDischarge
                );
                setShipmentRequestData({
                  ...shipmentRequestData,
                  portOfDischarge: newValue ? newValue.value : "",
                  destination: newDestination,
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label={shipmentRequestData?.portOfDischarge}
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedPortOfDischarge && (
              <Box mt={2}>
                <Chip
                  label={selectedPortOfDischarge.label}
                  onDelete={() => {
                    setSelectedPortOfDischarge(null);
                    const newDestination = determineDestination(
                      createShipmentRequest?.data?.incoterm || "",
                      "",
                      shipmentRequestData.portOfDischarge
                    );
                    setShipmentRequestData({
                      ...shipmentRequestData,
                      portOfDischarge: "",
                      destination: newDestination,
                    });
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid item xs={12}>
               <Typography variant="body2" sx={{ mb: 1 }}>
      Number of Shipments:
    </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
    <Button
      variant={splitShipment ? "contained" : "outlined"}
      onClick={() => setSplitShipment(!splitShipment)}
      sx={{ mr: 2 }}
    >
      {splitShipment ? "Disable Split" : "Enable Split"}
    </Button>
    {splitShipment && (
      <>
        <TextField
          type="number"
          size="small"
          value={splitParts}
          helperText={splitParts>=10 ? "Max 10 Shipments" : ""}
          InputProps={{ inputProps: { min: 0, max: 10 } }}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => {
            let value = Number(e.target.value);
            if (value > 10) value = 10;
            setSplitParts(value);
          }}
          inputProps={{ min: 1 }}
          sx={{ width: 150 }}
        />
      </>
    )}
  </Box>
              {splitShipment ? (
                <>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Split Shipment Details
                  </Typography>
                  {splitShipmentData.map((split, index) => (
                    <Card
                      key={index}
                      variant="outlined"
                      sx={{ p: 2, mb: 2, borderRadius: 2 }}
                    >
                      <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        Part {index + 1}
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            label="No. of Cartons"
                            type="number"
                            size="small"
                            fullWidth
                            value={split.numberOfCartons}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) =>
                              updateSplitData(index, "numberOfCartons", e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            label="Net Weight Per Carton"
                            type="number"
                            size="small"
                            fullWidth
                            value={split.netWeightPerCarton}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) =>
                              updateSplitData(index, "netWeightPerCarton", e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            label="Shipment Quantity"
                            type="number"
                            size="small"
                            fullWidth
                            value={split.shipmentQuantity}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) =>
                              updateSplitData(index, "shipmentQuantity", e.target.value)
                            }
                          />
                        </Grid>
                         <Grid item xs={12} sm={1}>
                                                 <TextField
                                                   variant="outlined"
                                                   size="small"
                                                   fullWidth
                                                   id="outlined-select-shipment-quantity-unit"
                                                   defaultValue={"KG"}
                                                   select
                                                   onChange={(e) =>
                                                    updateSplitData(index, "shipmentQuantityUnit", e.target.value)
                                                  }
                                                 >
                                                   {units.map((option) => (
                                                     <MenuItem key={option.value} value={option.value}>
                                                       {option.label}
                                                     </MenuItem>
                                                   ))}
                                                 </TextField>
                                               </Grid>
                                               <Grid item xs={12} sm={2}>
                                                  <TextField
                                                    label="Cartons Per Container"
                                                    type="number"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={split.cartonsPerContainer}
                                                    onWheel={(e) => e.target.blur()}
                                                    onChange={(e) =>
                                                      updateSplitData(index, "cartonsPerContainer", e.target.value)
                                                    }
                                                  />
                                                </Grid>
                        <Grid item xs={12} sm={2}>
                          <TextField
                            label="Shipment ETD"
                            type="date"
                            size="small"
                            fullWidth
                            value={split.shipmentETD}
                            onChange={(e) =>
                              updateSplitData(index, "shipmentETD", e.target.value)
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </>
              ) : (
                <Grid container spacing={2}>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Number Of Cartons</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentRequestData?.numberOfCases}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                const numberOfCases = e.target.value; // Capture the new net weight per case from the event
                let calculatedShipmentQuantity = numberOfCases * shipmentRequestData?.netWeightPerCase;

                // Ensure shipmentQuantity does not exceed createShipmentRequest.data.volume
                if (createShipmentRequest?.data?.volume && calculatedShipmentQuantity > createShipmentRequest.data.volume) {
                  toast.error('Shipment quantity cannot be greater than SO Quantity!');
                  calculatedShipmentQuantity = createShipmentRequest.data.volume;
                }

                setShipmentRequestData({
                  ...shipmentRequestData,
                  numberOfCases: numberOfCases,
                  shipmentQuantity: calculatedShipmentQuantity,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Net Weight Per Carton</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentRequestData?.netWeightPerCase}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                const netWeightPerCase = e.target.value; // Capture the new net weight per case from the event
                let calculatedShipmentQuantity = netWeightPerCase * shipmentRequestData?.numberOfCases;

                // Ensure shipmentQuantity does not exceed createShipmentRequest.data.volume
                if (createShipmentRequest?.data?.volume && calculatedShipmentQuantity > createShipmentRequest.data.volume) {
                  toast.error('Shipment quantity cannot be greater than SO Quantity!');
                  calculatedShipmentQuantity = createShipmentRequest.data.volume;
                }

                setShipmentRequestData({
                  ...shipmentRequestData,
                  netWeightPerCase: netWeightPerCase,
                  shipmentQuantity: calculatedShipmentQuantity,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Shipment Quantity</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentRequestData?.shipmentQuantity}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                if (e.target.value > createShipmentRequest?.data?.volume) {
                  toast.error('Shipment quantity cannot be greater than SO Quantity!');
                } else {
                  setShipmentRequestData({ ...shipmentRequestData, shipmentQuantity: e.target.value })
                }
              }
              }
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Unit</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              defaultValue={"KG"}
              id="outlined-select-shipment-quantity-unit"
              select
              onChange={(e) =>
                setShipmentRequestData({ ...shipmentRequestData, shipmentQuantityUnit: e.target.value })
              }
            >
              {units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Cartons/Cont</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentRequestData?.cartonsPerContainer}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setShipmentRequestData({ ...shipmentRequestData, cartonsPerContainer: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Shipment ETD</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              onKeyDown={(e) => e.preventDefault()}
              defaultValue={
                createShipmentRequest?.data?.estimatedTimeOfDeparture
                  ? format(parseISO(createShipmentRequest.data.estimatedTimeOfDeparture), "yyyy-MM-dd")
                  : ""
              }
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) =>
                setShipmentRequestData({
                  ...shipmentRequestData,
                  shipmentEstimatedTimeOfDeparture: e.target.value,
                })}
            />
          </Grid>
                   </Grid>
                       )}
                     </Grid>

          {/* <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              onChange={(e) =>
                setShipmentRequestData({ ...shipmentRequestData, status: e.target.value })
              }
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Payment Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-payment-status"
              select
              onChange={(e) =>
                setShipmentRequestData({ ...shipmentRequestData, paymentStatus: e.target.value })
              }
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          */}
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Loading Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentRequestData?.loadingRemark}
              onChange={(e) =>
                setShipmentRequestData({ ...shipmentRequestData, loadingRemark: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={shipmentRequestData?.remark}
              onChange={(e) =>
                setShipmentRequestData({ ...shipmentRequestData, remark: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6.5}></Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2" onClick={handleHistoryClick}
              style={{ cursor: "pointer", fontSize: "14px", color: "blue", textDecoration: "underline" }}>History</Typography>
            <FormControlLabel
              control={<Checkbox checked={includeSO} onChange={handleSOChange} />}
              label="Include SO"
            />
            <FormControlLabel
              control={<Checkbox disabled={!selectedPo} checked={includePO} onChange={handlePOChange} />}
              label="Include PO"
            />
            {!selectedPo && <Typography variant="body2" color="error">Please select a PO first to see the history.</Typography>}
          </Grid>
          {isHistoryVisible && (
            <Grid item xs={12} sm={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Shipment Request ID</TableCell>
                    <TableCell align="center">Type</TableCell>
                    <TableCell align="center">PO</TableCell>
                    <TableCell align="center">SO</TableCell>
                    <TableCell align="center">Creation Time</TableCell>
                    <TableCell align="center">ETD</TableCell>
                    <TableCell align="center">Number Of Cartons</TableCell>
                    <TableCell align="center">Shipment Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shipmentRequests?.map((request, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{request?.id}</TableCell>
                      <TableCell align="center">{request?.shipmentRequestType}</TableCell>
                      <TableCell align="center">{request?.purchaseOrder?.id}-{request?.purchaseOrder?.purchaseOrderNumber}</TableCell>
                      <TableCell align="center">{request?.salesOrder?.id}-{request?.salesOrder?.salesOrderNumber}</TableCell>
                      <TableCell align="center">{request?.timestamp ? format(parseISO(request?.timestamp), 'dd-MMM-yyyy') : ''}</TableCell>
                      <TableCell align="center">{request?.shipmentEstimatedTimeOfDeparture ? format(parseISO(request?.shipmentEstimatedTimeOfDeparture), 'dd-MMM-yyyy') : ''}</TableCell>
                      <TableCell align="center">
                        {request?.numberOfCases
                          ? `${request?.numberOfCases}`
                          : ''}
                      </TableCell>
                      <TableCell align="center">
                        {request?.shipmentQuantity
                          ? `${request?.shipmentQuantity} ${request?.shipmentQuantityUnit || ''}`
                          : ''}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center"><strong>Total Shipment Quantity:</strong></TableCell>
                    <TableCell align="center">
                      <strong>
                        {shipmentRequests?.reduce((total, request) => total + (request?.numberOfCases || 0), 0)}
                      </strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>
                        {shipmentRequests?.reduce((total, request) => total + (request?.shipmentQuantity || 0), 0)}
                      </strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center"><strong>Remaining SO Quantity:</strong></TableCell>
                    <TableCell align="center">
                      <strong>
                        {createShipmentRequest?.data?.numberOfCases
                          ? createShipmentRequest.data.numberOfCases -
                          shipmentRequests?.reduce((total, request) => total + (request?.numberOfCases || 0), 0)
                          : 0}
                      </strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>
                        {createShipmentRequest?.data?.volume
                          ? createShipmentRequest.data.volume -
                          shipmentRequests?.reduce((total, request) => total + (request?.shipmentQuantity || 0), 0)
                          : 0}
                      </strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          )}
        </Grid>
        <Button
          variant="outlined"
          size="small"
          sx={{ ml: 1.5, mt: 4, mr: 1 }}
          onClick={() => {
            setShipmentRequestData({ data: null })
            setCreateShipmentRequest({ visible: false })
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={(!fvSelected && !fwSelected) || (fvSelected && !selectedPo) || (fwSelected && !selectedSi)}
          style={(!fvSelected && !fwSelected) ? { whiteSpace: "nowrap" } : {}}
          size="small"
          sx={{ mt: 4 }}
          onClick={async () => {
            if ((fvSelected && quantity?.quantityRemainingInVendorInNetKg <= 0) || (fwSelected && quantity?.quantityRemainingInWarehouseInNetKg <= 0)) {
              (alert("Selected item's:\nRemaining quantity in vendor:" + (quantity?.quantityRemainingInVendorInNetKg || "") + "\nRemaining quantity in warehouse:" + (quantity?.quantityRemainingInWarehouseInNetKg || "") + "\nRemaining quantity in production:" + (quantity?.quantityRemainingInProductionInNetKg || "")));
            }

            try {
              if (splitShipment && splitParts > 1) {
                 const newShipmentRequests = [];
                 for (let i = 0; i < splitShipmentData.length; i++) {
                   const splitData = splitShipmentData[i];
                   const newShipment = {
                     ...shipmentRequestData,
                     numberOfCases: splitData.numberOfCartons,
                     netWeightPerCase: splitData.netWeightPerCarton,
                     shipmentQuantity: splitData.shipmentQuantity,
                     shipmentQuantityUnit: splitData.shipmentQuantityUnit,
                     cartonsPerContainer: splitData.cartonsPerContainer,
                     shipmentEstimatedTimeOfDeparture: splitData.shipmentETD,
                   };
                   newShipmentRequests.push(newShipment);
                   await createShipmentRequestApi(newShipment);
                 }
               } else {
                 await createShipmentRequestApi(shipmentRequestData);
               }
               toast.success("ShipmentRequest added successfully.");
               window.location.reload();
               navigate("/sales-order-view");
           } catch (error) {
             console.error(error?.response?.data || error);
             toast.error("Something went wrong. Please try again.");
           }
              setCreateShipmentRequest({ ...createShipmentRequest, data: shipmentRequestData, visible: false });

          }}
        >
          Create
        </Button>
      </Grid>
      <br></br>
      {fvSelected && !selectedPo && (
        <Typography variant="body2" color="error">
          Please select a PO.
        </Typography>
      )}
      {fwSelected && !selectedSi && (
        <Typography variant="body2" color="error">
          Please select a PO and an SI.
        </Typography>
      )}
      {!fvSelected && !fwSelected && (
        <Typography variant="body2" color="error">
          Please select one of 'From Vendor' or 'From Warehouse' options.
        </Typography>
      )}
    </>
  );
};

export default CreateShipment;
