import { Grid, MenuItem, Stack, TextField, Typography, IconButton, Autocomplete, FormControlLabel, Checkbox, TableBody, TableCell, Paper, Table, TableContainer, TableHead, TableRow, Card, CardContent } from "@mui/material";
import Button from "@mui/material/Button";
import { format, parseISO } from "date-fns";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import eurLogo from "../../../../assets/images/logo/sanEurLogoJpeg.jpeg";
import intlLogo from "../../../../assets/images/logo/sanIntlLogoJpeg.jpeg";
import antLogo from "../../../../assets/images/logo/agronixLogoJpeg.jpeg";
import { companies, titles } from "../../../../utils/menu-items";
import { ButtonGroup } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { retrieveFilteredAddressesApi } from "../../../../components/api/AddressApiService";


const convertImageToBase64 = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

const createExcelWithLogoAndData = async (sampleDetails) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("My Sheet");
  const tick = "✔"; 
  const len = sampleDetails?.items.length;
  const buyingCompanyHeader =
    sampleDetails?.buyingCompany === "SGE"
      ? "SanGroup Europe Kft."
      : sampleDetails?.buyingCompany === "ANT"
        ? "AGRONIX TRADING PTE. LTD."
        : "SANGROUP INTERNATIONAL PTE.LTD.";
  const buyingAddressOne =
    sampleDetails?.buyingCompany === "SGE"
      ? "Stefania ut 101-103"
      : "11 Irving Place #09-02";
  const buyingAddressTwo =
    sampleDetails?.buyingCompany === "SGE"
      ? "H-1143 Budapest, Hungary"
      : "Tai Seng Point Singapore 369551";
  const buyingAddressThree =
    sampleDetails?.buyingCompany === "SGE"
      ? "Registration no: 01-09-400244 VAT no: Hu27842588"
      : sampleDetails?.buyingCompany === "ANT"
        ? "Registration no: 202504646R"
        : "Registration no: 201717395N";

  var logo;

  if (sampleDetails.buyingCompany === "SGI") {
    logo = intlLogo;
  } else if (sampleDetails.buyingCompany === "SGE") {
    logo = eurLogo;
  } else if (sampleDetails.buyingCompany === "ANT") {
    logo = antLogo;
  } else {
    logo = intlLogo;
  }
  convertImageToBase64(logo, async (base64Image) => {
    const imageId = workbook.addImage({
      base64: base64Image,
      extension: "jpeg",
    });

    worksheet.addImage(imageId, "A1:D5");

    worksheet.pageSetup.orientation = 'landscape';
    worksheet.pageSetup.printArea = `A1:K${20 + len}`;

    worksheet.pageSetup.fitToPage = true;
    worksheet.pageSetup.fitToWidth = 1;
    worksheet.pageSetup.fitToHeight = 0;

    const startRow = 3;
    const headerRowNumber = startRow + 9;

    const dataForExcel = [
      [],
      [, , , , , , , , "SAMPLE REQUEST FORM"],
      [],
      [, , , , , , , , , , "Sample Request No: " + (sampleDetails?.sampleRequestNumber || "")],
      ["Date: " + (sampleDetails?.date
        ? format(
            parseISO(sampleDetails.date),
            "dd/MMM/yyyy",
          )
        : ""), , , , , , , , , , "Delivery Date: " + (sampleDetails?.deliveryDate
        ? format(
            parseISO(sampleDetails.deliveryDate),
            "dd/MMM/yyyy",
          )
        : "")],
      ["Ordered By: " + (sampleDetails?.receiver || ""), , , , , , , , , , "AWB No: " + (sampleDetails?.awbNumber || "")],
      ["Reference: " + (sampleDetails?.reference || "")],
      ["Supplier/Vendor: " + (sampleDetails?.sender || "")],
      [],
      ["Origin",
        "Crop Year",
        "Type",
        "Vendor Grade",
        "Sales Grade",
        "Nic %",
        "Sug %",
        "# of Samples",
        "Net Weight / Size per Sample",
        "Delivery Address",
        "Important Notes",
      ],
      ...sampleDetails?.items.map((item, index) => [
        item?.origin,
        item?.crop,
        item?.type,
        item?.buyingGrade,
        item?.salesGrade,
        item?.nicotine,
        item?.sugar,
        item?.numberOfSamples,
        item?.netWeightPerSample ? new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(item?.netWeightPerSample) : '',
        index === 0 ? (sampleDetails?.receiver ? sampleDetails.receiver : "") 
        : index === 1 ? (sampleDetails?.street ? sampleDetails.street : "") 
        : index === 2 ? (sampleDetails?.zipCode ? (sampleDetails.zipCode + (sampleDetails?.city ? (" "+sampleDetails.city):"")) : "") 
        : index === 3 ? (sampleDetails?.county ? sampleDetails.county:"")
        : index === 4 ? (sampleDetails?.country ? sampleDetails.country:"") 
        : index === 5 ? ((sampleDetails?.contactPerson ? ('ATTENTION: '+sampleDetails?.contactPerson): "")) : "", 
        index === 0 ? sampleDetails?.importantNotes : "",
      ]),
      [],
      [, , , , , , , "Original", "Copy"],
      ["Documents Required", , "Phytosanitary Cert",,,,,sampleDetails?.phytosanitaryOriginal ? tick : "",sampleDetails?.phytosanitaryCopy ? tick : "", "                                    Others Remark:", "  "+ (sampleDetails?.othersRemark || "")],
      [, , "Proforma Invoice",,,,,sampleDetails?.proformaOriginal ? tick : "", 
        sampleDetails?.proformaCopy ? tick : "",,],
      [, , "Weight List",,,,,sampleDetails?.weightListOriginal ? tick : "", 
        sampleDetails?.weightListCopy ? tick : "",,],
      [, , "Certificate of Origin",,,,, sampleDetails?.certificateOriginal ? tick : "", 
        sampleDetails?.certificateCopy ? tick : "",,],
      [],
      [],
    ];

    for (let i = (15 + len); i <= (20 + len); i++) {
      const cellC = worksheet.getCell(`C${i}`);
      const cellD = worksheet.getCell(`D${i}`);
      const cellE = worksheet.getCell(`E${i}`);
      const cellF = worksheet.getCell(`F${i}`);
      const cellG = worksheet.getCell(`G${i}`);
      const cellH = worksheet.getCell(`H${i}`);
      const cellI = worksheet.getCell(`I${i}`);

      cellC.border = {
        left: { style: "hair" },
      };
      cellI.border = {
        right: { style: "hair" },
      };
      if (i === (15 + len)) {
        cellC.border = {
          left: { style: "hair" },
          top: { style: "hair" },
        };
        cellD.border = {
          top: { style: "hair" },
        };
        cellE.border = {
          top: { style: "hair" },
        };
        cellF.border = {
          top: { style: "hair" },
        };
        cellG.border = {
          top: { style: "hair" },
        };
        cellH.border = {
          top: { style: "hair" },
        };
        cellI.border = {
          right: { style: "hair" },
          top: { style: "hair" },
        };
      } 
      if (i === (20 + len)) {
        cellC.border = {
          left: { style: "hair" },
          bottom: { style: "hair" },
        };
        cellD.border = {
          bottom: { style: "hair" },
        };
        cellE.border = {
          bottom: { style: "hair" },
        };
        cellF.border = {
          bottom: { style: "hair" },
        };
        cellG.border = {
          bottom: { style: "hair" },
        };
        cellH.border = {
          bottom: { style: "hair" },
        };
        cellI.border = {
          right: { style: "hair" },
          bottom: { style: "hair" },
        };
      }
    }

    for (let i = (15 + len); i <= (20 + len); i++) {
      const cellK = worksheet.getCell(`K${i}`);

      cellK.border = {
        left: { style: "hair" },
        right: { style: "hair" },
      };

      if (i === (15 + len)) {
        cellK.border = {
          top: { style: "hair" },
          left: { style: "hair" },
          right: { style: "hair" },
        };
      }
      if (i === (20 + len)) {
        cellK.border = {
          bottom: { style: "hair" },
          left: { style: "hair" },
          right: { style: "hair" },
        };
      }
    }

    dataForExcel.forEach((row, index) => {
      const rowIndex = startRow + index;
      const excelRow = worksheet.getRow(rowIndex);
      row.forEach((cell, cellIndex) => {
        const cellAddress = excelRow.getCell(cellIndex + 1);
        cellAddress.value = cell;

        if (rowIndex === headerRowNumber) {
          cellAddress.font = { bold: true, size: 8, };
          if (cellIndex === 1) {
            cellAddress.alignment = {
              vertical: "middle",
              wrapText: "true",
              horizontal: "center",
            };
            worksheet.getColumn(cellIndex).width = 12;
            worksheet.getColumn(cellIndex + 1).width = 12;
            worksheet.getColumn(cellIndex + 2).width = 8;
            worksheet.getColumn(cellIndex + 3).width = 9;
            worksheet.getColumn(cellIndex + 4).width = 10;
            worksheet.getColumn(cellIndex + 5).width = 10;
            worksheet.getColumn(cellIndex + 6).width = 9;
            worksheet.getColumn(cellIndex + 7).width = 9;
            worksheet.getColumn(cellIndex + 8).width = 12;
            worksheet.getColumn(cellIndex + 8).width = 13;
            worksheet.getColumn(cellIndex + 9).width = 30;
            worksheet.getColumn(cellIndex + 10).width = 35;
          } else if (cellIndex === 6 || cellIndex === 7) {
            cellAddress.alignment = {
              vertical: "middle",
              wrapText: "true",
              horizontal: "center",
            };
          } else {
            cellAddress.alignment = {
              vertical: "middle",
              wrapText: "true",
              horizontal: "center",
            };
          }
          cellAddress.font = {
            size: 8,
            bold: true,
          };
          cellAddress.border = {
            top: { style: "hair" },
            bottom: { style: "hair" },
            left: { style: "hair" },
            right: { style: "hair" },
          };
        } else if (rowIndex === 2) {
          cellAddress.border = {
            top: { style: "medium" },
          };
        } else if (rowIndex === 4) {
          cellAddress.font = {
            bold: true,
            underline: "single",
            size: 13,
            color: { argb: "002060" },
          };
        } else if (rowIndex === 6) {
          cellAddress.font = {
            bold: true,
            size: 8,
          };
        } else if (rowIndex === 7) {
          cellAddress.font = {
            bold: true,
            size: 8,
          };
        } else if (rowIndex === 8) {
          cellAddress.font = {
            bold: true,
            size: 8,
          };
        }
        else if (rowIndex === 9) {
          cellAddress.font = {
            bold: true,
            size: 8,
          };
        } else if (rowIndex === 10) {
          cellAddress.font = {
            bold: true,
            size: 8,
          };
        } else if (rowIndex > 12 && rowIndex <= 12 + len) {
          if (cellIndex !== 9 && cellIndex !== 10) {
            cellAddress.border = {
              top: { style: "hair", color: { argb: "6e6e6e" } },
              left: { style: "hair", color: { argb: "6e6e6e" } },
              bottom: { style: "hair", color: { argb: "6e6e6e" } },
              right: { style: "hair", color: { argb: "6e6e6e" } },
            };
            cellAddress.alignment = {
              wrapText: "true",
              vertical: "middle",
              horizontal: "center",
            };
            cellAddress.font = {
              size: 8,
            };
          }else{
            cellAddress.alignment = {
              wrapText: "true",
              vertical: "middle",
              horizontal: "left",
            };
            cellAddress.font = {
              size: 8,
            };
            cellAddress.border = {
              right: { style: "hair", color: { argb: "6e6e6e" } },
            };
          }
          if(rowIndex === (12+len)){
            cellAddress.border = {
              right: { style: "hair", color: { argb: "6e6e6e" } },
              left: { style: "hair", color: { argb: "6e6e6e" } },
              bottom: { style: "hair", color: { argb: "6e6e6e" } },
            };
          }
        } else if (rowIndex === 14 + len) {
          cellAddress.font = {
            size: 8,
            underline: "single",
            bold: true,
          };
          cellAddress.alignment = {
            wrapText: "true",
            vertical: "middle",
            horizontal: "center",
          };
        } else if (rowIndex === 15 + len) {
          if (cellIndex === 0) {
            cellAddress.font = {
              size: 8,
              underline: "single",
              bold: true,
            };
          } else if (cellIndex === 2) {
            cellAddress.font = {
              size: 9,
            };
            cellAddress.border = {
              top: { style: "hair" },
              left: { style: "hair" },
            };
          }
          else if (cellIndex === 7) {
            cellAddress.font = {
              size: 9,
            };
            cellAddress.alignment = {
              wrapText: "true",
              vertical: "middle",
              horizontal: "center",
            };
          }
          else if (cellIndex === 8) {
            cellAddress.font = {
              size: 9,
            };
            cellAddress.alignment = {
              wrapText: "true",
              vertical: "middle",
              horizontal: "center",
            };
          }
          else if (cellIndex === 9) {
            cellAddress.font = {
              size: 8,
              bold: true,
            };
            cellAddress.alignment = {
              wrapText: "true",
              vertical: "middle",
              horizontal: "center",
            };
          }
          else if (cellIndex === 10) {
            cellAddress.font = {
              size: 9,
            };
          }
        } else if (rowIndex === 16 + len) {
          if (cellIndex === 2) {
            cellAddress.font = {
              size: 9,
            };
            cellAddress.border = {
              left: { style: "hair" },
            };
          }else if (cellIndex === 7) {
            cellAddress.font = {
              size: 9,
            };
            cellAddress.alignment = {
              wrapText: "true",
              vertical: "middle",
              horizontal: "center",
            };
          }
          else if (cellIndex === 8) {
            cellAddress.font = {
              size: 9,
            };
            cellAddress.alignment = {
              wrapText: "true",
              vertical: "middle",
              horizontal: "center",
            };
          }
        } else if (rowIndex === 17 + len) {
          if (cellIndex === 2) {
            cellAddress.font = {
              size: 9,
            };
            cellAddress.border = {
              left: { style: "hair" },
            };
          }else if (cellIndex === 7) {
            cellAddress.font = {
              size: 9,
            };
            cellAddress.alignment = {
              wrapText: "true",
              vertical: "middle",
              horizontal: "center",
            };
          }
          else if (cellIndex === 8) {
            cellAddress.font = {
              size: 9,
            };
            cellAddress.alignment = {
              wrapText: "true",
              vertical: "middle",
              horizontal: "center",
            };
          }
        } else if (rowIndex === 18 + len) {
          if (cellIndex === 2) {
            cellAddress.font = {
              size: 9,
            };
            cellAddress.border = {
              left: { style: "hair" },
            };
          }else if (cellIndex === 7) {
            cellAddress.font = {
              size: 9,
            };
            cellAddress.alignment = {
              wrapText: "true",
              vertical: "middle",
              horizontal: "center",
            };
          }
          else if (cellIndex === 8) {
            cellAddress.font = {
              size: 9,
            };
            cellAddress.alignment = {
              wrapText: "true",
              vertical: "middle",
              horizontal: "center",
            };
          }
        } else if (rowIndex === 19 + len) {
          if (cellIndex === 2) {
            cellAddress.font = {
              size: 9,
            };
            cellAddress.border = {
              left: { style: "hair" },
            };
          }
        } else if (rowIndex === 20 + len) {
          if (cellIndex === 2) {
            cellAddress.font = {
              size: 9,
            };
            cellAddress.border = {
              left: { style: "hair" },
              bottom: { style: "hair" },
            };
          }
        }
      });
      excelRow.commit();
    });
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "OfferWithLogo.xlsx");
  });
};


const ExportSampleOrder = (props) => {
  const { setExportSamplePopup, exportSamplePopup } = props;
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
  const [filteredDeliveryAddresses, setFilteredDeliveryAddresses] = useState([]);
  const [items, setItems] = useState([]);

  const handleCheckboxChange = (field) => {
    setSampleDetails((prev) => ({
      ...prev,
      [field]: !prev[field], 
    }));
  };
  
  const filterDeliveryAddresses = ({
    id: 0,
    stakeholderId: exportSamplePopup?.data[0]?.receiver?.id,
    stakeholderIdList: [exportSamplePopup?.data[0]?.receiver?.id],
    type: "",
    typeList: ["Pay To", "Bill To", "Ship To"],
    street: "",
    streetList: [],
    zipCode: "",
    zipCodeList: [],
    city: "",
    cityList: [],
    county: "",
    countyList: [],
    country: "",
    countryList: [],
    remark: "",
    recordStatusList: ["ACTIVE"],
  });

  const [sampleDetails, setSampleDetails] = useState({
    date: exportSamplePopup?.data[0]?.timestamp,
    receiver: exportSamplePopup?.data[0]?.receiver?.name,
    sender: exportSamplePopup?.data[0]?.sender?.name,
    deliveryDate: exportSamplePopup?.data[0]?.deliveryDate,
    awbNumber: exportSamplePopup?.data[0]?.awbNumber,
    reference: exportSamplePopup?.data[0]?.purpose,
    numberOfSamples: exportSamplePopup?.data[0]?.numberOfSamples,
    netWeightPerSample: exportSamplePopup?.data[0]?.netWeightPerSample,
    importantNotes: exportSamplePopup?.data[0]?.importantNotes,
    deliveryAddress: selectedDeliveryAddress,
    contactPerson: exportSamplePopup?.data[0]?.receiver?.contactPerson,
    othersRemark: exportSamplePopup?.data[0]?.othersRemark,
    items: items,
    phytosanitaryOriginal: false,
    phytosanitaryCopy: false,
    proformaOriginal: false,
    proformaCopy: false,
    weightListOriginal: false,
    weightListCopy: false,
    certificateOriginal: false,
    certificateCopy: false,
  });

  useEffect(() => {
    if (!exportSamplePopup?.data || exportSamplePopup.data.length === 0) return;

    (async () => {
      try {
        const newItems = exportSamplePopup.data.map((sample) => {

          return {
            origin: sample?.salesOrigin || "",
            crop: sample?.salesCrop || "",
            type: sample?.item?.type || "",
            buyingGrade: sample?.item?.buyingGrade || "",
            salesGrade: sample?.salesGrade || "",
            nicotine: sample?.salesNicotine || "",
            sugar: sample?.salesSugar || "",
            numberOfSamples: sample?.numberOfSamples || "",
            netWeightPerSample: sample?.netWeightPerSample || "",
          };
        });

        while (newItems.length < 5) {
          newItems.push({
            origin: "",
            crop: "",
            type: "",
            buyingGrade: "",
            salesGrade: "",
            nicotine: "",
            sugar: "",
            numberOfSamples: "",
            netWeightPerSample: "",
          });
        }

        setItems(newItems);

        setSampleDetails((prev) => ({
          ...prev,
          items: newItems,
        }));
      } catch (err) {
        console.error("Error in references/items effect:", err);
      }
    })();
  }, [exportSamplePopup]);

  useEffect(() => {
    if (!exportSamplePopup?.data || exportSamplePopup.data.length === 0) return;

    (async () => {
      try {
        const [deliveryRes, notifyRes] = await Promise.all([
          retrieveFilteredAddressesApi(filterDeliveryAddresses),
        ]);

        setFilteredDeliveryAddresses(deliveryRes?.data || []);

      } catch (err) {
        console.error("Error in addresses/documents effect:", err);
      }
    })();
  }, [exportSamplePopup]);


  return (
    <>
      <Stack direction="column">
        <Typography variant="body1" mb={2}>
          Selected Items
        </Typography>
        {exportSamplePopup &&
  exportSamplePopup.data &&
  exportSamplePopup.data.map((sample, index) => (
    <Card key={sample.id} variant="outlined" style={{ marginBottom: "15px", padding: "15px" }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Sample {index + 1}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={0.75}>
            <Typography variant="body2"> Sample ID </Typography>
            <TextField disabled variant="outlined" size="small" fullWidth value={sample?.id || ""} />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Origin</Typography>
            <TextField disabled variant="outlined" size="small" fullWidth value={sample?.salesOrigin || ""} />
          </Grid>
          <Grid item xs={12} sm={0.8}>
            <Typography variant="body2">Crop</Typography>
            <TextField disabled variant="outlined" size="small" fullWidth value={sample?.salesCrop || ""} />
          </Grid>
          <Grid item xs={12} sm={0.8}>
            <Typography variant="body2">Type</Typography>
            <TextField disabled variant="outlined" size="small" fullWidth value={sample?.item?.type || ""} />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Vendor Grade</Typography>
            <TextField disabled variant="outlined" size="small" fullWidth value={sample?.item?.buyingGrade || ""} />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Sales Grade</Typography>
            <TextField disabled variant="outlined" size="small" fullWidth value={sample?.salesGrade || ""} />
          </Grid>
          <Grid item xs={12} sm={0.75}>
            <Typography variant="body2">Nicotine</Typography>
            <TextField disabled type="number" variant="outlined" size="small" fullWidth value={sample?.salesNicotine || ""} />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Sugar</Typography>
            <TextField disabled type="number" variant="outlined" size="small" fullWidth value={sample?.salesSugar || ""} />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2"># Of Sample</Typography>
            <TextField disabled type="number" variant="outlined" size="small" fullWidth value={sample?.numberOfSamples || ""} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Net Weight / Size per Sample</Typography>
            <TextField disabled type="number" variant="outlined" size="small" fullWidth value={sample?.netWeightPerSample || ""} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ))}
<Grid container spacing={2}>
    {/* Company, Date, Delivery Date */}
    <Grid item xs={12} sm={12} container spacing={2}>
    <Grid item xs={3}>
      <Typography variant="body2">Sample Request Number</Typography>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        value={sampleDetails?.sampleRequestNumber || ""}
        onChange={(e) =>
          setSampleDetails({
            ...sampleDetails,
            sampleRequestNumber: e.target.value,
          })
        }
      />
    </Grid>
    <Grid item xs={3}>
      <Typography variant="body2">Company</Typography>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        select
        value={sampleDetails?.buyingCompany}
        onChange={(e) =>
          setSampleDetails({
            ...sampleDetails,
            buyingCompany: e.target.value,
          })
        }
      >
        {companies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>

    <Grid item xs={3}>
      <Typography variant="body2">Date</Typography>
      <TextField
        size="small"
        fullWidth
        variant="outlined"
        id="date"
        type="date"
        value={sampleDetails?.date ? format(parseISO(sampleDetails.date), "yyyy-MM-dd") : ""}
        inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
        InputLabelProps={{ shrink: true }}
        onKeyDown={(e) => e.preventDefault()}
        onChange={(e) =>
          setSampleDetails({
            ...sampleDetails,
            date: e.target.value,
          })
        }
      />
    </Grid>

    <Grid item xs={3}>
      <Typography variant="body2">Delivery Date</Typography>
      <TextField
        size="small"
        fullWidth
        variant="outlined"
        id="date"
        type="date"
        value={sampleDetails?.deliveryDate ? format(parseISO(sampleDetails.deliveryDate), "yyyy-MM-dd") : ""}
        inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
        InputLabelProps={{ shrink: true }}
        onKeyDown={(e) => e.preventDefault()}
        disabled
      />
    </Grid>
  </Grid>

  {/* Supplier & Receiver */}
  <Grid item xs={12} sm={12} container spacing={2}>
    <Grid item xs={6}>
      <Typography variant="body2">Supplier</Typography>
      <TextField disabled variant="outlined" size="small" fullWidth value={sampleDetails?.sender || ""} />
    </Grid>
    <Grid item xs={6}>
      <Typography variant="body2">Receiver</Typography>
      <TextField disabled variant="outlined" size="small" fullWidth value={sampleDetails?.receiver || ""} />
    </Grid>
  </Grid>

  {/* Delivery Address - Line 1 & Line 2 */}
  <Grid item xs={12} sm={12} container spacing={2}>
    <Grid item xs={6}>
      <Typography variant="body2">Delivery Address Line 1</Typography>
      <Autocomplete
        options={filteredDeliveryAddresses}
        getOptionLabel={(option) => {
          if (!option) return '';
          const type = option?.type || "";
          const street = option?.street || "";
          const city = option?.city || "";
          const addressPart1 = [street, city].filter(Boolean).join(", ");
          return `${type}: ${addressPart1}`;
        }}
        value={selectedDeliveryAddress}
        onChange={(event, newValue) => {
          setSelectedDeliveryAddress(newValue);

          const street = newValue?.street || "";
          const city = newValue?.city || "";
          const county = newValue?.county || "";
          const zipCode = newValue?.zipCode || "";
          const country = newValue?.country || "";

          const deliveryAddressOne = [street, city].filter(Boolean).join(", ");
          const deliveryAddressTwo = [county, zipCode, country].filter(Boolean).join(", ");

          setSampleDetails((prevDetails) => ({
            ...prevDetails,
            deliveryAddress: deliveryAddressOne + deliveryAddressTwo,
            street: newValue?.street || "",
            city: newValue?.city || "",
            county: newValue?.county || "",
            zipCode: newValue?.zipCode || "",
            country: newValue?.country || "",
          }));
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" size="small" fullWidth />
        )}
        renderTags={() => null}
      />
    </Grid>
    <Grid item xs={6}>
      <Typography variant="body2">Delivery Address Line 2</Typography>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        disabled
        value={(selectedDeliveryAddress?.country || "") + " " + (selectedDeliveryAddress?.zipCode || "")}
      />
    </Grid>
  </Grid>

  {/* Important Notes, Others Remark, Reference */}
  <Grid item xs={12} sm={12} container spacing={2}>
    <Grid item xs={4}>
      <Typography variant="body2">Important Notes</Typography>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        value={sampleDetails?.importantNotes || ""}
        onChange={(e) =>
          setSampleDetails({
            ...sampleDetails,
            importantNotes: e.target.value,
          })
        }
      />
    </Grid>

    <Grid item xs={4}>
      <Typography variant="body2">Others Remark</Typography>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        value={sampleDetails?.othersRemark || ""}
        onChange={(e) =>
          setSampleDetails({
            ...sampleDetails,
            othersRemark: e.target.value,
          })
        }
      />
    </Grid>

    <Grid item xs={4}>
      <Typography variant="body2">Reference</Typography>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        value={sampleDetails?.reference || ""}
        onChange={(e) =>
          setSampleDetails({
            ...sampleDetails,
            reference: e.target.value,
          })
        }
      />
    </Grid>
  </Grid>
</Grid>

        <Typography variant="body1" mb={2} marginTop={5}>
        Documents Required
        </Typography>
        <Grid container spacing={2} marginBottom={5}>
        
        <Grid item xs={12} sm={6}>

  {/* Labels for checkboxes */}
  <Grid container spacing={1} style={{ marginTop: "8px", fontWeight: "bold" }}>
    <Grid item xs={6}></Grid> {/* Empty space for alignment */}
    <Grid item xs={3}><Typography>Original</Typography></Grid>
    <Grid item xs={3}><Typography>Copy</Typography></Grid>
  </Grid>

  {/* Phytosanitary Cert */}
  <Grid container spacing={1} alignItems="center">
    <Grid item xs={6}><Typography>Phytosanitary Cert</Typography></Grid>
    <Grid item xs={3}>
      <Checkbox
        checked={sampleDetails.phytosanitaryOriginal}
        onChange={() => handleCheckboxChange("phytosanitaryOriginal")}
      />
    </Grid>
    <Grid item xs={3}>
      <Checkbox
        checked={sampleDetails.phytosanitaryCopy}
        onChange={() => handleCheckboxChange("phytosanitaryCopy")}
      />
    </Grid>
  </Grid>

  {/* Proforma Invoice */}
  <Grid container spacing={1} alignItems="center">
    <Grid item xs={6}><Typography>Proforma Invoice</Typography></Grid>
    <Grid item xs={3}>
      <Checkbox
        checked={sampleDetails.proformaOriginal}
        onChange={() => handleCheckboxChange("proformaOriginal")}
      />
    </Grid>
    <Grid item xs={3}>
      <Checkbox
        checked={sampleDetails.proformaCopy}
        onChange={() => handleCheckboxChange("proformaCopy")}
      />
    </Grid>
  </Grid>

  {/* Weight List */}
  <Grid container spacing={1} alignItems="center">
    <Grid item xs={6}><Typography>Weight List</Typography></Grid>
    <Grid item xs={3}>
      <Checkbox
        checked={sampleDetails.weightListOriginal}
        onChange={() => handleCheckboxChange("weightListOriginal")}
      />
    </Grid>
    <Grid item xs={3}>
      <Checkbox
        checked={sampleDetails.weightListCopy}
        onChange={() => handleCheckboxChange("weightListCopy")}
      />
    </Grid>
  </Grid>

  {/* Certificate of Origin */}
  <Grid container spacing={1} alignItems="center">
    <Grid item xs={6}><Typography>Certificate of Origin</Typography></Grid>
    <Grid item xs={3}>
      <Checkbox
        checked={sampleDetails.certificateOriginal}
        onChange={() => handleCheckboxChange("certificateOriginal")}
      />
    </Grid>
    <Grid item xs={3}>
      <Checkbox
        checked={sampleDetails.certificateCopy}
        onChange={() => handleCheckboxChange("certificateCopy")}
      />
    </Grid>
  </Grid>
</Grid>

          {/*<Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Delivery Address Line One</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={sampleDetails?.deliveryAddressOne}
              onChange={(e) => {
                setSampleDetails({
                  ...sampleDetails,
                  deliveryAddressOne: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
            {selectedDeliveryAddress && (<Typography variant="body2" style={{ color: "red" }}>(Please use this field to make changes to the address as it will appear on the PDF. Note that your changes will not affect the actual address information.)</Typography>)}
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Delivery Address Line Two</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={sampleDetails?.deliveryAddressTwo}
              onChange={(e) => {
                setSampleDetails({
                  ...sampleDetails,
                  deliveryAddressTwo: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
            {selectedDeliveryAddress && (<Typography variant="body2" style={{ color: "red" }}>(Please use this field to make changes to the address as it will appear on the PDF. Note that your changes will not affect the actual address information.)</Typography>)}
          </Grid>*/}

        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() => {
              setExportSamplePopup({
                data: null,
                visible: false,
              })
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ whiteSpace: "nowrap" }}
            onClick={() => { createExcelWithLogoAndData(sampleDetails) }}
          >
            Create Shipping Instruction File
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default ExportSampleOrder;
