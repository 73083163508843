import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";

const ShippingInstructionHistoryButton = ({fetchHistoryApi, currentRow }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);

  const retrieveFilteredShippingInstructions = {
    id: 0,
    referencingShippingInstructionIdList: [currentRow?.id],
    recordStatusList: ["ACTIVE"],
  };

  const handleOpen = () => {
    setDialogOpen(true);
    setLoading(true);
    fetchHistoryApi(retrieveFilteredShippingInstructions)
      .then((response) => {
        setHistoryData(response.data || []);
      })
      .catch((error) => {
        console.error("Error fetching SI history:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        View History
      </Button>
      <Dialog open={dialogOpen} onClose={handleClose} maxWidth="xl" fullWidth>
        <DialogTitle>Shipping Instruction History</DialogTitle>
        <DialogContent>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            <>
              {/* Current Row Details Section */}
              <Table style={{ marginBottom: "20px" }} size="small">
                <TableHead>
                  <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                    <TableCell style={{ fontWeight: "bold" }}>ID</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>SR</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Type</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Quantity</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>SI Number</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>SI Date</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>PO</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>SO</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Item</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Warehouse</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Destination</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Point of Loading</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Point of Unloading</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Reference SI</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{ backgroundColor: "#e3f2fd" }}>
                    <TableCell>{currentRow.id}</TableCell>
                    <TableCell>{currentRow?.shipmentRequest?.id}</TableCell>
                    <TableCell>{currentRow?.shipmentRequest?.shipmentRequestType}</TableCell>
                    <TableCell>
                      {currentRow?.shipmentRequest?.shipmentQuantity
                        ? `${new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                          }).format(
                            currentRow?.shipmentRequest?.shipmentQuantity
                          )} ${currentRow?.shipmentRequest?.shipmentQuantityUnit}`
                        : ""}
                    </TableCell>
                    <TableCell>{currentRow?.finalShippingInstructionNumber}</TableCell>
                    <TableCell>
                      {currentRow?.shippingInstructionDate
                        ? format(
                            new Date(currentRow.shippingInstructionDate),
                            "dd/MM/yyyy"
                          )
                        : ""}
                    </TableCell>
                    <TableCell>{currentRow?.shipmentRequest?.purchaseOrder?.id}</TableCell>
                    <TableCell>{currentRow?.shipmentRequest?.salesOrder?.id}</TableCell>
                    <TableCell>
                      {currentRow?.shipmentRequest?.salesOrder?.offer?.item?.id ||
                        currentRow?.shipmentRequest?.purchaseOrder?.offer?.item?.id ||
                        ""}
                    </TableCell>
                    <TableCell>{currentRow?.warehouse}</TableCell>
                    <TableCell>{currentRow?.destination}</TableCell>
                    <TableCell>{currentRow?.pointOfLoading}</TableCell>
                    <TableCell>{currentRow?.pointOfUnloading}</TableCell>
                    <TableCell>
                      {currentRow?.shipmentRequest?.referenceShippingInstructionId}-
                      {currentRow?.shipmentRequest?.referenceShippingInstructionNumber}
                    </TableCell>
                    <TableCell>{currentRow?.status}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Typography variant="h6" style={{ marginTop: "20px" }}>
                History Details
              </Typography>
              {/* History Data Table */}
              <Table size="small">
                <TableHead>
                  <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                    <TableCell style={{ fontWeight: "bold" }}>ID</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>SR</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Type</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Quantity</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>SI Number</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>SI Date</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>PO</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>SO</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Item</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Warehouse</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Destination</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Point of Loading</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Point of Unloading</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Reference SI</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyData.length > 0 ? (
                    historyData.map((row) => (
                      <TableRow key={row.id} style={{ backgroundColor: "#f9f9f9" }}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row?.shipmentRequest?.id}</TableCell>
                        <TableCell>{row?.shipmentRequest?.shipmentRequestType}</TableCell>
                        <TableCell>
                          {row?.shipmentRequest?.shipmentQuantity
                            ? `${new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(
                                row?.shipmentRequest?.shipmentQuantity
                              )} ${row?.shipmentRequest?.shipmentQuantityUnit}`
                            : ""}
                        </TableCell>
                        <TableCell>{row?.finalShippingInstructionNumber}</TableCell>
                        <TableCell>
                          {row?.shippingInstructionDate
                            ? format(
                                new Date(row.shippingInstructionDate),
                                "dd/MM/yyyy"
                              )
                            : ""}
                        </TableCell>
                        <TableCell>{row?.shipmentRequest?.purchaseOrder?.id}</TableCell>
                        <TableCell>{row?.shipmentRequest?.salesOrder?.id}</TableCell>
                        <TableCell>
                          {row?.shipmentRequest?.salesOrder?.offer?.item?.id ||
                            row?.shipmentRequest?.purchaseOrder?.offer?.item?.id ||
                            ""}
                        </TableCell>
                        <TableCell>{row?.warehouse}</TableCell>
                        <TableCell>{row?.destination}</TableCell>
                        <TableCell>{row?.pointOfLoading}</TableCell>
                        <TableCell>{row?.pointOfUnloading}</TableCell>
                        <TableCell>
                          {row?.shipmentRequest?.referenceShippingInstructionId}-
                          {row?.shipmentRequest?.referenceShippingInstructionNumber}
                        </TableCell>
                        <TableCell>{row?.status}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <Typography style={{ padding: "10px" }}>
                      No history available for this SI.
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};


export default ShippingInstructionHistoryButton;
