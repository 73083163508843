import React, { useState, useEffect } from "react";
import { IconButton, Dialog, DialogTitle, DialogContent, CircularProgress, List, ListItem, TableCell, TableRow, TableBody, TableContainer, Table, Paper, TableHead } from "@mui/material";
import { History as HistoryIcon } from "@mui/icons-material";
import { retrieveFilteredRecordsApi } from "../../../../components/api/TradeApiService";
import { format } from "date-fns";

const TradeHistoryButton = ({ row }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);

    const handleRowClick = (id) => {
        setSelectedRows((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((selectedId) => selectedId !== id)
                : [...prevSelected, id]
        );
    };

    const toggleDialog = () => setIsOpen(!isOpen);

    useEffect(() => {
        if (isOpen) {
            setLoading(true);
            const filterTrade = {
                itemIdList: [row?.item?.id],
                rowStatusList: ["ACTIVE"],
            };
            retrieveFilteredRecordsApi(filterTrade)
                .then((response) => {
                    setHistoryData(response?.data);
                })
                .catch((err) => {
                    setHistoryData([]);
                })
                .finally(() => setLoading(false));
        }
    }, [isOpen, row]);

    return (
        <>
            <IconButton onClick={toggleDialog}>
                <HistoryIcon />
            </IconButton>

            <Dialog open={isOpen} onClose={toggleDialog}
                fullWidth
                maxWidth="xl"
                PaperProps={{
                    style: {
                        width: "90%",
                    },
                }}>
                <DialogTitle>Order History</DialogTitle>
                <DialogContent>
                    {loading ? (
                        <CircularProgress />
                    ) : historyData.length > 0 ? (
                        <TableContainer
                            component={Paper}
                            sx={{ maxHeight: "80vh", overflow: "auto", border: "1px solid #ccc" }}
                        >
                            <Table stickyHeader sx={{
                                borderCollapse: "collapse",
                                minWidth: 750,
                                "& .MuiTableCell-root": {
                                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                                },
                                "& .MuiTableCell-head": {
                                    backgroundColor: "#20315d",
                                    color: "white",
                                    borderRight: "1px solid rgba(49, 64, 128, 1)",
                                },
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            Trade ID
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            PO
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            SO
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            SR
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            SI
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                whiteSpace: "nowrap", borderRight: "1px solid #ccc",
                                                color: "#fff",
                                            }}
                                        >
                                            Operation
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            Record
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            Date
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            PO Company
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            SO Company
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            Item
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            Buying Grade
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            Sales Grade
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            Crop
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            Origin
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            Warehouse
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            Destination
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            PO BP
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            SO BP
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            POL
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            POD
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            Number Of Cartons
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            Net Weight Per Carton
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            Volume
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap",
                                                borderRight: "1px solid #ccc"
                                            }}
                                        >
                                            ATA
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap"
                                            }}
                                        >
                                            Timestamp
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                color: "#fff",
                                                whiteSpace: "nowrap"
                                            }}
                                        >
                                            Creator
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {historyData.map((histRow, index) => {
                                        const isSelected = selectedRows.includes(histRow?.id);
                                        const rowBgColor = isSelected ? "lightblue" : (index % 2 === 0 ? "#f9f9f9" : "#ffffff");
                                        return (
                                            <TableRow
                                                key={index}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRowClick(histRow?.id);
                                                }}
                                                sx={{ backgroundColor: rowBgColor, cursor: "pointer" }}
                                            >
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.id}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionShipmentRequestPurchaseOrderId ||
                                                        histRow?.shipmentRequestPurchaseOrderId ||
                                                        ((histRow?.operationType === "PO" ||
                                                            histRow?.operationType === "PO CANCELLATION") &&
                                                            histRow?.orderId)}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionShipmentRequestSalesOrderId ||
                                                        histRow?.shipmentRequestSalesOrderId ||
                                                        ((histRow?.operationType === "SO" ||
                                                            histRow?.operationType === "SO CANCELLATION") &&
                                                            histRow?.orderId)}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionShipmentRequestId ||
                                                        histRow?.shipmentRequestId}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {[histRow?.shippingInstructionId]}
                                                </TableCell>
                                                <TableCell align="center" sx={{
                                                    whiteSpace: "nowrap", borderRight: "1px solid #ccc",
                                                    position: "sticky",
                                                    left: 0,
                                                    background: "inherit",
                                                    zIndex: 1,
                                                }}>
                                                    {histRow?.operationType}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {(histRow?.shippingInstructionRecordStatus &&
                                                        histRow.shippingInstructionRecordStatus.split("")[0]) ||
                                                        (histRow?.shipmentRequestRecordStatus &&
                                                            histRow.shipmentRequestRecordStatus.split("")[0]) ||
                                                        (histRow?.orderRecordStatus &&
                                                            histRow.orderRecordStatus.split("")[0])}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {(histRow?.shippingInstructionId &&
                                                        `${histRow?.shippingInstructionShipmentRequestType} ${histRow?.finalShippingInstructionNumber || ""
                                                        }`) ||
                                                        (histRow?.operationType === "PO" &&
                                                            histRow?.purchaseOrderNumber) ||
                                                        (histRow?.operationType === "SO" &&
                                                            histRow?.salesOrderNumber) ||
                                                        (histRow?.operationType === "SR" &&
                                                            histRow?.shipmentRequestType) ||
                                                        ""}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {(histRow?.shippingInstructionDate
                                                        ? format(
                                                            new Date(histRow?.shippingInstructionDate),
                                                            "dd/MM/yyyy"
                                                        )
                                                        : "") ||
                                                        (histRow?.shipmentRequestDate
                                                            ? format(
                                                                new Date(histRow?.shipmentRequestDate),
                                                                "dd/MM/yyyy"
                                                            )
                                                            : "") ||
                                                        (histRow?.orderDate
                                                            ? format(
                                                                new Date(histRow?.orderDate),
                                                                "dd/MM/yyyy"
                                                            )
                                                            : "")}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionShipmentRequestPurchaseOrderCompany ||
                                                        histRow?.shipmentRequestPurchaseOrderCompany ||
                                                        ((histRow?.operationType === "PO" ||
                                                            histRow?.operationType === "PO CANCELLATION") &&
                                                            histRow?.orderCompany)}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionShipmentRequestSalesOrderCompany ||
                                                        histRow?.shipmentRequestSalesOrderCompany ||
                                                        ((histRow?.operationType === "SO" ||
                                                            histRow?.operationType === "SO CANCELLATION") &&
                                                            histRow?.orderCompany)}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionShipmentRequestPurchaseOrderOfferItemId ||
                                                        histRow?.shippingInstructionShipmentRequestSalesOrderOfferItemId ||
                                                        histRow?.shipmentRequestPurchaseOrderOfferItemId ||
                                                        histRow?.shipmentRequestSalesOrderOfferItemId ||
                                                        histRow?.orderOfferItemId}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionShipmentRequestPurchaseOrderOfferItemBuyingGrade ||
                                                        histRow?.shippingInstructionShipmentRequestSalesOrderOfferItemBuyingGrade ||
                                                        histRow?.shipmentRequestPurchaseOrderOfferItemBuyingGrade ||
                                                        histRow?.shipmentRequestSalesOrderOfferItemBuyingGrade ||
                                                        histRow?.orderOfferItemBuyingGrade ||
                                                        histRow?.orderOfferItemBuyingGrade}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionShipmentRequestPurchaseOrderOfferSalesGrade ||
                                                        histRow?.shippingInstructionShipmentRequestSalesOrderOfferSalesGrade ||
                                                        histRow?.shipmentRequestPurchaseOrderOfferSalesGrade ||
                                                        histRow?.shipmentRequestSalesOrderOfferSalesGrade ||
                                                        histRow?.orderOfferSalesGrade ||
                                                        histRow?.orderOfferSalesGrade}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionShipmentRequestPurchaseOrderOfferItemCrop ||
                                                        histRow?.shippingInstructionShipmentRequestSalesOrderOfferItemCrop ||
                                                        histRow?.shipmentRequestPurchaseOrderOfferItemCrop ||
                                                        histRow?.shipmentRequestSalesOrderOfferItemCrop ||
                                                        histRow?.orderOfferItemCrop ||
                                                        histRow?.orderOfferItemCrop}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionShipmentRequestPurchaseOrderOfferItemOrigin ||
                                                        histRow?.shippingInstructionShipmentRequestSalesOrderOfferItemOrigin ||
                                                        histRow?.shipmentRequestPurchaseOrderOfferItemOrigin ||
                                                        histRow?.shipmentRequestSalesOrderOfferItemOrigin ||
                                                        histRow?.orderOfferItemOrigin ||
                                                        histRow?.orderOfferItemOrigin}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionWarehouse ||
                                                        histRow?.shipmentRequestWarehouse}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionDestination ||
                                                        histRow?.shipmentRequestDestination}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionShipmentRequestPurchaseOrderOfferStakeholderName ||
                                                        histRow?.shipmentRequestPurchaseOrderOfferStakeholderName ||
                                                        (histRow?.operationType === "PO" &&
                                                            histRow?.orderOfferStakeholderName)}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionShipmentRequestSalesOrderOfferStakeholderName ||
                                                        histRow?.shipmentRequestSalesOrderOfferStakeholderName ||
                                                        (histRow?.operationType === "SO" &&
                                                            histRow?.orderOfferStakeholderName)}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionPointOfLoading ||
                                                        histRow?.shipmentRequestPortOfLoading ||
                                                        histRow?.orderPortOfLoading}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionPointOfUnloading ||
                                                        histRow?.shipmentRequestPortOfDischarge ||
                                                        histRow?.orderPortOfDischarge}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionShipmentRequestNumberOfCases ||
                                                        histRow?.shipmentRequestNumberOfCases ||
                                                        histRow?.orderNumberOfCases}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionShipmentRequestNetWeightPerCase ||
                                                        histRow?.shipmentRequestNetWeightPerCase ||
                                                        histRow?.orderNetWeightPerCase}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionShipmentRequestShipmentQuantity
                                                        ? `${new Intl.NumberFormat("en-US", {
                                                            style: "decimal",
                                                            minimumFractionDigits: 2,
                                                        }).format(
                                                            histRow?.shippingInstructionShipmentRequestShipmentQuantity
                                                        )} ${histRow?.shippingInstructionShipmentRequestShipmentQuantityUnit
                                                        }`
                                                        : "" || histRow?.shipmentRequestShipmentQuantity
                                                            ? `${new Intl.NumberFormat("en-US", {
                                                                style: "decimal",
                                                                minimumFractionDigits: 2,
                                                            }).format(histRow?.shipmentRequestShipmentQuantity)} ${histRow?.shipmentRequestShipmentQuantityUnit
                                                            }`
                                                            : "" || histRow?.orderVolume
                                                                ? `${new Intl.NumberFormat("en-US", {
                                                                    style: "decimal",
                                                                    minimumFractionDigits: 2,
                                                                }).format(histRow?.orderVolume)} ${histRow?.orderVolumeUnit
                                                                }`
                                                                : ""}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}>
                                                    {histRow?.shippingInstructionActualTimeOfArrival
                                                        ? format(
                                                            new Date(
                                                                histRow?.shippingInstructionActualTimeOfArrival
                                                            ),
                                                            "dd-MMM-yyyy"
                                                        )
                                                        : ""}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                                                    {histRow?.timestamp
                                                        ? format(
                                                            new Date(histRow.timestamp),
                                                            "dd/MM/yyyy HH:mm"
                                                        )
                                                        : ""}
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                                                    {histRow?.creator}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <p>No history available.</p>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default TradeHistoryButton;
