import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCost from "./AddCost";
import EditCost from "./EditCost";
import TextField from "@mui/material/TextField";
import { visuallyHidden } from "@mui/utils";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import ExportExcel from "../../../utils/excelExport";
import { useState } from "react";
import {
  retrieveAllCostsApi,
  retrieveFilteredCostsApi,
} from "../../../components/api/CostApiService";
import { useEffect } from "react";
import { format } from "date-fns";
import { Stack } from "@mui/material";
import { incoterms, recordStatus, status } from "../../../utils/menu-items";

const freightOfferByList = [
  {
    value: "A",
    label: "A",
  },
  {
    value: "B",
    label: "B",
  },
  {
    value: "C",
    label: "C",
  },
  {
    value: "D",
    label: "D",
  },
  {
    value: "me",
    label: "me",
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "editButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "basePrice",
    numeric: true,
    disablePadding: true,
    label: "Base Price",
  },
  {
    id: "freightCost",
    numeric: true,
    disablePadding: true,
    label: "Freight Cost",
  },
  {
    id: "freightOfferBy",
    numeric: false,
    disablePadding: true,
    label: "Freight Offer By",
  },
  {
    id: "freightOfferDate",
    numeric: false,
    disablePadding: true,
    label: "Freight Offer Date",
  },
  {
    id: "insuranceCost",
    numeric: true,
    disablePadding: true,
    label: "Insurance Cost",
  },
  {
    id: "storageCost",
    numeric: true,
    disablePadding: true,
    label: "Storage Cost",
  },
  {
    id: "financeCost",
    numeric: true,
    disablePadding: true,
    label: "Finance Cost",
  },
  {
    id: "others",
    numeric: true,
    disablePadding: true,
    label: "Others",
  },
  {
    id: "othersRemark",
    numeric: false,
    disablePadding: true,
    label: "Others Remark",
  },
  {
    id: "firstCommision",
    numeric: true,
    disablePadding: true,
    label: "1st Service Fee",
  },
  {
    id: "secondCommision",
    numeric: true,
    disablePadding: true,
    label: "2nd Service Fee",
  },
  {
    id: "thirdCommision",
    numeric: true,
    disablePadding: true,
    label: "3rd Service Fee",
  },
  {
    id: "totalUnitCost",
    numeric: true,
    disablePadding: true,
    label: "Total Unit Cost Before Profit",
  },
  {
    id: "profit",
    numeric: true,
    disablePadding: true,
    label: "Profit",
  },
  {
    id: "totalOfferCost",
    numeric: true,
    disablePadding: false,
    label: "Total Unit Cost After Profit",
  },
  {
    id: "remark",
    numeric: false,
    disablePadding: false,
    label: "Remark",
  },
  {
    id: "creationTime",
    numeric: false,
    disablePadding: true,
    label: "Creation Time",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: true,
    label: "Created By",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, showFilters, setShowFilters, rows, setAddView, selected,
    selectedData } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Stack direction="row" spacing={1} alignItems={"center"}>
          <Typography
            sx={{ fontWeight: 600, fontSize: "20px" }}
            variant="h6"
            id="tableTitle"
            component="div"
            color="#1E2685"
          >
            Costs
          </Typography>
        
          <Typography></Typography>
          {numSelected > 0 ? (
          <Typography
          sx={{ fontWeight: 200, fontSize: "16px" }}
            color="#1E2685"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (<Typography></Typography>)}
          
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack spacing={1} direction="row" sx={{ mr: 2 }}>
        <ExportExcel excelData={selectedData} fileName={"CostExport"} buttonLabel="Export Table"/>

        <Button
          disableElevation
          variant="outlined"
          size="small"
          onClick={() => setAddView(true)}
        >
          Add
        </Button>
        <Button
          variant="outlined"
          onClick={() => setShowFilters(!showFilters)}
          sx={{ minWidth: "130px", mr: 2 }}
          size="small"
        >
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function CostView() {
  const [rows, setRows] = useState([]);
  useEffect(() => retrieveCosts(), []);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [addView, setAddView] = useState(false);
  const [addViewStates, setAddViewStates] = useState(null);
  const [showFilters, setShowFilters] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [filterCost, setFilterCost] = useState({
    id: 0,
    incotermList: [],
    othersRemark: "",
    freightOfferByList: [],
    remark: "",
    statusList: ["ACTIVE"],
  });

  function retrieveCosts() {
    retrieveAllCostsApi()
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const [editCost, setEditCost] = useState({
    visible: false,
    data: null,
    index: null,
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedData(rows);
      return;
    }else{
      setSelected([]);
      setSelectedData([]);
    }
  };


  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    const selectedDataIndex = selectedData.indexOf(row);
    let newSelectedData = [];

    if (selectedDataIndex === -1) {
      newSelectedData = newSelectedData.concat(selectedData, row);
    } else if (selectedDataIndex === 0) {
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedDataIndex === selectedData.length - 1) {
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedDataIndex > 0) {
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedDataIndex),
        selectedData.slice(selectedDataIndex + 1),
      );
    }
    setSelectedData(newSelectedData);

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows, editCost],
  );

  return (
    <div>
      {addView ? (
        <AddCost
          setAddViewStates={setAddViewStates}
          addViewStates={addViewStates}
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
        />
      ) : editCost.visible ? (
        <EditCost
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editCost={editCost}
          setEditCost={setEditCost}
        />
      ) : (
        <>
          {showFilters && (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
              >
                <TextField
                  type="search"
                  id="outlined-select-incoterm"
                  select
                  fullWidth
                  label="Incoterm"
                  SelectProps={{
                    multiple: true,
                    value: filterCost.incotermList || [],
                    onChange: (e) => {
                      setFilterCost({
                        ...filterCost,
                        incotermList: e.target.value,
                      });
                    },
                  }}
                >
                  {incoterms.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="outlined-number"
                  label="Incoterm Price"
                  value={filterCost?.incotermPrice || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCost({
                      ...filterCost,
                      incotermPrice: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Profit"
                  value={filterCost?.profit || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCost({
                      ...filterCost,
                      profit: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Finance Cost"
                  value={filterCost?.financeCost || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCost({
                      ...filterCost,
                      financeCost: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Storage Cost"
                  value={filterCost?.storageCost || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCost({
                      ...filterCost,
                      storageCost: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Other Costs"
                  value={filterCost?.others || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCost({
                      ...filterCost,
                      others: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-search"
                  label="Other Costs Remark"
                  value={filterCost?.othersRemark || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterCost({
                      ...filterCost,
                      othersRemark: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Insurance Cost"
                  value={filterCost?.insuranceCost || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCost({
                      ...filterCost,
                      insuranceCost: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Freight Offer"
                  value={filterCost?.freightOffer || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCost({
                      ...filterCost,
                      freightOffer: e.target.value,
                    })
                  }
                />

                <TextField
                  type="search"
                  id="outlined-select-freight-offer-by"
                  select
                  fullWidth
                  label="Freight Offer By"
                  SelectProps={{
                    multiple: true,
                    value: filterCost.freightOfferByList || [],
                    onChange: (e) => {
                      setFilterCost({
                        ...filterCost,
                        freightOfferByList: e.target.value,
                      });
                    },
                  }}
                >
                  {freightOfferByList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="date"
                  type="date"
                  label="Freight Offer Date"
                  value={filterCost.freightOfferDate}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterCost({
                      ...filterCost,
                      freightOfferDate: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Freight Cost"
                  value={filterCost?.freightCost || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCost({
                      ...filterCost,
                      freightCost: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="First Commision"
                  value={filterCost?.firstCommision || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCost({
                      ...filterCost,
                      firstCommision: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Second Commision"
                  value={filterCost?.secondCommision || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCost({
                      ...filterCost,
                      secondCommision: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Third Commision"
                  value={filterCost?.thirdCommision || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCost({
                      ...filterCost,
                      thirdCommision: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Total Cost"
                  value={filterCost?.totalCost || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCost({
                      ...filterCost,
                      totalCost: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Net Profit"
                  value={filterCost?.netProfit || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCost({
                      ...filterCost,
                      netProfit: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-search"
                  label="Remark"
                  value={filterCost?.remark || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterCost({
                      ...filterCost,
                      remark: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Status"
                  type="search"
                  id="outlined-select-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterCost.statusList || [],
                    onChange: (e) => {
                      setFilterCost({
                        ...filterCost,
                        statusList: e.target.value,
                      });
                    },
                  }}
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <br></br>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      retrieveFilteredCostsApi(filterCost)
                        .then((response) => {
                          setRows(response?.data);
                        })
                        .catch((error) => {
                          console.log(error?.response?.data);
                        });
                    }}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      setFilterCost({
                        id: 0,
                        incotermList: [],
                        incotermPrice: null,
                        profit: null,
                        financeCost: null,
                        storageCost: null,
                        others: null,
                        othersRemark: "",
                        insuranceCost: null,
                        freightOffer: null,
                        freightOfferByList: [],
                        freightOfferDate: "",
                        freightCost: null,
                        firstCommision: null,
                        secondCommision: null,
                        thirdCommision: null,
                        totalCost: null,
                        netProfit: null,
                        remark: "",
                        statusList: ["ACTIVE"],
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box sx={{ width: "100%", mt: 2 }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                rows={rows}
                setAddView={setAddView}
                selected={selected}
                selectedData={selectedData}
              />
              <TableContainer style={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    minWidth: 750,
                    '& .MuiTableCell-head': {
                      backgroundColor: '#20315d',
                      color: 'white',
                    },
                  }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  style={{ width: "max-content" }}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          style={
                            index % 2
                              ? { background: "#f0f0f0" }
                              : { background: "white" }
                          }
                          hover
                          onClick={(event) => handleClick(event, row.id, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              onClick={(e) => {
                                setEditCost({
                                  visible: true,
                                  data: row,
                                  index: index,
                                });
                                e.preventDefault();
                              }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell align="center">{row?.id}</TableCell>
                          <TableCell align="center">
                          {row?.basePrice ? `${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.basePrice)}`: ''} {row?.basePriceCurrency}
                          </TableCell>
                          <TableCell align="center">
                          {row?.freightCost ? `${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.freightCost)}`: ''} {row?.freightCurrency}
                          </TableCell>
                          <TableCell align="center">
                            {row?.freightOfferBy}
                          </TableCell>
                          <TableCell align="center">
                            {row?.freightOfferDate
                              ? format(
                                  new Date(row?.freightOfferDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                          {row?.insuranceCost ? `${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.insuranceCost)}`: ''} {row?.insuranceCurrency}
                          </TableCell>
                          <TableCell align="center">
                          {row?.storageCost ? `${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.storageCost)}`: ''} {row?.storageCostCurrency}
                          </TableCell>
                          <TableCell align="center">
                          {row?.financeCost ? `${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.financeCost)}`: ''} {row?.financeCostCurrency}
                          </TableCell>
                          <TableCell align="center">
                          {row?.others ? `${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.others)}`: ''} {row?.othersCurrency}
                          </TableCell>
                          <TableCell align="center">
                            {row?.othersRemark}
                          </TableCell>
                          <TableCell align="center">
                          {row?.firstCommision ? `${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.firstCommision)}`: ''} {row?.firstCommisionCurrency}
                          </TableCell>
                          <TableCell align="center">
                          {row?.secondCommision ? `${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.secondCommision)}`: ''} {row?.secondCommisionCurrency}
                          </TableCell>
                          <TableCell align="center">
                          {row?.thirdCommision ? `${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.thirdCommision)}`: ''} {row?.thirdCommisionCurrency}
                          </TableCell>
                          <TableCell align="center">{row?.totalCost}</TableCell>
                          <TableCell align="center">
                          {row?.profit ? `${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.profit)}`: ''} {row?.profitCurrency}
                          </TableCell>        
                          <TableCell align="center">{row?.totalOfferCost}</TableCell>
                          <TableCell align="center">{row?.remark}</TableCell>
                          <TableCell align="center">{row?.timestamp ? format(new Date(row.timestamp), 'dd/MM/yyyy HH:mm'): ''}</TableCell>
                          <TableCell align="center">{row?.creator}</TableCell>
                          <TableCell align="center">{row?.status}</TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
        </>
      )}
    </div>
  );
}
