import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { retrieveSelectedShippingInstructionsByIdApi, retrieveShippingInstructionByIdApi } from "../../../../components/api/ShippingInstructionApiService";

const ShippingInstructionButton = ({ row, id, setEditShippingInstruction }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (loading) return; 
    setLoading(true);
    try {
      const shippingInstructionData = await retrieveSelectedShippingInstructionsByIdApi(id);
      if (!shippingInstructionData) {
        alert("No data returned from the API.");
        return;
      }
      setEditShippingInstruction({
        visible: true,
        data: shippingInstructionData?.data[0],
        index: row?.index,
      });
      console.log(shippingInstructionData?.data[0]);
    } catch (error) {
      console.error("Error retrieving shippingInstruction:", error);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <Button
      sx={{
        minWidth: 0,
        padding: "2px 4px",
        fontSize: "0.8rem",
        lineHeight: 1,
      }}
      onClick={() => {
        if (
          row?.shippingInstruction?.status === "SI Closed"
        ) {
          alert(
            "An SI connected to this SR is closed. Editing is not allowed.",
          );
          return;
        }else if (
            row?.shippingInstruction?.status === "SI Created"
          ) {
            alert(
              "An SI connected to this SR.",
            );
          }
        handleClick();
      }}
      variant="text"
      disabled={loading|| (row?.shippingInstructionRecordStatus==="PASSIVE")}
      startIcon={loading && <CircularProgress size={16} />}
    >
      {id}
    </Button>
  );
};

export default ShippingInstructionButton;
