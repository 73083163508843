import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { format, isValid } from "date-fns";
import ExportExcel from "../../../utils/excelExport";

// Pie Chart Imports
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip as ChartTooltip, Legend } from "chart.js";
import { retrieveAllWorkLogsApi, retrieveFilteredWorkLogsApi } from "../../../components/api/WorkLogApiService";
import AddWorkLog from "./AddWorkLog";
import EditWorkLog from "./EditWorkLog";
import ViewTicketDialog from "../TicketView/Dialogs/ViewTicketDialog";
Chart.register(ArcElement, ChartTooltip, Legend);

// --- Sorting Helpers ---
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// --- Table Column Definitions ---
const headCells = [
  { id: "editButton", disablePadding: true, label: "" },
  { id: "id", numeric: true, disablePadding: true, label: "ID" },
  { id: "ticketId", numeric: false, disablePadding: true, label: "Ticket ID" },
  { id: "taskType", numeric: false, disablePadding: true, label: "Task Type" },
  { id: "description", numeric: false, disablePadding: true, label: "Description" },
  { id: "timeSpent", numeric: false, disablePadding: true, label: "Time Spent (hrs)" },
  { id: "operationDate", numeric: false, disablePadding: true, label: "Log Date" },
  { id: "createdBy", numeric: false, disablePadding: true, label: "Logger" },
  { id: "recordStatus", numeric: false, disablePadding: true, label: "Record Status" },
];

// --- Enhanced Table Head ---
function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// --- Enhanced Table Toolbar ---
function EnhancedTableToolbar(props) {
  const { numSelected, showFilters, setShowFilters, selectedData, setAddView } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography
          sx={{ fontWeight: 600, fontSize: "20px" }}
          variant="h6"
          id="tableTitle"
          component="div"
          color="#1E2685"
        >
          Work Logs
        </Typography>
        {numSelected > 0 && (
          <Typography
            sx={{ fontWeight: 200, fontSize: "16px" }}
            color="#1E2685"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        )}
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack direction="row" spacing={1} sx={{ mr: 2 }}>
        <ExportExcel
          excelData={selectedData}
          fileName={"WorkLogsExport"}
          buttonLabel="Export Table"
        />
        <Button disableElevation variant="outlined" size="small" onClick={() => setAddView(true)}>
          Add
        </Button>
        <Button
          variant="outlined"
          onClick={() => setShowFilters(!showFilters)}
          sx={{ minWidth: "130px" }}
          size="small"
        >
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

// --- Pie Chart for Work Logs ---
function WorkLogPieChart({ rows, selectedDate }) {
  const filteredLogs = rows.filter((row) => {
    const logDate = new Date(row?.operationDate);
    return logDate.toDateString() === selectedDate.toDateString();
  });

  const aggregation = {};
  filteredLogs.forEach((row) => {
    const type = row?.taskType || "Other";
    aggregation[type] = (aggregation[type] || 0) + row?.timeSpent;
  });

  const labels = Object.keys(aggregation);
  const dataValues = Object.values(aggregation);
  const colors = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"];

  const data = {
    labels,
    datasets: [
      {
        data: dataValues,
        backgroundColor: colors.slice(0, labels.length),
      },
    ],
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Work Log Distribution for {selectedDate.toLocaleDateString()}
      </Typography>
      <Pie data={data} />
    </Box>
  );
}

// --- Main Combined Component ---
export default function WorkLogViewCombined() {
  // Use "rows" in place of "workLogs"
  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showFilters, setShowFilters] = useState(false);
  const [addView, setAddView] = useState(false);
  const [addViewStates, setAddViewStates] = useState(null);
  const [editWorkLog, setEditWorkLog] = useState({ visible: false, data: null });
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [viewTicket, setViewTicket] = useState({
    visible: false,
    data: null,
  });

  const handleView = (ticket) => {
    setViewTicket({ visible: true, data: ticket });
  };

  function safeFormatDate(dateValue) {
    if (!dateValue) return "";
    const d = new Date(dateValue);
    return isValid(d) ? format(d, "dd/MM/yyyy") : "";
  }

  useEffect(() => {
    const now = new Date();
    const operationDateFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate()).toISOString();
    const operationDateTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999).toISOString();
    const userMail = localStorage.getItem("username");
    const filterWorkLogs = {
        userMail,
        operationDateFrom,
        operationDateTo,
        recordStatusList: ["ACTIVE"],
      };
    retrieveWorkLogs(filterWorkLogs);
  }, []);

  // For demonstration, we’re using mock data.
 /* const retrieveWorkLogs = () => {
     retrieveAllWorkLogsApi()
          .then((response) => {
            setRows(response?.data);
          })
          .catch((error) => console.log(error.response?.data));
  };*/

const retrieveWorkLogs = (filterWorkLogs) => {
    retrieveFilteredWorkLogsApi(filterWorkLogs)
         .then((response) => {
           setRows(response?.data);
         })
         .catch((error) => console.log(error.response?.data));
 };
  // --- Sorting and Selection Handlers ---
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedData(rows);
      return;
    }
    setSelected([]);
    setSelectedData([]);
  };

  const handleClick = (event, id, row) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);

    const selectedDataIndex = selectedData.indexOf(row);
    let newSelectedData = [];
    if (selectedDataIndex === -1) {
      newSelectedData = newSelectedData.concat(selectedData, row);
    } else if (selectedDataIndex === 0) {
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedDataIndex === selectedData.length - 1) {
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedDataIndex > 0) {
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedDataIndex),
        selectedData.slice(selectedDataIndex + 1)
      );
    }
    setSelectedData(newSelectedData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleDateChange = (event) => {
    const value = event.target.value;
   let newDate = value ? new Date(value) : new Date();
   if (isNaN(newDate)) {
    newDate = new Date();
   }
    const operationDateFrom = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()).toISOString();
    const operationDateTo = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23, 59, 59, 999).toISOString();
    const userMail = localStorage.getItem("username");
    const filterWorkLogs = {
        userMail,
        recordStatusList: ["ACTIVE"],
        operationDateFrom,
        operationDateTo,
      };
    retrieveWorkLogs(filterWorkLogs);
    setSelectedDate(newDate);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const visibleRows = stableSort(rows, getComparator(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 2, p: 2, alignItems: "flex-start" }}>
      {/* Left Panel: Date Selector and Pie Chart */}
      <Box sx={{ width: "30%", minWidth: 300, display: "flex", flexDirection: "column" }}>
        <TextField
          id="date"
          type="date"
          label="Select Date"
          variant="outlined"
          fullWidth
          size="small"
          value={selectedDate.toISOString().substring(0, 10)}
          onChange={handleDateChange}
          sx={{ mb: 2 }}
          inputProps={{ min: "1999-01-01", max: new Date().toISOString().split("T")[0], }}
          InputLabelProps={{
            shrink: true,
          }}
          onKeyDown={(e) => e.preventDefault()}
        />
        <WorkLogPieChart rows={rows} selectedDate={selectedDate} />
        <Typography variant="h5" gutterBottom align="center">
        Daily Total Hours Spent: {rows.reduce((total, row) => total + (parseFloat(row.timeSpent) || 0), 0)}
        </Typography>
      </Box>

      {/* Right Panel: Table */}
      <Box sx={{ flexGrow: 1 }}>
        {addView ? (
           <AddWorkLog
           setAddViewStates={setAddViewStates}
           addViewStates={addViewStates}
           rows={rows}
           setRows={setRows}
           setAddView={setAddView}
         />
        ) : editWorkLog.visible ? (
            <EditWorkLog
            rows={rows}
            setRows={setRows}
            setAddView={setAddView}
            editWorkLog={editWorkLog}
            setEditWorkLog={setEditWorkLog}
          />
        ) : (
          <>
            {showFilters && (
              <Box sx={{ p: 2, backgroundColor: "#f5f5f5", borderRadius: 1 }}>
                <TextField fullWidth label="Search Work Logs" variant="outlined" size="small" />
              </Box>
            )}
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                selectedData={selectedData}
                setAddView={setAddView}
              />
              <TableContainer style={{ maxHeight: 500 }}>
                <Table stickyHeader sx={{ minWidth: 750 }} size={dense ? "small" : "medium"}>
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = selected.indexOf(row?.id) !== -1;
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row?.id, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row?.id}
                          selected={isItemSelected}
                          sx={{
                            cursor: "pointer",
                            backgroundColor: index % 2 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell padding="none">
                            <Button
                              onClick={(e) => {
                                setEditWorkLog({ visible: true, data: row });
                                e.stopPropagation();
                              }}
                              variant="outlined"
                              size="small"
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell align="center">{row?.id}</TableCell>
                          <TableCell align="center">
                             <Button
                                  sx={{
                                    minWidth: 0,
                                    padding: "2px 4px",
                                    fontSize: "0.8rem",
                                    lineHeight: 1,
                                  }}
                                  onClick={() => handleView(row?.ticket ? row.ticket : null)}
                                  variant="text"
                                >
                                  {row?.ticket?.id || "N/A"}
                                </Button>
                          </TableCell>
                          {viewTicket.visible && (<ViewTicketDialog
                              viewTicket={viewTicket} setViewTicket={setViewTicket}
                            />)}
                          <TableCell align="center">{row?.taskType}</TableCell>
                          <TableCell align="center">
                            <Tooltip title={row?.description} arrow>
                              <Box
                                sx={{
                                  maxWidth: 200,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {row?.description}
                              </Box>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="center">{row?.timeSpent} hrs</TableCell>
                         <TableCell align="center">{safeFormatDate(row?.operationDate)}</TableCell>
                         <TableCell align="center">{row?.createdBy?.name}</TableCell>
                          <TableCell align="center">{row?.recordStatus || "ACTIVE"}</TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={8} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </>
        )}
      </Box>
    </Box>
  );
}
