import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import {
  createItemApi,
  retrieveAllItemsApi,
  retrieveItemByIdApi,
  updateItemApi,
} from "../../../components/api/ItemApiService";
import { releaseLockApi } from "../../../components/api/LockApiService";
import { updateOfferApi } from "../../../components/api/OfferApiService";
import { retrieveQuantityByItemIdApi } from "../../../components/api/QuantityApiService";
import {
  createSampleApi,
  retrievePurposeFilteredSamplesApi,
  retrieveSampleByIdApi,
  updateSampleApi,
} from "../../../components/api/SampleApiService";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import {
  color,
  companies,
  currencies,
  incoterms,
  leafForm,
  offerStatus,
  origin,
  packagingTypes,
  paymentTerm,
  portsOfDischarge,
  portsOfLoading,
  recordStatus,
  samplePurposes,
  sampleStatus,
  status,
  type,
  units,
} from "../../../utils/menu-items";

const options = ["New", "Edit"];
function releaseLock(id) {
  // Return the promise from releaseLockApi
  return releaseLockApi("EDITVENDOROFFER", id)
    .then((response) => {
      // Optionally, you can return or process the response if needed
      return response;
    })
    .catch((error) => {
      console.log(error.response?.data);
      // Optionally, you can rethrow the error or handle it as needed
      throw error;
    });
}
const storedUserId = localStorage.getItem("userId");
const EditVendorOffer = (props) => {
  function retrieveItems() {
    retrieveAllItemsApi()
      .then((response) => {
        setItems(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveItemById(id) {
    retrieveItemByIdApi(id)
      .then((response) => {
        setItem(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveSampleById(id) {
    retrieveSampleByIdApi(id)
      .then((response) => {
        setSample(response?.data);
        console.log(id, response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveFilteredSamples(samplePurpose) {
    retrievePurposeFilteredSamplesApi(samplePurpose)
      .then((response) => {
        setFilteredSamples(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveStakeholders() {
    Promise.all([
      retrieveAllStakeholdersApi("VENDOR"),
      retrieveAllStakeholdersApi("CUSTOMER"),
    ])
      .then(([vendorResponse, customerResponse]) => {
        setStakeholders([...vendorResponse?.data, ...customerResponse?.data]);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveVendors() {
    retrieveAllStakeholdersApi("VENDOR")
      .then((response) => {
        setVendors(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveQuantity(itemId) {
    retrieveQuantityByItemIdApi(itemId)
      .then((response) => {
        console.log("QUANTITY: ", response?.data);
        setQuantity(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const { rows, setRows, editVendorOffer, setEditVendorOffer } = props;

  useEffect(() => {
    setEditVendorOffer({
      ...editVendorOffer,
      data: {
        ...editVendorOffer.data,
        itemStorage: editVendorOffer?.data?.item?.storage,
      },
    });
  }, [editVendorOffer?.data?.item]);

  useEffect(() => {
    setItem({
      ...item,
      moisture: 12.5,
    });
  }, []);

  const portOfDischargeIncoterms = [
    "C&F",
    "CFR",
    "CIF",
    "CIP",
    "CPT",
    "DAP",
    "DDP",
    "DPU",
  ];
  const portOfLoadingIncoterms = ["EXW", "FAS", "FCA", "FOB"];
  const [items, setItems] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [isNewItemOpen, setIsNewItemOpen] = useState(false);
  const [isEditItemOpen, setIsEditItemOpen] = useState(false);
  const [item, setItem] = useState([]);
  const [selectedOrigin, setSelectedOrigin] = useState("");
  const [isNewSampleOpen, setIsNewSampleOpen] = useState(false);
  const [isEditSampleOpen, setIsEditSampleOpen] = useState(false);
  const [sample, setSample] = useState([]);
  const [stakeholders, setStakeholders] = useState([]);
  const [filteredSamples, setFilteredSamples] = useState([]);

  const [selectedItem, setSelectedItem] = useState(
    editVendorOffer?.data?.item || null,
  );
  const [selectedVendor, setSelectedVendor] = useState(
    editVendorOffer?.data?.stakeholder || null,
  );

  const [selectedFirstSample, setSelectedFirstSample] = useState(
    editVendorOffer?.data?.firstSample || null,
  );
  const [selectedSecondSample, setSelectedSecondSample] = useState(
    editVendorOffer?.data?.secondSample || null,
  );
  const initialPaymentTerm = paymentTerm.find(
    (term) => term.value === editVendorOffer?.data?.paymentTerm,
  );
  const initialPortOfLoading = portsOfLoading.find(
    (term) => term.value === editVendorOffer?.data?.portOfLoading,
  );
  const initialPortOfDischarge = portsOfDischarge.find(
    (term) => term.value === editVendorOffer?.data?.portOfDischarge,
  );
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(
    initialPaymentTerm || null,
  );
  const [selectedPortOfLoading, setSelectedPortOfLoading] = useState(
    initialPortOfLoading || null,
  );
  const [selectedPortOfDischarge, setSelectedPortOfDischarge] = useState(
    initialPortOfDischarge || null,
  );
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const anchorRef = React.useRef(null);
  const anchorRef2 = React.useRef(null);
  const anchorRef3 = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedIndex2, setSelectedIndex2] = useState(0);
  const [selectedIndex3, setSelectedIndex3] = useState(0);
  const [quantity, setQuantity] = useState([]);

  const isButtonDisabled =
    ((editVendorOffer?.data?.status === "First Refusal" ||
      editVendorOffer?.data?.status === "Optioned") &&
      (!editVendorOffer?.data?.statusDeadline ||
        editVendorOffer?.data?.statusDeadline === "")) || (!editVendorOffer?.data?.offerDate);

  const handleClick = () => {
    if (selectedIndex === 0) {
      setIsNewItemOpen(true);
    } else {
      setIsEditItemOpen(true);
      retrieveItemById(editVendorOffer?.data?.item?.id);
    }
  };
  const handleClick2 = () => {
    if (selectedIndex2 === 0) {
      setIsNewSampleOpen(true);
      setIsEditSampleOpen(false);
      setSample([]);
    } else {
      setSample([]);
      setIsEditSampleOpen(true);
      setIsNewSampleOpen(false);
      retrieveSampleById(editVendorOffer?.data?.firstSample?.id);
    }
  };
  const handleClick3 = () => {
    if (selectedIndex3 === 0) {
      setIsNewSampleOpen(true);
      setIsEditSampleOpen(false);
      setSample([]);
    } else {
      setSample([]);
      setIsEditSampleOpen(true);
      setIsNewSampleOpen(false);
      retrieveSampleById(editVendorOffer?.data?.secondSample?.id);
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };
  const handleMenuItemClick2 = (event, index) => {
    setSelectedIndex2(index);
    setOpen2(false);
  };
  const handleMenuItemClick3 = (event, index) => {
    setSelectedIndex3(index);
    setOpen3(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleToggle2 = () => {
    setOpen2((prevOpen) => !prevOpen);
  };
  const handleToggle3 = () => {
    setOpen3((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleClose2 = (event) => {
    if (anchorRef2.current && anchorRef2.current.contains(event.target)) {
      return;
    }

    setOpen2(false);
  };
  const handleClose3 = (event) => {
    if (anchorRef3.current && anchorRef3.current.contains(event.target)) {
      return;
    }

    setOpen3(false);
  };

  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Edit Vendor Offer: {editVendorOffer?.data?.id}{" "}
          {editVendorOffer?.data?.approvalStatus === "Approved" && (
            <Typography variant="h5" color="error" sx={{ marginTop: "10px" }}>
              Approved offers can only be edited by managers.
            </Typography>
          )}
        </Typography>
        <Grid container item xs={12} spacing="8px">
          <Grid item xs={2} sm={1.5}>
            <Typography variant="body2">Offer Year</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOffer?.data?.offerYear}
              onChange={(e) => {
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    offerYear: e.target.value,
                    userId: storedUserId,
                    changes: "Offer Year -> " + e.target.value + ", ",
                  },
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Buying Company</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOffer?.data?.company}
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    company: e.target.value,
                  },
                })
              }
            >
              {companies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SG Approval Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              label={editVendorOffer?.data?.approvalStatus}
              disabled
            >
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={10} sm={1.5}>
            <Typography variant="body2">Offer Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOffer?.data?.offerGroupNumber}
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    offerGroupNumber: e.target.value,
                    userId: storedUserId,
                    changes: "Offer Number -> " + e.target.value + ", ",
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}></Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Record Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              label={editVendorOffer?.data?.recordStatus}
              disabled
            >
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Document Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOffer?.data?.documentStatus}
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    documentStatus: e.target.value,
                  },
                })
              }
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={1.5}>
            <Typography variant="body2">Offer Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: new Date().toISOString().split("T")[0], }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              error={!editVendorOffer?.data?.offerDate}
              helperText={!editVendorOffer?.data?.offerDate ? "Offer date is required" : ""}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editVendorOffer?.data?.offerDate
                  ? format(
                    parseISO(editVendorOffer.data.offerDate),
                    "yyyy-MM-dd",
                  )
                  : ""
              }
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    offerDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>

 <Grid item xs={12} sm={2}></Grid>

 <Grid item xs={12} sm={3}>
            <Typography variant="body2">Vendor</Typography>
            <Autocomplete
              options={vendors}
              getOptionLabel={(option) =>
                `${option?.id || ""} - ${option?.name || ""}`
              }
              value={selectedVendor}
              onChange={(event, newValue) => {
                setSelectedVendor(newValue);
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    stakeholder: newValue || {},
                  },
                });
              }}
              onOpen={retrieveVendors}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={`${selectedVendor?.id || ""} - ${selectedVendor?.name || ""
                    }`}
                />
              )}
              renderTags={() => null}
            />
          </Grid>

          <Grid item xs={12} sm={1}></Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOffer?.data?.status}
              onChange={(e) => {
                const newStatus = e.target.value;
                let newStatusDeadline = editVendorOffer?.data?.statusDeadline;
                if (newStatus !== "First Refusal" || newStatus !== "Optioned") {
                  newStatusDeadline = null;
                }

                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    status: newStatus,
                    statusDeadline: newStatusDeadline,
                  },
                });
              }}
            >
              <MenuItem
                key={editVendorOffer?.data?.status}
                value={editVendorOffer?.data?.status}
              >
                {editVendorOffer?.data?.status}
              </MenuItem>
              {offerStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status Deadline</Typography>
            <div
              style={{
                borderColor:
                  (editVendorOffer?.data?.statusDeadline === null ||
                    editVendorOffer?.data?.statusDeadline === "") &&
                    (editVendorOffer?.data?.status === "First Refusal" ||
                      editVendorOffer?.data?.status === "Optioned")
                    ? "red"
                    : "inherit",
                borderWidth:
                  (editVendorOffer?.data?.statusDeadline === null ||
                    editVendorOffer?.data?.statusDeadline === "") &&
                    (editVendorOffer?.data?.status === "First Refusal" ||
                      editVendorOffer?.data?.status === "Optioned")
                    ? "1px"
                    : "inherit",
                borderStyle:
                  (editVendorOffer?.data?.statusDeadline === null ||
                    editVendorOffer?.data?.statusDeadline === "") &&
                    (editVendorOffer?.data?.status === "First Refusal" ||
                      editVendorOffer?.data?.status === "Optioned")
                    ? "solid"
                    : "inherit",
              }}
            >
              <TextField
                id="date"
                type="date"
                inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                size="small"
                fullWidth
                disabled={
                  editVendorOffer?.data?.status !== "First Refusal" &&
                  editVendorOffer?.data?.status !== "Optioned"
                }
                value={
                  editVendorOffer?.data?.statusDeadline
                    ? format(
                      parseISO(editVendorOffer.data.statusDeadline),
                      "yyyy-MM-dd",
                    )
                    : ""
                }
                onChange={(e) =>
                  setEditVendorOffer({
                    ...editVendorOffer,
                    data: {
                      ...editVendorOffer.data,
                      statusDeadline: e.target.value
                        ? e.target.value + "T00:00:00.000+00:00"
                        : null,
                    },
                  })
                }
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={1.5}>
            <Typography variant="body2">Validity Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editVendorOffer?.data?.validityDate
                  ? format(
                    parseISO(editVendorOffer.data.validityDate),
                    "yyyy-MM-dd",
                  )
                  : ""
              }
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    validityDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}></Grid>
          
                    <Grid item xs={12} sm={2}>
                      <Typography variant="body2">SAP Vendor Code</Typography>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={editVendorOffer?.data?.stakeholder?.code ||""}
                        disabled
                      />
                    </Grid>

  <Grid item xs={12} sm={2}></Grid>

  <Grid item xs={12} sm={4}>
            <Typography variant="body2">Offer Link</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOffer?.data?.offerLink}
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    offerLink: e.target.value,
                  },
                })
              }
            />
          </Grid>

  <Grid item xs={12}>
            <Divider
              sx={{
                "&.MuiDivider-root": {
                  margin: "10px 0",
                  borderColor: "#1890ff",
                },
              }}
            />
          </Grid>

          <TableContainer component={Paper}
           sx={{
            border: "2px solid #d1dfe8",
            borderRadius: "8px", 
            overflow: "hidden", 
          }}>

      <Table   sx={{
      borderCollapse: "separate", 
      borderSpacing: "0", 
      tableLayout: "fixed",
      width: "100%", 
    }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "15%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            fontSize: "0.7rem", 
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Item
            </TableCell>
            <TableCell sx={{ width: "5%", border: "1px solid #d1dfe8",
             backgroundColor: "#98c1d9",
            fontSize: "0.7rem", 
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              ID
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
             backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Buying Grade
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Internal Grade
            </TableCell>
            <TableCell sx={{ width: "8%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Type
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Origin
            </TableCell>
            {selectedItem?.origin==="China" && <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Province
            </TableCell>}
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Crop
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Nicotine
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Sugar
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Moisture
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            fontSize: "0.7rem", 
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Current Location
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                flexWrap: "nowrap",
              }}
            >
           <Grid container xs={12} spacing="8px">
              <Grid item xs={12} sm={8}>
                <Autocomplete
                  options={items}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - Buying Grade: ${option?.buyingGrade || ""
                    } - ${option?.origin || ""} - ${option?.crop || ""}`
                  }
                  value={selectedItem}
                  onChange={(event, newValue) => {
                    setSelectedItem(newValue);
                    setEditVendorOffer({
                      ...editVendorOffer,
                      data: {
                        ...editVendorOffer.data,
                        item: newValue || {},
                      },
                    });
                    retrieveQuantity(newValue?.id);
                  }}
                  onOpen={retrieveItems}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ButtonGroup
                  variant="outlined"
                  ref={anchorRef}
                  aria-label="Button group with a nested menu"
                >
                  <Button onClick={handleClick}>
                    {options[selectedIndex]}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    sx={{
                      width: "20px !important",
                      minWidth: "0px !important",
                    }}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                disabled={index === 2}
                                selected={index === selectedIndex}
                                onClick={(event) =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
          
            </Box>
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={(selectedItem?.id || "")}
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedItem?.buyingGrade || ""
                }
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedItem?.internalGrade || ""
                }
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedItem?.type || ""
                }
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedItem?.origin || ""
                }
              />
            </TableCell>

            {selectedItem?.origin==="China" && <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedItem?.province || ""
                }
              />
            </TableCell>}

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedItem?.crop || ""
                }
              />
            </TableCell>
            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedItem?.nicotine || ""
                }
              />
            </TableCell>
            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedItem?.sugar || ""
                }
              />
            </TableCell>
            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={
                  selectedItem?.moisture || ""
                }
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
            <TextField
              variant="outlined"
              size="small"
              value={editVendorOffer?.data?.item?.storage || ""}
              fullWidth
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    itemStorage: e.target.value,
                    item: {
                      ...editVendorOffer?.data?.item,
                      storage: e.target.value,
                    },
                  },
                })
              }
            />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

<Grid item xs={12} sm={12}></Grid>
          <Grid item xs={12} sm={12}></Grid>

 <TableContainer component={Paper}
           sx={{
            border: "2px solid #d1dfe8",
            borderRadius: "8px", 
            overflow: "hidden", 
          }}>

      <Table   sx={{
      borderCollapse: "separate", 
      borderSpacing: "0", 
      tableLayout: "fixed",
      width: "100%", 
    }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            fontSize: "0.7rem", 
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Offer Sample
            </TableCell>
            <TableCell sx={{ width: "3%", border: "1px solid #d1dfe8",
             backgroundColor: "#98c1d9",
            fontSize: "0.7rem", 
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              ID
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
             backgroundColor: "#98c1d9",
            fontSize: "0.7rem", 
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
             Offer Sample Remark
            </TableCell>
            <TableCell sx={{ width: "5%", border: "1px solid #d1dfe8",
             backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Offer Sample Status
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Trial Sample
            </TableCell>
            <TableCell sx={{ width: "3%", border: "1px solid #d1dfe8",
             backgroundColor: "#98c1d9",
            fontSize: "0.7rem", 
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
               ID
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #d1dfe8",
             backgroundColor: "#98c1d9",
            fontSize: "0.7rem", 
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Trial Sample Remark
            </TableCell>
            <TableCell sx={{ width: "5%", border: "1px solid #d1dfe8",
            backgroundColor: "#98c1d9",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Trial Sample Status
            </TableCell>
         </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                flexWrap: "nowrap",
              }}
            >

<Grid container xs={12} spacing="8px">
              <Grid item xs={12} sm={8}>
                <Autocomplete
                  options={filteredSamples}
                  getOptionLabel={(option) =>
                    `ID: ${option?.id || ""}, Remark: ${option?.remark || ""
                    }, Buying Grade: ${option?.item?.buyingGrade || ""}`
                  }
                  value={selectedFirstSample}
                  onChange={(event, newValue) => {
                    setSelectedFirstSample(newValue);
                    setEditVendorOffer({
                      ...editVendorOffer,
                      data: {
                        ...editVendorOffer.data,
                        firstSample: newValue || {},
                      },
                    });
                  }}
                  onOpen={() => retrieveFilteredSamples("Offer Sample")}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={`ID: ${selectedFirstSample?.id || ""}, Remark: ${selectedFirstSample?.remark || ""
                        }, Buying Grade: ${selectedFirstSample?.item?.buyingGrade || ""
                        }`}
                    />
                  )}
                  renderTags={() => null}
                />
                {selectedFirstSample && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${selectedFirstSample?.id || ""}, Remark: ${selectedFirstSample?.remark || ""
                        }, Buying Grade: ${selectedFirstSample?.item?.buyingGrade || ""
                        }`}
                      onDelete={() => {
                        setSelectedFirstSample(null);
                        setEditVendorOffer({
                          ...editVendorOffer,
                          data: {
                            ...editVendorOffer.data,
                            firstSample: {},
                          },
                        });
                      }}
                    />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                <ButtonGroup
                  variant="outlined"
                  ref={anchorRef2}
                  aria-label="Button group with a nested menu"
                >
                  <Button onClick={handleClick2}>
                    {options[selectedIndex2]}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={open2 ? "split-button-menu" : undefined}
                    aria-expanded={open2 ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle2}
                    sx={{
                      width: "20px !important",
                      minWidth: "0px !important",
                    }}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={open2}
                  anchorEl={anchorRef2.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose2}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                disabled={index === 2}
                                selected={index === selectedIndex2}
                                onClick={(event) =>
                                  handleMenuItemClick2(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
            </Box>
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={(selectedFirstSample?.id || "")}
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={(selectedFirstSample?.remark || "")}
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOffer?.data?.firstSampleStatus}
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    firstSampleStatus: e.target.value,
                  },
                })
              }
            >
              {sampleStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
            <Grid container xs={12} spacing="8px">
              <Grid item xs={12} sm={8}>
                <Autocomplete
                  options={filteredSamples}
                  getOptionLabel={(option) =>
                    `ID: ${option?.id || ""}, Remark: ${option?.remark || ""
                    }, Buying Grade: ${option?.item?.buyingGrade || ""}`
                  }
                  value={selectedSecondSample}
                  onChange={(event, newValue) => {
                    setSelectedSecondSample(newValue);
                    setEditVendorOffer({
                      ...editVendorOffer,
                      data: {
                        ...editVendorOffer.data,
                        secondSample: newValue || {},
                      },
                    });
                  }}
                  onOpen={() => retrieveFilteredSamples("Trial Sample")}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={`ID: ${selectedSecondSample?.id || ""}, Remark: ${selectedSecondSample?.remark || ""
                        }, Buying Grade: ${selectedSecondSample?.item?.buyingGrade || ""
                        }`}
                    />
                  )}
                  renderTags={() => null}
                />
                {selectedSecondSample && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${selectedSecondSample?.id || ""}, Remark: ${selectedSecondSample?.remark || ""
                        }, Buying Grade: ${selectedSecondSample?.item?.buyingGrade || ""
                        }`}
                      onDelete={() => {
                        setSelectedSecondSample(null);
                        setEditVendorOffer({
                          ...editVendorOffer,
                          data: {
                            ...editVendorOffer.data,
                            secondSample: {},
                          },
                        });
                      }}
                    />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <ButtonGroup
                  variant="outlined"
                  ref={anchorRef3}
                  aria-label="Button group with a nested menu"
                >
                  <Button onClick={handleClick3}>
                    {options[selectedIndex3]}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={open3 ? "split-button-menu" : undefined}
                    aria-expanded={open3 ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle3}
                    sx={{
                      width: "20px !important",
                      minWidth: "0px !important",
                    }}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={open3}
                  anchorEl={anchorRef3.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose3}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                disabled={index === 2}
                                selected={index === selectedIndex3}
                                onClick={(event) =>
                                  handleMenuItemClick3(event, index)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={(selectedSecondSample?.id || "")}
              />
            </TableCell>

            <TableCell sx={{ border: "1px solid #d1dfe8" }}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                disabled
                value={(selectedSecondSample?.remark || "")}
              />
            </TableCell>

          <TableCell sx={{ border: "1px solid #d1dfe8" }}>
          <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOffer?.data?.secondSampleStatus}
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    secondSampleStatus: e.target.value,
                  },
                })
              }
            >
              {sampleStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

<Grid item xs={12} sm={12}></Grid>
          <Grid item xs={12} sm={12}></Grid>
          
          <TableContainer component={Paper}
           sx={{
            border: "2px solid #abcfff",
            borderRadius: "8px", 
            overflow: "hidden", 
          }}>

      <Table   sx={{
      borderCollapse: "separate", 
      borderSpacing: "0", 
      tableLayout: "fixed",
      width: "100%", 
    }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "9.5%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            fontSize: "0.7rem", 
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Net Weight Per Carton
            </TableCell>
            <TableCell sx={{ width: "9.5%", border: "1px solid #e0dada",
             backgroundColor: "#d7e7fc",
            fontSize: "0.7rem", 
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Number Of Cartons
            </TableCell>
            <TableCell sx={{ width: "10.5%", border: "1px solid #e0dada",
             backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Volume
            </TableCell>
            <TableCell sx={{ width: "9%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Unit
            </TableCell>
            <TableCell sx={{ width: "10.5%", border: "1px solid #e0dada",
             backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Total Amount
            </TableCell>
            <TableCell sx={{ width: "9.5%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Packaging
            </TableCell>
            <TableCell
        sx={{
          width: "19%",
          border: "1px solid #e0dada",
          backgroundColor: "#d7e7fc",
          fontSize: "0.7rem",
          padding: "4px 8px",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
      >
        Port Of Discharge
      </TableCell>
      <TableCell
        sx={{
          width: "19%",
          border: "1px solid #e0dada",
          backgroundColor: "#d7e7fc",
          fontSize: "0.7rem",
          padding: "4px 8px",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
      >
        Port Of Loading
      </TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>

          <TableCell sx={{ border: "1px solid #e0dada" }}>
            <Typography variant="body2">Net Weight Per Carton</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOffer?.data?.netWeightPerCase}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    netWeightPerCase: e.target.value,
                    totalAmount:
                      e.target.value *
                      editVendorOffer?.data?.numberOfCases *
                      editVendorOffer?.data?.finalOfferPrice,
                    volume:
                      e.target.value * editVendorOffer?.data?.numberOfCases,
                  },
                });
              }}
            />
          </TableCell>

           <TableCell sx={{ border: "1px solid #e0dada" }}>
            <Typography variant="body2">Number Of Cartons</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOffer?.data?.numberOfCases}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    numberOfCases: e.target.value,
                    totalAmount:
                      e.target.value *
                      editVendorOffer?.data?.netWeightPerCase *
                      editVendorOffer?.data?.finalOfferPrice,
                    volume:
                      e.target.value * editVendorOffer?.data?.netWeightPerCase,
                  },
                });
              }}
            />
          </TableCell>
          <TableCell sx={{ border: "1px solid #e0dada" }}>
            <Typography variant="body2">Enter Volume</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOffer?.data?.volume}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    volume: e.target.value,
                  },
                })
              }
            />
          </TableCell>
          <TableCell sx={{ border: "1px solid #e0dada" }}>
            <Typography variant="body2">Select Volume Unit</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOffer?.data?.volumeUnit}
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    volumeUnit: e.target.value,
                  },
                })
              }
            >
              {units.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </TableCell>

            <TableCell sx={{ border: "1px solid #e0dada" }}>
            <Typography variant="body2">Total Amount</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOffer?.data?.totalAmount}
              disabled
            />
          </TableCell>

            <TableCell sx={{ border: "1px solid #e0dada" }}>
            <Typography variant="body2"> Packaging</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOffer?.data?.packaging}
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    packaging: e.target.value,
                  },
                })
              }
            >
              {packagingTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </TableCell>

            <TableCell sx={{ border: "1px solid #e0dada" }}>
            <Typography variant="body2">Port Of Discharge</Typography>
            <Autocomplete
              options={portsOfDischarge}
              getOptionLabel={(option) => option?.label || ""}
              value={selectedPortOfDischarge}
              onChange={(event, newValue) => {
                setSelectedPortOfDischarge(newValue);
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    portOfDischarge: newValue?.value || "",
                  },
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  style={{
                    border:
                      portOfDischargeIncoterms.includes(
                        editVendorOffer?.data?.incoterm,
                      ) &&
                        (editVendorOffer?.data?.portOfDischarge == null ||
                          editVendorOffer?.data?.portOfDischarge === "")
                        ? "3px solid red"
                        : "none",
                    borderRadius:
                      portOfDischargeIncoterms.includes(
                        editVendorOffer?.data?.incoterm,
                      ) &&
                        (editVendorOffer?.data?.portOfDischarge == null ||
                          editVendorOffer?.data?.portOfDischarge === "")
                        ? "5px"
                        : "none",
                  }}
                />
              )}
              renderTags={() => null}
            />
          </TableCell>

          <TableCell sx={{ border: "1px solid #e0dada" }}>
            <Typography variant="body2">Port Of Loading</Typography>
            <Autocomplete
              options={portsOfLoading}
              getOptionLabel={(option) => option?.label || ""}
              value={selectedPortOfLoading}
              onChange={(event, newValue) => {
                setSelectedPortOfLoading(newValue);
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    portOfLoading: newValue?.value || "",
                  },
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  style={{
                    border:
                      portOfLoadingIncoterms.includes(
                        editVendorOffer?.data?.incoterm,
                      ) &&
                        (editVendorOffer?.data?.portOfLoading == null ||
                          editVendorOffer?.data?.portOfLoading === "")
                        ? "3px solid red"
                        : "none",
                    borderRadius:
                      portOfLoadingIncoterms.includes(
                        editVendorOffer?.data?.incoterm,
                      ) &&
                        (editVendorOffer?.data?.portOfLoading == null ||
                          editVendorOffer?.data?.portOfLoading === "")
                        ? "5px"
                        : "none",
                  }}
                />
              )}
              renderTags={() => null}
            />
          </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Box sx={{ marginTop: "16px" }}></Box>

      <Table   sx={{
      borderCollapse: "separate", 
      borderSpacing: "0", 
      tableLayout: "fixed",
      width: "100%", 
    }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "9%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              First Price
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Currency
            </TableCell>
            <TableCell sx={{ width: "9%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Final Price
            </TableCell>
            <TableCell sx={{ width: "6%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Currency
            </TableCell>
            <TableCell sx={{ width: "10%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Incoterm
            </TableCell>
            <TableCell sx={{ width: "19%", border: "1px solid #e0dada",
            backgroundColor: "#d7e7fc",
            padding: "4px 8px", 
            whiteSpace: "nowrap",
            textAlign: "center", }}>
              Payment Term
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>    
            <TableCell sx={{ border: "1px solid #e0dada" }}>
            <Typography variant="body2">Enter First Offer Price</Typography>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={editVendorOffer?.data?.firstOfferPrice}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    firstOfferPrice: e.target.value,
                  },
                });
              }}
            />
           </TableCell>

          <TableCell sx={{ border: "1px solid #e0dada" }}>
            <Typography variant="body2">Select First Offer Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOffer?.data?.firstOfferPriceCurrency}
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    firstOfferPriceCurrency: e.target.value,
                  },
                })
              }
            >
              <MenuItem
                key={editVendorOffer?.data?.firstOfferPriceCurrency}
                value={editVendorOffer?.data?.firstOfferPriceCurrency}
              >
                {editVendorOffer?.data?.firstOfferPriceCurrency}
              </MenuItem>
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </TableCell>

            <TableCell sx={{ border: "1px solid #e0dada" }}>
            <Typography variant="body2"> Final Offer Price</Typography>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={editVendorOffer?.data?.finalOfferPrice}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    finalOfferPrice: e.target.value,
                    totalAmount:
                      e.target.value *
                      editVendorOffer?.data?.numberOfCases *
                      editVendorOffer?.data?.netWeightPerCase,
                  },
                });
              }}
            />
          </TableCell>
          <TableCell sx={{ border: "1px solid #e0dada" }}>
            <Typography variant="body2"> Final Offer Currency</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOffer?.data?.finalOfferPriceCurrency}
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    finalOfferPriceCurrency: e.target.value,
                  },
                })
              }
            >
              <MenuItem
                key={editVendorOffer?.data?.finalOfferPriceCurrency}
                value={editVendorOffer?.data?.finalOfferPriceCurrency}
              >
                {editVendorOffer?.data?.finalOfferPriceCurrency}
              </MenuItem>
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </TableCell>

            <TableCell sx={{ border: "1px solid #e0dada" }}>
            <Typography variant="body2"> Incoterm</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              select
              value={editVendorOffer?.data?.incoterm}
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    incoterm: e.target.value,
                  },
                })
              }
            >
              {incoterms.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </TableCell>

            <TableCell sx={{ border: "1px solid #e0dada" }}>
            <Typography variant="body2"> Payment Term</Typography>
            <Autocomplete
              options={paymentTerm}
              getOptionLabel={(option) => option?.label || ""}
              value={selectedPaymentTerm}
              onChange={(event, newValue) => {
                setSelectedPaymentTerm(newValue);
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    paymentTerm: newValue?.value || "",
                  },
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
          </TableCell>

          </TableRow>
        </TableBody>
      </Table>

      <Box sx={{ marginTop: "16px" }}></Box>

<Table
 sx={{
  borderCollapse: "separate", // Allows borderRadius to work
  borderSpacing: "0", // Removes unwanted spacing between cells
  tableLayout: "fixed",
  width: "100%", // Ensures the table takes full width
}}
>
  <TableHead>
    <TableRow>
      <TableCell
        sx={{
          width: "31%",
          border: "1px solid #e0dada",
          backgroundColor: "#d7e7fc",
          fontSize: "0.7rem",
          padding: "4px 8px",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
      >
        Remark
      </TableCell>
      <TableCell
        sx={{
          width: "31%",
          border: "1px solid #e0dada",
          backgroundColor: "#d7e7fc",
          fontSize: "0.7rem",
          padding: "4px 8px",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
      >
        Comment
      </TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    <TableRow>
     <TableCell sx={{ border: "1px solid #e0dada" }}>
            <Typography variant="body2"> Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOffer?.data?.remark}
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    remark: e.target.value,
                  },
                })
              }
            />
          </TableCell>

           <TableCell sx={{ border: "1px solid #e0dada" }}>
            <Typography variant="body2"> Comment</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendorOffer?.data?.comment}
              onChange={(e) =>
                setEditVendorOffer({
                  ...editVendorOffer,
                  data: {
                    ...editVendorOffer.data,
                    comment: e.target.value,
                  },
                })
              }
            />
          </TableCell>
    </TableRow>
  </TableBody>
</Table>

    </TableContainer>
       
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>
              releaseLock(editVendorOffer?.data?.id)
                .then(() => {
                  setEditVendorOffer({
                    visible: false,
                    data: null,
                    index: null,
                  });
                })
                .catch((error) => {
                  toast.error("Error releasing lock:", error);
                })
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={isButtonDisabled}
            onClick={() => {
              releaseLock(editVendorOffer?.data?.id)
                .then(() => {
                  let updatedOfferData = { ...editVendorOffer.data };

                  if (editVendorOffer?.data?.approvalStatus === "Approved") {
                    updatedOfferData = {
                      ...updatedOfferData,
                      operation: "APPROVEDEDIT",
                      approvalStatus: "Not Approved"
                    };
                  }

                  updateOfferApi(updatedOfferData.id, updatedOfferData)
                    .then(() => {
                      toast.success("Vendor offer edited successfully.");
                      if (rows && setRows) {
                        const idx = rows.findIndex(row => row.id === updatedOfferData.id);
                      
                        if (idx !== -1) {
                          const newRows = [...rows];
                          newRows[idx] = updatedOfferData; 
                          setRows(newRows); 
                        }
                      }
                      setEditVendorOffer({
                        visible: false,
                        data: null,
                        index: null,
                      });
                    })
                    .catch((error) => {
                      toast.error("Error updating offer:", error);
                    });
                })
                .catch((error) => {
                  toast.error("Error releasing lock:", error);
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              releaseLock(editVendorOffer?.data?.id)
                .then(() => {
                  editVendorOffer.data.recordStatus = "PASSIVE";
                  updateOfferApi(editVendorOffer.data.id, editVendorOffer.data)
                    .then((response) => {
                      toast.success("Customer offer deleted successfully.");
                      window.location.reload();

                      navigate("/customer-offer-view");
                    })
                    .catch((error) => {
                      toast.error("Something went wrong.");
                    });
                  setEditVendorOffer({
                    visible: false,
                    data: null,
                    index: null,
                  });
                })
                .catch((error) => {
                  toast.error("Error updating offer:", error);
                });
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
      {isNewItemOpen && (
        <>
          {" "}
          <Grid item xs={12} style={{ position: "relative" }}>
            <Typography
              variant="h3"
              align="center"
              style={{
                color: "#777f87",
                position: "absolute",
                top: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#fafafb",
                padding: "0 10px",
              }}
            >
              New Item Details
            </Typography>
          </Grid>
          <Grid
            container
            spacing="12px"
            style={{
              border: "1px solid #dbd9d9",
              borderRadius: "20px",
              marginTop: "30px",
              padding: "20px",
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Grid container xs={12} spacing="8px">
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Tobacco Code</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setItem({
                      ...item,
                      tobaccoCode: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Sales Grade</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setItem({
                      ...item,
                      salesGrade: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Buying Grade</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setItem({
                      ...item,
                      buyingGrade: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Internal Grade</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setItem({
                      ...item,
                      internalGrade: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Select Origin</Typography>
                <Autocomplete
                  options={origin}
                  value={selectedOrigin}
                  onChange={(event, newValue) => {
                    const newTerm = newValue ? newValue.value : "";
                    setSelectedOrigin(newTerm);
                    setItem({
                      ...item,
                      origin: newTerm,
                    });
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
                {selectedOrigin && (
                  <Box mt={2}>
                    <Chip
                      label={
                        origin.find((term) => term.value === selectedOrigin)
                          ?.label || ""
                      }
                      onDelete={() => setSelectedOrigin("")}
                    />
                  </Box>
                )}
              </Grid>

              {selectedOrigin === "China" && (
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Province</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) =>
                      setItem({
                        ...item,
                        province: e.target.value,
                      })
                    }
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Select Type</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-type"
                  select
                  onChange={(e) =>
                    setItem({
                      ...item,
                      type: e.target.value,
                    })
                  }
                >
                  {type.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Select Leaf Form</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-leaf-form"
                  select
                  onChange={(e) =>
                    setItem({ ...item, leafForm: e.target.value })
                  }
                >
                  {leafForm.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Select Color</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-color"
                  select
                  onChange={(e) => setItem({ ...item, color: e.target.value })}
                >
                  {color.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Crop Year</Typography>
                <TextField
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setItem({
                      ...item,
                      crop: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Current Location</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-storage"
                  value={item?.storage}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      storage: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Nicotine Value</Typography>
                <TextField
                  size="small"
                  type="number"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      nicotine: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Sugar Value</Typography>
                <TextField
                  size="small"
                  type="number"
                  fullWidth
                  variant="outlined"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      sugar: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Moisture Value</Typography>
                <TextField
                  size="small"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={item?.moisture}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      moisture: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Status</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-status"
                  select
                  onChange={(e) =>
                    setItem({
                      ...item,
                      status: e.target.value,
                    })
                  }
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                size="small"
                sx={{ ml: 1.5, mt: 4, mr: 1 }}
                onClick={() => {
                  setIsNewItemOpen(false);
                  setItem([]);
                  setSelectedOrigin("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ mt: 4 }}
                onClick={() => {
                  createItemApi(item)
                    .then((response) => {
                      toast.success("Item added successfully.");
                    })
                    .catch((error) => {
                      console.log(error.response.data);
                      toast.error("Something Went Wrong.");
                    });
                  setIsNewItemOpen(false);
                  setItem([]);
                  setSelectedOrigin("");
                }}
              >
                Create Item
              </Button>
            </Grid>
          </Grid>{" "}
        </>
      )}
      {isEditItemOpen && (
        <>
          {" "}
          <Grid item xs={12} style={{ position: "relative" }}>
            <Typography
              variant="h3"
              align="center"
              style={{
                color: "#777f87",
                position: "absolute",
                top: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#fafafb",
                padding: "0 10px",
              }}
            >
              Edit Item {item?.id} Details
            </Typography>
          </Grid>
          <Grid
            container
            spacing="12px"
            style={{
              border: "1px solid #dbd9d9",
              borderRadius: "20px",
              marginTop: "30px",
              padding: "20px",
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Grid container xs={12} spacing="8px">
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Tobacco Code</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={item?.tobaccoCode || ""}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      tobaccoCode: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Sales Grade</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={item?.salesGrade || ""}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      salesGrade: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Buying Grade</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={item?.buyingGrade || ""}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      buyingGrade: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Internal Grade</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={item?.internalGrade || ""}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      internalGrade: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Select Origin</Typography>
                <Autocomplete
                  options={origin}
                  value={selectedOrigin || ""}
                  onChange={(event, newValue) => {
                    const newTerm = newValue ? newValue.value : "";
                    setSelectedOrigin(newTerm);
                    setItem({
                      ...item,
                      origin: newTerm,
                    });
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      label={item?.origin}
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
                {selectedOrigin && (
                  <Box mt={2}>
                    <Chip
                      label={
                        origin.find((term) => term.value === selectedOrigin)
                          ?.label || ""
                      }
                      onDelete={() => setSelectedOrigin("")}
                    />
                  </Box>
                )}
              </Grid>

              {(selectedOrigin === "China" || item?.origin === "China") && (
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">Province</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={item?.province || ""}
                    onChange={(e) =>
                      setItem({
                        ...item,
                        province: e.target.value,
                      })
                    }
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Select Type</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={item?.type || ""}
                  id="outlined-select-type"
                  select
                  onChange={(e) =>
                    setItem({
                      ...item,
                      type: e.target.value,
                    })
                  }
                >
                  {type.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Select Leaf Form</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={item?.leafForm || ""}
                  id="outlined-select-leaf-form"
                  select
                  onChange={(e) =>
                    setItem({ ...item, leafForm: e.target.value })
                  }
                >
                  {leafForm.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Select Color</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={item?.color || ""}
                  id="outlined-select-color"
                  select
                  onChange={(e) => setItem({ ...item, color: e.target.value })}
                >
                  {color.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Crop Year</Typography>
                <TextField
                  size="small"
                  fullWidth
                  value={item?.crop || ""}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      crop: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Current Location</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-storage"
                  value={item?.storage || ""}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      storage: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Nicotine Value</Typography>
                <TextField
                  size="small"
                  type="number"
                  fullWidth
                  value={item?.nicotine || ""}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      nicotine: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Sugar Value</Typography>
                <TextField
                  size="small"
                  type="number"
                  fullWidth
                  value={item?.sugar || ""}
                  variant="outlined"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      sugar: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Moisture Value</Typography>
                <TextField
                  size="small"
                  type="number"
                  fullWidth
                  value={item?.moisture || ""}
                  variant="outlined"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      moisture: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Status</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={item?.status}
                  id="outlined-select-status"
                  select
                  onChange={(e) =>
                    setItem({
                      ...item,
                      status: e.target.value,
                    })
                  }
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                size="small"
                sx={{ ml: 1.5, mt: 4, mr: 1 }}
                onClick={() => {
                  setIsEditItemOpen(false);
                  setItem([]);
                  setSelectedOrigin("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ mt: 4 }}
                onClick={() => {
                  updateItemApi(item.id, item)
                    .then((response) => {
                      toast.success("Item edited successfully.");
                    })
                    .catch((error) => {
                      toast.error("Something went wrong.");
                      console.log(error.response.data);
                    });
                  setIsEditItemOpen(false);
                  setItem([]);
                  setSelectedOrigin("");
                }}
              >
                Save Item
              </Button>
            </Grid>
          </Grid>{" "}
        </>
      )}

      {isNewSampleOpen && (
        <>
          {" "}
          <Grid item xs={12} style={{ position: "relative" }}>
            <Typography
              variant="h3"
              align="center"
              style={{
                color: "#777f87",
                position: "absolute",
                top: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#fafafb",
                padding: "0 10px",
              }}
            >
              New Sample Details
            </Typography>
          </Grid>
          <Grid
            container
            spacing="12px"
            style={{
              border: "1px solid #dbd9d9",
              borderRadius: "20px",
              marginTop: "30px",
              padding: "20px",
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Grid container xs={12} spacing="8px">
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sample Purpose</Typography>
                <TextField
                  id="outlined-select-status"
                  select
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!sample?.purpose}
                    helperText={!sample?.purpose ? "Sample purpose is required" : ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      purpose: e.target.value,
                    })
                  }
                >
                  {samplePurposes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Item</Typography>
                <Autocomplete
                  options={items}
                  getOptionLabel={(option) =>
                    `ID: ${option.id || ""} - Buying Grade: ${option.buyingGrade || ""
                    } - Sales Grade: ${option.salesGrade || ""} - ${option?.origin || ""
                    } - Crop: ${option.crop || ""}`
                  }
                  value={sample?.itemId}
                  onChange={(event, newValue) => {
                    setSample({
                      ...sample,
                      itemId: newValue ? newValue.id : null,
                    });
                  }}
                  onOpen={retrieveItems}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
                {sample?.itemId && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${items.find((item) => item.id === sample?.itemId)?.id ||
                        ""
                        }`}
                      style={{ marginBottom: "5px" }}
                      onDelete={() =>
                        setSample({
                          ...sample,
                          itemId: null,
                        })
                      }
                    />
                    <br />
                    <Chip
                      label={`Buying Grade: ${items.find((item) => item.id === sample?.itemId)
                        ?.buyingGrade || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                    <Chip
                      label={`Sales Grade: ${items.find((item) => item.id === sample?.itemId)
                        ?.salesGrade || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                    <Chip
                      label={`Crop: ${items.find((item) => item.id === sample?.itemId)
                        ?.crop || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sample Image Link</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      image: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sender</Typography>
                <Autocomplete
                  options={stakeholders}
                  getOptionLabel={(option) =>
                    `${option.id || ""} - ${option.type || ""} - ${option.name || ""
                    }`
                  }
                  value={sample?.senderId}
                  onChange={(event, newValue) => {
                    setSample({
                      ...sample,
                      senderId: newValue ? newValue.id : null,
                    });
                  }}
                  onOpen={retrieveStakeholders}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
                {sample?.senderId && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${stakeholders.find(
                        (stakeholder) => stakeholder.id === sample?.senderId,
                      )?.id || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                      onDelete={() =>
                        setSample({
                          ...sample,
                          senderId: null,
                        })
                      }
                    />
                    <br />
                    <Chip
                      label={`Type: ${stakeholders.find(
                        (stakeholder) => stakeholder.id === sample?.senderId,
                      )?.type || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                    <Chip
                      label={`Name: ${stakeholders.find(
                        (stakeholder) => stakeholder.id === sample?.senderId,
                      )?.name || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Receiver</Typography>
                <Autocomplete
                  options={stakeholders}
                  getOptionLabel={(option) =>
                    `${option.id || ""} - ${option.type || ""} - ${option.name || ""
                    }`
                  }
                  value={sample?.receiverId}
                  onChange={(event, newValue) => {
                    setSample({
                      ...sample,
                      receiverId: newValue ? newValue.id : null,
                    });
                  }}
                  onOpen={retrieveStakeholders}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderTags={() => null}
                />
                {sample?.receiverId && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${stakeholders.find(
                        (stakeholder) =>
                          stakeholder.id === sample?.receiverId,
                      )?.id || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                      onDelete={() =>
                        setSample({
                          ...sample,
                          receiverId: null,
                        })
                      }
                    />
                    <br />
                    <Chip
                      label={`Type: ${stakeholders.find(
                        (stakeholder) =>
                          stakeholder.id === sample?.receiverId,
                      )?.type || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                    <Chip
                      label={`Name: ${stakeholders.find(
                        (stakeholder) =>
                          stakeholder.id === sample?.receiverId,
                      )?.name || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Number Of Samples</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  fullWidth
                  value={sample?.numberOfSamples || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      numberOfSamples: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Net Weight Per Sample</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  fullWidth
                  value={sample?.netWeightPerSample || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      netWeightPerSample: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Sales Grade</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesGrade: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Sales Nicotine</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesNicotine: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Sales Sugar</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesSugar: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Sales Moisture</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesMoisture: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Sales Origin</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesOrigin: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Sales Crop</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesCrop: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Date Of Delivery</Typography>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  id="date"
                  type="date"
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      deliveryDate: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Date Of Receive</Typography>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  id="date"
                  type="date"
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      receiveDate: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">
                  Enter Courier Information
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      courier: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter AWB Number</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      awbNumber: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Remark</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      remark: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Enter Customer Feedback</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      customerFeedback: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Is Sample Approved</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      approved: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">
                  Enter SG Approval Status
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      approvalStatus: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sample Paid By</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      paidBy: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Status</Typography>
                <TextField
                  id="outlined-select-status"
                  select
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      status: e.target.value,
                    })
                  }
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                size="small"
                sx={{ ml: 1.5, mt: 4, mr: 1 }}
                onClick={() => {
                  setIsNewSampleOpen(false);
                  setSample([]);
                  setSelectedOrigin("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ mt: 4 }}
                onClick={() => {
                  createSampleApi(sample)
                    .then((response) => {
                      toast.success("Sample added successfully.");
                    })
                    .catch((error) => {
                      console.log(error.response.data);
                      toast.error("Something Went Wrong.");
                    });
                  setIsNewSampleOpen(false);
                  setSample([]);
                  setSelectedOrigin("");
                }}
              >
                Create Sample
              </Button>
            </Grid>
          </Grid>{" "}
        </>
      )}
      {isEditSampleOpen && (
        <>
          {" "}
          <Grid item xs={12} style={{ position: "relative" }}>
            <Typography
              variant="h3"
              align="center"
              style={{
                color: "#777f87",
                position: "absolute",
                top: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#fafafb",
                padding: "0 10px",
              }}
            >
              Edit Sample {sample?.id} Details
            </Typography>
          </Grid>
          <Grid
            container
            spacing="12px"
            style={{
              border: "1px solid #dbd9d9",
              borderRadius: "20px",
              marginTop: "30px",
              padding: "20px",
              boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Grid container xs={12} spacing="8px">
              <Grid item xs={12} sm={2}>
                <Typography variant="body2"> Sample Purpose</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={!sample?.purpose}
                    helperText={!sample?.purpose ? "Sample purpose is required" : ""}
                  id="outlined-select-purpose"
                  select
                  value={sample?.purpose || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      purpose: e.target.value,
                    })
                  }
                >
                  {samplePurposes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Item</Typography>
                <Autocomplete
                  options={items}
                  getOptionLabel={(option) =>
                    `ID: ${option.id || ""} - Buying Grade: ${option.buyingGrade || ""
                    } - Sales Grade: ${option.salesGrade || ""} - ${option?.origin || ""
                    } - Crop: ${option.crop || ""}`
                  }
                  value={sample?.item?.id}
                  onChange={(event, newValue) => {
                    setSample({
                      ...sample,
                      item: {
                        ...sample?.item,
                        id: newValue ? newValue.id : null,
                      },
                    });
                  }}
                  onOpen={retrieveItems}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={
                        (sample?.item?.id || "") +
                        " " +
                        (sample?.item?.buyingGrade || "") +
                        " " +
                        (sample?.item?.salesGrade || "")
                      }
                    />
                  )}
                  renderTags={() => null}
                />
                {sample?.item?.id && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${items.find((item) => item.id === sample?.item.id)?.id ||
                        ""
                        }`}
                      style={{ marginBottom: "5px" }}
                      onDelete={() =>
                        setSample({
                          ...sample,
                          item: {
                            ...sample?.item,
                            id: null,
                          },
                        })
                      }
                    />
                    <br />
                    <Chip
                      label={`Buying Grade: ${items.find((item) => item.id === sample?.item.id)
                        ?.buyingGrade || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                    <Chip
                      label={`Sales Grade: ${items.find((item) => item.id === sample?.item.id)
                        ?.salesGrade || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                    <Chip
                      label={`Crop: ${items.find((item) => item.id === sample?.item.id)
                        ?.crop || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Image URL</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.image || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      image: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sender</Typography>
                <Autocomplete
                  options={stakeholders}
                  getOptionLabel={(option) =>
                    `${option.id || ""} - ${option.type || ""} - ${option.name || ""
                    }`
                  }
                  value={sample?.sender?.id}
                  onChange={(event, newValue) => {
                    setSample({
                      ...sample,
                      sender: {
                        ...sample?.sender,
                        id: newValue ? newValue.id : null,
                      },
                    });
                  }}
                  onOpen={retrieveStakeholders}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={
                        (sample?.sender?.id || "") +
                        " " +
                        (sample?.sender?.name || "")
                      }
                    />
                  )}
                  renderTags={() => null}
                />
                {sample?.sender?.id && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${stakeholders.find(
                        (stakeholder) => stakeholder.id === sample?.sender.id,
                      )?.id || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                      onDelete={() =>
                        setSample({
                          ...sample,
                          sender: {
                            ...sample?.sender,
                            id: null,
                          },
                        })
                      }
                    />
                    <br />
                    <Chip
                      label={`Type: ${stakeholders.find(
                        (stakeholder) => stakeholder.id === sample?.sender.id,
                      )?.type || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                    <Chip
                      label={`Name: ${stakeholders.find(
                        (stakeholder) => stakeholder.id === sample?.sender.id,
                      )?.name || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Receiver</Typography>
                <Autocomplete
                  options={stakeholders}
                  getOptionLabel={(option) =>
                    `${option.id || ""} - ${option.type || ""} - ${option.name || ""
                    }`
                  }
                  value={sample?.receiver?.id}
                  onChange={(event, newValue) => {
                    setSample({
                      ...sample,
                      receiver: {
                        ...sample?.receiver,
                        id: newValue ? newValue.id : null,
                      },
                    });
                  }}
                  onOpen={retrieveStakeholders}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={
                        (sample?.receiver?.id || "") +
                        " " +
                        (sample?.receiver?.name || "")
                      }
                    />
                  )}
                  renderTags={() => null}
                />
                {sample?.receiver?.id && (
                  <Box mt={2}>
                    <Chip
                      label={`ID: ${stakeholders.find(
                        (stakeholder) =>
                          stakeholder.id === sample?.receiver.id,
                      )?.id || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                      onDelete={() =>
                        setSample({
                          ...sample,
                          receiver: {
                            ...sample?.receiver,
                            id: null,
                          },
                        })
                      }
                    />
                    <br />
                    <Chip
                      label={`Type: ${stakeholders.find(
                        (stakeholder) =>
                          stakeholder.id === sample?.receiver.id,
                      )?.type || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                    <Chip
                      label={`Name: ${stakeholders.find(
                        (stakeholder) =>
                          stakeholder.id === sample?.receiver.id,
                      )?.name || ""
                        }`}
                      style={{ marginBottom: "5px" }}
                    />
                    <br />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Number Of Samples</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  fullWidth
                  value={sample?.numberOfSamples || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      numberOfSamples: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Net Weight Per Sample</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  fullWidth
                  value={sample?.netWeightPerSample || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      netWeightPerSample: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sales Grade</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.salesGrade || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesGrade: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sales Nicotine</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={sample?.salesNicotine || ""}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesNicotine: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sales Sugar</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  value={sample?.salesSugar || ""}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesSugar: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sales Moisture</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  value={sample?.salesMoisture || ""}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesMoisture: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sales Origin</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.salesOrigin || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesOrigin: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Sales Crop</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={sample?.salesCrop || ""}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      salesCrop: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Date Of Delivery</Typography>
                <TextField
                  id="date"
                  type="date"
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    sample?.deliveryDate
                      ? format(parseISO(sample?.deliveryDate), "yyyy-MM-dd")
                      : ""
                  }
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      deliveryDate: e.target.value
                        ? e.target.value + "T00:00:00.000+00:00"
                        : null,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Date Of Receive</Typography>
                <TextField
                  id="date"
                  type="date"
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    sample?.receiveDate
                      ? format(parseISO(sample?.receiveDate), "yyyy-MM-dd")
                      : ""
                  }
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      receiveDate: e.target.value
                        ? e.target.value + "T00:00:00.000+00:00"
                        : null,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Courier</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.courier || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      courier: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">AWB Number</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.awbNumber || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      awbNumber: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Remark</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.remark || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      remark: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Customer Feedback</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.customerFeedback || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      customerFeedback: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Approved</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.approved || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      approved: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Approval Details</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.approvalDetails || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      approvalDetails: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2">Paid By</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={sample?.paidBy || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      paidBy: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2"> Status</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="outlined-select-status"
                  select
                  value={sample?.status || ""}
                  onChange={(e) =>
                    setSample({
                      ...sample,
                      status: e.target.value,
                    })
                  }
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                size="small"
                sx={{ ml: 1.5, mt: 4, mr: 1 }}
                onClick={() => {
                  setIsEditSampleOpen(false);
                  setSample([]);
                  setSelectedOrigin("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ mt: 4 }}
                onClick={() => {
                  updateSampleApi(sample?.id, sample)
                    .then((response) => {
                      toast.success("Sample edited successfully.");
                    })
                    .catch((error) => {
                      toast.error("Something went wrong.");
                      console.log(error.response.data);
                    });
                  setIsEditSampleOpen(false);
                  setSample([]);
                  setSelectedOrigin("");
                }}
              >
                Save Sample
              </Button>
            </Grid>
          </Grid>{" "}
        </>
      )}
      {isButtonDisabled && (
        <Typography style={{ color: "red", marginTop: "10px" }}>
          Please ensure all required fields are completed.
        </Typography>
      )}
    </>
  );
};

export default EditVendorOffer;
