import { ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { createCostApi, eurUsdExchangeApi } from "../../../components/api/CostApiService";
import { currencies, recordStatus } from "../../../utils/menu-items";

const AddCost = (props) => {
  const navigate = useNavigate();
  const { setAddViewStates, addViewStates, rows, setRows, setAddView } = props;

  useEffect(() => {
    setAddViewStates({
      ...addViewStates,
      basePriceCurrency: "USD",
      freightCurrency: "USD",
      insuranceCurrency: "USD",
      storageCostCurrency: "USD",
      financeCostCurrency: "USD",
      othersCurrency: "USD",
      firstCommisionCurrency: "USD",
      secondCommisionCurrency: "USD",
      thirdCommisionCurrency: "USD",
      profitCurrency: "USD",
    });
  }, []);

  const addViewStatesFieldMap = {
    basePriceCurrency: "basePrice",
    freightCurrency: "freightCost",
    insuranceCurrency: "insuranceCost",
    storageCostCurrency: "storageCost",
    financeCostCurrency: "financeCost",
    othersCurrency: "others",
    firstCommisionCurrency: "firstCommision",
    secondCommisionCurrency: "secondCommision",
    thirdCommisionCurrency: "thirdCommision",
    profitCurrency: "profit",
  };

  const toNum = (val) => parseFloat(val) || 0;

  const sumAllCosts = (data) => {
    return (
      toNum(data.basePrice) +
      toNum(data.freightCost) +
      toNum(data.insuranceCost) +
      toNum(data.storageCost) +
      toNum(data.financeCost) +
      toNum(data.others) +
      toNum(data.firstCommision) +
      toNum(data.secondCommision) +
      toNum(data.thirdCommision)
    );
  };

  // Recalculate totalCost (sum of cost fields) AND totalOfferCost (totalCost + profit)
  const recalcTotals = (data) => {
    const totalBeforeProfit = sumAllCosts(data);
    data.totalCost = totalBeforeProfit.toFixed(2);
    data.totalOfferCost = (totalBeforeProfit + toNum(data.profit)).toFixed(2);
  };
  
  const handleSetCost = (field, newValue, extraLogicFn) => {
    setAddViewStates((prev) => {
      // prev is the entire addViewStates object
      const newData = { ...prev };

      // Update the single field
      newData[field] = newValue;

      // If there's extra logic (e.g., setting freightCost from pricePerContainer)
      if (extraLogicFn) {
        extraLogicFn(newData);
      }

      // Recalc totalCost & totalOfferCost
      recalcTotals(newData);
      return newData;
    });
  };

  const handleCurrencyExchange = async (field, amount) => {
    const addViewStatesField = addViewStatesFieldMap[field];
    const currentCurrency = addViewStates[field];
    const targetCurrency = currentCurrency === "EUR" ? "USD" : "EUR";
  
    try {
      const response = await eurUsdExchangeApi(currentCurrency, amount);
      const newFloatValue = parseFloat(response?.data) || 0;
      const updatedCost = response?.data;
  
      setAddViewStates((prev) => {
        const newData = { ...prev };
        newData[addViewStatesField] = newFloatValue; 
        newData[field] = targetCurrency;
        recalcTotals(newData);
        return newData;
      });
    } catch (error) {
      setAddViewStates((prevCost) => ({
        ...prevCost,
        [addViewStatesField]: prevCost[addViewStatesField],
        [field]: prevCost[field],
      }));
      console.error(error.response.data);
    }
  };


  return (
    <>
      <Stack direction="column" spacing={1}>
        <Typography variant="h2" mb={2}>
          Create New Cost
        </Typography>
        <Grid container spacing={2}>
                {/* Base Price */}
                <Grid item xs={12} sm={2} md={1.5} lg={1}>
                  <Typography variant="body2">Base Price /KG</Typography>
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={addViewStates?.basePrice}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) =>
                      handleSetCost("basePrice", e.target.value)
                    }
             />
                </Grid>
        
                {/* Base Price Currency */}
                <Grid item xs={12} sm={2} md={1.5} lg={1}>
                  <Typography variant="body2">Currency</Typography>
                  <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      label={addViewStates?.basePriceCurrency}
                      id="outlined-select-incoterm-price-currency"
                      select
                      onChange={(e) => handleSetCost("basePriceCurrency", e.target.value)}
                    >
                      {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
              </TextField>
                </Grid>
        
                {/* Exchange Buttons (Base Price) */}
                <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                  <Typography variant="body2">Exchange</Typography>
                  <Button
                    variant="outlined"
                    disabled={
                      addViewStates?.basePriceCurrency === "USD"
                    }
                    onClick={() =>
                      handleCurrencyExchange(
                        "basePriceCurrency",
                        addViewStates?.basePrice || 0.0
                      )
                    }
                  >
                    $
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={
                      addViewStates?.basePriceCurrency === "EUR"
                    }
                    onClick={() =>
                      handleCurrencyExchange(
                        "basePriceCurrency",
                        addViewStates?.basePrice || 0.0
                      )
                    }
                  >
                    €
                  </Button>
      </Grid>
      </Grid>

      <Grid container spacing={2}>
        {/* Freight Cost (/KG) */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Freight Cost /KG</Typography>
          <TextField
              type="number"
              variant="outlined"
              size="small"
              sx={{backgroundColor:"#d6dce4", borderRadius:"4px"}}
              fullWidth
              value={addViewStates?.freightCost}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                handleSetCost("freightCost", e.target.value)
              }
            />
        </Grid>

        {/* Freight Currency */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Currency</Typography>
          <TextField
              variant="outlined"
              size="small"
              fullWidth
              label={addViewStates?.freightCurrency}
              id="outlined-select-freight-cost-currency"
              select
              onChange={(e) => handleSetCost("freightCurrency", e.target.value)}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
        </Grid>

        {/* Exchange Buttons (Freight) */}
        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
          <Typography variant="body2">Exchange</Typography>
          <Button
              variant="outlined"
              disabled={
                addViewStates?.freightCurrency === "USD"
              }
              onClick={() =>
                handleCurrencyExchange(
                  "freightCurrency",
                  addViewStates?.freightCost || 0.0
                )
              }
            >
              $
            </Button>
            <Button
              variant="outlined"
              disabled={
                addViewStates?.freightCurrency === "EUR"
              }
              onClick={() =>
                handleCurrencyExchange(
                  "freightCurrency",
                  addViewStates?.freightCost || 0.0
                )
              }
            >
              €
            </Button>
        </Grid>

        {/* Freight Offer By */}
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Freight Offer By</Typography>
          <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => handleSetCost("freightOfferBy", e.target.value)}
            />
        </Grid>

        {/* Freight Offer Date */}
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Freight Offer Date</Typography>
          <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) =>
                handleSetCost("freightOfferDate", e.target.value)
              }
            />
        </Grid>

        {/* Price Per Container */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Price / Container</Typography>
          <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.pricePerContainer}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                handleSetCost("pricePerContainer", e.target.value, (data) => {
                  const ppc = toNum(e.target.value);
                  const kg = toNum(data.kgPerContainer) || 1;
                  data.freightCost = (ppc / kg).toFixed(2);
                })
              }
            />
        </Grid>

        {/* Kg Per Container */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Kg / Container</Typography>
          <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.kgPerContainer}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                handleSetCost("kgPerContainer", e.target.value, (data) => {
                  const kg = toNum(e.target.value) || 1;
                  const ppc = toNum(data.pricePerContainer);
                  data.freightCost = (ppc / kg).toFixed(2);
                })
              }
            />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {/* Insurance Cost */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Insurance Cost /KG</Typography>
          <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.insuranceCost}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                handleSetCost("insuranceCost", e.target.value)
              }
            />
        </Grid>

        {/* Insurance Currency */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Currency</Typography>
          <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-insurance-cost-currency"
              select
              label={addViewStates?.insuranceCurrency}
              onChange={(e) => handleSetCost("insuranceCurrency", e.target.value)}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
        </Grid>

        {/* Exchange Buttons (Insurance) */}
        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
          <Typography variant="body2">Exchange</Typography>
          <Button
            variant="outlined"
            disabled={
              addViewStates?.insuranceCurrency === "USD"
            }
            onClick={() =>
              handleCurrencyExchange(
                "insuranceCurrency",
                addViewStates?.insuranceCost || 0.0
              )
            }
          >
            $
          </Button>
          <Button
            variant="outlined"
            disabled={
              addViewStates?.insuranceCurrency === "EUR"
            }
            onClick={() =>
              handleCurrencyExchange(
                "insuranceCurrency",
                addViewStates?.insuranceCost || 0.0
              )
            }
          >
            €
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {/* Storage Cost */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Storage Cost /KG</Typography>
          <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.storageCost}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                handleSetCost("storageCost", e.target.value)
              }
            />
        </Grid>

        {/* Storage Cost Currency */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Currency</Typography>
          <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-storage-cost-currency"
              select
              label={addViewStates?.storageCostCurrency}
              onChange={(e) => handleSetCost("storageCostCurrency", e.target.value)}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
        </Grid>

        {/* Exchange Buttons (Storage) */}
        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
          <Typography variant="body2">Exchange</Typography>
          <Button
             variant="outlined"
             disabled={
               addViewStates?.storageCostCurrency === "USD"
             }
             onClick={() =>
              handleCurrencyExchange(
                "storageCostCurrency",
                addViewStates?.storageCost || 0.0
              )
            }
           >
             $
           </Button>
           <Button
             variant="outlined"
             disabled={
               addViewStates?.storageCostCurrency === "EUR"
             }
             onClick={() =>
              handleCurrencyExchange(
                "storageCostCurrency",
                addViewStates?.storageCost || 0.0
              )
            }
           >
             €
           </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
            {/* First Commision */}
            <Grid item xs={12} sm={2} md={1.5} lg={1}>
              <Typography variant="body2">1st Service Fee /KG</Typography>
              <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.firstCommision}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                handleSetCost("firstCommision", e.target.value)
              }
            />
            </Grid>
          
            {/* First Commision Currency */}
            <Grid item xs={12} sm={2} md={1.5} lg={1}>
              <Typography variant="body2">Currency</Typography>
              <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              label={addViewStates?.firstCommisionCurrency}
              onChange={(e) => handleSetCost("firstCommisionCurrency", e.target.value)}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </Grid>
          
            {/* Exchange Buttons (First Commision) */}
            <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
              <Typography variant="body2">Exchange</Typography>
              <Button
                variant="outlined"
                disabled={
                  addViewStates?.firstCommisionCurrency === "USD"
                }
                onClick={() =>
                  handleCurrencyExchange(
                    "firstCommisionCurrency",
                    addViewStates?.firstCommision || 0.0
                  )
                }
              >
                $
              </Button>
              <Button
                variant="outlined"
                disabled={
                  addViewStates?.firstCommisionCurrency === "EUR"
                }
                onClick={() =>
                  handleCurrencyExchange(
                    "firstCommisionCurrency",
                    addViewStates?.firstCommision || 0.0
                  )
                }
              >
                €
              </Button>
            </Grid>
          
            {/* Second Commision */}
            <Grid item xs={12} sm={2} md={1.5} lg={1}>
              <Typography variant="body2">2nd Service Fee /KG</Typography>
              <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.secondCommision}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                handleSetCost("secondCommision", e.target.value)
              }
            />
            </Grid>
          
            {/* Second Commision Currency */}
            <Grid item xs={12} sm={2} md={1.5} lg={1}>
              <Typography variant="body2">Currency</Typography>
              <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              label={addViewStates?.secondCommisionCurrency}
              onChange={(e) => handleSetCost("secondCommisionCurrency", e.target.value)}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </Grid>
          
            {/* Exchange Buttons (Second Commision) */}
            <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
              <Typography variant="body2">Exchange</Typography>
              <Button
                  variant="outlined"
                  disabled={
                    addViewStates?.secondCommisionCurrency === "USD"
                  }
                  onClick={() =>
                    handleCurrencyExchange(
                      "secondCommisionCurrency",
                      addViewStates?.secondCommision || 0.0
                    )
                  }
                >
                  $
                </Button>
                <Button
                  variant="outlined"
                  disabled={
                    addViewStates?.secondCommisionCurrency === "EUR"
                  }
                  onClick={() =>
                    handleCurrencyExchange(
                      "secondCommisionCurrency",
                      addViewStates?.secondCommision || 0.0
                    )
                  }
                >
                  €
                </Button>
            </Grid>
          
            {/* Third Commision */}
            <Grid item xs={12} sm={2} md={1.5} lg={1}>
              <Typography variant="body2">3rd Service Fee /KG</Typography>
              <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.thirdCommision}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                handleSetCost("thirdCommision", e.target.value)
              }
            />
            </Grid>
          
            {/* Third Commision Currency */}
            <Grid item xs={12} sm={2} md={1.5} lg={1}>
              <Typography variant="body2">Currency</Typography>
              <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              label={addViewStates?.thirdCommisionCurrency}
              onChange={(e) => handleSetCost("thirdCommisionCurrency", e.target.value)}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </Grid>
          
            {/* Exchange Buttons (Third Commision) */}
            <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
              <Typography variant="body2">Exchange</Typography>
              <Button
                variant="outlined"
                disabled={
                  addViewStates?.thirdCommisionCurrency === "USD"
                }
                onClick={() =>
                  handleCurrencyExchange(
                    "thirdCommisionCurrency",
                    addViewStates?.thirdCommision || 0.0
                  )
                }
              >
                $
              </Button>
              <Button
                variant="outlined"
                disabled={
                  addViewStates?.thirdCommisionCurrency === "EUR"
                }
                onClick={() =>
                  handleCurrencyExchange(
                    "thirdCommisionCurrency",
                    addViewStates?.thirdCommision || 0.0
                  )
                }
              >
                €
              </Button>
            </Grid>
          </Grid>

                <Grid container spacing={2}>
                  {/* Finance Cost */}
                  <Grid item xs={12} sm={2} md={1.5} lg={1}>
                    <Typography variant="body2">Finance Cost /KG</Typography>
                    <TextField
                        type="number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={addViewStates?.financeCost}
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) =>
                          handleSetCost("financeCost", e.target.value)
                        }
                      />
                  </Grid>
          
                  {/* Finance Cost Currency */}
                  <Grid item xs={12} sm={2} md={1.5} lg={1}>
                    <Typography variant="body2">Currency</Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      id="outlined-select-finance-cost-currency"
                      select
                      label={addViewStates?.financeCostCurrency}
                      onChange={(e) => handleSetCost("financeCostCurrency", e.target.value)}
                    >
                      {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
          
                  {/* Exchange Buttons (Finance) */}
                  <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
                    <Typography variant="body2">Exchange</Typography>
                    <Button
                       variant="outlined"
                       disabled={
                         addViewStates?.financeCostCurrency === "USD"
                       }
                       onClick={() =>
                        handleCurrencyExchange(
                          "financeCostCurrency",
                          addViewStates?.financeCost || 0.0
                        )
                      }
                     >
                       $
                     </Button>
                     <Button
                       variant="outlined"
                       disabled={
                         addViewStates?.financeCostCurrency === "EUR"
                       }
                       onClick={() =>
                        handleCurrencyExchange(
                          "financeCostCurrency",
                          addViewStates?.financeCost || 0.0
                        )
                      }
                     >
                       €
                     </Button>
                  </Grid>
                </Grid>

      <Grid container spacing={2}>
        {/* Others */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Others /KG</Typography>
          <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.others}
              onWheel={(e) => e.target.blur()}
              onChange={(e) =>
                handleSetCost("others", e.target.value)
              }
            />
        </Grid>

        {/* Others Currency */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Currency</Typography>
          <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-other-cost-currency"
              select
              label={addViewStates?.othersCurrency}
              onChange={(e) => handleSetCost("othersCurrency", e.target.value)}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
        </Grid>

        {/* Exchange Buttons (Others) */}
        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
          <Typography variant="body2">Exchange</Typography>
          <Button
             variant="outlined"
             disabled={
               addViewStates?.othersCurrency === "USD"
             }
             onClick={() =>
              handleCurrencyExchange(
                "othersCurrency",
                addViewStates?.others || 0.0
              )
            }
           >
             $
           </Button>
           <Button
             variant="outlined"
             disabled={
               addViewStates?.othersCurrency === "EUR"
             }
             onClick={() =>
              handleCurrencyExchange(
                "othersCurrency",
                addViewStates?.others || 0.0
              )
            }
           >
             €
           </Button>
        </Grid>

        {/* Others Remark */}
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Other Costs Remark</Typography>
          <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.othersRemark || ""}
              onChange={(e) => handleSetCost("othersRemark", e.target.value)}
            />
        </Grid>
      </Grid>

 <Grid container spacing={2}>
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Total Unit Cost Before Profit /KG</Typography>
          <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  sx={{
                    // Give the overall root a background/border radius, etc.
                    backgroundColor: '#ddebf7',
                    borderRadius: '4px',
                 
                    // Then specifically target the disabled state
                    '&.Mui-disabled': {
                      opacity: 1, // remove default "disabled" dim
                      color: 'gray', // set text color
                    },
                 
                    // OR to be super safe, target the input inside the disabled root:
                    '& .MuiOutlinedInput-input.Mui-disabled': {
                      color: 'gray',
                      WebkitTextFillColor: 'gray', // for Safari
                    },
                  }}
                  disabled
                  fullWidth
                  value={addViewStates?.totalCost || ""}
                  onWheel={(e) => e.target.blur()}
                />
        </Grid>
      </Grid>

  <Grid container spacing={2}>
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Profit /KG</Typography>
          <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.profit}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => handleSetCost("profit", e.target.value)}
            />
        </Grid>

        {/* Profit Currency */}
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Currency</Typography>
          <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-profit-currency"
              select
              label={addViewStates?.profitCurrency}
              onChange={(e) => handleSetCost("profitCurrency", e.target.value)}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
        </Grid>

        {/* Exchange Buttons (Profit) */}
        <Grid item xs={12} sm={2.5} md={2} lg={1.5}>
          <Typography variant="body2">Exchange</Typography>
          <Button
            variant="outlined"
            disabled={
              addViewStates?.profitCurrency === "USD"
            }
            onClick={() =>
              handleCurrencyExchange("profitCurrency", addViewStates?.profit || 0.0)
            }
          >
            $
          </Button>
          <Button
            variant="outlined"
            disabled={
              addViewStates?.profitCurrency === "EUR"
            }
            onClick={() =>
              handleCurrencyExchange("profitCurrency", addViewStates?.profit || 0.0)
            }
          >
            €
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={2} md={1.5} lg={1}>
          <Typography variant="body2">Total Unit Cost After Profit /KG</Typography>
            <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                   sx={{
                     // Give the overall root a background/border radius, etc.
                     backgroundColor: '#9bc2e6',
                     borderRadius: '4px',
                   
                     // Then specifically target the disabled state
                     '&.Mui-disabled': {
                       opacity: 1, // remove default "disabled" dim
                       color: 'gray', // set text color
                     },
                   
                     // OR to be super safe, target the input inside the disabled root:
                     '& .MuiOutlinedInput-input.Mui-disabled': {
                       color: 'gray',
                       WebkitTextFillColor: 'gray', // for Safari
                     },
                   }}
                  disabled
                  fullWidth
                  value={addViewStates?.totalOfferCost || ""}
                  onWheel={(e) => e.target.blur()}
                />
        </Grid>
      </Grid>

      {/* ------------------- Row: Remark & Status ------------------- */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Remark</Typography>
          <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={addViewStates?.remark || ""}
              onChange={(e) => handleSetCost("remark", e.target.value)}
            />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Typography variant="body2">Status</Typography>
          <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              label={"ACTIVE"}
              select
              value={addViewStates?.recordStatus || ""}
              onChange={(e) => handleSetCost("recordStatus", e.target.value)}
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
        </Grid>
      </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button variant="outlined" onClick={() => setAddView(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const newId = Math.floor(Math.random() * 100000);
              setAddViewStates({ ...addViewStates, id: newId });
              setRows([addViewStates, ...rows]);
              setAddView(false);

              createCostApi(addViewStates)
                .then((response) => {
                  toast.success("Cost added successfully.");
                  window.location.reload();
                  navigate("/cost-view");
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Add
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default AddCost;
