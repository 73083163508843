import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { createMultipleOffersApi, retrieveMaxOfferNumberApi } from "../../../components/api/OfferApiService";
import AddVendorOffer from "./AddVendorOffer";
import toast from "react-hot-toast";

const AddVendorOfferView = (props) => {
  const {
    rows,
    setRows,
    setAddView,
    setAddViewStatesArray,
    addViewStatesArray,
    newOfferGroupNumber,
  } = props;

  const navigate = useNavigate();

  const [offerGroupNumber, setOfferGroupNumber] = useState(null);

  function getMaxOfferNumber() {
    retrieveMaxOfferNumberApi("VENDOR")
      .then((response) => {
        setOfferGroupNumber(response?.data + 1);
      })
      .catch((error) => console.log(error.response?.data));
  }

  useEffect(() => {
    getMaxOfferNumber();
  }, []);

  
  if (offerGroupNumber === null) {
    return <div>Loading...</div>;
  }

  const handleAddAnother = () => {
    addViewStatesArray.map((offer) => (offer.show = false));
    setAddViewStatesArray([
      ...addViewStatesArray,
      {
        id: Math.floor(Math.random() * 100000),

        orderGroupNumber: null,
        itemId: null,
        company: null,
        approvalStatus: null,
        status: null,
        statusDeadline: null,
        stakeholderId: null,
        estimatedCostId: null,
        firstOfferPrice: null,
        firstOfferPriceCurrency: null,
        finalOfferPrice: null,
        finalOfferPriceCurrency: null,
        firstSampleId: null,
        firstSampleStatus: null,
        secondSampleId: null,
        secondSampleStatus: null,
        paymentTerm: null,
        incoterm: null,
        numberOfCases: null,
        netWeightPerCase: null,
        volume: null,
        volumeUnit: null,
        offerDate: null,
        validityDate: null,
        remark: null,
        comment: null,
        portOfLoading: null,
        purchaseOrderId: null,
        offerLink: null,
        documentStatus: null,
        recordStatus: null,
        editingPermission: null,
        offerYear: null,
        offerType: "VENDOR",
        show: true,
      },
    ]);
  };

  const isButtonDisabled = addViewStatesArray.some(
    (record) =>
      ((record.status === "First Refusal" || record.status === "Optioned") &&
      (!record.statusDeadline || record.statusDeadline.trim() === "") ) || (!record?.offerDate),
  );

  return (
    <Stack spacing={1}>
      <Typography variant="h4" mb={2}>
        Create New Vendor Offer
      </Typography>
      {addViewStatesArray.map((offer, index) => (
        <AddVendorOffer
          key={index}
          offer={offer}
          index={index}
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          setAddViewStatesArray={setAddViewStatesArray}
          addViewStatesArray={addViewStatesArray}
          newOfferGroupNumber={offerGroupNumber}
        />
      ))}
      <Stack direction="column" spacing={1} mt={1}>
        <Stack direction="row" spacing={1} mt={1}>
          <Button variant="text" onClick={() => handleAddAnother()}>
            + Add another
          </Button>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" onClick={() => setAddView(false)}>
            Cancel
          </Button>
          <Button
            disabled={isButtonDisabled}
            variant="contained"
            onClick={async () => {
              createMultipleOffersApi(addViewStatesArray)
                .then((response) => {
                  toast.success("Vendor offer added successfully.");
                  if (rows && setRows) {
                    setRows([...addViewStatesArray, ...rows]);
                  }
                  navigate("/vendor-offer-view");
                  setAddViewStatesArray([ {
                    offerGroupNumber: null,
                    orderGroupNumber: null,
                    itemId: null,
                    company: null,
                    approvalStatus: null,
                    status: null,
                    statusDeadline: null,
                    stakeholderId: null,
                    estimatedCostId: null,
                    firstOfferPrice: null,
                    firstOfferPriceCurrency: null,
                    finalOfferPrice: null,
                    finalOfferPriceCurrency: null,
                    firstSampleId: null,
                    firstSampleStatus: null,
                    secondSampleId: null,
                    secondSampleStatus: null,
                    paymentTerm: null,
                    salesOrigin: null,
                    salesCrop: null,
                    salesNicotine: null,
                    incoterm: null,
                    numberOfCases: null,
                    netWeightPerCase: null,
                    volume: null,
                    volumeUnit: null,
                    offerDate: null,
                    validityDate: null,
                    remark: null,
                    comment: null,
                    portOfLoading: null,
                    portOfLoadingList: [],
                    portOfDischargeList: [],
                    purchaseOrderId: null,
                    offerLink: null,
                    documentStatus: null,
                    recordStatus: null,
                    editingPermission: null,
                    offerYear: null,
                    offerType: "VENDOR",
                    show: true,
                  },]);
                  setAddView(false);
                })
                .catch((error) => {
                  toast.error(error.response.data.message);
                  console.log(error.response.data);
                });
            }}
          >
            Add
          </Button>
        </Stack>
        {isButtonDisabled && (
          <Typography style={{ color: "red", marginTop: "10px" }}>
            Please ensure all required fields are completed.
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default AddVendorOfferView;
