import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { retrieveShipmentRequestByIdApi } from "../../../../components/api/ShipmentRequestApiService";

const ShipmentRequestButton = ({ row, id, setEditShipmentRequest }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (loading) return; 
    setLoading(true);
    try {
      const shipmentRequestData = await retrieveShipmentRequestByIdApi(id);
      if (!shipmentRequestData) {
        alert("No data returned from the API.");
        return;
      }
      if (
        shipmentRequestData?.data?.status === "SI Closed"
      ) {
        alert(
          "An SI connected to this SR is closed. Editing is not allowed.",
        );
        return;
      }else if (
        shipmentRequestData?.data?.status === "SI Created"
        ) {
          alert(
            "An SI connected to this SR.",
          );
        }
      setEditShipmentRequest({
        visible: true,
        data: shipmentRequestData?.data,
        index: row?.index,
      });
      console.log(shipmentRequestData);
    } catch (error) {
      console.error("Error retrieving shipmentRequest:", error);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <Button
      sx={{
        minWidth: 0,
        padding: "2px 4px",
        fontSize: "0.8rem",
        lineHeight: 1,
      }}
      onClick={() => {
        if (
          row?.shipmentRequest?.status === "SI Closed"
        ) {
          alert(
            "An SI connected to this SR is closed. Editing is not allowed.",
          );
          return;
        }else if (
            row?.shipmentRequest?.status === "SI Created"
          ) {
            alert(
              "An SI connected to this SR.",
            );
          }
        handleClick();
      }}
      variant="text"
      disabled={loading || (row?.shipmentRequestRecordStatus==="PASSIVE")}
      startIcon={loading && <CircularProgress size={16} />}
    >
      {id}
    </Button>
  );
};

export default ShipmentRequestButton;
