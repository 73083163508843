import { apiClient } from "./ApiClient";

export const retrieveAllShippingInstructionsApi = () =>
  apiClient.get(`/shipment-view/shipping-instructions`);
export const retrieveFilteredShippingInstructionsApi = (shippingInstruction) =>
  apiClient.post(
    `/shipment-view/shipping-instruction/filter`,
    shippingInstruction,
  );
export const createShippingInstructionApi = (shippingInstruction) =>
  apiClient.post(
    `/shipment-view/shipping-instruction/add`,
    shippingInstruction,
  );
export const createMultipleShippingInstructionsApi = (
  shippingInstructionList,
) =>
  apiClient.post(
    `/shipment-view/shipping-instruction/add-multiple`,
    shippingInstructionList,
  );
export const updateShippingInstructionApi = (
  shippingInstructionId,
  shippingInstruction,
) =>
  apiClient.put(
    `/shipment-view/shipping-instruction/edit/${shippingInstructionId}`,
    shippingInstruction,
  );
export const updateMultipleShippingInstructionsApi = (
  shippingInstructionList,
) =>
  apiClient.put(
    `/shipment-view/shipping-instruction/edit/shipping-instruction-list`,
    shippingInstructionList,
  );
export const deleteShippingInstructionsApi = (shippingInstructionList) =>
  apiClient.put(
    `/shipment-view/shipping-instruction/delete-list`,
    shippingInstructionList,
  );
export const retrieveSelectedShippingInstructionsByIdApi = (
  shippingInstructionIdList,
) =>
  apiClient.post(
    `/shipment-view/shipping-instruction/get-selected`,
    shippingInstructionIdList,
  );
  export const retrieveShippingInstructionByIdApi = (shippingInstructionId) => apiClient.get(`/shipment-view/shipping-instruction/get/${shippingInstructionId}`);

export const retrieveMaxShippingInstructionNumberApi = (siType, currentYear) =>
  apiClient.post(`/shipment-view/shipping-instruction/max-si-number/${siType}`, currentYear);
